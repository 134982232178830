export default ({ status, tokenSale }) => {
  if (status === 'SUCCESSFUL' || status === 'SOLDOUT') return '100'
  else {
    return tokenSale
      ? ((tokenSale.tokensSold * 100) / tokenSale.tokenSaleCap)
          .toFixed(1)
          .toString()
      : '0'
  }
}
