import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

export default ({ className, isOpen, nickname }) => (
  <Modal className={className} isOpen={isOpen} size="lg">
    <ModalHeader>KYC Incomplete</ModalHeader>
    <ModalBody>
      <p>Dear {nickname}, </p>

      <p>
        Thank you for your patronage and support for the setting up of Starbase!
        <br />
        The groundwork is nearing completion and we can look forward to exciting
        projects getting launched through our platform. You can keep up to date
        with the project's progress on our{' '}
        <a
          href="https://blog.starbase.co/"
          target="_blank"
          rel="noopener noreferrer"
        >
          blog
        </a>
        .
      </p>

      <p>
        There is a matter that needs your assistance on:
        <br />
        As your contribution <strong>exceeds USD $3,000.00</strong> during the
        November 2017 Starbase Token Generation Event (ICO), your profile needs
        to be submitted for KYC and AML check through our partner,{' '}
        <a
          href="https://bitcoinsuisse.ch/buy-sell#Form"
          target="_blank"
          rel="noopener noreferrer"
        >
          Bitcoin Suisse AG
        </a>
        . This is nothing to be alarmed about as it is a an industry wide
        regulation that all ICOs need to comply.
      </p>

      <p>
        Nonetheless, we sincerely apologize for this inconvenience. Hence, we
        will reward you with bonus STAR tokens upon successfully completing your
        profile submission through Bitcoin Suisse AG.
      </p>
      <p>
        Rewards amounts are as follows:
        <br />
        <table class="table table-sm">
          <thead class="thead-light">
            <tr>
              <th scope="col">For contributions of</th>
              <th scope="col">Reward</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>$3,000 to $25,000 USD</td>
              <th>10,000 STAR</th>
            </tr>

            <tr>
              <td>$25,000 to $100,000 USD</td>
              <th>20,000 STAR</th>
            </tr>

            <tr>
              <td>Above $100,000 USD</td>
              <th>50,000 STAR</th>
            </tr>
          </tbody>
        </table>
      </p>

      <p>
        Please be advised that you have a{' '}
        <strong>time limit of 6 months</strong> to submit your profile to the
        additional KYC. Meanwhile, your access to contribute to projects hosted
        by Starbase Platform is suspended until all KYC checks are successfully
        passed.
      </p>
      <p>
        Thank you for your understanding and we look forward to your continued
        support!
      </p>

      <p>
        <hr />
        <a
          href="https://bitcoinsuisse.ch/buy-sell#Form"
          class="btn btn-warning btn-lg btn-block"
        >
          Redirect me to Bitcoin Suisse KYC Form
        </a>
        <small>
          By clicking in this button you agree that you have to cooperate with
          Bitcoin Suisse AG and provide requested documents and information in a
          timely and efficient manner, otherwise your request for registration
          on the Starbase Platform can be rejected and you will not be able to
          register on the Starbase Platform now or at any time in the future.
        </small>
      </p>
    </ModalBody>
  </Modal>
)
