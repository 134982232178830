// IMPORTANT NOTE:
// Both documentTypesOptions.js in frontend and backend need to be kept identical.
// If you modify this file, be sure to apply the same changes to the other one.

export default [
  {
    // image: { src: bank },
    label: 'Bank statement',
    value: '1',
  },
  {
    // image: { src: card },
    label: 'Credit card statement',
    value: '2',
  },
  {
    // image: { src: house },
    label: 'House insurance certificate',
    value: '3',
  },
  {
    // image: { src: mortgage },
    label: 'Mortgage statement',
    value: '4',
  },
  {
    // image: { src: tax },
    label: 'Tax bill',
    value: '5',
  },
  {
    // image: { src: bill },
    label: 'Utility bill',
    value: '6',
  },
]
