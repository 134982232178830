import saveAs from 'file-saver'

import {
  DELETE_ACCOUNT_FAILURE,
  DELETE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_SUCCESS,
  FETCH_CURRENT_USER_FAILURE,
  FETCH_CURRENT_USER_REQUEST,
  FETCH_CURRENT_USER_SUCCESS,
  FETCH_MY_DATA_FAILURE,
  FETCH_MY_DATA_REQUEST,
  FETCH_MY_DATA_SUCCESS,
  POST_REQUEST_ACCOUNT_DELETION_FAILURE,
  POST_REQUEST_ACCOUNT_DELETION_REQUEST,
  POST_REQUEST_ACCOUNT_DELETION_SUCCESS,
  REQUEST_ETHEREUM_ADDRESS_IS_USED,
  REQUEST_ETHEREUM_ADDRESS_IS_USED_SUCCESS,
  REQUEST_ETHEREUM_ADDRESS_IS_USED_FAILURE,
} from '../types'

import * as api from '../../api'
import auth from '../../app/auth'
import captureException from '../../sentryHelpers/captureException'
import { toUserProfile } from '../routerActions'

export const fetchCurrentUser = () => dispatch => {
  // No need to fetchCurrentUser() for anonymous visitors.
  // Removes error on public pages when user hasn't signed in with Auth0:
  if (!auth.isAuthenticated()) return

  dispatch({ type: FETCH_CURRENT_USER_REQUEST })

  return api
    .fetchCurrentUser()
    .then(payload => dispatch({ type: FETCH_CURRENT_USER_SUCCESS, payload }))
    .catch(error => {
      captureException(error)
      dispatch({ type: FETCH_CURRENT_USER_FAILURE, error: error.message })
    })
}

export const requestEthereumAddressIsUsed = ({
  ethereumAddress,
}) => dispatch => {
  dispatch({ type: REQUEST_ETHEREUM_ADDRESS_IS_USED })

  return api
    .requestEthereumAddressIsUsed({ ethereumAddress })
    .then(payload =>
      dispatch({ type: REQUEST_ETHEREUM_ADDRESS_IS_USED_SUCCESS, payload })
    )
    .catch(error => {
      captureException(error)
      dispatch({
        type: REQUEST_ETHEREUM_ADDRESS_IS_USED_FAILURE,
        error: error.message,
      })
    })
}

export const requestAccountDeletion = () => async dispatch => {
  dispatch({ type: POST_REQUEST_ACCOUNT_DELETION_REQUEST })

  return api
    .requestDeletionEmail()
    .then(() => {
      dispatch({ type: POST_REQUEST_ACCOUNT_DELETION_SUCCESS })
    })
    .catch(error => {
      captureException(error)
      dispatch({
        type: POST_REQUEST_ACCOUNT_DELETION_FAILURE,
        error: error.message,
      })
    })
}

export const deleteAccount = ({ token }) => async dispatch => {
  dispatch({ type: DELETE_ACCOUNT_REQUEST })

  return api
    .deleteAccount({ token })
    .then(() => {
      dispatch({ type: DELETE_ACCOUNT_SUCCESS })
      dispatch(toUserProfile())
    })
    .catch(error => {
      alert(
        'Sorry, we were not able to delete your profile, something went wrong on your request. Please contact us: support@starbase.co'
      )
      captureException(error)
      dispatch({
        type: DELETE_ACCOUNT_FAILURE,
        error: error.message,
      })
      dispatch(toUserProfile())
    })
}

export const fetchMyData = () => async dispatch => {
  dispatch({ type: FETCH_MY_DATA_REQUEST })

  return api
    .fetchMyData()
    .then(res => {
      const blob = new Blob([JSON.stringify(res, null, 2)], {
        type: 'text/plain;charset=utf-8',
      })
      saveAs(blob, 'starbase-myData.txt')
      dispatch({ type: FETCH_MY_DATA_SUCCESS })
    })
    .catch(error => {
      captureException(error)
      dispatch({
        type: FETCH_MY_DATA_FAILURE,
        error: error.message,
      })
    })
}
