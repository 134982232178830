import { connect } from 'react-redux'

import selectIsUserRegistered from 'store/selectors/selectIsUserRegistered'
import { deleteAccount } from 'store/currentUser/currentUserActions'
import { toProfile } from 'store/routerActions'

import DeleteUserProfileModal from './DeleteUserProfileModal'

const mapStateToProps = ({
  location: {
    payload: { jwtToken },
  },
  ...state
}) => ({
  isOpen: !!(jwtToken && selectIsUserRegistered(state)),
  jwtToken,
  user: state.currentUser.user,
})

const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = ({ isOpen, jwtToken, user }, { dispatch }) => ({
  isOpen,
  onCancel: () => dispatch(toProfile()),
  onConfirmDeletion: () => dispatch(deleteAccount({ token: jwtToken })),
  user,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(DeleteUserProfileModal)
