import React from 'react'
import styled from 'styled-components/macro'

const CurrencySwitchLink = styled.div`
  color: ${props => props.theme.color.dark};
  cursor: pointer;
  position: absolute;
  right: 17px;
  top: 132px;
  &:hover {
    color: ${props => props.theme.color.iconLinkHover};
  }
  @media (min-width: 400px) {
    top: 0;
  }
`

const CurrencySwitchIcon = styled.i`
  position: relative;
  top: 1px;
  font-size: 12px;
`

const CurrencySwitch = ({ purchaseCurrency, useEth, useStar }) => (
  <CurrencySwitchLink onClick={purchaseCurrency === 'ETH' ? useStar : useEth}>
    <CurrencySwitchIcon className="icon-switch" />{' '}
    {purchaseCurrency === 'ETH' ? 'STAR' : 'ETH'}{' '}
  </CurrencySwitchLink>
)

export default CurrencySwitch
