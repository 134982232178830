import { connect } from 'react-redux'
import Balances from './Balances'

const emptyObject = {}

const mapStateToProps = ({
  balances: { items },
  currentUser: { user = emptyObject },
}) => {
  return {
    items,
    isUserRegistered: !!user.id,
    user,
  }
}

const mapDispatchToProps = () => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Balances)
