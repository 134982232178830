import {
  CLOSE_MODAL,
  FORGET_MODAL,
  OPEN_MODAL,
  SET_ACTIVE_TOAST,
  TOGGLE_USER_DROPDOWN_MENU,
} from '../types'

import omit from 'lodash/omit'

const initialState = {
  header: {
    userDropdownMenu: false,
  },
  toast: {
    activeToasts: [],
  },
  modals: {},
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CLOSE_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          [payload]: { isDismissed: true },
        },
      }

    case FORGET_MODAL:
      return {
        ...state,
        modals: omit(state.modals, payload),
      }

    case OPEN_MODAL:
      return {
        ...state,

        // Force the modal from the payload to the end of the list by
        // filtering it out of the state then adding it again.
        // Otherwise, redux updates it in-place (performance optimization):
        modals: {
          ...omit(state.modals, payload),
          [payload]: { isDismissed: false },
        },
      }

    case TOGGLE_USER_DROPDOWN_MENU:
      return {
        ...state,
        header: {
          ...state.header,
          userDropdownMenu: !state.header.userDropdownMenu,
        },
      }

    case SET_ACTIVE_TOAST:
      return {
        ...state,
        toast: {
          ...state.toast,
          activeToasts: [...state.toast.activeToasts, payload.toastId],
        },
      }

    default:
      return state
  }
}
