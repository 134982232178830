import captureException from '../../../sentryHelpers/captureException'
import { postCancelledPurchase } from '../../../api'

import {
  CANCEL_PURCHASE_REQUEST,
  CANCEL_PURCHASE_SUCCESS,
  CANCEL_PURCHASE_FAILURE,
} from '../../types'

const cancelPurchase = ({ purchaseId }) => async dispatch => {
  dispatch({ type: CANCEL_PURCHASE_REQUEST, purchaseId })

  return postCancelledPurchase({
    purchaseId,
  })
    .then(({ purchase }) => {
      dispatch({ type: CANCEL_PURCHASE_SUCCESS, purchase })
    })
    .catch(error => {
      captureException(error)
      dispatch({ type: CANCEL_PURCHASE_FAILURE, error: error.message })
    })
}

export default cancelPurchase
