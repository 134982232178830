import {
  FETCH_BALANCES_DATA_SUCCESS,
  FETCH_BALANCES_DATA_FAILURE,
} from '../types'

const initialState = {
  error: null,
  isLoading: true,
  items: [],
}

export default (state = initialState, { type, payload, error }) => {
  switch (type) {
    case FETCH_BALANCES_DATA_SUCCESS:
      return {
        ...state,
        items: payload.balances,
        isLoading: false,
      }

    case FETCH_BALANCES_DATA_FAILURE:
      return { ...state, error, isLoading: false }

    default:
      return state
  }
}
