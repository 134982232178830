import Link from 'redux-first-router-link'
import React from 'react'
import styled from 'styled-components/macro'

import { toProjectDashboard } from 'store/routerActions'

const BackdropOverlay = styled.div`
  background: rgba(113, 106, 202, 0.41);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
`

const Dropdown = styled.div`
  color: ${props => props.theme.color.white};
  i.angle.down.icon {
    opacity: 0.5;
  }
  &.visible {
    i.angle.down.icon {
      opacity: 1;
    }
  }
  img {
    border-radius: 50%;
    width: 32px;
  }
  svg {
    fill: white;
    opacity: ${props => (props.isVisible ? '1' : '.5')};
  }
`
const UserName = styled.span`
  font-size: 14px;
  font-weight: 500;
  margin-left: 8px;

  svg {
    height: 12px;
    left: 1px;
    position: relative;
    top: -1px;
    width: 12px;
  }
`
const DropdownToggle = styled.div`
  cursor: pointer;
  position: relative;
  z-index: 10;
`
const DropdownMenu = styled.div`
  background-color: ${props => props.theme.color.white};
  border-radius: 0;
  cursor: pointer;
  left: -100%;
  margin: 0 -15px;
  overflow: hidden;
  position: absolute;
  right: 0%;
  text-align: center;
  top: 93px;
  z-index: 999;

  @media (min-width: ${props => props.theme.mediaQueries.md}) {
    border-radius: ${props => props.theme.borderRadius.button};
    left: initial;
    right: 17px;
    top: 45px;
  }
`
const DropdownItem = styled.div`
  border-top: ${props =>
    props.divider ? `1px solid ${props.theme.color.light}` : 'none'};
  color: ${props => props.theme.color.dark};
  padding: ${props => (props.header ? '20px 50px' : '0 16px')};

  ${props =>
    props.body
      ? `
      color: ${props => props.theme.color.dark};
      font-size: ${props => props.theme.font.actionFontSize};
      text-align: left;
      height: 48px;
      line-height:48px;
      font-weight:500;
      cursor: pointer;
      &:hover {
        background: ${props => props.theme.color.lighter};
      }
     `
      : ''};

  img {
    border-radius: 50%;
    margin-bottom: 12px;
    width: 58px;
  }

  &:hover {
    background: ${props => props.theme.color.lighter};
  }
`
const Name = styled.div`
  color: ${props => props.theme.color.dark};
  font-size: 18px;
  margin-bottom: 2px;
`
const UserEmail = styled.div`
  color: ${props => props.theme.color.steel};
  font-size: 14px;
`

const StyledLink = styled(Link)`
  color: ${props => props.theme.color.dark};
  font-size: 1.07rem;
  font-weight: 400;
  text-decoration: none;

  &:hover {
    color: ${props => props.theme.color.primaryPurple};
    text-decoration: none;
  }
`

const UserProfileMenu = ({
  hasProject,
  isVisible,
  logout,
  projectOwnedSlug,
  toggleDropdown,
  toUserProfile,
  user,
}) =>
  user && (
    <Dropdown isVisible={isVisible} onClick={toggleDropdown}>
      <DropdownToggle>
        <img alt="User Profile" src={user.photo} />
        <UserName>
          {user.name}
          <svg viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
            <path d="M1395 736q0 13-10 23l-466 466q-10 10-23 10t-23-10l-466-466q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l393 393 393-393q10-10 23-10t23 10l50 50q10 10 10 23z" />
          </svg>
        </UserName>
      </DropdownToggle>
      {isVisible && (
        <div>
          <BackdropOverlay />
          <DropdownMenu>
            <DropdownItem header onClick={toUserProfile}>
              <img alt="User Profile" src={user.photo} />
              <Name>{user.name}</Name>
              <UserEmail>{user.email}</UserEmail>
            </DropdownItem>
            <DropdownItem divider />
            {hasProject && (
              <StyledLink
                text="Manage my project"
                to={toProjectDashboard({
                  slug: projectOwnedSlug,
                })}
              >
                <DropdownItem body>Manage my project</DropdownItem>
              </StyledLink>
            )}
            <DropdownItem divider />
            <DropdownItem body onClick={logout}>
              Sign out
            </DropdownItem>
          </DropdownMenu>
        </div>
      )}
    </Dropdown>
  )

export default UserProfileMenu
