import 'whatwg-fetch'
import Cookies from 'js-cookie'
import { SERVER_ERROR } from './errors'

import captureException from './sentryHelpers/captureException'
import detectEnvironment from './detectEnvironment'

const handleGetResponse = async res => {
  if (res.ok) return Promise.resolve(await res.json())

  const error = (await res.json()).error
  if (error) {
    captureException(error)
    return Promise.reject(new Error(error))
  }

  captureException(SERVER_ERROR)
  return Promise.reject(new Error(SERVER_ERROR))
}

export const authorizedGet = ({ url }) => {
  const idToken = Cookies.get('id_token')
  return fetch(url, {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    headers: { authorization: idToken ? `Bearer ${idToken}` : '' },
  }).then(handleGetResponse)
}

const handlePostResponse = async res => {
  if (res.status === 403 || res.status === 422 || res.status === 500) {
    const { error } = await res.json()
    console.error(error)
    throw new Error(error)
  }
  if (!res.ok) {
    const unknownErrorMessage = 'Server error, please try again later.'
    console.error(unknownErrorMessage)
    throw new Error(unknownErrorMessage)
  }
  return await res.json()
}

const authorizedPost = ({ url, data }) => {
  const idToken = Cookies.get('id_token')
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      authorization: `Bearer ${idToken}`,
      'Content-type': 'application/json',
    },
  }).then(handlePostResponse)
}

const authorizedDelete = ({ url, data }) => {
  const idToken = Cookies.get('id_token')
  return fetch(url, {
    method: 'DELETE',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      authorization: `Bearer ${idToken}`,
      'Content-type': 'application/json',
    },
  }).then(handlePostResponse)
}

const authorizedPut = ({ url, data }) => {
  const idToken = Cookies.get('id_token')
  return fetch(url, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      authorization: `Bearer ${idToken}`,
      'Content-type': 'application/json',
    },
  }).then(handlePostResponse)
}

export const fetchAllBounties = () => authorizedGet({ url: `/api/bounties` })

export const fetchProjectBounties = ({ projectSlug }) =>
  authorizedGet({ url: `/api/bounties/${projectSlug}` })

export const fetchCurrentUser = () =>
  authorizedGet({ url: '/api/users/current' })

export const fetchAllowedCountries = () =>
  authorizedGet({ url: '/api/countries' })

const valuesToFormData = (values = {}) => {
  const formData = new FormData()
  formData.append('values', JSON.stringify(values))
  return formData
}

export const postProfileForm = ({
  day,
  month,
  year,
  pepEndDay,
  pepEndMonth,
  pepEndYear,
  pepRelationship,
  pepRelationshipOther,
  pepStartDay,
  pepStartMonth,
  pepStartYear,
  // Remove residencyProofFile from being sent to backend:
  // (send only residencyProof)
  // eslint-disable-next-line no-unused-vars
  residencyProofFile,
  ...rest
}) => {
  const idToken = Cookies.get('id_token')
  const values = {
    birthDate: `${year}-${month}-${day}`,
    pepRelationship:
      pepRelationship === 'Other' ? pepRelationshipOther : pepRelationship,
    pepStartDate: `${pepStartYear}-${pepStartMonth}-${pepStartDay}`,
    pepEndDate: `${pepEndYear}-${pepEndMonth}-${pepEndDay}`,
    ...rest,
  }
  const formData = valuesToFormData(values)

  return fetch('/api/users', {
    method: 'POST',
    body: formData,
    headers: {
      Accept: 'application/json',
      authorization: `Bearer ${idToken}`,
    },
  }).then(handlePostResponse)
}

export const postNewCommentForm = newCommentForm =>
  authorizedPost({
    url: '/api/comments',
    data: newCommentForm,
  })

export const fetchCommentableComments = ({ commentableId, commentableType }) =>
  authorizedGet({ url: `/api/comments/${commentableType}/${commentableId}` })

export const postPendingPurchase = ({ purchase }) =>
  authorizedPost({ url: '/api/purchases/pending', data: purchase })

export const postCancelledPurchase = ({ purchaseId }) =>
  authorizedPost({ url: '/api/purchases/cancelled', data: { id: purchaseId } })

export const postOnfidoCreateApplicant = ({ day, month, year, ...rest }) =>
  authorizedPost({
    url: '/api/onfido/create/applicant',
    data: { birthDate: `${year}-${month}-${day}`, ...rest },
  })

export const postOnfidoCreateToken = ({ onfidoApplicantId }) =>
  authorizedPost({
    url: '/api/onfido/create/token',
    data: { onfidoApplicantId },
  })

export const postOnfidoCreateCheck = ({ onfidoApplicantId }) =>
  authorizedPost({
    url: '/api/onfido/create/check',
    data: { onfidoApplicantId },
  })

export const requestDeletionEmail = () =>
  authorizedPost({ url: '/api/users/requestDeletionEmail' })

export const deleteAccount = ({ token }) =>
  authorizedDelete({ url: `/api/users/current?token=${token}` })

export const fetchMyData = () => authorizedGet({ url: '/api/users/mydata' })

export const requestEthereumAddressIsUsed = ({ ethereumAddress }) =>
  authorizedPost({
    url: `/api/users/checkEthereumAddress`,
    data: { ethereumAddress },
  })

export const fetchVoucherDefinition = ({ tokenSaleId }) =>
  authorizedGet({ url: `/api/vouchers/sale/${tokenSaleId}/definition` })

export const fetchVoucherCode = ({ tokenSaleId }) =>
  authorizedGet({ url: `/api/vouchers/sale/${tokenSaleId}/code` })

export const postVoucherCodeForm = voucherForm =>
  authorizedPost({
    url: '/api/vouchers/code/',
    data: voucherForm,
  })

export const postValidateVoucherCode = ({
  tokenSaleId,
  code,
  purchaseValue,
  tokenPrice,
}) =>
  authorizedPost({
    url: '/api/vouchers/code/validate',
    data: { tokenSaleId, code, purchaseValue, tokenPrice },
  })

export const postBountyClaim = bountyClaimForm =>
  authorizedPost({
    url: '/api/bounties/claims',
    data: bountyClaimForm,
  })

export const fetchStakeDefinition = ({ projectSlug }) =>
  authorizedGet({ url: `/api/stakes/definitions/${projectSlug}` })

export const postAcceptedStake = data =>
  authorizedPost({
    url: '/api/stakes/accepted',
    data,
  })

export const fetchStarEthSwapRate = () =>
  authorizedGet({ url: `/api/swap/rate` })

export const postEthSwapCost = data =>
  authorizedPost({
    url: '/api/swap/cost',
    data,
  })

export const postRating = data =>
  authorizedPost({
    url: '/api/rating',
    data,
  })

/* PROJECT OWNER ACTIONS */

export const fetchProjectDashboard = ({ slug }) =>
  authorizedGet({ url: `/api/project/dashboard/${slug}` })

export const putAcceptBountyClaim = data =>
  authorizedPut({
    url: '/api/bounties/claims/accept',
    data,
  })

export const putRejectBountyClaim = data =>
  authorizedPut({
    url: '/api/bounties/claims/reject',
    data,
  })

export const putUpdateProject = data =>
  authorizedPut({
    url: `/api/project/${data.slug}`,
    data,
  })

export const postCreateBountyCampaign = data =>
  authorizedPost({
    url: `/api/bounties`,
    data,
  })

export const postCreateBountyTask = data =>
  authorizedPost({
    url: `/api/bounties/tasks`,
    data,
  })

export const deleteBountyTask = bountyId =>
  authorizedDelete({ url: `/api/bounties/tasks/${bountyId}` })

const anonymousPost = ({ url, data }) =>
  fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
  }).then(res => res.json())

export const postAnalyticsEvent = (name, data) =>
  anonymousPost({
    url: `/api/analytics`,
    data: {
      ...data,
      eventName: name,
      environment: detectEnvironment(),
    },
  })
