import {
  CLEAR_DASHBOARD_ERROR,
  FETCH_DASHBOARD_DATA_SUCCESS,
  FETCH_DASHBOARD_DATA_FAILURE,
} from '../types'

const initialState = {
  currentUserPurchases: [],
  error: null,
  isLoading: true,
  rewards: {
    bounties: [],
    voucher: {
      asBuyer: [],
      asVoucherOwner: [],
    },
  },
  bounties: {
    claimed: [],
  },
}

export default (state = initialState, { type, payload, error }) => {
  switch (type) {
    case FETCH_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        ...payload.dashboard,
        isLoading: false,
      }

    case FETCH_DASHBOARD_DATA_FAILURE:
      return { ...state, error, isLoading: false }

    case CLEAR_DASHBOARD_ERROR:
      return { ...state, error: null, isLoading: false }

    default:
      return state
  }
}
