import { connect } from 'react-redux'

import { closeModal, openModal } from 'store/ui/uiActions'
import selectActiveModalName from 'store/selectors/selectActiveModalName'

import RejectBountyClaimModal from './RejectBountyClaimModal'

const mapStateToProps = (state, { bountyClaimId }) => {
  const MODAL_NAME = `REJECT_BOUNTY_CLAIM_MODAL_${bountyClaimId}`

  return {
    isOpen: selectActiveModalName(state) === MODAL_NAME,
    bountyClaimId,
    modalName: MODAL_NAME,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
})

const mergeProps = ({ bountyClaimId, isOpen, modalName }, { dispatch }) => ({
  bountyClaimId,
  isOpen,
  open: e => {
    e.preventDefault()
    dispatch(openModal(modalName))
  },
  close: () => dispatch(closeModal(modalName)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(RejectBountyClaimModal)
