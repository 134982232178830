import {
  FETCH_PROJECT_REQUEST,
  FETCH_PROJECT_SUCCESS,
  FETCH_PROJECT_FAILURE,
  FETCH_PROJECTS_REQUEST,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_FAILURE,
} from '../types'

import { authorizedGet } from '../../api'
import addComputedValues from './addComputedValues'
import captureException from '../../sentryHelpers/captureException'

const fieldsToQuery = ({ fields }) =>
  fields.length > 0 ? `?fields=${fields.join(',')}` : ''

export const fetchProject = ({ slug, fields = [] }) => dispatch => {
  dispatch({ type: FETCH_PROJECT_REQUEST, slug, fields })

  const query = fieldsToQuery({ fields })

  return authorizedGet({ url: `/api/projects/${slug}${query}` })
    .then(payload => {
      dispatch({ type: FETCH_PROJECT_SUCCESS, payload })
      return payload
    })
    .catch(error => {
      captureException(error)
      dispatch({ type: FETCH_PROJECT_FAILURE, error: error.message })
    })
}

export const fetchProjects = ({ fields }) => dispatch => {
  dispatch({ type: FETCH_PROJECTS_REQUEST, fields })

  const query = fieldsToQuery({ fields })

  return authorizedGet({ url: `/api/projects${query}` })
    .then(addComputedValues)
    .then(({ projects }) =>
      dispatch({ type: FETCH_PROJECTS_SUCCESS, payload: { projects } })
    )
    .catch(error => {
      captureException(error)
      dispatch({ type: FETCH_PROJECTS_FAILURE, error: error.message })
    })
}

export const fetchProjectWithTokenSale = ({
  projectSlug,
  tokenSaleSlug,
}) => dispatch => {
  dispatch({ type: FETCH_PROJECT_REQUEST, projectSlug, tokenSaleSlug })

  return authorizedGet({
    url: `/api/projects/${projectSlug}/sale/${tokenSaleSlug}`,
  })
    .then(payload => {
      dispatch({ type: FETCH_PROJECT_SUCCESS, payload })
      return payload
    })
    .catch(error => {
      captureException(error)
      dispatch({ type: FETCH_PROJECT_FAILURE, error: error.message })
    })
}
