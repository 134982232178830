import React from 'react'

const ProjectsHeader = ({ projects }) => (
  <div class="page-content mb-2">
    <div class="page-title">
      <div class="row justify-content-between align-items-center">
        <div class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
          {!!projects.length && (
            <>
              <div class="d-inline-block">
                <h5 class="h4 d-inline-block font-weight-400 mb-0 text-white">
                  Projects
                </h5>
              </div>
              <div class="align-items-center ml-4 d-inline-flex">
                <span class="h4 text-info mb-0 mr-2">{projects.length}</span>
                <span class="text-sm opacity-7 text-white">projects</span>
              </div>

              <div class="align-items-center ml-4 d-inline-flex">
                <span class="h4 text-info mb-0 mr-2">
                  {projects.filter(p => p.activeTokenSale !== null).length}
                </span>
                <span class="text-sm opacity-7 text-white">
                  Ongoing projects
                </span>
              </div>
            </>
          )}
        </div>

        <div class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
          <a
            href="https://bit.ly/Starbase_Submission"
            class="btn btn-sm btn-white btn-icon-only rounded-circle ml-4"
            data-toggle="modal"
          >
            <span class="btn-inner--icon">
              <i class="fa fa-plus" />
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
)

export default ProjectsHeader
