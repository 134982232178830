import React from 'react'

import AllowCookiesModal from './AllowCookiesModal'

class AllowCookiesModalContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isCookiesAllowed: false }
  }

  onAllowCookies = () => {
    localStorage.setItem('isCookiesAllowed', 'true')
    this.setState({ isCookiesAllowed: true })
  }

  render() {
    const isOpen = !(
      localStorage.getItem('isCookiesAllowed') === 'true' ||
      this.state.isCookiesAllowed
    )

    return (
      <AllowCookiesModal isOpen={isOpen} onAllowCookies={this.onAllowCookies} />
    )
  }
}

export default AllowCookiesModalContainer
