import React from 'react'
import styled from 'styled-components/macro'

const ProtectedActionIcon = styled(({ className }) => (
  <span role="img" aria-label="Lock" className={className}>
    🔒
  </span>
)).attrs({
  'aria-label': 'Protected action icon',
  role: 'img',
})`
  margin-right: 0.5em;
  position: relative;
  top: -0.1em;
`

export default ProtectedActionIcon
