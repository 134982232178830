import BigNumber from 'bignumber.js'

import {
  WITHDRAW_BONUS_TOKENS_FAILURE,
  WITHDRAW_BONUS_TOKENS_START,
  WITHDRAW_BONUS_TOKENS_TRANSACTION_CONFIRMED,
  WITHDRAW_BONUS_TOKENS_TRANSACTION_PENDING,
  WITHDRAW_BONUS_TOKENS_ENABLE_BUTTON,
} from 'store/types'

import tokenAbi from './tokenAbi'

import { TRANSACTION_REJECTED_BY_USER_ERROR } from 'errors'

import captureException from 'sentryHelpers/captureException'

export const withdrawAllBonusTokens = ({
  bonusTokenContractAbi,
  bonusTokenAddress,
  ethereumAddress,
}) => dispatch => {
  dispatch({ type: WITHDRAW_BONUS_TOKENS_START })

  const { web3 } = window
  const bonusTokenContract = new web3.eth.Contract(
    JSON.parse(bonusTokenContractAbi),
    bonusTokenAddress
  )

  bonusTokenContract.methods
    .withdrawBonusTokens()
    .send({
      from: ethereumAddress,
    })
    .on('transactionHash', () => {
      dispatch({ type: WITHDRAW_BONUS_TOKENS_TRANSACTION_PENDING })
    })
    .on('receipt', receipt => {
      if (receipt.status === '0x0') {
        dispatch({ type: WITHDRAW_BONUS_TOKENS_FAILURE })
      } else {
        dispatch({ type: WITHDRAW_BONUS_TOKENS_TRANSACTION_CONFIRMED })
        window.location.reload(true)
      }
    })
    .on('error', error => dispatch(handleError(error, bonusTokenAddress)))
}

export const handleError = (e, bonusTokenAddress) => dispatch => {
  captureException(e)
  const error = e.message.includes(
    'Error: MetaMask Tx Signature: User denied transaction signature'
  )
    ? TRANSACTION_REJECTED_BY_USER_ERROR
    : e

  dispatch({
    type: WITHDRAW_BONUS_TOKENS_FAILURE,
    error: error instanceof Error ? error.message : error,
  })

  dispatch({
    payload: bonusTokenAddress,
    type: WITHDRAW_BONUS_TOKENS_ENABLE_BUTTON,
  })
}

export const getBonusTokensBalance = async ({
  bonusTokenContractAbi,
  bonusTokenAddress,
  ethereumAddress,
}) => {
  const { web3 } = window
  const bonusTokenContract = new web3.eth.Contract(
    JSON.parse(bonusTokenContractAbi),
    bonusTokenAddress
  )

  const bonusTokenBalance = await bonusTokenContract.methods
    .bonusTokenBalances(ethereumAddress)
    .call()
  const tokenAddress = await bonusTokenContract.methods.tokenOnSale().call()

  const decimals = await new web3.eth.Contract(tokenAbi, tokenAddress).methods
    .decimals()
    .call()

  return new BigNumber(bonusTokenBalance)
    .div(new BigNumber(10).pow(decimals))
    .toFixed()
}
