import { getContractWrite, toWei } from './generalHelper'

import starTokenContractAbi from './starTokenContractAbi'
const starTokenContractAddress =
  process.env.REACT_APP_STAR_TOKEN_CONTRACT_ADDRESS

export default ({ ethereumAddressFrom, starAmount, spenderAddress }) => {
  const contract = getContractWrite(
    JSON.stringify(starTokenContractAbi),
    starTokenContractAddress
  )
  return contract.methods
    .approve(spenderAddress, toWei(starAmount))
    .send({ from: ethereumAddressFrom })
}
