import { connect } from 'react-redux'

import { closeModal, openModal } from 'store/ui/uiActions'
import selectActiveModalName from 'store/selectors/selectActiveModalName'

import ChangeMetamaskAddressTutorial from './ChangeMetamaskAddressTutorial'

const MODAL_NAME = 'TUTORIAL_CHANGE_METAMASK_ADDRESS'

const mapStateToProps = state => ({
  isOpen: selectActiveModalName(state) === MODAL_NAME,
})

const mapDispatchToProps = dispatch => ({
  open: e => {
    e.preventDefault()
    dispatch(openModal(MODAL_NAME))
  },
  close: () => dispatch(closeModal(MODAL_NAME)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeMetamaskAddressTutorial)
