import React from 'react'

import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'

import AcceptBountyClaimForm from './AcceptBountyClaimFormContainer'

export default ({ close, isOpen, bountyClaimId, bountyTask }) => (
  <Modal isOpen={isOpen} size="lg">
    <ModalHeader>
      Approve the Bounty Claim{' '}
      <small className="text-muted"> {bountyClaimId}</small>
    </ModalHeader>
    <ModalBody>
      <div className="alert alert-warning" role="alert">
        Suggested amount: {bountyTask.maxRewardAmount}{' '}
        {bountyTask.bountyDefinition.tokenSymbol} Tokens
      </div>
      <AcceptBountyClaimForm
        bountyClaimId={bountyClaimId}
        form={'AcceptBountyClaimForm-' + bountyClaimId}
      />
      <hr />
      <Button color="secondary" onClick={close}>
        Close
      </Button>
    </ModalBody>
  </Modal>
)
