import { connect } from 'react-redux'
import TabItem from './TabItem'

const mapStateToProps = state => ({
  stepComplete: state.location.payload.step,
})

export default connect(
  mapStateToProps,
  null
)(TabItem)
