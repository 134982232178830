import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { putUpdateProject } from 'store/project/projectActions'

import React, { useEffect } from 'react'
import { Field, Form } from 'redux-form'
import { validateRequired, url } from 'formHelpers/formValidation'

import FieldText from 'app/components/forms/fields/FieldText'
import MarkdownTextEditor from 'app/components/forms/fields/MarkdownTextEditor'
import BodyContainer from 'app/components/BodyContainer'
import Wrapper from 'app/components/Wrapper'

const ProjectEdit = ({ project, onSubmit, initForm, submitting }) => {
  useEffect(() => {
    initForm()
  }, [])

  return (
    <Wrapper>
      <BodyContainer>
        <Form onSubmit={onSubmit}>
          <h2>
            Edit - {project.name}{' '}
            <button
              disabled={submitting}
              type="submit"
              className="btn btn-success"
            >
              Update
            </button>
          </h2>
          <hr />

          <div className="form-group">
            <label>Whitepaper URL</label>
            <small className="form-text text-muted">
              Please insert a URL that points to your whitepaper pdf file
            </small>
            <Field
              isTouched
              component={FieldText}
              className="form-control"
              name="whitepaperUrl"
              validate={[validateRequired, url]}
            />
          </div>

          <div className="form-group">
            <label>Token Icon URL</label>
            <small className="form-text text-muted">
              Please insert a URL that points to your Token Icon image file
            </small>
            <Field
              isTouched
              component={FieldText}
              className="form-control"
              name="tokenIconUrl"
              placeholder={project.tokenIconUrl}
              validate={[validateRequired, url]}
            />
          </div>

          <div className="form-group">
            <label>Twitter Profile</label>
            <small className="form-text text-muted">
              Please insert your twitter profile without the @(at)
            </small>
            <Field
              isTouched
              component={FieldText}
              className="form-control"
              name="twitterProfile"
              placeholder={project.twitterProfile}
            />
          </div>

          <div className="form-group">
            <label>Medium Profile</label>
            <small className="form-text text-muted">
              Please insert your medium profile, you can find on your blog URL.
              Example: https://medium.com/myBlog/ your profile is "myBlog".
            </small>
            <Field
              isTouched
              component={FieldText}
              className="form-control"
              name="mediumProfile"
              placeholder={project.mediumProfile}
            />
          </div>

          {/* <div className="form-group">
        <label>Is Listed?</label>
        <div className="form-check">
          <Field
            className="form-check-input"
            component="input"
            id="isListed"
            name="isListed"
            type="checkbox"
          />
          <label className="form-check-label" htmlFor="isListed">
            True
          </label>
          <small className="form-text text-muted">
            Marking this, we will show your project on our home page
          </small>
        </div>
      </div> */}

          <div className="form-group">
            <label>Description</label>
            <small className="form-text text-muted">
              Please insert a description of your project
            </small>
            <Field
              initialValue={project.description}
              component={MarkdownTextEditor}
              name="description"
              type="text"
            />
          </div>
          <hr />
        </Form>
      </BodyContainer>
    </Wrapper>
  )
}

const mapStateToProps = ({ project: { item } }) => ({
  project: item,
  initialValues: {
    whitepaperUrl: item.whitepaperUrl,
    tokenIconUrl: item.tokenIconUrl,
    isListed: item.isListed,
    description: item.description,
    twitterProfile: item.twitterProfile,
  },
})

const mapDispatchToProps = dispatch => ({
  dispatch,
})

const mergeProps = (
  { project, initialValues },
  { dispatch },
  { handleSubmit, initialize, submitting }
) => ({
  project,
  submitting,
  initForm: () => dispatch(initialize(initialValues)),
  onSubmit: handleSubmit(newProject =>
    dispatch(putUpdateProject({ ...newProject, slug: project.slug }))
  ),
})

export default reduxForm({
  form: 'projectEditForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(ProjectEdit)
)
