import React from 'react'
import styled from 'styled-components/macro'

const Pagetitle = styled.span`
  padding: 2em 0;
`

const Title = styled.h4`
  color: ${props => props.theme.color.darker};
  display: inline-block;
  font-weight: ${props => props.theme.font.w300};
  padding: 0px 0px 0.8em 0;
`
const Subtitle = styled.span`
  font-size: 12px;
  border: 1px solid ${props => props.theme.color.primaryPurple};
  padding: 2px 8px;
  color: ${props => props.theme.color.primaryPurple};
  border-radius: 2px;
  vertical-align: super;
`

const PageTitle = ({ title, subtitle }) => (
  <Pagetitle>
    {title && <Title>{title}</Title>}
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
  </Pagetitle>
)

export default PageTitle
