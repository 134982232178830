import React from 'react'
import styled from 'styled-components/macro'

import FormFooter from './FormFooterContainer'
import FormTitle from './FormTitle'

const Icon = styled.i`
  color: ${props => props.theme.color.primaryPurple};
  display: block;
  font-size: 2rem;
}
`

const Alert = styled.div`
  margin-bottom: 1rem;
  border: 1px solid transparent;
  background-color: #fff;
  color: ${props => props.theme.color.primaryPurple};
  font-weight: 400;
  border-radius: 4px;
  text-align: center;
  margin: auto;
  @media (min-width: ${props => props.theme.mediaQueries.sm}) {
    padding: 40px 30px 45px;
    border-color: ${props => props.theme.color.primaryPurple};
  }
`

const AlertContainer = styled.div`
  margin: 2em auto 3em;
  width: 100%;
`

const BottomMargin = styled.div`
  margin-bottom: 30px;
  a {
    color: ${props => props.theme.color.primaryPurpleHover};
  }
`

const DigitalIdentificationForm = ({ onSubmit }) => (
  <form onSubmit={onSubmit}>
    <AlertContainer>
      <Alert>
        <div>
          <Icon className="icon-warning" />
          <strong>Disclaimer</strong>
        </div>
        By continuing you agree that you are not a citizen of the United States
        of America <br /> or any other jurisdiction in which it is not
        permissible to participate in token crowd contributions.
      </Alert>
    </AlertContainer>
    <FormTitle title="Digital Identification" />

    <div className="row justify-content-center">
      <BottomMargin className="col-12">
        <p>
          To streamline the KYC/AML process, Starbase will be using{' '}
          <a
            href="https://onfido.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            ONFIDO
          </a>{' '}
          to perform KYC/AML checks.
        </p>

        <p>
          <a
            href="https://www.youtube.com/watch?v=nTMpWkVGjO8"
            target="_blank"
            rel="noopener noreferrer"
          >
            Here{' '}
          </a>
          we provide a tutorial how to proceed with Onfido.
        </p>

        <div id="onfido-mount" />
      </BottomMargin>
    </div>

    <FormFooter leftButtonText="Back" />
  </form>
)

export default DigitalIdentificationForm
