import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import selectIsUserProjectOwnerFromLocation from 'store/selectors/selectIsUserProjectOwnerFromLocation'

import BodyContainer from 'app/components/BodyContainer'
import ProtectedContent from 'app/components/ProtectedContent'
import ProtectedPageModal from 'app/components/ProtectedPageModal'

const ProjectOwner = ({ isUserProjectOwner, isLoading, children }) => (
  <>
    {isLoading ? (
      <div className="alert alert-light text-center p-5" role="alert">
        Loading...
      </div>
    ) : (
      <BodyContainer>
        <ProtectedPageModal title="Access Denied" isOpen={!isUserProjectOwner}>
          <p>In order to access this page you must be a project owner</p>
        </ProtectedPageModal>
        <ProtectedContent isProtected={!isUserProjectOwner}>
          {children}
        </ProtectedContent>
      </BodyContainer>
    )}
  </>
)

const mapStateToProps = state => ({
  isLoading: get(state, 'projectOwned.isLoading', false),
  isUserProjectOwner: selectIsUserProjectOwnerFromLocation(state),
})

export default connect(mapStateToProps)(ProjectOwner)
