import Link from 'redux-first-router-link'
import Modal from 'reactstrap/lib/Modal'
import ModalBody from 'reactstrap/lib/ModalBody'
import ModalHeader from 'reactstrap/lib/ModalHeader'
import React from 'react'
import styled from 'styled-components/macro'

import addTokenMetaMaskGifSrc from 'app/assets/images/tutorials/addtokenmetamask.gif'
import MetaMaskImage from 'app/components/MetamaskImage'
import { toProject } from 'store/routerActions'

import {
  PURCHASE_EXCEEDS_SUPPLY_SERVER_ERROR,
  TRANSACTION_REJECTED_BY_USER_ERROR,
} from 'errors'

const Icon = styled.i`
  margin: 0 7.5px;
  padding-top: 5px;
`

const CircleIcon = styled(Icon).attrs({
  className: 'icon-circlethin',
})`
  color: ${props => props.theme.color.lighter};
`

const OkIcon = styled(Icon).attrs({
  className: 'icon-okcircled',
})`
  color: ${props => props.theme.color.greenSuccess};
`

const InProgressIcon = styled(Icon).attrs({
  className: 'icon-spin2',
})`
  color: ${props => props.theme.color.infoBlue};
  animation: spin 2s linear infinite;
`

const FeedbackMessage = styled.span`
  color: ${props =>
    props.isNotStarted ? props.theme.color.light : props.theme.color.darker};
  font-weight: ${props => (props.isInProgress ? 'bold' : 'normal')};
`
const MetaMaskGif = styled.img`
  width: 100%;
  margin-bottom: 20px;
  ${props => props.theme.boxShadow.balanceCard};
`

const ProgressStepRow = styled.div`
  margin-bottom: 7px;
`

const PayStatusErrorDetails = styled.p`
  background: #eee;
  border-radius: 3px;
  margin: 20px 20px 30px;
  padding: 20px;
`

const FeedbackItem = ({ children, isStarted, isCompleted }) => {
  const isNotStarted = !isStarted
  const isInProgress = isStarted && !isCompleted

  return (
    <ProgressStepRow className="row align-items-center">
      {isNotStarted && <CircleIcon />}
      {isInProgress && <InProgressIcon />}
      {isCompleted && <OkIcon />}
      <FeedbackMessage isNotStarted={isNotStarted} isInProgress={isInProgress}>
        {children}
      </FeedbackMessage>
    </ProgressStepRow>
  )
}

const PayStatusModal = ({
  changeModalStep,
  closeModal,
  completedSteps,
  payStatus,
  project,
  purchaseCurrency,
  user,
}) => (
  <Modal
    size={payStatus.payStatusModalStep === 'default' ? 'md' : 'lg'}
    isOpen={payStatus.isPayStatusModalOpen}
  >
    <ModalHeader>
      {payStatus.payStatusModalStep === 'tutorial'
        ? 'How to add a new token to your MetaMask wallet'
        : completedSteps.PURCHASE_TOKENS_SUCCESS
        ? 'Thank you for your purchase !'
        : 'Your purchase'}
    </ModalHeader>

    <ModalBody>
      {payStatus.isPending && (
        <div className="text-center">
          <p>
            Please use <b>MetaMask</b> now.
          </p>
          <p>
            <MetaMaskImage />
          </p>
          <p>
            In order to complete your purchase,
            <br />
            <b>do not leave or refresh this page.</b>
          </p>
          <hr />
        </div>
      )}

      {!completedSteps.PURCHASE_TOKENS_FAILURE &&
        (payStatus.payStatusModalStep === 'default' ? (
          <div>
            <p>Completed steps</p>

            {purchaseCurrency === 'STAR' && (
              <FeedbackItem
                isStarted={completedSteps.METAMASK_APPROVE_TRANSACTION_START}
                isCompleted={
                  completedSteps.METAMASK_APPROVE_TRANSACTION_SUCCESS
                }
              >
                MetaMask is creating the <b>Approval</b> transaction for you
                <br />
                <small> Please check your MetaMask to confirm it</small>
              </FeedbackItem>
            )}

            {purchaseCurrency === 'STAR' && (
              <FeedbackItem
                isStarted={completedSteps.APPROVAL_TRANSACTION_PENDING}
                isCompleted={completedSteps.APPROVAL_TRANSACTION_CONFIRMED}
              >
                {completedSteps.APPROVAL_TRANSACTION_CONFIRMED ? (
                  <div>
                    Approval transaction was <b>confirmed</b> on the blockchain.
                  </div>
                ) : (
                  <div>
                    Approval transaction is <b>pending</b> on the blockchain...
                  </div>
                )}
              </FeedbackItem>
            )}

            <FeedbackItem
              isStarted={completedSteps.METAMASK_BUYTOKENS_TRANSACTION_START}
              isCompleted={
                completedSteps.METAMASK_BUYTOKENS_TRANSACTION_SUCCESS
              }
            >
              MetaMask is creating the <b>buy Tokens</b> transaction for you
              <br />
              <small> Please check your MetaMask to confirm it</small>
            </FeedbackItem>

            <FeedbackItem
              isStarted={completedSteps.POST_PENDING_PURCHASE_REQUEST}
              isCompleted={completedSteps.POST_PENDING_PURCHASE_SUCCESS}
            >
              Registering your purchase on the Starbase platform
            </FeedbackItem>

            <FeedbackItem
              isStarted={completedSteps.PURCHASE_TOKENS_SUCCESS}
              isCompleted={completedSteps.PURCHASE_TOKENS_SUCCESS}
            >
              Registration of your purchase was completed
              <br />
            </FeedbackItem>
          </div>
        ) : (
          <MetaMaskGif
            src={addTokenMetaMaskGifSrc}
            alt="Adding tokens to MetaMask GIF"
          />
        ))}

      {!payStatus.isPending && !completedSteps.PURCHASE_TOKENS_SUCCESS && (
        <div className="text-center">
          <p>
            The transaction has been cancelled.
            <br />
            {payStatus.error && (
              <PayStatusErrorDetails>
                {payStatus.error === PURCHASE_EXCEEDS_SUPPLY_SERVER_ERROR
                  ? `Given the amount already raised for this project, your purchase would exceed the supply limit, please try again with a lower amount.`
                  : payStatus.error === TRANSACTION_REJECTED_BY_USER_ERROR
                  ? `Transaction rejected by the user.`
                  : `Unknown error, please try again later`}
              </PayStatusErrorDetails>
            )}
          </p>

          <button className="btn btn-primary" onClick={closeModal}>
            I understand, close this dialog
          </button>
        </div>
      )}

      {!payStatus.isPending && completedSteps.PURCHASE_TOKENS_SUCCESS && (
        <div className="container">
          {payStatus.payStatusModalStep === 'default' && (
            <div className="row">
              <div className="col-12 text-center">
                <hr />
                <p>
                  The purchase is <strong>being confirmed</strong> on the
                  blockchain. <br />
                  Once it is confirmed, you will receive an Email to{' '}
                  <b>{user.email}</b> with a payment invoice.
                </p>
              </div>
              <div className="col-12">
                <hr />
                <h5>First time purchasing this token?</h5>
                <p>
                  Before you can see your new tokens with MetaMask, you must add
                  the token to your MetaMask wallet.
                </p>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-4">
              <button
                className="btn btn-secondary"
                onClick={() =>
                  changeModalStep({
                    step:
                      payStatus.payStatusModalStep === 'tutorial'
                        ? 'default'
                        : 'tutorial',
                  })
                }
              >
                {payStatus.payStatusModalStep === 'tutorial'
                  ? 'Back'
                  : 'Show me how'}
              </button>
            </div>

            {payStatus.payStatusModalStep === 'default' && (
              <div className="col-8 text-right">
                <Link
                  className="btn btn-success"
                  onClick={closeModal}
                  to={toProject(project)}
                >
                  I understand, back to the project
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </ModalBody>
  </Modal>
)

export default PayStatusModal
