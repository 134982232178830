import styled from 'styled-components/macro'

const ArrowIcon = styled.i.attrs({
  className: 'icon-right-big',
})`
  font-size: 50px;
  color: ${({ skeleton, theme: { color } }) =>
    skeleton ? color.lighter : color.light};
  &:before {
    transform: rotate(90deg) translateX(-10px);
    @media (min-width: ${props => props.theme.mediaQueries.lg}) {
      transform: none;
    }
  }
`

export default ArrowIcon
