import styled from 'styled-components/macro'

const TableRow = styled.div`
  text-align: center;
  padding: 20px 0;
  position: relative;
  border-bottom: 1px dashed ${props => props.theme.color.steel};
  div {
    font-size: ${props => props.theme.font.md};
    margin-bottom: 10px;
  }
  &:hover {
    background-color: ${props => props.theme.color.lighter};
    text-decoration: none;
  }
  @media (min-width: ${props => props.theme.mediaQueries.md}) {
    border-bottom: block;
  }
`

export default TableRow
