import store from './store/store'
import {
  setPurchaseAccepted,
  setPurchaseRejected,
} from './store/pay/payActions'
import * as web3Helpers from './web3Helpers'
import selectActiveTokenContractsAddresses from './store/selectors/selectActiveTokenContractsAddresses'
import selectPurchases from './store/selectors/selectPurchases'

let subscription = null

const onPurchaseAccepted = ({ purchase }) =>
  store.dispatch(setPurchaseAccepted({ purchase }))

const onPurchaseRejected = ({ purchase }) =>
  store.dispatch(setPurchaseRejected({ purchase }))

export default () =>
  store.subscribe(() => {
    const tokenContractsAddresses = selectActiveTokenContractsAddresses(
      store.getState()
    )
    if (!tokenContractsAddresses.length) return

    if (subscription) return // must have only one subscription
    subscription = web3Helpers.websocketWeb3.eth.subscribe('logs', {
      address: tokenContractsAddresses,
    })
    console.log({ subscription }, 'for tokenSales ', {
      tokenContractsAddresses,
    })

    subscription.on('data', async log => {
      console.log('Received log ', { log })
      const { transactionHash } = log
      const tx = await web3Helpers.websocketWeb3.eth.getTransactionReceipt(
        transactionHash
      )
      if (!tx) return // if unconfirmed then is tx is null
      subscription.emit('transaction', tx)
    })

    subscription.on('transaction', transaction => {
      const purchases = selectPurchases(store.getState())
      purchases.forEach(async purchase => {
        const { transactionHash } = transaction
        const { ethereumTransactionHash } = purchase
        if (
          transactionHash.toLowerCase() !==
          ethereumTransactionHash.toLowerCase()
        )
          return
        console.log('confirmed transaction', { transaction })
        if (transaction.status === '0x1') onPurchaseAccepted({ purchase })
        else if (transaction.status === '0x0') onPurchaseRejected({ purchase })
      })
    })
  })
