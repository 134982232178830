import React from 'react'
import { FormattedDate } from 'react-intl'

const DateWrapper = ({ value = new Date(), type = 'short', ...props }) => {
  const dateTimestamp = value
    ? new Date(value).getTime()
    : new Date(new Date()).getTime()

  // Jun 25, 2019
  if (type === 'short') {
    props = {
      ...props,
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }
  }

  //long Dec 17, 2018, 10:59 PM UTC
  if (type === 'long') {
    props = {
      ...props,
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
    }
  }

  return <FormattedDate value={dateTimestamp} {...props} />
}

export default DateWrapper
