import styled from 'styled-components/macro'

const Jumbotron = styled.div.attrs({
  className: 'jumbotron',
})`
  border: 1px solid ${props => props.theme.color.primaryPurple};
  && {
    background-color: #fcfcfc;
    padding: 2em;
  }
`
export default Jumbotron
