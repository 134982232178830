import { connect } from 'react-redux'
import { change, reduxForm } from 'redux-form'

import TermsOfUse from './TermsOfUse'

import {
  confirmTermsOfUseScrollComplete,
  showTermsOfUseScrollIncompleteTip,
} from '../../../../../store/profile/profileActions'

const mapStateToProps = state => ({
  isTermsOfUseReadingComplete: state.profile.isTermsOfUseReadingComplete,
  isTermsOfUseScrollIncompleteTipVisible:
    state.profile.isTermsOfUseScrollIncompleteTipVisible,
})

const tolerance = 10

const mapDispatchToProps = dispatch => ({
  init: dispatch(change('profileForm', 'isTermsOfUseAccepted', false)),
  handleScroll: e => {
    e.target.scrollHeight - e.target.scrollTop <=
      e.target.clientHeight + tolerance &&
      dispatch(confirmTermsOfUseScrollComplete())
  },
  dispatch,
})

const mergeProps = (
  { isTermsOfUseReadingComplete, isTermsOfUseScrollIncompleteTipVisible },
  { dispatch, handleScroll }
) => ({
  isTermsOfUseReadingComplete,
  isTermsOfUseScrollIncompleteTipVisible,
  handleScroll,
  shouldScrollIncompleteTipVisible: () =>
    !isTermsOfUseReadingComplete &&
    dispatch(showTermsOfUseScrollIncompleteTip()),
})

export default reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  form: 'profileForm',
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(TermsOfUse)
)
