import {
  FETCH_DASHBOARD_DATA_REQUEST,
  FETCH_DASHBOARD_DATA_SUCCESS,
  FETCH_DASHBOARD_DATA_FAILURE,
} from '../types'

import captureException from '../../sentryHelpers/captureException'
import { authorizedGet } from '../../api'

export const fetchDashboardData = () => dispatch => {
  dispatch({ type: FETCH_DASHBOARD_DATA_REQUEST })

  return authorizedGet({ url: '/api/dashboard' })
    .then(payload => dispatch({ type: FETCH_DASHBOARD_DATA_SUCCESS, payload }))
    .catch(error => {
      captureException(error)
      dispatch({ type: FETCH_DASHBOARD_DATA_FAILURE, error: error.message })
    })
}
