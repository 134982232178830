import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import { putAcceptBountyClaim } from 'store/project/projectActions'

import AcceptBountyClaimForm from './AcceptBountyClaimForm'

const mapStateToProps = (
  {
    location: {
      payload: { slug },
    },
  },
  { bountyClaimId }
) => ({
  slug,
  bountyClaimId,
})

const mapDispatchToProps = dispatch => ({
  dispatch,
})

const mergeProps = (
  { slug, bountyClaimId },
  { dispatch },
  { handleSubmit }
) => ({
  slug,
  bountyClaimId,
  onSubmit: handleSubmit(({ rewardAmount }) =>
    dispatch(
      putAcceptBountyClaim({
        bountyClaimId,
        rewardAmount,
        slug,
      })
    )
  ),
})

export default reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(AcceptBountyClaimForm)
)
