import { connect } from 'react-redux'

import SubmitResult from './SubmitResult'

const mapStateToProps = (/*state*/) => ({})

const mapDispatchToProps = () => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmitResult)
