import { Field } from 'redux-form'
import React from 'react'
import styled from 'styled-components/macro'

import { validateRequired } from 'formHelpers/formValidation'

import FieldSelect from './FieldSelect'
import FieldLabel from './FieldLabel'

const currentYear = new Date().getFullYear()
const maxYear = currentYear - 18
const minYear = currentYear - 100

const date = {
  days: Array.from(Array(31), (_, x) => ({ value: x + 1 })),
  months: Array.from(Array(12), (_, x) => ({ value: x + 1 })),
  years: Array.from(Array(maxYear - minYear + 1), (_, x) => ({
    value: x + minYear,
  })),
}

const FieldContainer = styled.div`
  width: 27%;
  float: left;
`
const DateSeparator = styled.div`
  font-size: 250%;
  float: left;
  position: relative;
  text-align: center;
  top: -2px;
  width: 9.5%;
`
const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1)

const prefixWith = (prefix, value) =>
  prefix ? `${prefix}${capitalize(value)}` : value

const FieldSelectBirthdate = ({
  isTouched,
  prefix = '',
  validate = validateRequired,
}) => (
  <div>
    <FieldContainer>
      <FieldLabel hidden htmlFor={prefixWith(prefix, 'year')} children="Year" />
      <Field
        component={FieldSelect}
        isTouched={isTouched}
        name={prefixWith(prefix, 'year')}
        options={date.years}
        placeholder="Year"
        validate={validate}
      />
    </FieldContainer>

    <DateSeparator>/</DateSeparator>

    <FieldContainer>
      <FieldLabel
        hidden
        htmlFor={prefixWith(prefix, 'month')}
        children="Month"
      />
      <Field
        component={FieldSelect}
        isTouched={isTouched}
        name={prefixWith(prefix, 'month')}
        options={date.months}
        placeholder="Month"
        validate={validate}
      />
    </FieldContainer>

    <DateSeparator>/</DateSeparator>

    <FieldContainer>
      <FieldLabel hidden htmlFor={prefixWith(prefix, 'day')} children="Day" />
      <Field
        component={FieldSelect}
        isTouched={isTouched}
        name={prefixWith(prefix, 'day')}
        options={date.days}
        placeholder="Day"
        validate={validate}
      />
    </FieldContainer>
  </div>
)

export default FieldSelectBirthdate
