import * as web3Helpers from 'web3Helpers'

import {
  METAMASK_WITHDRAW_STAKE_TOKENS_TRANSACTION_START,
  METAMASK_WITHDRAW_STAKE_TOKENS_TRANSACTION_SUCCESS,
  WITHDRAW_STAKE_TOKENS_FAILURE,
  WITHDRAW_STAKE_TOKENS_START,
  WITHDRAW_STAKE_TOKENS_SUCCESS,
  WITHDRAW_STAKE_TOKENS_TRANSACTION_CONFIRMED,
  WITHDRAW_STAKE_TOKENS_TRANSACTION_PENDING,
} from 'store/types'

import { TRANSACTION_REJECTED_BY_USER_ERROR } from 'errors'

export default stakeParams => async dispatch => {
  try {
    const {
      ethereumAddressFrom,
      stakeContractAbi,
      stakeContractAddress,
    } = stakeParams

    dispatch({ type: WITHDRAW_STAKE_TOKENS_START })
    dispatch({ type: METAMASK_WITHDRAW_STAKE_TOKENS_TRANSACTION_START })

    await web3Helpers
      .withdrawStake({
        ethereumAddressFrom,
        stakeContractAbi,
        stakeContractAddress,
      })
      .on('transactionHash', () => {
        dispatch({ type: METAMASK_WITHDRAW_STAKE_TOKENS_TRANSACTION_SUCCESS })
        dispatch({ type: WITHDRAW_STAKE_TOKENS_TRANSACTION_PENDING })
      })
      .on('receipt', receipt => {
        if (receipt.status === '0x0')
          dispatch({ type: WITHDRAW_STAKE_TOKENS_FAILURE })
        else dispatch({ type: WITHDRAW_STAKE_TOKENS_TRANSACTION_CONFIRMED })
      })
      .on('error', error => dispatch(handleError(error)))

    dispatch({ type: WITHDRAW_STAKE_TOKENS_SUCCESS })
  } catch (error) {
    dispatch(handleError(error))
  }
}

const handleError = e => dispatch => {
  console.error({ e })
  const error = e.message.includes(
    'Error: MetaMask Tx Signature: User denied transaction signature'
  )
    ? TRANSACTION_REJECTED_BY_USER_ERROR
    : e

  dispatch({
    type: WITHDRAW_STAKE_TOKENS_FAILURE,
    error: error instanceof Error ? error.message : error,
  })
}
