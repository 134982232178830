import React from 'react'
import styled from 'styled-components/macro'

const Label = styled.label`
  color: ${props => props.theme.color.darker};
  font-size: ${props => props.theme.font.label};
  margin-bottom: -6px;
  ${props =>
    props.hidden
      ? `
          position: absolute;
          left: -10000px;
          top: auto;
          width: 1px;
          height: 1px;
          overflow: hidden;
        `
      : ``};
`

const FieldLabel = ({ children, hidden, htmlFor }) => (
  <Label hidden={hidden} htmlFor={htmlFor} className="col-form-label">
    {children}
  </Label>
)

export default FieldLabel
