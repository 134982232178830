import styled from 'styled-components/macro'

import metaMaskImage from '../assets/images/logo-metamask.png'

const MetaMaskImage = styled.img.attrs({
  alt: 'MetaMask Logo',
  src: metaMaskImage,
})`
  max-width: 128px;
`

export default MetaMaskImage
