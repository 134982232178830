import React from 'react'
import styled from 'styled-components/macro'

const PurchaseStatus = styled.a.attrs({
  className: 'badge',
})`
  && {
    padding: 0.5em 1em 0.5em 1em;
    letter-spacing: 0.05em;
    color: white;
    font-size: ${props => props.theme.font.xs};
    vertical-align: middle;
    background-color: ${props =>
      props.status === 'PENDING'
        ? props.theme.color.yellow
        : props.status === 'CONFIRMED'
        ? props.theme.color.greenSuccess
        : props.theme.color.red};
  }
`
const ExternalLinkIcon = styled.i`
  font-size: ${props => props.theme.font.xs};
  color: ${props => props.theme.color.white};
  top: 3px;
  position: relative;
`

const PurchaseBadgeStatus = ({ status, url }) => (
  <PurchaseStatus status={status} href={url} target="_blank">
    {status}
    <ExternalLinkIcon className="icon-link-ext" />
  </PurchaseStatus>
)

export default PurchaseBadgeStatus
