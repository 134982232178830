export const toDashboard = () => ({ type: 'DASHBOARD' })

export const toHome = () => ({ type: 'HOME' })

export const toPay = ({ slug }) => ({
  type: 'PAY',
  payload: { slug },
})

export const toProfile = ({ step = 1 } = {}) => ({
  type: 'PROFILE',
  payload: { step },
})

export const toProject = ({ slug, tab }) => ({
  type: 'PROJECT',
  payload: { slug, tab },
})

export const toProjectTokenSale = ({ slug, tokenSaleSlug, tab }) => ({
  type: 'PROJECT_TOKEN_SALE',
  payload: { slug, tokenSaleSlug, tab },
})

export const toProjects = () => ({ type: 'PROJECTS' })

export const toUserProfile = () => ({ type: 'USER_PROFILE' })

export const toBounties = () => ({ type: 'BOUNTIES' })

// Footer links
export const toAbout = () => ({ type: 'ABOUT' })
export const toPrivacy = () => ({ type: 'PRIVACY' })
export const toRegistrationsStart = () => ({ type: 'REGISTRATIONS_START' })
export const toTermsOfUse = () => ({ type: 'TERMS_OF_USE' })

// project owner links
export const toProjectDashboard = ({ slug }) => ({
  type: 'PROJECT_DASHBOARD',
  payload: { slug },
})

export const toProjectEdit = slug => ({
  type: 'PROJECT_EDIT',
  payload: { slug },
})

export const toCreateBountyDefinition = slug => ({
  type: 'PROJECT_CREATE_BOUNTY_DEFINITION',
  payload: { slug },
})

export const toCreateBountyTask = ({ slug, bountyDefinitionId }) => ({
  type: 'PROJECT_CREATE_BOUNTY_TASK',
  payload: { slug, bountyDefinitionId },
})
