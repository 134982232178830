import Onfido from 'onfido-sdk-ui'
import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import DigitalIdentificationForm from './DigitalIdentificationForm'

import * as api from '../../../../../api'
import captureException from '../../../../../sentryHelpers/captureException'
import { toProfile } from '../../../../../store/routerActions'

const mapStateToProps = state => ({
  onfidoApplicantId: state.form.profileForm.values.onfidoApplicantId,
})

const mapDispatchToProps = dispatch => ({
  dispatch,
})

const mergeProps = ({ onfidoApplicantId }, { dispatch }, { ...ownProps }) => ({
  ...ownProps,

  initOnfido: () =>
    api
      .postOnfidoCreateToken({ onfidoApplicantId })
      .then(({ token }) => {
        Onfido.init({
          token,
          containerId: 'onfido-mount',
          steps: [
            {
              type: 'welcome',
              options: {
                title: 'Verify your Identity',
                descriptions: [
                  'To create an account, we will need to verify your identity.',
                  'It will only take a couple of minutes.',
                ],
              },
            },
            {
              type: 'document',
              options: {
                documentTypes: {
                  passport: true,
                  national_identity_card: true,
                },
              },
            },
            'face',
            'complete',
          ],
          onComplete: () => dispatch(toProfile({ step: 4 })),
        })
      })
      .catch(captureException),

  onSubmit: e => e.preventDefault(), // this is needed to prevent onfido to automatically submit our form on completion,
})

class DigitalIdentificationFormWrapper extends React.Component {
  componentWillMount() {
    this.props.initOnfido()
  }

  render() {
    return <DigitalIdentificationForm onSubmit={this.props.onSubmit} />
  }
}

export default reduxForm({
  form: 'profileForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(DigitalIdentificationFormWrapper)
)
