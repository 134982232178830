import { connect } from 'react-redux'

import {
  closeModal,
  fetchBonusTokensBalance,
  openModal,
} from 'store/withdrawBonusTokens/withdrawBonusTokensActions'

import WithdrawBonusTokenButtons from './WithdrawBonusTokenButtons'

const getUniqueCampaigns = ({ bountyDefinitions, voucherClaims }) => {
  const mappedVoucherClaims = voucherClaims.map(({ voucherCode }) => ({
    bonusTokenAddress: voucherCode.voucherDefinition.bonusTokenAddress,
    bonusTokenContractAbi: voucherCode.voucherDefinition.bonusTokenContract.abi,
    tokenSymbol: voucherCode.voucherDefinition.tokenSale.tokenSymbol,
  }))

  const mappedBountyClaims = bountyDefinitions.map(
    ({ bonusTokenAddress, bonusTokenContract, tokenSymbol }) => ({
      bonusTokenAddress,
      bonusTokenContractAbi: bonusTokenContract.abi,
      tokenSymbol,
    })
  )

  const mappedClaims = [...mappedVoucherClaims, ...mappedBountyClaims]
  const campaignIds = [
    ...mappedVoucherClaims.map(vc => vc.bonusTokenAddress),
    ...mappedBountyClaims.map(bc => bc.bonusTokenAddress),
  ]

  const uniqueClaims = [...new Set(campaignIds)].map(bonusTokenAddress => ({
    bonusTokenAddress,
    ...mappedClaims.find(
      claim => (claim.bonusTokenAddress = bonusTokenAddress)
    ),
  }))

  return uniqueClaims
}

const mapStateToProps = ({
  currentUser: {
    user: { ethereumAddress },
  },
  dashboard: {
    bounties,
    rewards: {
      voucher: { asBuyer, asVoucherOwner },
    },
  },
  withdrawBonusTokens,
}) => {
  const {
    status: { isModalOpen },
  } = withdrawBonusTokens

  return {
    ethereumAddress,
    isModalOpen,
    campaigns: getUniqueCampaigns({
      bountyDefinitions: bounties.claimed
        .map(({ bountyTask }) => bountyTask)
        .map(({ bountyDefinition }) => bountyDefinition)
        .filter(Boolean),
      voucherClaims: [...asBuyer, ...asVoucherOwner],
    }),
    withdrawBonusTokens,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
})

const mergeProps = (
  { campaigns, ethereumAddress, isModalOpen, withdrawBonusTokens },
  { dispatch }
) => ({
  campaigns,
  dispatch,
  ethereumAddress,
  isModalOpen,
  fetchBonusTokensBalances: ({ ethereumAddress, campaigns }) =>
    campaigns.forEach(campaign =>
      dispatch(fetchBonusTokensBalance({ ethereumAddress, ...campaign }))
    ),
  onCloseModal: () => dispatch(closeModal()),
  onOpenModal: async ({
    balance,
    bonusTokenContractAbi,
    bonusTokenAddress,
    ethereumAddress,
    tokenSymbol,
  }) =>
    dispatch(
      openModal({
        balance,
        bonusTokenContractAbi,
        bonusTokenAddress,
        ethereumAddress,
        tokenSymbol,
      })
    ),
  onToggle: () =>
    isModalOpen ? dispatch(closeModal()) : dispatch(openModal()),
  withdrawBonusTokens,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(WithdrawBonusTokenButtons)
