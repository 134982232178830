import React from 'react'
import styled from 'styled-components/macro'

import logoColor from '../assets/images/logo-color.svg'

const ModifiedContainer = styled.div.attrs({
  className: 'container',
})`
  background: white;
  margin: 50px 0;
  padding: 50px;
  text-align: center;
  border-radius: 5px;
`

const Logo = styled.img`
  height: 8.5rem;
`

export default () => (
  <ModifiedContainer>
    <Logo src={logoColor} />
    <h1>Launching soon!</h1>
    <br />
    <p>The platform will open very soon. We&rsquo;re very excited!</p>

    <p>Stay tuned!</p>
    <p>&mdash; The Starbase Team</p>
  </ModifiedContainer>
)
