import { createSelector } from 'reselect'
import get from 'lodash/get'

const selectIdTokenPayload = state =>
  get(state, 'authData.authResult.idTokenPayload')

export default createSelector(
  selectIdTokenPayload,
  idTokenPayload => {
    if (!idTokenPayload) return null

    return {
      name: idTokenPayload.nickname,
      photo: idTokenPayload.picture,
      email: idTokenPayload.email,
    }
  }
)
