import React from 'react'

const privacyPolicyFile =
  'https://s3-ap-southeast-1.amazonaws.com/starbase-envs/production/starbase-platform/starbase_privacy_policy_2018_10_15.pdf'

const Privacy = () => (
  <div>
    <object
      data={privacyPolicyFile}
      type="application/pdf"
      width="100%"
      height="750px"
    >
      <embed src={privacyPolicyFile} type="application/pdf" />
      <p>
        This browser does not support PDFs. Please download the PDF to view it:{' '}
        <a href={privacyPolicyFile}>Download PDF</a>.
      </p>
    </object>{' '}
  </div>
)
export default Privacy
