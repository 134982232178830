import { change } from 'redux-form'
import get from 'lodash/get'

import store from '../../store/store'

import {
  SET_METAMASK_DATA,
  SET_METAMASK_FAILURE,
  SET_METAMASK_LOADED,
} from '../types'

import captureException from '../../sentryHelpers/captureException'
import { requestEthereumAddressIsUsed } from '../currentUser/currentUserActions'

import { METAMASK_IS_LOCKED_ERROR } from '../../errors'

const setMetamaskData = ({ account, network }) => ({
  type: SET_METAMASK_DATA,
  payload: { account, network },
})

export const setMetamaskLoaded = () => ({ type: SET_METAMASK_LOADED })

const syncAccount = () => dispatch => {
  try {
    // Request account access if needed
    return window.ethereum
      .enable()
      .then(accounts => {
        if (accounts.length === 0) {
          dispatch({
            type: SET_METAMASK_FAILURE,
            error: METAMASK_IS_LOCKED_ERROR,
          })
        }
        return accounts[0]
      })
      .then(account => {
        const storedAddress = get(
          store.getState(),
          'form.profileForm.values.ethereumAddress',
          null
        )

        const locationType = get(store.getState(), 'location.type', null)
        const locationStep = get(store.getState(), 'location.payload.step', 0)
        if (locationType === 'PROFILE' && locationStep === 1) {
          if (!storedAddress)
            dispatch(
              requestEthereumAddressIsUsed({
                ethereumAddress: account,
              })
            )

          if (account !== storedAddress)
            dispatch(
              requestEthereumAddressIsUsed({
                ethereumAddress: account,
              })
            )

          store.dispatch(change('profileForm', 'ethereumAddress', account))
        }

        dispatch(setMetamaskData({ account }))

        return account
      })
      .catch(error => {
        captureException(error)
        dispatch(setMetamaskData({ account: null }))
        dispatch({
          type: SET_METAMASK_FAILURE,
          error: error.message,
        })
      })
  } catch (error) {
    captureException(error)
    dispatch(setMetamaskData({ account: null }))
    dispatch({
      type: SET_METAMASK_FAILURE,
      error: error.message,
    })
  }
}

const syncNetwork = () => dispatch => {
  return window.web3.eth.net
    .getNetworkType()
    .then(netId => {
      dispatch(setMetamaskData({ network: netId }))
      return netId
    })
    .catch(error => {
      captureException(error)
      dispatch(setMetamaskData({ network: null }))
      dispatch({
        type: SET_METAMASK_FAILURE,
        error: error.message,
      })
    })
}

export const syncAllMetaMask = () => async dispatch => {
  if (!window.web3) return
  if (!window.web3.eth) return
  if (!window.web3.eth.getAccounts()) return

  const account = await dispatch(syncAccount())
  if (!account) return

  return dispatch(syncNetwork())
}
