import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const ProgressBarWrapper = styled.div`
  overflow: visible;
  margin-bottom: 30px;
  position: relative;
  display: block;
  height: 15px;
  background: ${props => props.theme.color.lightBlue};
  border-radius: ${props => props.theme.borderRadius.tab};
`
const ProgressTextWrapper = styled.div`
  position: relative;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: ${props => 100 - props.progress + '%'};
`

const StyledProgressBar = styled.div`
  background: ${props => props.theme.color.greenSuccess};
  position: absolute;
  border-radius: 4px;
  top: 0;
  bottom: 0;
  left: 0;
  right: ${props => 100 - props.progress + '%'};
  &:after {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: ${props => props.theme.color.greenSuccessBright};
    position: absolute;
    right: -10px;
    top: -2px;
  }
`
const ProgressText = styled.div`
  position: absolute;
  top: 17px;
  right: -18px;
  font-size: ${props => props.theme.font.xs};
`

const ProgressBar = ({ progress }) => (
  <ProgressBarWrapper>
    <StyledProgressBar progress={progress} />
    <ProgressTextWrapper progress={progress}>
      <ProgressText>{progress}%</ProgressText>
    </ProgressTextWrapper>
  </ProgressBarWrapper>
)

ProgressBar.propTypes = {
  progress: PropTypes.string,
}

export default ProgressBar
