import { schema } from 'normalizr'

const getComment = depth => {
  return new schema.Entity(
    'comments',
    depth === 0
      ? {}
      : {
          comments: [getComment(depth - 1)],
        }
  )
}

export default [getComment(2)]
