import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { postCreateBountyTask } from 'store/project/projectActions'

import React from 'react'
import { Field, Form } from 'redux-form'
import { validateRequired, validateIsNumber } from 'formHelpers/formValidation'

import FieldText from 'app/components/forms/fields/FieldText'
import FieldTextArea from 'app/components/forms/fields/FieldTextArea'
import FieldSelect from 'app/components/forms/fields/FieldSelect'
import BodyContainer from 'app/components/BodyContainer'
import Wrapper from 'app/components/Wrapper'

const bountyTaskTypes = [
  { label: 'NORMAL', value: 'NORMAL' },
  { label: 'SHARE ON TWITTER', value: 'SOCIAL_TWITTER_SHARE' },
  { label: 'SHARE ON FACEBOOK', value: 'SOCIAL_FACEBOOK_SHARE' },
]

const ProjectCreateBountyTask = ({ onSubmit, submitting }) => (
  <Wrapper>
    <BodyContainer>
      <Form onSubmit={onSubmit}>
        <h2>New Bounty Task</h2>
        <hr />
        <div className="form-group">
          <label>Name</label>
          <small className="form-text text-muted">
            Give a name for you bounty task
          </small>
          <Field
            isTouched
            component={FieldText}
            className="form-control"
            name="name"
            validate={[validateRequired]}
          />
        </div>

        <div className="form-group">
          <label>Max. Reward Amount</label>
          <small className="form-text text-muted">
            Insert the maximum amount of tokens to be given away for that task
          </small>
          <Field
            isTouched
            component={FieldText}
            className="form-control"
            name="maxRewardAmount"
            validate={[validateRequired, validateIsNumber]}
          />
        </div>

        <div className="form-group">
          <label>Bounty Task Type</label>
          <small className="form-text text-muted">
            Select a type of bounty task.
            <br />
            <b>Normal:</b> the hunters will post a URL as proof of their work.
            <br />
            <b>Share on Twitter:</b> the hunters will share your description on
            their twitter account.
            <br />
            <b>Share on Facebook:</b> the hunters will share your description on
            their facebook account.
          </small>
          <Field
            isTouched
            name="type"
            component={FieldSelect}
            placeholder="Select a type of task"
            size="md"
            options={bountyTaskTypes}
            validate={[validateRequired]}
          />
        </div>

        <div className="form-group">
          <label>Description</label>
          <small className="form-text text-muted">
            Please insert a description of your bounty task. What kind of
            actions the bounty hunters should peform.
          </small>
          <Field
            isTouched
            component={FieldTextArea}
            className="form-control"
            name="description"
            validate={[validateRequired]}
          />
        </div>

        <hr />
        <button disabled={submitting} type="submit" className="btn btn-success">
          Create
        </button>
      </Form>
    </BodyContainer>
  </Wrapper>
)

const mapStateToProps = ({
  location: {
    payload: { slug, bountyDefinitionId },
  },
}) => ({
  slug,
  bountyDefinitionId,
})

const mapDispatchToProps = dispatch => ({
  dispatch,
})

const mergeProps = (
  { slug, bountyDefinitionId },
  { dispatch },
  { handleSubmit, submitting }
) => ({
  slug,
  bountyDefinitionId,
  submitting,
  onSubmit: handleSubmit(newTask =>
    dispatch(postCreateBountyTask({ ...newTask, slug, bountyDefinitionId }))
  ),
})

export default reduxForm({
  form: 'projectCreateBountyTask',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(ProjectCreateBountyTask)
)
