import React from 'react'
import styled from 'styled-components/macro'

import FieldErrorMessage from './FieldErrorMessage'

const Input = styled.input`
  height: 52px;
  color: ${props => props.theme.color.dark};
  font-size: ${props => props.theme.font.md};
  border: 1px solid ${props => props.theme.color.inputBorder};
  margin-bottom: ${props => props.theme.spacing.fieldMarginBottom};
  &.form-control {
    border-color: ${props =>
      props.hasError ? props.theme.color.red : props.theme.color.inputBorder};
  }
`

const FieldText = ({
  autoFocus,
  disabled,
  input,
  isTouched,
  change,
  meta,
  placeholder,
  className,
  isShowingError = true,
}) => (
  <div>
    <Input
      {...input}
      autoFocus={autoFocus}
      hasError={(isTouched || meta.dirty) && meta.error}
      className={className ? className : 'form-control'}
      placeholder={placeholder}
      onChange={change ? change : input.onChange}
      type="text"
      disabled={disabled}
    />
    {isShowingError && <FieldErrorMessage isTouched={isTouched} meta={meta} />}
  </div>
)

export default FieldText
