import store from '../store/store'

import {
  setMetamaskLoaded,
  syncAllMetaMask,
} from '../store/metaMask/metaMaskActions'

import { fetchBalancesData } from '../store/balances/balancesActions'

export default async () => {
  await store.dispatch(syncAllMetaMask())
  store.dispatch(setMetamaskLoaded())

  const syncMetaMaskInterval = setInterval(
    () => store.dispatch(syncAllMetaMask()),
    5000
  )
  const fetchBalancesInterval = setInterval(
    () => store.dispatch(fetchBalancesData()),
    5000
  )

  return { syncMetaMaskInterval, fetchBalancesInterval }
}
