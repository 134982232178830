import React from 'react'
import styled from 'styled-components/macro'
import AgreeWithTermsCheck from './AgreeWithTermsCheck'
import ContractsInfo from './ContractsInfo'
import CurrencyChooser from './CurrencyChooser'
import PaymentBoxes from './PaymentBoxes'
import VoucherCode from './VoucherCodeContainer'

const SubmitButton = styled.button.attrs({
  className: 'btn btn-lg btn-success w-100',
  type: 'submit',
})`
  background-color: ${({ theme }) => theme.color.btnSuccess} !important;
  &:hover {
    background-color: ${({ theme }) => theme.color.btnSuccessHover}!important;
  }
`

const Form = ({
  fiatRates,
  isFieldPurchaseValueEnabled,
  onSubmit,
  payForm,
  payStatus,
  project,
  sale,
  useEth,
  useStar,
}) => (
  <form onSubmit={onSubmit}>
    <h2 className="mt-2 text-center">Contribute to {project.name}</h2>

    {payForm.purchaseCurrency == null && (
      <CurrencyChooser isEthAccepted={sale.isEthAccepted} />
    )}

    {payForm.purchaseCurrency != null && (
      <>
        <div>
          <h4 className="col-12 text-center mt-5">
            Enter the amount to contribute on{' '}
            <span className="badge badge-success">{sale.name}</span>
          </h4>

          <div className="row justify-content-center">
            <div className="col-xl-12">
              <div className="row justify-content-center align-items-center">
                <PaymentBoxes
                  fiatRates={fiatRates}
                  isFieldPurchaseValueEnabled={isFieldPurchaseValueEnabled}
                  purchaseCurrency={payForm.purchaseCurrency}
                  purchaseValue={payForm.purchaseValue}
                  sale={sale}
                  useEth={useEth}
                  useStar={useStar}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <div className="col-xl-8">
            <>
              <ContractsInfo sale={sale} />

              <VoucherCode tokenSale={sale} />

              <AgreeWithTermsCheck
                project={project}
                tokenSaleContractAddress={sale.tokenSaleContractAddress}
              />

              <div className="mt-3 text-center">
                <SubmitButton
                  disabled={!payForm.isAgreedWithTerms || payStatus.isPending}
                >
                  Purchase
                </SubmitButton>
              </div>
            </>
          </div>
        </div>
      </>
    )}
  </form>
)

export default Form
