import * as Sentry from '@sentry/browser'

export default error => {
  console.error('original error: ', error)

  try {
    if (process.env.REACT_APP_DISABLE_ERROR_REPORTING === 'true') return

    const eventId = Sentry.captureException(error)
    const sentryUrl = `https://sentry.io/starbase-co/starbase-platform?query=${eventId}`
    console.error(`\n${error.message}\n${sentryUrl}\n`)

    return sentryUrl
  } catch (e) {
    console.error('!!!!!!!!!! REPORT TO SENTRY FAILED !!!!!!!!!!')
  }
}
