import React from 'react'
import BodyContainer from 'app/components/BodyContainer'
import Wrapper from 'app/components/Wrapper'

import BountyTask from './BountyTask'

export default ({ campaigns, isLoading }) => (
  <>
    {isLoading ? (
      <Wrapper className="mt-4 mb-4">
        <BodyContainer
          className="pt-5 pb-5 text-center"
          style={{ minHeight: 0 }}
        >
          Loading...
        </BodyContainer>
      </Wrapper>
    ) : (
      <>
        {!!campaigns.length ? (
          <>
            {campaigns.map((campaign, index) => (
              <BountyTask campaign={campaign} key={index} />
            ))}
          </>
        ) : (
          <Wrapper className="mb-4">
            <BodyContainer
              className="pt-5 pb-5 text-center"
              style={{ minHeight: 0 }}
            >
              <h5 className="text-muted">No Bounty Campaigns published.</h5>
            </BodyContainer>
          </Wrapper>
        )}
      </>
    )}
  </>
)
