import { connect } from 'react-redux'
import { groupBy } from 'lodash'
import Bounties from './Bounties'

const toArray = obj => {
  return Object.keys(obj).map(key => {
    return obj[key]
  })
}

const mapStateToProps = ({ bounties: { allBountyTasks, isLoading } }) => ({
  allBountyTasks,
  isLoading,
  campaigns: toArray(
    groupBy(allBountyTasks, b => b.bountyDefinition.projectId)
  ),
})

export default connect(mapStateToProps)(Bounties)
