import React from 'react'
import Tooltip from 'app/components/Tooltip'

const getFiatPreview = ({
  fiat,
  fiatRates,
  purchaseCurrency,
  purchaseValue,
}) => {
  if (!fiatRates || fiatRates.length === 0) return 0

  const fiatRate = fiatRates.find(
    r => r.symbol === purchaseCurrency && r.fiat === fiat
  )
  const rate = fiatRate.value
  const result = purchaseValue ? purchaseValue * rate : 0
  return result.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: result > 1 ? 2 : 6,
  })
}

const tooltipContent =
  'USD estimation included without warranty, for the exact value, please refer to the conversion within Metamask.'

const UsdPreview = ({ fiatRates, purchaseCurrency, purchaseValue }) => (
  <span className="text-muted">
    <Tooltip TooltipContent={() => tooltipContent}>approximately</Tooltip>: $USD{' '}
    {getFiatPreview({
      fiat: 'USD',
      fiatRates,
      purchaseCurrency,
      purchaseValue,
    })}
  </span>
)

export default UsdPreview
