import { Provider } from 'react-redux'
import React from 'react'
import ReactDOM from 'react-dom'

import store from 'store/store'
import googleAnalytics from 'gaHelpers/googleAnalytics'
import initMetaMaskSyncController from './syncMetaMaskHelpers/initMetaMaskSyncController'
import purchasesStatusSubscribtionSync from 'purchasesStatusSubscribtionSync'
import sentryInit from 'sentryHelpers/init'
import web3Init from 'web3Helpers/init'

import App from 'app/AppContainer'
import DisabledFrontend from 'app/scenes/DisabledFrontend'

const { REACT_APP_ENABLE_HOT_RELOAD } = process.env

const disableFronted = process.env.REACT_APP_DISABLE_FRONTEND === 'true'
const bypassDisableFrontend =
  process.env.REACT_APP_DISABLE_FRONTEND_ALLOW_BYPASS === 'true' &&
  localStorage.getItem('IDDQD') === 'true'

sentryInit() // Sentry is best initialized as early as possible (before initApp)

const initApp = () => {
  web3Init()
  googleAnalytics()
  setInterval(web3Init, 3000)
  initMetaMaskSyncController()
  purchasesStatusSubscribtionSync()

  const render = App => {
    const root = document.getElementById('root')
    ReactDOM.render(
      disableFronted && !bypassDisableFrontend ? (
        <DisabledFrontend />
      ) : (
        <Provider store={store}>
          <App />
        </Provider>
      ),
      root
    )
  }

  if (!global.Intl) {
    import('intl')
      .then(() => import('intl/locale-data/jsonp/en.js'))
      .then(() => render(App))
  } else {
    render(App)
  }

  if (module.hot && REACT_APP_ENABLE_HOT_RELOAD === 'true') {
    module.hot.accept('./app/AppContainer', () => {
      const App = require('./app/AppContainer').default
      render(App)
    })
  }
}

initApp()
