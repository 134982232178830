import { connect } from 'react-redux'

import selectActiveModalName from 'store/selectors/selectActiveModalName'
import TgeUserRedirectionModal from './TgeUserRedirectionModal'

const MODAL_NAME = 'TGE_REDIRECTION'

const mapStateToProps = ({
  authData: {
    authResult: {
      idTokenPayload: { nickname },
    },
  },
  ...state
}) => ({
  nickname,
  isOpen: selectActiveModalName(state) === MODAL_NAME,
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TgeUserRedirectionModal)
