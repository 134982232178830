import React, { useState } from 'react'
import Link from 'redux-first-router-link'
import styled from 'styled-components/macro'
import { get } from 'lodash'

import { toProjectEdit, toProject } from 'store/routerActions'

import BodyContainer from 'app/components/BodyContainer'
import ProtectedContent from 'app/components/ProtectedContent'
import ProtectedPageModal from 'app/components/ProtectedPageModal'
import Wrapper from 'app/components/Wrapper'
import Markdown from 'app/components/Markdown'

import BountyCampaign from './bounty/BountyCampaign'
import BountyClaims from './bounty/BountyClaims'

const Tab = ({ setTab, tab }) => (
  <ul className="nav nav-tabs mt-4 mb-5">
    <li className="nav-item" onClick={() => setTab(0)}>
      <div className={tab === 0 ? 'nav-link active' : 'nav-link'}>
        Description
      </div>
    </li>

    <li className="nav-item" onClick={() => setTab(1)}>
      <div className={tab === 1 ? 'nav-link active' : 'nav-link'}>
        Bounty Campaigns
      </div>
    </li>

    <li className="nav-item" onClick={() => setTab(2)}>
      <div className={tab === 2 ? 'nav-link active' : 'nav-link'}>
        Bounty Claims
      </div>
    </li>
  </ul>
)

const SmallHeader = styled.div`
  font-size: 20px;
  color: grey;
`

const SmallBox = styled.div.attrs({
  className: 'col-sm mb-4',
})`
  color: black;
`
const ProjectDetails = ({ project }) => (
  <div class="row">
    <SmallBox>
      <SmallHeader>Token Icon URL:</SmallHeader>{' '}
      <img
        className="rounded"
        src={project.tokenIconUrl}
        height="100"
        alt="project token logo"
      />
      {/* <a href={project.tokenIconUrl} target="_blank" rel="noopener noreferrer">
        {project.tokenIconUrl}
      </a> */}
    </SmallBox>

    <SmallBox>
      <SmallHeader>White Paper URL:</SmallHeader>
      <a href={project.whitepaperUrl} target="_blank" rel="noopener noreferrer">
        {project.whitepaperUrl}
      </a>
    </SmallBox>
  </div>
)

const ProjectAnalytics = ({
  projectViews,
  purchaseViews,
  // saleViews,
  purchaseCanceled,
  purchaseStart,
  purchaseSuccess,
}) => (
  <>
    <div class="row">
      <SmallBox>
        <div class="card card-stats bg-success">
          <div class="card-body">
            <div class="d-flex">
              <div>
                <div class="icon text-white icon-sm">
                  <i class="fa fa-wallet" />
                </div>
              </div>
              <div class="pl-4">
                <span class="d-block h5 text-white mr-2 mb-1">
                  {get(purchaseSuccess, 'aggregate.count')}
                </span>
                <span class="text-white">Successful Payments</span>
              </div>
            </div>
          </div>
        </div>
      </SmallBox>

      <SmallBox>
        <div class="card card-stats bg-info">
          <div class="card-body">
            <div class="d-flex">
              <div>
                <div class="icon text-white icon-sm">
                  <i class="fa fa-shopping-basket" />
                </div>
              </div>
              <div class="pl-4">
                <span class="d-block h5 text-white mr-2 mb-1">
                  {get(purchaseStart, 'aggregate.count')}
                </span>
                <span class="text-white">Processed Payments</span>
              </div>
            </div>
          </div>
        </div>
      </SmallBox>

      <SmallBox>
        <div class="card card-stats bg-danger">
          <div class="card-body">
            <div class="d-flex">
              <div>
                <div class="icon text-white icon-sm">
                  <i class="fa fa-times-circle" />
                </div>
              </div>
              <div class="pl-4">
                <span class="d-block h5 text-white mr-2 mb-1">
                  {get(purchaseCanceled, 'aggregate.count')}
                </span>
                <span class="text-white">Canceled Payments</span>
              </div>
            </div>
          </div>
        </div>
      </SmallBox>
    </div>

    <div class="row">
      <SmallBox>
        <div class="card card-stats">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <h6 class="text-muted mb-1">Payment Pageviews</h6>
                <span class="h5 font-weight-bold mb-0">
                  {get(purchaseViews, 'aggregate.count')}
                </span>
              </div>
              <div class="col-auto">
                <div class="icon bg-gradient-success text-white rounded-circle icon-shape">
                  <i class="fa fa-wallet" />
                </div>
              </div>
            </div>
            {/* <p class="mt-3 mb-0 text-sm">
              <span class="badge badge-soft-success mr-2">
                <i class="fa fa-arrow-up" /> 25%
              </span>
              <span class="text-nowrap">Since last month</span>
            </p> */}
          </div>
        </div>
      </SmallBox>

      <SmallBox>
        <div class="card card-stats">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <h6 class="text-muted mb-1">Conversion Rate</h6>
                <span class="h5 font-weight-bold mb-0">
                  {(get(purchaseSuccess, 'aggregate.count') /
                    get(projectViews, 'aggregate.count')) *
                    100}
                  %
                </span>
              </div>
              <div class="col-auto">
                <div class="icon bg-info text-white rounded-circle icon-shape">
                  <i class="fa fa-shopping-cart" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </SmallBox>

      <SmallBox>
        <div class="card card-stats">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <h6 class="text-muted mb-1">Project Pageviews</h6>
                <span class="h5 font-weight-bold mb-0">
                  {get(projectViews, 'aggregate.count')}
                </span>
              </div>
              <div class="col-auto">
                <div class="icon bg-warning text-white rounded-circle icon-shape">
                  <i class="fa fa-home" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </SmallBox>
    </div>
  </>
)

const GoogleAnalytics = ({ access_token, viewId, slug }) => {
  const { gapi } = window
  gapi.analytics.ready(() => {
    /**
     * Authorize the user immediately if the user has already granted access.
     * If no access has been created, render an authorize button inside the
     * element with the ID "embed-api-auth-container".
     */
    gapi.analytics.auth.authorize({
      serverAuth: {
        access_token,
      },
    })

    let timelineChart = new gapi.analytics.googleCharts.DataChart({
      query: {
        metrics: 'ga:pageviews,ga:sessions,ga:users',
        dimensions: 'ga:date',
        'start-date': '120daysAgo',
        'end-date': 'yesterday',
        filters: `ga:pagePath==/projects/${slug}`,
      },
      chart: {
        container: 'chart-container',
        type: 'LINE',
        options: {
          width: '100%',
        },
      },
    })

    timelineChart.set({ query: { ids: viewId } }).execute()

    // countries
    let countriesChart = new gapi.analytics.googleCharts.DataChart({
      query: {
        metrics: 'ga:pageviews',
        dimensions: 'ga:country',
        'start-date': '120daysAgo',
        'end-date': 'yesterday',
        'max-results': 6,
        sort: '-ga:pageviews',
        filters: `ga:pagePath==/projects/${slug}`,
      },
      chart: {
        container: 'chart-1-container',
        type: 'PIE',
        options: {
          width: '100%',
          pieHole: 4 / 9,
        },
      },
    })

    countriesChart.set({ query: { ids: viewId } }).execute()
  })

  return (
    <>
      <h2>
        Page Metrics: <small class="text-muted">past 120 days</small>
      </h2>
      <div id="embed-api-auth-container">
        <div id="chart-container" />
        <h3 class="text-center">Users</h3>
        <div id="chart-1-container" />
      </div>
    </>
  )
}

const ProjectDashboard = ({
  isUserProjectOwner,
  isLoading,
  bountyCampaigns,
  bountyClaims,
  project,
  analytics,
  googleAnalytics,
}) => {
  const [tab, setTab] = useState(0)

  return (
    <Wrapper>
      {isLoading ? (
        <div className="alert alert-light text-center p-5" role="alert">
          Loading...
        </div>
      ) : (
        <>
          <ProtectedPageModal
            title="Access Denied"
            isOpen={!isUserProjectOwner}
          >
            <p>In order to access this page you must be a project owner</p>
          </ProtectedPageModal>

          <BodyContainer>
            <ProtectedContent isProtected={!isUserProjectOwner}>
              <h2>
                <Link to={toProject({ slug: project.slug })}>
                  {project.name}
                </Link>

                <Link
                  className="btn btn-primary ml-4"
                  to={toProjectEdit(project.slug)}
                >
                  Edit project
                </Link>
              </h2>
              <hr />
              <ProjectAnalytics {...analytics} />
              <GoogleAnalytics {...googleAnalytics} />
              <ProjectDetails project={project} />
              <Tab tab={tab} setTab={setTab} />
              {tab === 0 && <Markdown content={project.description} />}

              {tab === 1 && (
                <BountyCampaign project={project} campaigns={bountyCampaigns} />
              )}

              {tab === 2 && (
                <BountyClaims project={project} bountyClaims={bountyClaims} />
              )}
            </ProtectedContent>
          </BodyContainer>
        </>
      )}
    </Wrapper>
  )
}

export default ProjectDashboard
