import React from 'react'
import { Field, Form } from 'redux-form'

import { validateRequired } from 'formHelpers/formValidation'

import FieldTextArea from 'app/components/forms/fields/FieldTextArea'

export default ({ onSubmit }) => (
  <Form className="mb-3" onSubmit={onSubmit}>
    <div className="form-group">
      <label>Reason:</label>
      <Field
        component={FieldTextArea}
        name="poComment"
        placeholder="Please specify the reason for rejection"
        validate={[validateRequired]}
      />
    </div>

    <button type="submit" className="btn btn-success">
      Confirm
    </button>
  </Form>
)
