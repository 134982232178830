import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import { withdrawBonusTokens } from 'store/withdrawBonusTokens/withdrawBonusTokensActions'

import WithdrawBonusTokenModal from './WithdrawBonusTokenModal'

const mapStateToProps = (
  {
    withdrawBonusTokens: {
      status: { completedSteps },
      openClaim,
    },
  },
  { isOpen, onToggle }
) => {
  if (!openClaim)
    return {
      completedSteps,
      isOpen,
      onToggle,
    }

  const {
    balance,
    bonusTokenContractAbi,
    bonusTokenAddress,
    ethereumAddress,
    tokenSymbol,
  } = openClaim

  return {
    completedSteps,
    isOpen,
    onToggle,
    balance,
    bonusTokenContractAbi,
    bonusTokenAddress,
    ethereumAddress,
    tokenSymbol,
  }
}
const mapDispatchToProps = dispatch => ({
  dispatch,
})

const mergeProps = (
  {
    completedSteps,
    isOpen,
    onToggle,
    balance,
    bonusTokenContractAbi,
    bonusTokenAddress,
    ethereumAddress,
    tokenSymbol,
  },
  { dispatch },
  { handleSubmit, invalid, submitSucceeded, reset }
) => ({
  completedSteps,
  invalid,
  isOpen,
  submitSucceeded,
  balance,
  bonusTokenContractAbi,
  bonusTokenAddress,
  ethereumAddress,
  onToggle: () => {
    reset()
    onToggle()
  },
  onSubmit: handleSubmit(() =>
    dispatch(
      withdrawBonusTokens({
        bonusTokenContractAbi,
        bonusTokenAddress,
        ethereumAddress,
      })
    )
  ),
  tokenSymbol,
})

export default reduxForm({
  form: 'withdrawForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(WithdrawBonusTokenModal)
)
