import {
  FETCH_BALANCES_DATA_REQUEST,
  FETCH_BALANCES_DATA_SUCCESS,
  FETCH_BALANCES_DATA_FAILURE,
} from '../types'

import captureException from '../../sentryHelpers/captureException'
import { authorizedGet } from '../../api'

export const fetchBalancesData = () => dispatch => {
  dispatch({ type: FETCH_BALANCES_DATA_REQUEST })

  return authorizedGet({ url: '/api/balances' })
    .then(payload => dispatch({ type: FETCH_BALANCES_DATA_SUCCESS, payload }))
    .catch(error => {
      captureException(error)
      dispatch({ type: FETCH_BALANCES_DATA_FAILURE, error: error.message })
    })
}
