import styled from 'styled-components'

export default styled.span`
  color: ${props =>
    props.color === 'dark'
      ? props.theme.color.darker
      : props.theme.color.lighter};
  font-size: ${props => props.theme.font.md};
  display: block;
  position: relative;
  .badge-info {
    color: #fff;
    position: absolute;
    right: -20px;
    @media (min-width: 420px) {
      right: 30px;
    }
  }
`
