import React from 'react'
import classnames from 'classnames'
import styled from 'styled-components/macro'

import Button from '../../../../components/button/Button'

const Footer = styled.div`
  margin: 30px 0 0;
`

const FormFooter = ({
  isSubmitting,
  submissionErrors,
  toPrevStep,
  leftButtonText,
  rightButtonText,
}) => (
  <Footer>
    {submissionErrors && (
      <div
        className={classnames('row', 'justify-content-center')}
        style={{ color: 'red' }}
      >
        <div className="col-auto">
          <div>
            <p>Please correct these errors and try again:</p>
            <ul>
              {submissionErrors.map(e => (
                <li>{e}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    )}

    <div className="row justify-content-between">
      {leftButtonText && (
        <div className="col-auto">
          <Button onClick={toPrevStep} outline>
            {leftButtonText}
          </Button>
        </div>
      )}

      {rightButtonText && (
        <div
          className={classnames(
            ['col-md-5', 'text-md-right'],
            leftButtonText ? 'offset-md-3' : 'offset-md-5'
          )}
        >
          <p>
            <small>
              By clicking <b>Next</b>, you agree to give consent for processing
              and storing your data as prompted by the platform.
            </small>
          </p>
        </div>
      )}

      {rightButtonText && (
        <div className="col-auto">
          <Button disabled={isSubmitting}>{rightButtonText}</Button>
        </div>
      )}
    </div>
  </Footer>
)

export default FormFooter
