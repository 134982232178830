import React from 'react'
import styled from 'styled-components/macro'
import { Field } from 'redux-form'
import { createNumberMask } from 'redux-form-input-masks'

import ArrowIcon from '../../ArrowIcon'
import CurrencySwitch from './CurrencySwitch'
import UsdPreview from './UsdPreview'

const etherMask = createNumberMask({
  decimalPlaces: 6,
  locale: 'en-US',
})

const starMask = createNumberMask({
  decimalPlaces: 2,
  locale: 'en-US',
})

const CurrencySymbol = styled.div`
  color: white;
  font-size: ${props => props.theme.font.md};
  font-weight: 700;
  text-align: center;
  padding: 12px 0;
  border-radius: ${({ theme }) => theme.borderRadius.tab};
  background-color: rgba(0, 0, 0, 0.15);
`

const PaymentBox = styled.div`
  height: 100px;
  box-shadow: ${({ theme }) => theme.color.boxShadow};
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.borderRadius.tab};
  padding-left: 1em;
  padding-right: 1em;
  margin-bottom: ${props => props.selectedcurrency === 'other' && '23px'};
  padding-top: 1.5em;
  background-color: ${props =>
    props.selectedcurrency == null
      ? props.theme.color.lighter
      : props.selectedcurrency === 'STAR'
      ? props.theme.color.primaryPurple
      : props.selectedcurrency === 'ETH'
      ? props.theme.color.darker
      : props.theme.color.iconGrey};
`

const PaymentInput = styled(Field).attrs({
  className: 'form-control form-control-lg',
})`
  && {
    background: transparent;
    border: none;
    border-bottom: 1px solid;
    border-radius: 0px;
    color: white;
    outline: none;
  }
  &&:focus {
    background: transparent;
    color: white;
    outline: none;
  }
`

const PaymentOutput = styled.input.attrs({
  className: 'form-control form-control-lg',
})`
  &&& {
    background: transparent;
    border: none;
    border-bottom: 1px solid;
    border-radius: 0px;
    color: white;
    text-align: center;
  }
  &&::placeholder {
    font-size: 14px;
    color: white;
  }
`

const PaymentBoxOuter = styled(({ title, className, children }) => (
  <div className={className}>
    <h6 className="text-secondary font-weight-bold">{title}</h6>
    {children}
  </div>
)).attrs({
  className: 'col-12 col-lg-5 mt-3',
})`
  min-height: 150px !important;
  text-align: center;
`
const PaymentBoxes = ({
  fiatRates,
  isFieldPurchaseValueEnabled,
  purchaseCurrency,
  purchaseValue,
  sale,
  useEth,
  useStar,
}) => (
  <>
    <PaymentBoxOuter title="CONTRIBUTE">
      {sale.isEthAccepted && (
        <CurrencySwitch
          purchaseCurrency={purchaseCurrency}
          useEth={useEth}
          useStar={useStar}
        />
      )}

      <PaymentBox selectedcurrency={purchaseCurrency}>
        <div className="input-group align-items-end">
          <div className="col-8">
            <PaymentInput
              component="input"
              disabled={!isFieldPurchaseValueEnabled}
              name="purchaseValue"
              type="text"
              {...(purchaseCurrency === 'STAR' ? starMask : etherMask)}
            />
          </div>
          <div className="col-4">
            <CurrencySymbol>{purchaseCurrency}</CurrencySymbol>
          </div>
        </div>
      </PaymentBox>

      <UsdPreview
        fiatRates={fiatRates}
        purchaseCurrency={purchaseCurrency}
        purchaseValue={purchaseValue}
      />
    </PaymentBoxOuter>

    <div className="col-12 col-lg-2 mt-4 text-center">
      <ArrowIcon />
    </div>

    <PaymentBoxOuter title="RECEIVE">
      <PaymentBox selectedcurrency="other">
        <div className="input-group align-items-end">
          <div className="col-8">
            <PaymentOutput
              disabled={true}
              name="amountPurchaseToken"
              placeholder="Amount you will receive"
              type="text"
              value={
                purchaseValue
                  ? (
                      (purchaseCurrency === 'STAR'
                        ? sale.starPrice
                        : sale.ethPrice) * purchaseValue
                    ).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : ''
              }
            />
          </div>
          <div className="col-4">
            <CurrencySymbol>{sale.tokenSymbol}</CurrencySymbol>
          </div>
        </div>
      </PaymentBox>
    </PaymentBoxOuter>
  </>
)

export default PaymentBoxes
