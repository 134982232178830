import React from 'react'
import styled from 'styled-components/macro'

import PageTitle from 'app/components/pagetitle/PageTitle'

import About from 'app/scenes/about/AboutContainer'
import Bounties from 'app/scenes/bounties/BountiesContainer'
import Dashboard from 'app/scenes/dashboard/DashboardContainer'
import Dev from 'app/scenes/dev/DevContainer'
import Pay from 'app/scenes/pay/PayContainer'
import Privacy from 'app/scenes/privacy/PrivacyContainer'
import Profile from 'app/scenes/profile/ProfileContainer'
import Project from 'app/scenes/project/ProjectContainer'
import Projects from 'app/scenes/projects/ProjectsContainer'
import RegistrationsStart from 'app/scenes/registrationsStart/RegistrationsStartContainer'
import TermsOfUse from 'app/scenes/termsOfUse/TermsOfUseContainer'
import TokenSale from 'app/scenes/project/TokenSaleContainer'
import UserProfile from 'app/scenes/profile/components/userProfile/UserProfileContainer'

import ProjectDashboard from 'app/scenes/project/dashboard/ProjectDashboardContainer'
import ProjectEdit from 'app/scenes/project/dashboard/edit/ProjectEditContainer'
import ProjectCreateBountyCampaign from 'app/scenes/project/dashboard/bounty/ProjectCreateBountyCampaign'
import ProjectCreateBountyTask from 'app/scenes/project/dashboard/bounty/ProjectCreateBountyTask'

import ProjectOwnerContainer from 'app/scenes/project/dashboard/ProjectOwnerContainer'

const BodyInner = styled.div`
  background: #f8f8fb;
  border-radius: 0px;
  overflow: hidden;
  padding-bottom: 45px;
  @media (min-width: ${props => props.theme.mediaQueries.md}) {
    border-radius: ${props => props.theme.borderRadius.container};
  }
`

const ModifiedContainer = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  @media (max-width: ${props => props.theme.mediaQueries.md}) {
    .container {
      width: 100%;
      max-width: none;
    }
  }
`
const ModifiedColumn = styled.div`
  padding-top: 2em;
  @media (max-width: ${props => props.theme.mediaQueries.md}) {
    padding-left: 0;
    padding-right: 0;
  }
`

const Body = ({ scene }) => (
  <div className="page-content">
    <BodyInner>
      <ModifiedContainer className="p-0 px-md-4">
        <div className="row">
          {scene === 'REGISTRATIONS_START' && (
            <ModifiedColumn className="col-12">
              <RegistrationsStart />
            </ModifiedColumn>
          )}

          {scene === 'ABOUT' && (
            <ModifiedColumn className="col-12">
              <PageTitle title="About" />
              <About />
            </ModifiedColumn>
          )}

          {scene === 'BOUNTIES' && (
            <ModifiedColumn className="col-12">
              <Bounties />
            </ModifiedColumn>
          )}

          {scene === 'DASHBOARD' && (
            <ModifiedColumn className="col-12">
              <Dashboard />
            </ModifiedColumn>
          )}

          {scene === 'DEV' && <Dev />}

          {scene === 'PAY' && (
            <ModifiedColumn className="col-12">
              <Pay />
            </ModifiedColumn>
          )}

          {scene === 'PRIVACY' && (
            <ModifiedColumn className="col-12">
              <PageTitle title="Privacy Policy" />
              <Privacy />
            </ModifiedColumn>
          )}

          {scene === 'PROFILE' && (
            <ModifiedColumn className="col-12">
              <PageTitle title="Customer Identification" />
              <Profile />
            </ModifiedColumn>
          )}

          {scene === 'PROJECTS' && (
            <ModifiedColumn className="col-12">
              <Projects />
            </ModifiedColumn>
          )}

          {scene === 'PROJECT' && (
            <ModifiedColumn className="col-12">
              <Project />
            </ModifiedColumn>
          )}

          {scene === 'PROJECT_TOKEN_SALE' && (
            <ModifiedColumn className="col-12">
              <TokenSale />
            </ModifiedColumn>
          )}

          {scene === 'PROJECT_DASHBOARD' && (
            <ModifiedColumn className="col-12">
              <ProjectDashboard />
            </ModifiedColumn>
          )}

          {scene === 'PROJECT_EDIT' && (
            <ModifiedColumn className="col-12">
              <ProjectOwnerContainer>
                <ProjectEdit form="projectEdit" />
              </ProjectOwnerContainer>
            </ModifiedColumn>
          )}

          {scene === 'PROJECT_CREATE_BOUNTY_DEFINITION' && (
            <ModifiedColumn className="col-12">
              <ProjectOwnerContainer>
                <ProjectCreateBountyCampaign form="projectCreateBountyCampaign" />
              </ProjectOwnerContainer>
            </ModifiedColumn>
          )}

          {scene === 'PROJECT_CREATE_BOUNTY_TASK' && (
            <ModifiedColumn className="col-12">
              <ProjectOwnerContainer>
                <ProjectCreateBountyTask form="projectCreateBountyTask" />
              </ProjectOwnerContainer>
            </ModifiedColumn>
          )}

          {scene === 'TERMS_OF_USE' && (
            <ModifiedColumn className="col-12">
              <PageTitle title="Terms of Use" />
              <TermsOfUse />
            </ModifiedColumn>
          )}

          {['USER_PROFILE', 'USER_PROFILE_DELETION'].includes(scene) && (
            <ModifiedColumn className="col-12">
              <UserProfile />
            </ModifiedColumn>
          )}
        </div>
      </ModifiedContainer>
    </BodyInner>
  </div>
)

export default Body
