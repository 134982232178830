import Web3 from 'web3'

import detectEnvironment from '../detectEnvironment'

// Fixes broken public pages if Metamask is not installed:
const fallbackProvider =
  detectEnvironment() === 'production'
    ? process.env.REACT_APP_WEB3_PROVIDER_URL_PRODUCTION
    : process.env.REACT_APP_WEB3_PROVIDER_URL_DEFAULT

let provider

if (window.ethereum) {
  provider = new Web3(window.ethereum)
} else if (window.web3) {
  provider = new Web3(window.web3.currentProvider)
} else {
  provider = new Web3(fallbackProvider)
}

export default () => {
  window.web3 = provider
}
