import React from 'react'
import Editor from 'rich-markdown-editor'

const MarkdownTextEditor = ({ input, initialValue }) => (
  <div>
    <Editor defaultValue={initialValue} onChange={v => input.onChange(v())} />
  </div>
)

export default MarkdownTextEditor
