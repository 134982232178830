import {
  compose as _compose,
  applyMiddleware,
  combineReducers,
  createStore,
} from 'redux'
import { connectRoutes } from 'redux-first-router'
import { reducer as formReducer } from 'redux-form'
import thunkMiddleware from 'redux-thunk'

import authReducer from './auth/authReducer'
import balancesReducer from './balances/balancesReducer'
import bountiesReducer from './bounties/bountiesReducer'
import commentsReducer from './comments/commentsReducer'
import currentUserReducer from './currentUser/currentUserReducer'
import dashboardReducer from './dashboard/dashboardReducer'
import devReducer from './dev/devReducer'
import metaMaskReducer from './metaMask/metaMaskReducer'
import payReducer from './pay/payReducer'
import profileReducer from './profile/profileReducer'
import projectReducer from './project/projectReducer'
import projectsReducer from './projects/projectsReducer'
import rankingReducer from './ranking/rankingReducer'
import routesMap from '../routesMap'
import swapReducer from './swap/swapReducer'
import uiReducer from './ui/uiReducer'
import valuesReducer from './values/valuesReducer'
import voucherReducer from './voucher/voucherReducer'
import withdrawBonusTokensReducer from './withdrawBonusTokens/withdrawBonusTokensReducer'

import { persistAuthData } from './persistAuthData'

import sentryReporterMiddleware from './sentryReporterMiddleware'
import analyticsEventsMiddleware from './analyticsEventsMiddleware'

const {
  REACT_APP_ENABLE_REDUX_DEVTOOLS,
  REACT_APP_DISABLE_PERSIST_AUTH_DATA,
} = process.env

const {
  reducer: locationReducer,
  middleware: routerMiddleware,
  enhancer: routerEnhancer,
} = connectRoutes(routesMap, { initialDispatch: true })

const rootReducer = combineReducers({
  authData: authReducer,
  balances: balancesReducer,
  bounties: bountiesReducer,
  comments: commentsReducer,
  currentUser: currentUserReducer,
  dashboard: dashboardReducer,
  dev: devReducer,
  form: formReducer,
  location: locationReducer,
  metaMask: metaMaskReducer,
  pay: payReducer,
  profile: profileReducer,
  project: projectReducer,
  projects: projectsReducer,
  ranking: rankingReducer,
  swap: swapReducer,
  ui: uiReducer,
  values: valuesReducer,
  voucher: voucherReducer,
  withdrawBonusTokens: withdrawBonusTokensReducer,
})

const enhancers = [routerEnhancer]
const middlewares = [
  thunkMiddleware,
  routerMiddleware,
  process.env.REACT_APP_DISABLE_ERROR_REPORTING === 'true'
    ? null
    : sentryReporterMiddleware,
  analyticsEventsMiddleware,
].filter(e => e)

const compose =
  REACT_APP_ENABLE_REDUX_DEVTOOLS === 'true'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || _compose
    : _compose

const composedEnhancers = compose(
  ...enhancers,
  applyMiddleware(...middlewares)
)

const store = createStore(rootReducer, composedEnhancers)

if (REACT_APP_DISABLE_PERSIST_AUTH_DATA !== 'true') persistAuthData(store)

export default store
