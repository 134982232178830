import Web3 from 'web3'
import BigNumber from 'bignumber.js'

import detectEnvironment from 'detectEnvironment'

const fallbackProvider =
  detectEnvironment() === 'production'
    ? process.env.REACT_APP_WEB3_PROVIDER_URL_PRODUCTION
    : process.env.REACT_APP_WEB3_PROVIDER_URL_DEFAULT

const web3Read = new Web3(fallbackProvider)

export const getContractRead = (contractAbi, contractAddress) =>
  new web3Read.eth.Contract(JSON.parse(contractAbi), contractAddress)

export const getContractWrite = (contractAbi, contractAddress) => {
  const { web3 } = window
  return new web3.eth.Contract(JSON.parse(contractAbi), contractAddress)
}

export const toWei = amount =>
  web3Read.utils.toWei(new BigNumber(amount.toString()).toString())

export const ethereumAddressToSeed = ethereumAddress =>
  parseInt(web3Read.utils.toChecksumAddress(ethereumAddress).slice(2, 10), 16)
