import {
  CLEAR_ALL_BOUNTIES_ERROR,
  CLEAR_PROJECT_BOUNTIES_ERROR,
  FETCH_PROJECT_BOUNTIES_SUCCESS,
  FETCH_PROJECT_BOUNTIES_FAILURE,
  FETCH_ALL_BOUNTIES_SUCCESS,
  FETCH_ALL_BOUNTIES_FAILURE,
  POST_BOUNTY_CLAIM_REQUEST_SUCCESS,
  POST_BOUNTY_CLAIM_REQUEST_FAILURE,
  FETCH_PROJECT_BOUNTIES_CLAIMED_SUCCESS,
} from '../types'

const initialState = {
  error: null,
  isLoading: true,
  allBountyTasks: [],
  bountyCampaigns: [
    {
      bountyDefinition: null,
      bounties: [],
    },
  ],
  claimed: [],
}

export default (state = initialState, { type, payload, error }) => {
  switch (type) {
    case CLEAR_ALL_BOUNTIES_ERROR:
    case CLEAR_PROJECT_BOUNTIES_ERROR:
      return { ...state, error: null, isLoading: false }

    case FETCH_ALL_BOUNTIES_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
        allBountyTasks: payload.bountyTasks,
      }

    case FETCH_PROJECT_BOUNTIES_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
        bountyCampaigns: payload.bountyCampaigns,
      }

    case FETCH_PROJECT_BOUNTIES_CLAIMED_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
        claimed: payload.claimed,
      }

    case FETCH_ALL_BOUNTIES_FAILURE:
    case FETCH_PROJECT_BOUNTIES_FAILURE:
    case POST_BOUNTY_CLAIM_REQUEST_FAILURE:
      return { ...state, error, isLoading: false }

    case POST_BOUNTY_CLAIM_REQUEST_SUCCESS:
      const { bountyClaim } = payload

      return {
        ...state,
        error: null,
        isLoading: false,
        claimed: [...state.claimed, bountyClaim],
      }

    default:
      return state
  }
}
