import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import MetaMaskImage from '../MetamaskImage'

const MetaMaskAccountNotFoundModal = () => (
  <Modal isOpen={true}>
    <ModalHeader>Oops, you don't have an active MetaMask wallet</ModalHeader>
    <ModalBody>
      <p>Please unlock your MetaMask wallet to access the platform</p>
      <p className="text-center">
        <MetaMaskImage />
      </p>
    </ModalBody>
  </Modal>
)

export default MetaMaskAccountNotFoundModal
