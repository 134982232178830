import React from 'react'
import styled from 'styled-components/macro'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'

const MetaMaskGif = styled.img`
  ${props => props.theme.boxShadow.balanceCard};
  margin-bottom: 20px;
  width: 100%;
`
const addChangeAddressGifSrc = require('app/assets/images/tutorials/changeMetamaskAddress.gif')

export default ({ children, className, close, isOpen, mode = 'modal', open }) =>
  mode === 'modal' ? (
    <Modal className={className} isOpen={isOpen} size="lg">
      <ModalHeader>How to change your MetaMask account address</ModalHeader>
      <ModalBody>
        <MetaMaskGif
          alt="How to change MetaMask account address GIF"
          src={addChangeAddressGifSrc}
        />
        <Button color="primary" onClick={close}>
          Close this dialog
        </Button>
      </ModalBody>
    </Modal>
  ) : mode === 'opener' ? (
    React.Children.map(children, child =>
      React.cloneElement(child, { onClick: open })
    )
  ) : null
