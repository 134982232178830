import { USER_LOGGED_IN, USER_LOGGED_OUT } from '../types'

export const userLoggedIn = (
  authResult,
  syncMetaMaskInterval,
  fetchBalancesInterval
) => ({
  type: USER_LOGGED_IN,
  authResult,
  syncMetaMaskInterval,
  fetchBalancesInterval,
})

export const userLoggedOut = () => ({
  type: USER_LOGGED_OUT,
})
