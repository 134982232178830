import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { postCreateBountyCampaign } from 'store/project/projectActions'

import React from 'react'
import { Field, Form } from 'redux-form'
import { validateRequired, validateIsNumber } from 'formHelpers/formValidation'

import FieldText from 'app/components/forms/fields/FieldText'
import FieldDatepicker from 'app/components/forms/fields/FieldDatepicker'
import BodyContainer from 'app/components/BodyContainer'
import Wrapper from 'app/components/Wrapper'

const ProjectCreateBountyCampaign = ({ onSubmit, submitting }) => (
  <Wrapper>
    <BodyContainer>
      <Form onSubmit={onSubmit}>
        <h2>New Bounty Campaign</h2>
        <hr />
        <div className="form-group">
          <label>Name</label>
          <small className="form-text text-muted">
            Give a name for you Campaign
          </small>
          <Field
            isTouched
            component={FieldText}
            className="form-control"
            name="name"
            validate={[validateRequired]}
          />
        </div>

        <div className="form-group">
          <label>Start Date</label>
          <small className="form-text text-muted">
            Pick an start date of you campaign.
          </small>
          <Field
            isTouched
            component={FieldDatepicker}
            className="form-control"
            name="startDate"
            validate={[validateRequired]}
          />
        </div>
        <div className="form-group">
          <label>End Date</label>
          <small className="form-text text-muted">
            Set when the campaign will finish. After this period, no user can
            claim anymore.
          </small>
          <Field
            isTouched
            component={FieldDatepicker}
            className="form-control"
            name="endDate"
            validate={[validateRequired]}
          />
        </div>

        <div className="form-group">
          <label>Token Symbol</label>
          <small className="form-text text-muted">
            Insert the symbol of your token
          </small>
          <Field
            isTouched
            component={FieldText}
            className="form-control"
            name="tokenSymbol"
            validate={[validateRequired]}
          />
        </div>
        <div className="form-group">
          <label>Reward Cap</label>
          <small className="form-text text-muted">
            Insert the maximum amount of tokens to be given away
          </small>
          <Field
            isTouched
            component={FieldText}
            className="form-control"
            name="rewardCap"
            validate={[validateRequired, validateIsNumber]}
          />
        </div>
        <div className="form-group">
          <label>Max. Claims Per User</label>
          <small className="form-text text-muted">
            Insert the maximum times an user can claim a bounty task. We
            recommend 1 claim per user as default.
          </small>
          <Field
            isTouched
            component={FieldText}
            className="form-control"
            name="maxClaimsPerUser"
            placeholder="1"
            validate={[validateRequired, validateIsNumber]}
          />
        </div>

        <hr />
        <button disabled={submitting} type="submit" className="btn btn-success">
          Create
        </button>
      </Form>
    </BodyContainer>
  </Wrapper>
)

const mapStateToProps = ({
  location: {
    payload: { slug },
  },
}) => ({
  slug,
})

const mapDispatchToProps = dispatch => ({
  dispatch,
})

const mergeProps = ({ slug }, { dispatch }, { handleSubmit, submitting }) => ({
  slug,
  submitting,
  onSubmit: handleSubmit(newCampaign =>
    dispatch(postCreateBountyCampaign({ ...newCampaign, slug }))
  ),
})

export default reduxForm({
  form: 'projectCreateBountyCampaign',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(ProjectCreateBountyCampaign)
)
