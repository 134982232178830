import React from 'react'
import Remarkable from 'remarkable'
import styled from 'styled-components/macro'

const markdownToHTML = new Remarkable({ html: true })

const MarkdownContainer = styled.div`
  font-size: 62.5%;
  font-weight: 300;
  letter-spacing: 0.01em;
  line-height: 1.7;
`

const MarkdownInner = styled.div`
  color: ${({ theme }) => theme.color.dark};
  font-size: 1.5em;
  font-weight: 300;
  letter-spacing: 0.01em;
  line-height: 1.7;
  text-align: left;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }) => theme.color.primaryPurpleAlt};
    font-weight: 500;
    letter-spacing: -0.1rem;
    margin-bottom: 1rem;
    margin-top: 0;
  }

  h1 {
    font-size: 2.5rem;
    line-height: 1.1;
    margin-bottom: 1.2rem;
    margin-top: 1.8rem;
  }

  h2 {
    font-size: 1.8rem;
    line-height: 1.15;
    margin-top: 1.4rem;
    opacity: 0.85;
  }

  h3 {
    font-size: 1.4rem;
    line-height: 1.2;
    margin-top: 1.2rem;
    opacity: 0.7;
  }

  h4 {
    font-size: 1.2rem;
    letter-spacing: -0.08rem;
    line-height: 1.25;
    opacity: 0.55;
  }

  h5 {
    font-size: 1.2rem;
    letter-spacing: -0.05rem;
    line-height: 1.4;
    opacity: 0.45;
  }

  h6 {
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 1.3;
    opacity: 0.45;
    text-transform: uppercase;
  }

  b,
  strong {
    font-weight: bold;
  }

  p {
    margin-top: 0;
  }

  a {
    color: ${({ theme }) => theme.color.primaryPurple};
    text-decoration: none

    &:focus,
    &:hover {
      color: ${({ theme }) => theme.color.primaryPurpleHover};
      text-decoration: underline;
    }
  }

  blockquote {
    border-left: 0.3rem solid ${({ theme }) => theme.color.primaryPurple};
    margin-left: 0;
    margin-right: 0;
    padding: 1rem 1.5rem;

    *:last-child {
      margin-bottom: 0;
    }
  }

  dl,
  ol,
  ul {
    font-size: 98%;
    list-style: none;
    margin-left: 1.5rem;
    margin-right: 3rem;
    margin-top: 1.5rem;
    padding-left: 0;
  }

  ol {
    list-style: decimal inside;
  }

  ul {
    list-style: circle inside;
  }

  img {
    border: solid 0.1rem ${({ theme }) => theme.color.lighter};
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }

  hr {
    border: 0;
    border-top: 0.1rem solid ${({ theme }) => theme.color.lighter};
    margin: 3rem 0;
  }

  code {
    background: ${({ theme }) => theme.color.lighter};
    border-radius: 0.4rem;
    font-size: 86%;
    margin: 0 0.2rem;
    padding: 0.2rem 0.5rem;
    white-space: nowrap;
  }

  pre {
    background: ${({ theme }) => theme.color.lighter};
    border-left: 0.3rem solid ${({ theme }) => theme.color.primaryPurple};
    overflow-y: hidden;

    & > code {
      border-radius: 0;
      display: block;
      padding: 1rem 1.5rem;
      white-space: pre;
    }
  }

  table {
    border-spacing: 0;
    width: 100%;

    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.md}) {
      border-spacing: 0;
      display: flex;
      width: 100%;

      thead {
        border-right: solid 0.1rem ${({ theme }) => theme.color.lighter};

        td,
        th {
          padding-left: 0;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 1.2rem;
          }
        }
      }

      tbody {
        display: flex;
        overflow-x: auto;
        white-space: nowrap;

        tr {
          border-right: solid 0.1rem ${({ theme }) => theme.color.lighter};

          &:last-child {
            border-right: none;
          }
        }
      }
    }
  }

  td,
  th {
    border-bottom: 0.1rem solid ${({ theme }) => theme.color.lighter};
    padding: 1.2rem 1.5rem;
    text-align: left;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.md}) {
      display: block;

      &:first-child {
        padding-left: 1.2rem;
      }

      &:last-child {
        padding-right: 1.2rem;
      }
    }
  }

  dd,
  dt,
  li,
  blockquote,
  dl,
  figure,
  ol,
  p,
  pre,
  ul {
    margin-bottom: 1.5rem;
  }
`

export default ({ content }) => (
  <MarkdownContainer>
    <MarkdownInner
      dangerouslySetInnerHTML={{
        __html: markdownToHTML.render(content),
      }}
    />
  </MarkdownContainer>
)
