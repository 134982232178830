import React from 'react'
import styled from 'styled-components/macro'
import Link from 'redux-first-router-link'

import formatDate from 'app/scenes/helpers/formatDate'
import { toProject, toProjectTokenSale } from 'store/routerActions'

import TableRow from 'app/components/TableRow'
import MobileLabel from 'app/components/MobileLabel'

const Badge = styled.small.attrs({
  className: 'badge badge-secondary',
})`
  && {
    width: 100%;
    font-size: ${props => props.theme.font.lg};
    padding: 12px 5px;
  }
`

const VoucherCodes = ({ codes }) => (
  <div className="mb-4">
    <h4>Your Vouchers</h4>

    <div>
      {codes.length === 0 && (
        <div className="alert alert-light text-center" role="alert">
          No voucher codes were created
        </div>
      )}

      {codes.map(voucherCode => (
        <TableRow
          key={voucherCode.code}
          className="row align-items-center justify-content-start"
        >
          <div className="col-md-12 col-lg-3">
            <MobileLabel className="d-block d-md-block">Project</MobileLabel>
            <Link
              to={toProject(voucherCode.voucherDefinition.tokenSale.project)}
            >
              {voucherCode.voucherDefinition.tokenSale.project.name}
            </Link>
          </div>

          <div className="col-md-6 col-lg-2">
            <MobileLabel className="d-block d-md-block">Token Sale</MobileLabel>
            <Link
              to={toProjectTokenSale({
                slug: voucherCode.voucherDefinition.tokenSale.project.slug,
                tokenSaleSlug: voucherCode.voucherDefinition.tokenSale.name,
              })}
            >
              {voucherCode.voucherDefinition.tokenSale.name}
            </Link>
          </div>

          <div className="col-md-6 col-lg-2">
            <MobileLabel className="d-block d-md-block">Code</MobileLabel>
            <Badge>{voucherCode.code}</Badge>
          </div>

          <div className="col-md-6 col-lg-2">
            <MobileLabel className="d-block d-md-block">Reward</MobileLabel>
            {voucherCode.voucherDefinition.rewardPercentVoucherOwner}%
          </div>

          <div className="col-md-6 col-lg-3">
            <MobileLabel className="d-block d-md-block">Date</MobileLabel>
            {formatDate(voucherCode.createdAt)}
          </div>
        </TableRow>
      ))}
    </div>
  </div>
)

export default VoucherCodes
