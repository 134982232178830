import { USER_LOGGED_IN, USER_LOGGED_OUT, RESTORE_AUTH_DATA } from '../types'

export const initialState = { authResult: {} }

export default (state = initialState, { type, authResult }) => {
  switch (type) {
    case RESTORE_AUTH_DATA:
    case USER_LOGGED_IN:
      return { ...state, authResult }
    case USER_LOGGED_OUT:
      return initialState
    default:
      return state
  }
}
