import captureException from 'sentryHelpers/captureException'
import { getContractRead, toWei } from 'web3Helpers/generalHelper'

const BigNumber = require('bignumber.js')

export default async ({
  ethereumAddress,
  stakeAmount,
  stakeContractAbi,
  stakeContractAddress,
}) => {
  try {
    const contract = getContractRead(stakeContractAbi, stakeContractAddress)

    const staked = await contract.methods.totalStakedFor(ethereumAddress).call()

    const newStakedInWei = new BigNumber(staked)
      .plus(new BigNumber(toWei(stakeAmount)))
      .toString()

    const points = await contract.methods
      .computeStakingPoints(ethereumAddress, toWei(stakeAmount))
      .call()

    const discount = await contract.methods
      .getDiscountEstimateForPointsForUser(points, ethereumAddress)
      .call()

    return {
      ethereumAddress,
      staked: newStakedInWei / 1e18,
      points: points / 1e18,
      discount: discount / 10,
    }
  } catch (error) {
    captureException(error)
  }
}
