import { connect } from 'react-redux'

import RegistrationsStart from './RegistrationsStart'

const mapStateToProps = () => ({})

const mapDispatchToProps = () => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationsStart)
