import get from 'lodash/get'
import { connect } from 'react-redux'

import auth from 'app/auth'
import { closeModal, openModal } from 'store/ui/uiActions'
import selectActiveModalName from 'store/selectors/selectActiveModalName'

import SessionEndingWarningModal from './SessionEndingWarningModal'

const MODAL_NAME = 'SESSION_ENDING_WARNING'

const FIVE_MIMUTES = 1000 * 60 * 5

const mapStateToProps = state => ({
  isDismissed: get(state.ui.modals[MODAL_NAME], 'isDismissed'),
  isOpen: selectActiveModalName(state) === MODAL_NAME,
})

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(closeModal(MODAL_NAME)),
  open: () => dispatch(openModal(MODAL_NAME)),
})

const mergeProps = ({ isDismissed, isOpen }, { close, open }) => {
  const timeRemaining = auth.timeRemaining()
  const isAuthenticated = auth.isAuthenticated()
  const isSessionEnding = timeRemaining < FIVE_MIMUTES

  const shouldOpen =
    !isOpen && (isAuthenticated && isSessionEnding && !isDismissed)

  return {
    // autoOpen runs continuously, opens the modal automatically
    autoOpen: shouldOpen ? open() : null,
    close,
    isOpen,
    minutesRemaining: Math.floor(timeRemaining / 1000 / 60),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(SessionEndingWarningModal)
