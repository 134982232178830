import React from 'react'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap'
import { FormattedNumber } from 'react-intl'
import Link from 'redux-first-router-link'
import styled from 'styled-components/macro'

import { toUserProfile } from '../../../store/routerActions'

const BalanceVerified = styled(UncontrolledDropdown)`
  &.dropdown-item:active {
    background-color: transparent;
  }
`

const BalanceAmount = styled(DropdownToggle)`
  &.col-8 {
    padding-left: 30px;
    padding-right: 7px;
  }

  &.dropdown-toggle:after {
    position: relative;
    top: 2px;
  }
`

const Item = styled(DropdownItem)`
  cursor: default;
  font-size: ${({ theme }) => theme.font.sm};
  &&:active {
    background-color: white;
    color: black;
    outline: 0;
  }
  &&:hover {
    background-color: white;
  }
`

const BalanceUnverified = styled(Link)`
  border-radius: ${({ theme }) => theme.borderRadius.button};
  color: white;
  &:hover {
    color: white;
    text-decoration: none;
  }
`

const Icon = styled.img`
  height: 30px;
  margin-left: 7px;
`

const Balances = ({ isUserRegistered, items, user }) => (
  <>
    {!isUserRegistered && (
      <BalanceUnverified to={toUserProfile()}>Unverified</BalanceUnverified>
    )}

    {isUserRegistered && (
      <BalanceVerified>
        <BalanceAmount
          caret
          id="ethereumAddressTooltip"
          className="btn btn-outline-secondary"
          outline
        >
          My Balance
        </BalanceAmount>
        <DropdownMenu right>
          {items.length === 0 ? (
            <div className="text-center">Loading...</div>
          ) : (
            <div className="text-right">
              {items.map((balance, index) => (
                <div key={balance.tokenSymbol}>
                  <Item id={`balanceTooltip${index}`}>
                    <FormattedNumber
                      maximumFractionDigits={8}
                      minimumFractionDigits={8}
                      value={balance.amount}
                    />
                    <Icon
                      alt="balance"
                      className="rounded-circle"
                      src={balance.project.tokenIconUrl}
                    />
                  </Item>
                  {index !== items.length - 1 && <Item divider />}
                  <UncontrolledTooltip
                    autohide={false}
                    placement="left"
                    target={`balanceTooltip${index}`}
                  >
                    My {balance.tokenSymbol} balance
                  </UncontrolledTooltip>
                </div>
              ))}
            </div>
          )}
        </DropdownMenu>
        <UncontrolledTooltip
          autohide={false}
          placement="left"
          target="ethereumAddressTooltip"
        >
          From address: {user.ethereumAddress}
        </UncontrolledTooltip>
      </BalanceVerified>
    )}
  </>
)

export default Balances
