import normalizeComments from './normalizeComments'
import {
  CLEAR_COMMENTS_ERROR,
  FETCH_COMMENTABLE_COMMENTS_SUCCESS,
  FETCH_COMMENTABLE_COMMENTS_FAILURE,
  POST_NEW_COMMENT_FORM_FAILURE,
  POST_NEW_COMMENT_FORM_SUCCESS,
} from '../types'

const initialState = {
  isLoadedSuccessfully: false,
  items: {},
  error: null,
}

export default (state = initialState, { type, payload, error }) => {
  switch (type) {
    case CLEAR_COMMENTS_ERROR:
      return { ...state, error: null }

    case FETCH_COMMENTABLE_COMMENTS_SUCCESS:
      return {
        ...state,
        error: null,
        isLoadedSuccessfully: true,
        items: normalizeComments(payload).entities.comments || {},
      }

    case FETCH_COMMENTABLE_COMMENTS_FAILURE:
      return { ...state, error, isLoadedSuccessfully: false }

    case POST_NEW_COMMENT_FORM_FAILURE:
      return { ...state, error }

    case POST_NEW_COMMENT_FORM_SUCCESS:
      const { comment } = payload

      const isSubComment = Object.keys(state.items || {}).includes(
        comment.commentableId
      )

      return {
        ...state,
        items: {
          ...state.items,
          ...(isSubComment
            ? {
                [comment.commentableId]: {
                  ...state.items[comment.commentableId],
                  comments: [
                    ...(state.items[comment.commentableId].comments || []),
                    comment.id,
                  ],
                },
              }
            : {}),
          [comment.id]: comment,
        },
        error: null,
      }

    default:
      return state
  }
}
