import React from 'react'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  padding: 100px;
  width: 100%;
  text-align: center;
`

const Preview = styled.div`
  margin-top: 40px;
  background: #ffffff;
  text-align: left;
  padding: 20px;
`

const Dev = ({
  makeDemoUser,
  makeDemoUserAndProject,
  makeDemoUserAndProjectAndBounty,
  makeDemoUserAndProjectAndTokenSale,
  makeDemoUserAndProjectAndTokenSales,
  makeDemoUserAndProjectAndTokenSalesAndVoucherDefinition,
  makeDemoUserAndProjectWithStake,
  makeUserApplicationRejected,
  preview,
}) => (
  <Wrapper>
    <p>
      <button className="btn btn-outline-dark" onClick={makeDemoUser}>
        Generate User
      </button>
    </p>

    <p>
      <button
        className="btn btn-outline-secondary"
        onClick={makeDemoUserAndProject}
      >
        Generate User + Project (CANDIDATE)
      </button>
    </p>

    <p>
      <button
        className="btn btn-outline-primary"
        onClick={makeDemoUserAndProjectAndTokenSales}
      >
        Generate User + Project + TokenSale1 (SUCCESSFUL) + TokenSale2 (ONGOING)
      </button>
    </p>

    <p>
      <button
        className="btn btn-outline-primary"
        onClick={() => makeDemoUserAndProjectAndTokenSale('ONGOING')}
      >
        Generate User + Project + TokenSale (ONGOING)
      </button>
    </p>

    <p>
      <button
        className="btn btn-outline-success"
        onClick={() => makeDemoUserAndProjectAndTokenSale('SUCCESSFUL')}
      >
        Generate User + Project + TokenSale (SUCCESSFUL)
      </button>
    </p>

    <p>
      <button
        className="btn btn-outline-success"
        onClick={makeDemoUserAndProjectWithStake}
      >
        <div>Generate User + Project (upcoming) with stake </div>
        <div>
          <small>
            this generator can be used only once because of unique stakeAddress
            constraint
          </small>
        </div>
      </button>
    </p>

    <p>
      <button
        className="btn btn-outline-success"
        onClick={() => makeDemoUserAndProjectAndTokenSale('SOLDOUT')}
      >
        Generate User + Project + TokenSale (SOLDOUT)
      </button>
    </p>

    <p>
      <button
        className="btn btn-outline-danger"
        onClick={() => makeDemoUserAndProjectAndTokenSale('UNSUCCESSFUL')}
      >
        Generate User + Project + TokenSale (UNSUCCESSFUL)
      </button>
    </p>

    <p>
      <button
        className="btn btn-outline-warning"
        onClick={() => makeDemoUserAndProjectAndTokenSale('UPCOMING')}
      >
        Generate User + Project + TokenSale (UPCOMING)
      </button>
    </p>

    <p>
      <button
        className="btn btn-secondary"
        onClick={makeDemoUserAndProjectAndBounty}
      >
        Generate User + Project (candidate) + Bounty
      </button>
    </p>

    <p>
      <button
        className="btn btn-outline-primary"
        onClick={makeDemoUserAndProjectAndTokenSalesAndVoucherDefinition}
      >
        Generate User + Project + TokenSale (ONGOING) + Voucher Definition
      </button>
    </p>

    <p>
      <button className="btn btn-info" onClick={makeUserApplicationRejected}>
        Generate Event "userApplicationRejected"
      </button>
    </p>
    {preview && (
      <div className="mt-4">
        <Preview>
          <p>Created following entities:</p>
          <hr />
          <pre>{preview}</pre>
        </Preview>
      </div>
    )}
  </Wrapper>
)

export default Dev
