import { connect } from 'react-redux'
import { get } from 'lodash'

import cancelPurchase from 'store/pay/payActions/cancelPurchase'
import {
  PURCHASE_TOKENS_CHANGE_MODAL_STEP,
  PURCHASE_TOKENS_CLOSE_MODAL,
} from 'store/types'
import selectProjectFromLocation from 'store/selectors/selectProjectFromLocation'

import PayStatusModal from './PayStatusModal'

const emptyObject = {}

const mapStateToProps = ({
  currentUser: { user = emptyObject },
  form,
  pay: { spendTokensStatus = emptyObject },
  ...state
}) => ({
  completedSteps: spendTokensStatus.completedSteps,
  isPurchaseRejectedByUser:
    spendTokensStatus.completedSteps.POST_PENDING_PURCHASE_SUCCESS &&
    spendTokensStatus.completedSteps.PURCHASE_TOKENS_FAILURE &&
    !spendTokensStatus.completedSteps
      .SIGN_AND_SEND_TRANSACTION_TO_TOKEN_SALE_CONTRACT_SUCCESS,
  payStatus: spendTokensStatus,
  project: selectProjectFromLocation(state) || emptyObject,
  purchase: spendTokensStatus.purchase,
  purchaseCurrency: get(form, 'payForm.values.purchaseCurrency', emptyObject),
  user,
})

const mapDispatchToProps = dispatch => ({
  changeModalStep: ({ step }) =>
    dispatch({
      type: PURCHASE_TOKENS_CHANGE_MODAL_STEP,
      payload: { step },
    }),
  closeModal: () => dispatch({ type: PURCHASE_TOKENS_CLOSE_MODAL }),
  dispatch,
})

const mergeProps = (
  {
    completedSteps,
    isPurchaseRejectedByUser,
    payStatus,
    project,
    purchase,
    purchaseCurrency,
    user,
  },
  { changeModalStep, closeModal, dispatch }
) => {
  return {
    changeModalStep,
    closeModal,
    completedSteps,
    payStatus,
    project,
    purchaseCurrency,
    rejectedPurchaseHandler:
      isPurchaseRejectedByUser &&
      dispatch(cancelPurchase({ purchaseId: purchase.id })),
    user,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(PayStatusModal)
