import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import MetaMaskImage from '../MetamaskImage'

const MetaMaskAccountNotFoundModal = ({ ethereumAddress }) => (
  <Modal isOpen={true}>
    <ModalHeader>Oops, you are using a different MetaMask address</ModalHeader>
    <ModalBody className="text-center">
      <p>
        Please select this registered address:
        <br />
        <strong>{ethereumAddress}</strong>
        <br />
        as your MetaMask account
      </p>
      <p>
        <MetaMaskImage />
      </p>
    </ModalBody>
  </Modal>
)

export default MetaMaskAccountNotFoundModal
