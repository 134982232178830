import Remarkable from 'remarkable'
const { truncate } = require('lodash')

const MISSING_COVER_PICTURE_URL = 'http://via.placeholder.com/350x200'

const md = new Remarkable({ xhtmlOut: true })
const parser = new DOMParser()

const extractProjectShortDescription = ({ description }) => {
  const xmlString = `<root>${md.render(description)}</root>`
  const doc = parser.parseFromString(xmlString, 'application/xml')
  const firstParagraph = doc.getElementsByTagName('p')[0]
  return truncate(firstParagraph ? firstParagraph.textContent : '', {
    length: 100,
  })
}

const extractProjectCoverPicture = ({ description }) =>
  (description.match(/!\[.*?\]\((.*)\)\s/) || [])[1]

const decorateProject = project => {
  const src = extractProjectCoverPicture(project) || MISSING_COVER_PICTURE_URL

  return {
    ...project,
    coverPicture: src,
    shortDescription: extractProjectShortDescription(project),
  }
}

export default ({ projects }) => ({
  projects: projects.map(decorateProject),
})
