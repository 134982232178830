import { connect } from 'react-redux'

import Projects from './Projects'

const mapStateToProps = state => ({
  projects: state.projects.items.map(project => ({
    ...project,
    activeTokenSale: project.tokenSales.find(ts => ts.isActive) || null,
  })),
  isLoading: state.projects.isLoading,
})

const mapDispatchToProps = () => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Projects)
