const selectBalanceByTokenSymbol = ({
  state: {
    balances: { items },
  },
  tokenSymbol,
}) => {
  const token = items.filter(item => item.tokenSymbol === tokenSymbol)[0]
  return token ? token.amount : null
}

export default selectBalanceByTokenSymbol
