import React from 'react'
import styled from 'styled-components/macro'
import { Field } from 'redux-form'

import TermsOfUseText from 'app/components/TermsOfUseText'

const AgreeActionArea = styled.div`
  padding: 30px 15px 30px 0px;
  border-radius: 4px;
  text-align: left;
  &.col-12.row {
    margin-left: 0px;
  }
`
const CheckboxColumn = styled.div`
  &.col-1 {
    padding-right: 0;
    text-align: right;
  }
`
const TipTrigger = styled.div`
  display: ${props => (props.isTermsOfUseReadingComplete ? 'none' : 'block')};
  height: 25px;
  position: absolute;
  width: 100%;
`
const ScrollableTextArea = styled.div`
  background: #f7f7f7;
  border-bottom: 10px solid
    ${props =>
      props.isTermsOfUseReadingComplete
        ? props.theme.color.greenSuccess
        : props.isTermsOfUseScrollIncompleteTipVisible
        ? props.theme.color.warning
        : props.theme.color.primaryPurpleAlt};
  height: 500px;
  overflow: scroll;
  padding: 60px;
  text-align: left;
  width: 100%;
`
const TermsOfUse = ({
  handleScroll,
  isTermsOfUseReadingComplete,
  isTermsOfUseScrollIncompleteTipVisible,
  onSubmit,
  shouldScrollIncompleteTipVisible,
}) => (
  <form onSubmit={onSubmit}>
    <div className="row">
      <div className="col-12">
        <div className="row justify-content-between">
          <div className="col-12">
            <ScrollableTextArea
              onScroll={handleScroll}
              isTermsOfUseReadingComplete={isTermsOfUseReadingComplete}
              isTermsOfUseScrollIncompleteTipVisible={
                isTermsOfUseScrollIncompleteTipVisible
              }
            >
              <TermsOfUseText />
            </ScrollableTextArea>
            <AgreeActionArea className="col-12 row mt-4">
              <CheckboxColumn className="col-1">
                <TipTrigger
                  onClick={shouldScrollIncompleteTipVisible}
                  isTermsOfUseReadingComplete={isTermsOfUseReadingComplete}
                />
                <Field
                  name="isTermsOfUseAccepted"
                  id="isTermsOfUseAccepted"
                  component="input"
                  type="checkbox"
                  props={{
                    disabled: !isTermsOfUseReadingComplete,
                  }}
                />
              </CheckboxColumn>
              <div className="col-11">
                I have read and accepted the{' '}
                <a
                  href="https://s3-ap-southeast-1.amazonaws.com/starbase-envs/production/starbase-platform/starbase_kyc_data_protection_notice.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {' '}
                  Data Protection Notice for KYC Procedure
                </a>
                <div className="mt-2 mb-2 text-muted"> AND </div>I understand
                and accept that the Starbase Project has been assigned by
                Starbase Platform GmbH to Starbase PTE LTD in accordance with
                para 32 of the STAR TGE Terms of Use and that all liabilities
                towards the contributors of the STAR TGE and all STAR holders
                (if any) have been transferred to Starbase PTE LTD as well. The
                Starbase Platform will be deployed and maintained by Starbase
                PTE LTD.
              </div>
            </AgreeActionArea>
          </div>
        </div>
      </div>
    </div>
  </form>
)

export default TermsOfUse
