import React from 'react'
import styled from 'styled-components/macro'

import FieldErrorMessage from './FieldErrorMessage'

const Input = styled.textarea`
  min-height: 120px;
  color: ${props => props.theme.color.dark};
  font-size: 13px;
  border: 1px solid ${props => props.theme.color.inputBorder};
  margin-bottom: ${props => props.theme.spacing.fieldMarginBottom};
  &.form-control {
    border-color: ${props =>
      props.hasError ? props.theme.color.red : props.theme.color.inputBorder};
  }
`

const FieldTextArea = ({ input, isTouched, name, placeholder, meta }) => (
  <div>
    <Input
      {...input}
      className="form-control"
      type="textarea"
      hasError={(isTouched || meta.touched) && meta.error}
      name={name}
      placeholder={placeholder}
    />
    <FieldErrorMessage isTouched={isTouched} meta={meta} />
  </div>
)

export default FieldTextArea
