import React from 'react'
import styled from 'styled-components'
import max from 'lodash/max'
import min from 'lodash/min'
import sum from 'lodash/sum'
import { FormattedNumber } from 'react-intl'

import getTokenSaleProgress from 'app/scenes/helpers/getTokenSaleProgress'

import ProgressBar from 'app/components/ProgressBar'
import ProjectStatusBadge from 'app/components/ProjectStatusBadge'
import DateWrapper from 'app/components/DateWrapper'

import BadgeTokenSaleName from './BadgeTokenSaleName'
import CurrencyIcon from './CurrencyIcon'
import MetricSubtitle from './MetricSubtitle'

const TimeUtc = styled.span`
  font-weight: ${props => props.theme.font.w700};
  color: ${props => props.theme.color.white};
  font-size: ${props => props.theme.font.lg};
`
const TimeUserLocal = styled.div`
  color: ${props => props.theme.color.transluentWhite};
  font-size: ${props => props.theme.font.xs};
`

const IconTime = styled.i`
  position: relative;
  top: 2px;
  &::before {
    font-weight: 900;
  }
`

const Outer = styled.div`
  padding: 2em;
  background-color: ${props => props.theme.color.primaryPurple};
  border-radius: ${props => props.theme.borderRadius.tab}
    ${props => props.theme.borderRadius.tab} 0px 0px;
  color: ${props => props.theme.color.white};
`

const Topinfo = ({ project, tokenSale, stakeDefinitions }) => {
  if (project.status === 'SUCCESSFUL') {
    tokenSale = {
      ...tokenSale,
      endDate: max(project.tokenSales.map(t => t.endDate)),
      startDate: min(project.tokenSales.map(t => t.startDate)),
      tokenSaleCap: sum(project.tokenSales.map(t => t.tokenSaleCap)),
      tokensSold: sum(project.tokenSales.map(t => t.tokensSold)),
      starsRaised: sum(project.tokenSales.map(t => t.starsRaised)),
      ethsRaised: sum(project.tokenSales.map(t => t.ethsRaised)),
      buyersCount: project.buyersCount,
    }
  }

  return (
    <Outer>
      <div className="row mb-2">
        <div className="col-12 text-center">
          <h1 className="text-white">{project.name}</h1>
        </div>
      </div>
      <div className="row justify-content-center text-center">
        {tokenSale && (
          <div className="col-auto">
            <BadgeTokenSaleName>{tokenSale.name}</BadgeTokenSaleName>
          </div>
        )}

        <div className="col-auto">
          <ProjectStatusBadge
            name={project.name}
            status={!!stakeDefinitions.length ? 'STAKING' : project.status}
          />
        </div>
      </div>

      <div className="row justify-content-center  mb-3">
        <div className="col-12 text-center">
          {tokenSale && (
            <div>
              <MetricSubtitle>Duration</MetricSubtitle>

              <IconTime className="icon-hourglass" />
              <TimeUtc>
                <DateWrapper
                  value={new Date(tokenSale.startDate).getTime()}
                  type="long"
                  timeZone="UTC"
                />{' '}
                -{' '}
                <DateWrapper
                  value={tokenSale.endDate}
                  type="long"
                  timeZone="UTC"
                />
              </TimeUtc>
              <TimeUserLocal>
                <DateWrapper value={tokenSale.startDate} type="long" /> -{' '}
                <DateWrapper value={tokenSale.endDate} type="long" />
              </TimeUserLocal>
            </div>
          )}
        </div>
      </div>

      {project.status !== 'CANDIDATE' && (
        <div className="row">
          <div className="col-12">
            <ProgressBar
              progress={getTokenSaleProgress({
                status: project.status,
                tokenSale,
              }).toString()}
            />
          </div>
        </div>
      )}

      {tokenSale && (
        <div className="row">
          <div className="col text-center">
            <span title="STAR raised">
              <CurrencyIcon className="icon-star" />
              <TimeUtc>
                <FormattedNumber value={tokenSale.starsRaised} />
              </TimeUtc>
            </span>
            <br />
            <MetricSubtitle> Star Raised </MetricSubtitle>
          </div>
          <div className="col text-center">
            <span title="ETH raised">
              <CurrencyIcon className="icon-ethereum" />
              <TimeUtc>
                <FormattedNumber value={tokenSale.ethsRaised} />
              </TimeUtc>
            </span>
            <br />
            <MetricSubtitle> ETH Raised </MetricSubtitle>
          </div>
          <div className="w-100 mb-4 d-block d-md-none" />
          <div className="col text-center">
            {tokenSale && (
              <div>
                <IconTime className="icon-supporters" />
                <span className="ml-1">
                  <TimeUtc>
                    <FormattedNumber value={tokenSale.buyersCount} />
                  </TimeUtc>
                </span>
                <MetricSubtitle>Supporters</MetricSubtitle>
              </div>
            )}
          </div>
          <div className="col text-center">
            <TimeUtc>
              <FormattedNumber value={tokenSale.tokensSold} />
            </TimeUtc>
            <br />
            <MetricSubtitle>Tokens Sold</MetricSubtitle>
          </div>
          <div className="w-100 mb-4 d-block d-sm-none" />
          <div className="col text-center">
            <TimeUtc>
              <FormattedNumber value={tokenSale.tokenSaleCap} />
            </TimeUtc>
            <br />
            <MetricSubtitle> Tokensale Cap </MetricSubtitle>
          </div>
        </div>
      )}
    </Outer>
  )
}

export default Topinfo
