import { connect } from 'react-redux'
import get from 'lodash/get'

import auth from 'app/auth'

import selectIdTokenNamePhotoEmail from '../../../../../store/selectors/selectIdTokenNamePhotoEmail'
import selectIsUserRegistered from '../../../../../store/selectors/selectIsUserRegistered'
import CreateYourProfileDialog from './CreateYourProfileDialog'

const emptyObject = {}

const mapStateToProps = ({ ...state }) => ({
  isUserRegistered: selectIsUserRegistered(state),
  isUserAuthenticated: auth.isAuthenticated(),
  user: get(state, 'currentUser.user', emptyObject),
  userPhoto: (selectIdTokenNamePhotoEmail(state) || emptyObject).photo,
})

const mapDispatchToProps = () => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateYourProfileDialog)
