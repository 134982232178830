import React from 'react'
import Link from 'redux-first-router-link'
import { toProfile } from 'store/routerActions'
import BodyContainer from '../../components/BodyContainer'
import ProtectedContent from '../../components/ProtectedContent'
import ProtectedPageModal from '../../components/ProtectedPageModal'
import Wrapper from '../../components/Wrapper'

import Balances from './Balances'
import Bounties from './Bounties'
import Purchases from './Purchases'
import VoucherCodes from './VoucherCodes'
import VoucherRewards from './VoucherRewards'
import WithdrawBonusTokenButtons from './bonusTokens/WithdrawBonusTokenButtonsContainer'

const Rewards = ({ bountyClaimed, rewards }) => (
  <>
    <h4 className="mb-4">Rewards</h4>
    <div className="container-fluid">
      <WithdrawBonusTokenButtons />
      <VoucherRewards rewards={rewards.voucher} />
      <Bounties bountyClaims={bountyClaimed} />
    </div>
  </>
)

const Dashboard = ({
  balances,
  isUserRegistered,
  isBalanceLoading,
  isDashboardLoading,
  purchases,
  rewards,
  bounties: { claimed },
  voucherCodes,
}) => (
  <Wrapper>
    {isDashboardLoading ? (
      <div className="alert alert-light text-center p-5" role="alert">
        Loading...
      </div>
    ) : (
      <>
        <ProtectedPageModal title="Access Denied" isOpen={!isUserRegistered}>
          <p>
            In order to access your dashboard, you need first to complete the
            registration form.
          </p>
          <p>
            <Link className="btn btn-primary" to={toProfile()}>
              To the registration form
            </Link>
          </p>
        </ProtectedPageModal>

        <BodyContainer>
          <ProtectedContent isProtected={!isUserRegistered}>
            <Balances balances={balances} isLoading={isBalanceLoading} />
            <Purchases purchases={purchases} />
            <VoucherCodes codes={voucherCodes} />
            <hr />
            <Rewards bountyClaimed={claimed} rewards={rewards} />
          </ProtectedContent>
        </BodyContainer>
      </>
    )}
  </Wrapper>
)

export default Dashboard
