import { connect } from 'react-redux'

import MetaMaskErrorModals from './MetaMaskErrorModals'

const mapStateToProps = ({ currentUser, metaMask, profile, location }) => ({
  currentUser,
  metaMask,
  profile,
  location,
})
const mapDispatchToProps = () => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MetaMaskErrorModals)
