import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import { putRejectBountyClaim } from 'store/project/projectActions'

import RejectBountyClaimForm from './RejectBountyClaimForm'

const mapStateToProps = (
  {
    location: {
      payload: { slug },
    },
  },
  { bountyClaimId }
) => ({
  slug,
  bountyClaimId,
})

const mapDispatchToProps = dispatch => ({
  dispatch,
})

const mergeProps = (
  { slug, bountyClaimId },
  { dispatch },
  { handleSubmit }
) => ({
  slug,
  bountyClaimId,
  onSubmit: handleSubmit(({ poComment }) =>
    dispatch(
      putRejectBountyClaim({
        bountyClaimId,
        poComment,
        slug,
      })
    )
  ),
})

export default reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(RejectBountyClaimForm)
)
