import { createSelector } from 'reselect'
import flatten from 'lodash/flatten'

const selectProjects = state => state.projects.items

const getProjectsTokenSales = projects =>
  flatten(projects.map(p => p.tokenSales)).filter(ts => ts.isActive)

export default createSelector(
  selectProjects,
  getProjectsTokenSales
)
