import React from 'react'
import styled from 'styled-components/macro'

import Button from '../../../../components/button/Button'
import FormFooter from './FormFooterContainer'
import Loading from '../../../../components/loading/Loading'

const ContentWrapper = styled.div`
  margin-bottom: 4em;
  padding-top: 7em;
`

const SubmitInfo = ({ isSubmitting, onSubmit }) => (
  <form onSubmit={onSubmit}>
    <div className="row justify-content-center text-center">
      <ContentWrapper className="col-7">
        <h3>All Information was collected!</h3>
        <p>
          To finalize your KYC/AML process, please click on the button below:
        </p>

        <p>
          <Button disabled={isSubmitting} color="success" className="btn-block">
            Submit
            {isSubmitting && <Loading />}
          </Button>
        </p>

        <small>
          By clicking <b>Submit</b>, you agree to give consent <br /> for
          processing and storing your data as prompted by the platform.
        </small>
      </ContentWrapper>
    </div>
    <FormFooter leftButtonText="Back" />
  </form>
)

export default SubmitInfo
