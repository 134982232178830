import React from 'react'
import styled from 'styled-components/macro'
import { Field } from 'redux-form'

import {
  validateIsAlpha,
  validateRequired,
  minLength3,
} from 'formHelpers/formValidation'

import FieldLabel from 'app/components/forms/fields/FieldLabel'
import FieldSelect from 'app/components/forms/fields/FieldSelect'
import FieldSelectDate from 'app/components/forms/fields/FieldSelectDate'
import FieldText from 'app/components/forms/fields/FieldText'
import FieldTextArea from 'app/components/forms/fields/FieldTextArea'
import Tooltip from 'app/components/Tooltip'
import Jumbotron from 'app/components/Jumbotron'

import ChangeMetamaskAddressTutorial from '../modals/ChangeMetamaskAddressTutorialContainer'
import FormFooter from './FormFooterContainer'
import FormTitle from './FormTitle'

import fundsOriginOptions from './data/fundsOriginOptions'
import incomeOptions from './data/incomeOptions'
import tradingVolumeOptions from './data/tradingVolumeOptions'

const StyledField = styled(Field)`
  box-shadow: 0 3px 20px 0 ${props => props.theme.color.fieldBoxShadow};
  border-color: ${props => props.theme.color.lightBlue};
  color: ${props => props.theme.color.dark};
  font-family: sans-serif, Arial;
  padding: 1rem 1.75rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
  display: block;
  width: 100%;
  background-color: ${props => props.theme.color.white};
  background-image: none;
  background-clip: padding-box;
  border: 1px solid ${props => props.theme.color.light};
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  &:focus {
    box-shadow: 0 3px 20px 0 ${props => props.theme.color.fieldBoxShadowFocus};
    border: 1px solid ${props => props.theme.color.primaryPurple};
    outline: none;
  }
`

const TooltipContent = styled.div`
  padding-left: 10px;
  p {
    margin-bottom: 10px;
  }
  ul,
  ul li {
    margin: 0;
    padding: 0;
  }
`

const AliasTooltipContent = () => (
  <TooltipContent>
    <p>Your other name(s) such as:</p>
    <ul>
      <li>after marriage/divorce</li>
      <li>pen name</li>
      <li>or any other name by which you are commonly known as</li>
    </ul>
  </TooltipContent>
)

const FirstNameTooltipContent = () => (
  <TooltipContent>
    <p>
      Your <strong>full first</strong> name as it appears on your legal
      document.
    </p>
  </TooltipContent>
)

const LastNameTooltipContent = () => (
  <TooltipContent>
    <p>
      Your <strong>full last</strong> name as it appears on your legal document.
    </p>
  </TooltipContent>
)

const PersonalInfoForm = ({
  allowedNationalities,
  isLegalPerson,
  isPep,
  isPepRelationshipOther,
  onSubmit,
}) => (
  <form onSubmit={onSubmit}>
    <ChangeMetamaskAddressTutorial />

    <FormTitle title="Personal Information" />
    <div className="row">
      <div className="col-12">
        <div className="row justify-content-between">
          <div className="col-12 col-md-6">
            <Tooltip TooltipContent={() => <FirstNameTooltipContent />}>
              <FieldLabel htmlFor="firstName" children="Full first name" />
            </Tooltip>

            <Field
              autoFocus
              isTouched
              component={FieldText}
              name="firstName"
              validate={[validateRequired, minLength3]}
              placeholder="e.g.: Alexandra Maria"
            />

            <Tooltip TooltipContent={() => <LastNameTooltipContent />}>
              <FieldLabel htmlFor="lastName" children="Full last name" />
            </Tooltip>
            <Field
              component={FieldText}
              isTouched
              name="lastName"
              validate={[validateRequired, minLength3]}
              placeholder="e.g.: Gama Lenige"
            />
            <Tooltip TooltipContent={() => <AliasTooltipContent />}>
              <FieldLabel htmlFor="alias" children="Alias" />
            </Tooltip>
            <Field
              component={FieldText}
              isTouched
              name="alias"
              validate={null}
              placeholder="e.g.: Alex Lenige McGill"
            />

            <FieldLabel children="Birthdate" />
            <FieldSelectDate isTouched />
          </div>

          <div className="col-12 col-md-6">
            <FieldLabel htmlFor="phoneNumber" children="Phone number" />
            <Field
              component={FieldText}
              isTouched
              name="phoneNumber"
              placeholder="e.g.: +1 (045) 6669-0690"
              validate={[validateRequired]}
            />
            <FieldLabel htmlFor="profession" children="Current profession" />
            <Field
              component={FieldText}
              isTouched
              name="profession"
              placeholder="e.g.: Software Developer"
              validate={[validateRequired, validateIsAlpha]}
            />

            <FieldLabel htmlFor="professionSector" children="Sector" />
            <Field
              component={FieldText}
              isTouched
              name="professionSector"
              placeholder="e.g.: IT"
              validate={[validateRequired, validateIsAlpha]}
            />

            <FieldLabel htmlFor="nationality" children="Nationality" />
            <Field
              component={FieldSelect}
              isTouched
              name="nationality"
              options={allowedNationalities}
              placeholder={'Select your Nationality'}
              size="md"
              validate={[validateRequired]}
            />
          </div>
        </div>
      </div>

      <div className="col-12">
        <FormTitle additional title="Politically exposed person (PEP)" />
        <FieldLabel
          htmlFor="isPep"
          children="Are you, or any party connected to you, a politically exposed person? "
        />
        <Field
          component={FieldSelect}
          isTouched
          name="isPep"
          options={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
          placeholder="Please select an option"
          size="md"
          validate={[validateRequired]}
        />
        {isPep && (
          <div className="row">
            <div className="col-6">
              <FieldLabel htmlFor="pepName" children="Name of PEP" />
              <Field
                component={FieldText}
                isTouched
                name="pepName"
                placeholder=""
                validate={isPep ? [validateRequired, validateIsAlpha] : []}
              />
              <FieldLabel
                htmlFor="pepFunctionName"
                children="Name of public function"
              />
              <Field
                component={FieldText}
                isTouched
                name="pepFunctionName"
                placeholder=""
                validate={isPep ? [validateRequired, validateIsAlpha] : []}
              />
            </div>
            <div className="col-6">
              <FieldLabel htmlFor="pepCountry" children="Country" />
              <Field
                component={FieldSelect}
                isTouched
                name="pepCountry"
                options={allowedNationalities}
                placeholder={''}
                size="md"
                validate={isPep ? [validateRequired] : []}
              />
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-6">
                  <FieldLabel children="Period of service: start" />
                  <FieldSelectDate
                    isTouched
                    prefix="pepStart"
                    validate={isPep ? validateRequired : null}
                  />
                </div>
                <div className="col-6">
                  <FieldLabel children="Period of service: end" />
                  <FieldSelectDate
                    isTouched
                    prefix="pepEnd"
                    validate={isPep ? validateRequired : null}
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <FieldLabel
                htmlFor="pepRelationship"
                children="What is your relationship to the politically exposed person?"
              />
              <div className="row">
                <div className="col-6">
                  <Field
                    component={FieldSelect}
                    isTouched
                    name="pepRelationship"
                    options={[
                      { label: 'Self', value: 'Self' },
                      { label: 'Spouse', value: 'Spouse' },
                      { label: 'Child', value: 'Child' },
                      { label: 'Parent', value: 'Parent' },
                      { label: "Child's Spouse", value: "Child's Spouse" },
                      { label: 'Other', value: 'Other' },
                    ]}
                    placeholder={'Please select an option'}
                    size="md"
                    validate={isPep ? validateRequired : null}
                  />
                </div>
                {isPepRelationshipOther && (
                  <div className="col-6">
                    <Field
                      component={FieldText}
                      name="pepRelationshipOther"
                      placeholder="Please specify"
                      validate={
                        isPep && isPepRelationshipOther
                          ? validateRequired
                          : null
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="col-12">
        <FormTitle additional title="Legal person" />
        <FieldLabel
          htmlFor="isLegalPerson"
          children="Are you representing a business / organization? "
        />
        <Field
          component={FieldSelect}
          isTouched
          name="isLegalPerson"
          options={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
          placeholder="Please select an option"
          size="md"
          validate={[validateRequired]}
        />

        {isLegalPerson && (
          <div className="row">
            <div className="col-6">
              <FieldLabel
                htmlFor="legalPersonName"
                children="Full legal name"
              />
              <Field
                component={FieldText}
                isTouched
                name="legalPersonName"
                placeholder=""
                validate={isLegalPerson ? validateRequired : null}
              />

              <FieldLabel
                htmlFor="legalPersonRegistrationNumber"
                children="Registration number / incorporation number"
              />
              <Field
                component={FieldText}
                isTouched
                name="legalPersonRegistrationNumber"
                placeholder=""
                validate={isLegalPerson ? validateRequired : null}
              />

              <FieldLabel htmlFor="legalPersonIndustry" children="Industry" />
              <Field
                component={FieldText}
                isTouched
                name="legalPersonIndustry"
                placeholder=""
                validate={isLegalPerson ? validateRequired : null}
              />

              <FieldLabel
                htmlFor="legalPersonPhoneNumber"
                children="Contact number"
              />
              <Field
                component={FieldText}
                isTouched
                name="legalPersonPhoneNumber"
                placeholder=""
                validate={isLegalPerson ? validateRequired : null}
              />
            </div>

            <div className="col-6">
              <FieldLabel
                htmlFor="legalPersonDirectorNames"
                children="Names of the directors"
              />
              <Field
                component={FieldText}
                isTouched
                name="legalPersonDirectorNames"
                placeholder=""
                validate={isLegalPerson ? validateRequired : null}
              />

              <FieldLabel
                htmlFor="legalPersonBeneficialOwnerNames"
                children="Names of the beneficial owners"
              />
              <Field
                component={FieldText}
                isTouched
                name="legalPersonBeneficialOwnerNames"
                placeholder=""
                validate={isLegalPerson ? validateRequired : null}
              />

              <FieldLabel
                htmlFor="legalPersonAddress"
                children="Address of principal place of business (if different from above):"
              />
              <Field
                component={FieldTextArea}
                isTouched
                name="legalPersonAddress"
                placeholder="Please specify the full legal address"
                validate={null}
              />
            </div>
          </div>
        )}
      </div>

      <div className="col-12">
        <FormTitle additional title="Additional Information" />
        <Jumbotron>
          <FieldLabel
            htmlFor="ethereumAddress"
            children="Your Ethereum Address from MetaMask"
          />
          <p className="text-secondary">
            <small>
              The ethereum address below was provided by your MetaMask wallet.
              We will attach your personal data to this ethereum address and
              whitelist it once your account is verified.
            </small>
          </p>
          <StyledField
            component={FieldText}
            disabled={true}
            name="ethereumAddress"
            placeholder="0x..."
            type="text"
            validate={validateRequired}
          />

          <ul className="text-secondary">
            <li>
              <small>
                Only whitelisted ethereum addresses can participate in a token
                sale.
              </small>
            </li>

            <li>
              <small>
                Each user can only attach one ethereum address to their profile.
                To use another address the user must resubmit a new application.
              </small>
            </li>

            <li>
              <small>
                To use a different Ethereum address, change the selected
                MetaMask account using your MetaMask plugin.
                <br />
                <ChangeMetamaskAddressTutorial mode="opener">
                  <button className="btn btn-link">Show me how</button>
                </ChangeMetamaskAddressTutorial>
              </small>
            </li>
          </ul>
        </Jumbotron>

        <FieldLabel htmlFor="annualIncome" children="Annual income in USD" />
        <Field
          component={FieldSelect}
          isTouched
          name="annualIncome"
          placeholder="Please select a range"
          options={incomeOptions}
          size="md"
          validate={validateRequired}
        />
      </div>
      <div className="col-12">
        <FieldLabel
          htmlFor="fundsOrigin"
          children="Origin of funds used in the business relationship with Starbase PTE LTD."
        />
        <Field
          component={FieldSelect}
          isTouched
          name="fundsOrigin"
          options={fundsOriginOptions}
          placeholder="Please select an origin"
          size="md"
          validate={validateRequired}
        />
      </div>

      <div className="col-12">
        <FieldLabel
          htmlFor="fundsDescription"
          children="Origin of funds description"
        />
        <Field
          component={FieldTextArea}
          name="fundsDescription"
          placeholder="Detailed description of the background of the assets involved in the business relationship"
          validate={[validateRequired]}
        />
      </div>

      <div className="col-12">
        <FieldLabel
          htmlFor="tradingVolume"
          children="Trading volume average per month in USD"
        />
        <Field
          component={FieldSelect}
          isTouched
          name="tradingVolume"
          options={tradingVolumeOptions}
          placeholder="Please select a range"
          validate={validateRequired}
        />
      </div>
    </div>

    <FormFooter rightButtonText="Next" />
  </form>
)

export default PersonalInfoForm
