import React from 'react'
import { sumBy } from 'lodash'

const BountiesHeader = ({ bounties }) => (
  <div class="page-content mb-2">
    <div class="page-title">
      <div class="row justify-content-between align-items-center">
        <div class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
          {!!bounties.length && (
            <>
              <div class="d-inline-block">
                <h5 class="h4 d-inline-block font-weight-400 mb-0 text-white">
                  Bounties Campaigns
                </h5>
              </div>
              <div class="align-items-center ml-4 d-inline-flex">
                <span class="h4 text-info mb-0 mr-2">{bounties.length}</span>
                <span class="text-sm opacity-7 text-white">bounties</span>
              </div>

              <div class="align-items-center ml-4 d-inline-flex">
                <span class="h4 text-info mb-0 mr-2">
                  {sumBy(bounties, b => Number(b.bountyDefinition.rewardCap))}
                </span>
                <span class="text-sm opacity-7 text-white">Reward Tokens</span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  </div>
)

export default BountiesHeader
