export const USER_LOGGED_OUT = 'USER_LOGGED_OUT'
export const USER_LOGGED_IN = 'USER_LOGGED_IN'
export const RESTORE_AUTH_DATA = 'RESTORE_AUTH_DATA'

export const TERMS_OF_USE_READING_COMPLETED = 'TERMS_OF_USE_READING_COMPLETED'

export const TERMS_OF_USE_SHOW_SCROLL_INCOMPLETE_TIP =
  'TERMS_OF_USE_SHOW_SCROLL_INCOMPLETE_TIP'

export const TOGGLE_USER_DROPDOWN_MENU = 'TOGGLE_USER_DROPDOWN_MENU'

export const SET_METAMASK_LOADED = 'SET_METAMASK_LOADED'
export const SET_METAMASK_DATA = 'SET_METAMASK_DATA'
export const SET_METAMASK_FAILURE = 'SET_METAMASK_FAILURE'

export const FETCH_CURRENT_USER_REQUEST = 'FETCH_CURRENT_USER_REQUEST'
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS'
export const FETCH_CURRENT_USER_FAILURE = 'FETCH_CURRENT_USER_FAILURE'

export const FETCH_FIAT_RATES_REQUEST = 'FETCH_FIAT_RATES_REQUEST'
export const FETCH_FIAT_RATES_SUCCESS = 'FETCH_FIAT_RATES_SUCCESS'
export const FETCH_FIAT_RATES_FAILURE = 'FETCH_FIAT_RATES_FAILURE'

export const FETCH_PROJECT_REQUEST = 'FETCH_PROJECT_REQUEST'
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS'
export const FETCH_PROJECT_FAILURE = 'FETCH_PROJECT_FAILURE'

export const FETCH_PROJECTS_REQUEST = 'FETCH_PROJECTS_REQUEST'
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS'
export const FETCH_PROJECTS_FAILURE = 'FETCH_PROJECTS_FAILURE'

export const FETCH_DASHBOARD_DATA_REQUEST = 'FETCH_DASHBOARD_DATA_REQUEST'
export const FETCH_DASHBOARD_DATA_SUCCESS = 'FETCH_DASHBOARD_DATA_SUCCESS'
export const FETCH_DASHBOARD_DATA_FAILURE = 'FETCH_DASHBOARD_DATA_FAILURE'

export const METAMASK_APPROVE_TRANSACTION_START =
  'METAMASK_APPROVE_TRANSACTION_START'
export const METAMASK_APPROVE_TRANSACTION_SUCCESS =
  'METAMASK_APPROVE_TRANSACTION_SUCCESS'

export const APPROVAL_TRANSACTION_CONFIRMED = 'APPROVAL_TRANSACTION_CONFIRMED'
export const APPROVAL_TRANSACTION_PENDING = 'APPROVAL_TRANSACTION_PENDING'
export const METAMASK_BUYTOKENS_TRANSACTION_START =
  'METAMASK_BUYTOKENS_TRANSACTION_START'
export const METAMASK_BUYTOKENS_TRANSACTION_SUCCESS =
  'METAMASK_BUYTOKENS_TRANSACTION_SUCCESS'

export const POST_PENDING_PURCHASE_REQUEST = 'POST_PENDING_PURCHASE_REQUEST'
export const POST_PENDING_PURCHASE_SUCCESS = 'POST_PENDING_PURCHASE_SUCCESS'
export const POST_PENDING_PURCHASE_FAILURE = 'POST_PENDING_PURCHASE_FAILURE'

export const CANCEL_PURCHASE_REQUEST = 'CANCEL_PURCHASE_REQUEST'
export const CANCEL_PURCHASE_SUCCESS = 'CANCEL_PURCHASE_SUCCESS'
export const CANCEL_PURCHASE_FAILURE = 'CANCEL_PURCHASE_FAILURE'

export const PURCHASE_TOKENS_START = 'PURCHASE_TOKENS_START'
export const PURCHASE_TOKENS_SUCCESS = 'PURCHASE_TOKENS_SUCCESS'
export const PURCHASE_TOKENS_FAILURE = 'PURCHASE_TOKENS_FAILURE'
export const PURCHASE_TOKENS_CHANGE_MODAL_STEP =
  'PURCHASE_TOKENS_CHANGE_MODAL_STEP'
export const PURCHASE_TOKENS_CLOSE_MODAL = 'PURCHASE_TOKENS_CLOSE_MODAL'
export const PURCHASE_TOKENS_ACCEPTED = 'PURCHASE_TOKENS_ACCEPTED'
export const PURCHASE_TOKENS_REJECTED = 'PURCHASE_TOKENS_REJECTED'

export const POST_PROFILE_FORM_REQUEST = 'POST_PROFILE_FORM_REQUEST'
export const POST_PROFILE_FORM_SUCCESS = 'POST_PROFILE_FORM_SUCCESS'
export const POST_PROFILE_FORM_FAILURE = 'POST_PROFILE_FORM_FAILURE'

export const FETCH_ALLOWED_COUNTRIES_REQUEST = 'FETCH_ALLOWED_COUNTRIES_REQUEST'
export const FETCH_ALLOWED_COUNTRIES_SUCCESS = 'FETCH_ALLOWED_COUNTRIES_SUCCESS'
export const FETCH_ALLOWED_COUNTRIES_FAILURE = 'FETCH_ALLOWED_COUNTRIES_FAILURE'

export const CLOSE_ALERT = 'CLOSE_ALERT'

export const CLEAR_METAMASK_ERROR = 'CLEAR_METAMASK_ERROR'
export const CLEAR_COMMENTS_ERROR = 'CLEAR_COMMENTS_ERROR'
export const CLEAR_CURRENT_USER_ERROR = 'CLEAR_CURRENT_USER_ERROR'
export const CLEAR_DASHBOARD_ERROR = 'CLEAR_DASHBOARD_ERROR'
export const CLEAR_PROFILE_ERROR = 'CLEAR_PROFILE_ERROR'
export const CLEAR_PAY_ERROR = 'CLEAR_PAY_ERROR'
export const CLEAR_PROJECTS_ERROR = 'CLEAR_PROJECTS_ERROR'

export const SET_ACTIVE_TOAST = 'SET_ACTIVE_TOAST'

export const FETCH_BALANCES_DATA_REQUEST = 'FETCH_BALANCES_DATA_REQUEST'
export const FETCH_BALANCES_DATA_SUCCESS = 'FETCH_BALANCES_DATA_SUCCESS'
export const FETCH_BALANCES_DATA_FAILURE = 'FETCH_BALANCES_DATA_FAILURE'

export const CLOSE_MODAL = 'CLOSE_MODAL'
export const FORGET_MODAL = 'FORGET_MODAL'
export const OPEN_MODAL = 'OPEN_MODAL'

export const POST_REQUEST_ACCOUNT_DELETION_REQUEST =
  'POST_REQUEST_ACCOUNT_DELETION_REQUEST'
export const POST_REQUEST_ACCOUNT_DELETION_SUCCESS =
  'POST_REQUEST_ACCOUNT_DELETION_SUCCESS'
export const POST_REQUEST_ACCOUNT_DELETION_FAILURE =
  'POST_REQUEST_ACCOUNT_DELETION_FAILURE'

export const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE'
export const DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST'
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS'

export const FETCH_MY_DATA_FAILURE = 'FETCH_MY_DATA_FAILURE'
export const FETCH_MY_DATA_REQUEST = 'FETCH_MY_DATA_REQUEST'
export const FETCH_MY_DATA_SUCCESS = 'FETCH_MY_DATA_SUCCESS'

export const SET_DEV_PREVIEW = 'SET_DEV_PREVIEW'

export const POST_NEW_COMMENT_FORM_FAILURE = 'POST_NEW_COMMENT_FORM_FAILURE'
export const POST_NEW_COMMENT_FORM_REQUEST = 'POST_NEW_COMMENT_FORM_REQUEST'
export const POST_NEW_COMMENT_FORM_SUCCESS = 'POST_NEW_COMMENT_FORM_SUCCESS'

export const FETCH_COMMENTABLE_COMMENTS_REQUEST =
  'FETCH_COMMENTABLE_COMMENTS_REQUEST'
export const FETCH_COMMENTABLE_COMMENTS_SUCCESS =
  'FETCH_COMMENTABLE_COMMENTS_SUCCESS'
export const FETCH_COMMENTABLE_COMMENTS_FAILURE =
  'FETCH_COMMENTABLE_COMMENTS_FAILURE'

export const CLEAR_PROJECT_BOUNTIES_ERROR = 'CLEAR_PROJECT_BOUNTIES_ERROR'

export const FETCH_PROJECT_BOUNTIES_REQUEST = 'FETCH_PROJECT_BOUNTIES_REQUEST'
export const FETCH_PROJECT_BOUNTIES_SUCCESS = 'FETCH_PROJECT_BOUNTIES_SUCCESS'
export const FETCH_PROJECT_BOUNTIES_FAILURE = 'FETCH_PROJECT_BOUNTIES_FAILURE'

export const CLEAR_ALL_BOUNTIES_ERROR = 'CLEAR_ALL_BOUNTIES_ERROR'

export const FETCH_ALL_BOUNTIES_REQUEST = 'FETCH_ALL_BOUNTIES_REQUEST'
export const FETCH_ALL_BOUNTIES_SUCCESS = 'FETCH_ALL_BOUNTIES_SUCCESS'
export const FETCH_ALL_BOUNTIES_FAILURE = 'FETCH_ALL_BOUNTIES_FAILURE'
export const FETCH_PROJECT_BOUNTIES_CLAIMED_SUCCESS =
  'FETCH_PROJECT_BOUNTIES_CLAIMED_SUCCESS'
export const POST_BOUNTY_CLAIM_REQUEST = 'POST_BOUNTY_CLAIM_REQUEST'
export const POST_BOUNTY_CLAIM_REQUEST_SUCCESS =
  'POST_BOUNTY_CLAIM_REQUEST_SUCCESS'
export const POST_BOUNTY_CLAIM_REQUEST_FAILURE =
  'POST_BOUNTY_CLAIM_REQUEST_FAILURE'

export const FETCH_VOUCHER_DEFINITION_REQUEST =
  'FETCH_VOUCHER_DEFINITION_REQUEST'
export const FETCH_VOUCHER_DEFINITION_SUCCESS =
  'FETCH_VOUCHER_DEFINITION_SUCCESS'
export const FETCH_VOUCHER_DEFINITION_FAILURE =
  'FETCH_VOUCHER_DEFINITION_FAILURE'

export const POST_VOUCHER_CODE_FORM_REQUEST = 'POST_VOUCHER_CODE_FORM_REQUEST'
export const POST_VOUCHER_CODE_FORM_SUCCESS = 'POST_VOUCHER_CODE_FORM_SUCCESS'
export const POST_VOUCHER_CODE_FORM_FAILURE = 'POST_VOUCHER_CODE_FORM_FAILURE'

export const VALIDATE_VOUCHER_CODE_FORM_REQUEST =
  'VALIDATE_VOUCHER_CODE_FORM_REQUEST'
export const VALIDATE_VOUCHER_CODE_FORM_SUCCESS =
  'VALIDATE_VOUCHER_CODE_FORM_SUCCESS'
export const VALIDATE_VOUCHER_CODE_FORM_FAILURE =
  'VALIDATE_VOUCHER_CODE_FORM_FAILURE'

export const CLEAR_ALL_VOUCHERS_ERROR = 'CLEAR_ALL_VOUCHERS_ERROR'

export const FETCH_VOUCHER_CODE_REQUEST = 'FETCH_VOUCHER_CODE_REQUEST'
export const FETCH_VOUCHER_CODE_SUCCESS = 'FETCH_VOUCHER_CODE_SUCCESS'
export const FETCH_VOUCHER_CODE_FAILURE = 'FETCH_VOUCHER_CODE_FAILURE'

export const CLEAR_ALL_PROJECT_ERROR = 'CLEAR_ALL_PROJECT_ERROR'
export const FETCH_PROJECT_DASHBOARD_REQUEST = 'FETCH_PROJECT_DASHBOARD_REQUEST'
export const FETCH_PROJECT_DASHBOARD_SUCCESS = 'FETCH_PROJECT_DASHBOARD_SUCCESS'
export const FETCH_PROJECT_DASHBOARD_FAILURE = 'FETCH_PROJECT_DASHBOARD_FAILURE'

export const PUT_ACCEPT_BOUNTY_CLAIM_REQUEST = 'PUT_ACCEPT_BOUNTY_CLAIM_REQUEST'
export const PUT_ACCEPT_BOUNTY_CLAIM_SUCCESS = 'PUT_ACCEPT_BOUNTY_CLAIM_SUCCESS'
export const PUT_ACCEPT_BOUNTY_CLAIM_FAILURE = 'PUT_ACCEPT_BOUNTY_CLAIM_FAILURE'

export const PUT_REJECT_BOUNTY_CLAIM_REQUEST = 'PUT_REJECT_BOUNTY_CLAIM_REQUEST'
export const PUT_REJECT_BOUNTY_CLAIM_SUCCESS = 'PUT_REJECT_BOUNTY_CLAIM_SUCCESS'
export const PUT_REJECT_BOUNTY_CLAIM_FAILURE = 'PUT_REJECT_BOUNTY_CLAIM_FAILURE'

export const REQUEST_ETHEREUM_ADDRESS_IS_USED =
  'REQUEST_ETHEREUM_ADDRESS_IS_USED'
export const REQUEST_ETHEREUM_ADDRESS_IS_USED_SUCCESS =
  'REQUEST_ETHEREUM_ADDRESS_IS_USED_SUCCESS'
export const REQUEST_ETHEREUM_ADDRESS_IS_USED_FAILURE =
  'REQUEST_ETHEREUM_ADDRESS_IS_USED_FAILURE'

export const CLEAR_STAKE_ERROR = 'CLEAR_STAKE_ERROR'
export const STAKE_TOKENS_CLOSE_MODAL = 'STAKE_TOKENS_CLOSE_MODAL'
export const STAKE_TOKENS_OPEN_MODAL = 'STAKE_TOKENS_OPEN_MODAL'

export const STAKE_TOKENS_CLOSE_USER_NOT_ACCEPTED_MODAL =
  'STAKE_TOKENS_CLOSE_USER_NOT_ACCEPTED_MODAL'
export const STAKE_TOKENS_OPEN_USER_NOT_ACCEPTED_MODAL =
  'STAKE_TOKENS_OPEN_USER_NOT_ACCEPTED_MODAL'
export const STAKE_TOKENS_CLOSE_USER_NOT_AUTHENTICATED_MODAL =
  'STAKE_TOKENS_CLOSE_USER_NOT_AUTHENTICATED_MODAL'
export const STAKE_TOKENS_OPEN_USER_NOT_AUTHENTICATED_MODAL =
  'STAKE_TOKENS_OPEN_USER_NOT_AUTHENTICATED_MODAL'
export const STAKE_TOKENS_CLOSE_USER_NOT_REGISTERED_MODAL =
  'STAKE_TOKENS_CLOSE_USER_NOT_REGISTERED_MODAL'
export const STAKE_TOKENS_OPEN_USER_NOT_REGISTERED_MODAL =
  'STAKE_TOKENS_OPEN_USER_NOT_REGISTERED_MODAL'

export const STAKE_TOKENS_FAILURE = 'STAKE_TOKENS_FAILURE'
export const STAKE_TOKENS_START = 'STAKE_TOKENS_START'
export const STAKE_TOKENS_SUCCESS = 'STAKE_TOKENS_SUCCESS'

export const METAMASK_STAKE_TOKENS_TRANSACTION_START =
  'METAMASK_STAKE_TOKENS_TRANSACTION_START'
export const METAMASK_STAKE_TOKENS_TRANSACTION_SUCCESS =
  'METAMASK_STAKE_TOKENS_TRANSACTION_SUCCESS'

export const STAKE_TOKENS_TRANSACTION_PENDING =
  'STAKE_TOKENS_TRANSACTION_PENDING'
export const STAKE_TOKENS_TRANSACTION_CONFIRMED =
  'STAKE_TOKENS_TRANSACTION_CONFIRMED'

export const FETCH_STAKE_DEFINITION_REQUEST = 'FETCH_STAKE_DEFINITION_REQUEST'
export const FETCH_STAKE_DEFINITION_SUCCESS = 'FETCH_STAKE_DEFINITION_SUCCESS'
export const FETCH_STAKE_DEFINITION_FAILURE = 'FETCH_STAKE_DEFINITION_FAILURE'

export const FETCH_STAKE_NEW_POSITION_SUCCESS =
  'FETCH_STAKE_NEW_POSITION_SUCCESS'

export const WITHDRAW_STAKE_TOKENS_START = 'WITHDRAW_STAKE_TOKENS_START'
export const METAMASK_WITHDRAW_STAKE_TOKENS_TRANSACTION_START =
  'METAMASK_WITHDRAW_STAKE_TOKENS_TRANSACTION_START'
export const METAMASK_WITHDRAW_STAKE_TOKENS_TRANSACTION_SUCCESS =
  'METAMASK_WITHDRAW_STAKE_TOKENS_TRANSACTION_SUCCESS'
export const WITHDRAW_STAKE_TOKENS_TRANSACTION_PENDING =
  'WITHDRAW_STAKE_TOKENS_TRANSACTION_PENDING'
export const WITHDRAW_STAKE_TOKENS_FAILURE = 'WITHDRAW_STAKE_TOKENS_FAILURE'
export const WITHDRAW_STAKE_TOKENS_TRANSACTION_CONFIRMED =
  'WITHDRAW_STAKE_TOKENS_TRANSACTION_CONFIRMED'
export const WITHDRAW_STAKE_TOKENS_SUCCESS = 'WITHDRAW_STAKE_TOKENS_SUCCESS'
export const WITHDRAW_STAKE_TOKENS_OPEN_MODAL =
  'WITHDRAW_STAKE_TOKENS_OPEN_MODAL'
export const WITHDRAW_STAKE_TOKENS_CLOSE_MODAL =
  'WITHDRAW_STAKE_TOKENS_CLOSE_MODAL'

export const WITHDRAW_BONUS_TOKENS_DISABLE_BUTTON =
  'WITHDRAW_BONUS_TOKENS_DISABLE_BUTTON'
export const WITHDRAW_BONUS_TOKENS_ENABLE_BUTTON =
  'WITHDRAW_BONUS_TOKENS_ENABLE_BUTTON'
export const FETCH_BONUS_TOKEN_BALANCE_SUCCESS =
  'FETCH_BONUS_TOKEN_BALANCE_SUCCESS'
export const FETCH_BONUS_TOKEN_BALANCE_FAILURE =
  'FETCH_BONUS_TOKEN_BALANCE_FAILURE'
export const WITHDRAW_BONUS_TOKENS_OPEN_MODAL =
  'WITHDRAW_BONUS_TOKENS_OPEN_MODAL'
export const WITHDRAW_BONUS_TOKENS_CLOSE_MODAL =
  'WITHDRAW_BONUS_TOKENS_CLOSE_MODAL'
export const WITHDRAW_BONUS_TOKENS_FAILURE = 'WITHDRAW_BONUS_TOKENS_FAILURE'
export const WITHDRAW_BONUS_TOKENS_START = 'WITHDRAW_BONUS_TOKENS_START'
export const WITHDRAW_BONUS_TOKENS_TRANSACTION_PENDING =
  'WITHDRAW_BONUS_TOKENS_TRANSACTION_PENDING'
export const WITHDRAW_BONUS_TOKENS_TRANSACTION_CONFIRMED =
  'WITHDRAW_BONUS_TOKENS_TRANSACTION_CONFIRMED'

export const FETCH_SWAP_ETH_STAR_RATE_SUCCESS =
  'FETCH_SWAP_ETH_STAR_RATE_SUCCESS'
export const FETCH_SWAP_ETH_STAR_RATE_FAILURE =
  'FETCH_SWAP_ETH_STAR_RATE_FAILURE'
export const SWAP_TOKENS_FAILURE = 'SWAP_TOKENS_FAILURE'
export const SWAP_TOKENS_START = 'SWAP_TOKENS_START'
export const SWAP_TOKENS_SUCCESS = 'SWAP_TOKENS_SUCCESS'
export const SWAP_TOKENS_TRANSACTION_CONFIRMED =
  'SWAP_TOKENS_TRANSACTION_CONFIRMED'
export const SWAP_TOKENS_TRANSACTION_PENDING = 'SWAP_TOKENS_TRANSACTION_PENDING'
export const SWAP_TOKENS_OPEN_MODAL = 'SWAP_TOKENS_OPEN_MODAL'
export const SWAP_TOKENS_CLOSE_MODAL = 'SWAP_TOKENS_CLOSE_MODAL'

export const PUT_SUBMIT_ERROR = 'PUT_SUBMIT_ERROR'
