import Web3 from 'web3'

import detectEnvironment from '../detectEnvironment'

const provider =
  detectEnvironment() === 'production'
    ? process.env.REACT_APP_WEB3_WEBSOCKET_PROVIDER_URL_PRODUCTION
    : process.env.REACT_APP_WEB3_WEBSOCKET_PROVIDER_URL_DEFAULT

const websocketWeb3 = new Web3(new Web3.providers.WebsocketProvider(provider))

export default websocketWeb3
