import { connect } from 'react-redux'
import get from 'lodash/get'

import FormFooter from './FormFooter'
import { toProfile } from '../../../../../store/routerActions'

const mapStateToProps = state => ({
  isSubmitting: get(state.form.profileForm, 'submitting', false),
  step: state.location.payload.step,
  submissionErrors: get(state.form.profileForm, 'error', null),
})

const mapDispatchToProps = dispatch => ({
  dispatch,
})

const mergeProps = (
  { isSubmitting, step, submissionErrors },
  { dispatch },
  ownProps
) => {
  const isFirstStep = step === 1
  const isLastStep = step === 4
  const toPrevStep = () => dispatch(toProfile({ step: step - 1 }))

  return {
    ...ownProps,
    isFirstStep,
    isLastStep,
    isSubmitting,
    submissionErrors,
    toPrevStep,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(FormFooter)
