import {
  FETCH_BONUS_TOKEN_BALANCE_FAILURE,
  FETCH_BONUS_TOKEN_BALANCE_SUCCESS,
  WITHDRAW_BONUS_TOKENS_OPEN_MODAL,
  WITHDRAW_BONUS_TOKENS_CLOSE_MODAL,
  WITHDRAW_BONUS_TOKENS_DISABLE_BUTTON,
} from 'store/types'

import captureException from 'sentryHelpers/captureException'
import {
  getBonusTokensBalance,
  withdrawAllBonusTokens,
  handleError,
} from 'web3Helpers/bonusTokens'

export const openModal = ({
  balance,
  bonusTokenContractAbi,
  bonusTokenAddress,
  ethereumAddress,
  tokenSymbol,
}) => dispatch =>
  dispatch({
    type: WITHDRAW_BONUS_TOKENS_OPEN_MODAL,
    payload: {
      balance,
      bonusTokenContractAbi,
      bonusTokenAddress,
      ethereumAddress,
      tokenSymbol,
    },
  })

export const closeModal = () => dispatch =>
  dispatch({ type: WITHDRAW_BONUS_TOKENS_CLOSE_MODAL })

export const fetchBonusTokensBalance = ({
  bonusTokenContractAbi,
  bonusTokenAddress,
  ethereumAddress,
}) => async dispatch => {
  try {
    const balance = await getBonusTokensBalance({
      bonusTokenContractAbi,
      bonusTokenAddress,
      ethereumAddress,
    })

    const payload = {
      [bonusTokenAddress]: {
        balance,
        bonusTokenContractAbi,
        bonusTokenAddress,
        ethereumAddress,
      },
    }

    dispatch({
      type: FETCH_BONUS_TOKEN_BALANCE_SUCCESS,
      payload,
    })
  } catch (error) {
    captureException(error)
    dispatch({ type: FETCH_BONUS_TOKEN_BALANCE_FAILURE, error: error.message })
  }
}

export const withdrawBonusTokens = ({
  bonusTokenContractAbi,
  bonusTokenAddress,
  ethereumAddress,
}) => async dispatch => {
  try {
    dispatch({
      payload: bonusTokenAddress,
      type: WITHDRAW_BONUS_TOKENS_DISABLE_BUTTON,
    })

    dispatch(
      withdrawAllBonusTokens({
        bonusTokenContractAbi,
        bonusTokenAddress,
        ethereumAddress,
      })
    )
  } catch (e) {
    dispatch(handleError(e))
  }
}
