import Modal from 'reactstrap/lib/Modal'
import ModalBody from 'reactstrap/lib/ModalBody'
import React from 'react'

export default ({ close, isOpen }) => (
  <Modal isOpen={isOpen}>
    <ModalBody>
      <div className="text-center">
        <div className="alert alert-info">
          <p className="mb-0">Your have been be logged out automatically.</p>
        </div>

        <p>
          For your security, your session is closed automatically after a
          certain time.
        </p>

        <p className="small">
          To continue using the platform, please login again.
        </p>

        <button className="btn btn-primary" onClick={close}>
          I understand, close this dialog
        </button>
      </div>
    </ModalBody>
  </Modal>
)
