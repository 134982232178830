import * as api from '../../../api'
import * as web3Helpers from '../../../web3Helpers'

import { TRANSACTION_REJECTED_BY_USER_ERROR } from '../../../errors'

import {
  APPROVAL_TRANSACTION_CONFIRMED,
  APPROVAL_TRANSACTION_PENDING,
  METAMASK_APPROVE_TRANSACTION_START,
  METAMASK_APPROVE_TRANSACTION_SUCCESS,
  METAMASK_BUYTOKENS_TRANSACTION_START,
  METAMASK_BUYTOKENS_TRANSACTION_SUCCESS,
  POST_PENDING_PURCHASE_FAILURE,
  POST_PENDING_PURCHASE_REQUEST,
  POST_PENDING_PURCHASE_SUCCESS,
  PURCHASE_TOKENS_FAILURE,
  PURCHASE_TOKENS_START,
  PURCHASE_TOKENS_SUCCESS,
} from '../../types'

const spendStarTokens = purchaseParams => async dispatch => {
  try {
    dispatch({ type: PURCHASE_TOKENS_START })

    dispatch({ type: METAMASK_APPROVE_TRANSACTION_START })

    const {
      ethereumAddressFrom, //actually the from is an starbase address but we need to store the user approval from address
      purchaseValue,
      tokenSaleContractAbi,
      tokenSaleContractAddress,
    } = purchaseParams

    await web3Helpers
      .approveStar({
        ethereumAddressFrom,
        starAmount: purchaseValue,
        spenderAddress: tokenSaleContractAddress,
      })
      .on('transactionHash', () => {
        dispatch({ type: METAMASK_APPROVE_TRANSACTION_SUCCESS })
        dispatch({ type: APPROVAL_TRANSACTION_PENDING })
      })
      .on('error', error => dispatch(handleError(error)))

    dispatch({ type: APPROVAL_TRANSACTION_CONFIRMED })
    dispatch({ type: METAMASK_BUYTOKENS_TRANSACTION_START })

    web3Helpers
      .buyTokens({
        ethereumAddressFrom,
        tokenSaleContractAbi,
        tokenSaleContractAddress,
      })
      .on('transactionHash', async hash => {
        dispatch({ type: METAMASK_BUYTOKENS_TRANSACTION_SUCCESS })

        const pendingPurchase = await dispatch(
          postPendingPurchase({
            ...purchaseParams,
            purchaseCurrency: 'STAR',
            ethereumTransactionHash: hash,
          })
        )

        dispatch({ type: PURCHASE_TOKENS_SUCCESS, payload: pendingPurchase })
      })
      .on('error', error => dispatch(handleError(error)))
  } catch (e) {
    dispatch(handleError(e))
  }
}

const spendEthTokens = purchaseParams => async dispatch => {
  try {
    dispatch({ type: PURCHASE_TOKENS_START })
    dispatch({ type: METAMASK_BUYTOKENS_TRANSACTION_START })

    const {
      ethereumAddressFrom,
      purchaseValue,
      tokenSaleContractAbi,
      tokenSaleContractAddress,
    } = purchaseParams

    const { web3 } = window

    web3Helpers
      .buyTokens({
        ethWeiValue: web3.utils.toWei(purchaseValue.toString()),
        ethereumAddressFrom,
        tokenSaleContractAbi,
        tokenSaleContractAddress,
      })
      .on('transactionHash', async hash => {
        dispatch({ type: METAMASK_BUYTOKENS_TRANSACTION_SUCCESS })

        const pendingPurchase = await dispatch(
          postPendingPurchase({
            ...purchaseParams,
            purchaseCurrency: 'ETH',
            ethereumTransactionHash: hash,
          })
        )

        dispatch({ type: PURCHASE_TOKENS_SUCCESS, payload: pendingPurchase })
      })
      .on('error', error => dispatch(handleError(error)))
  } catch (e) {
    dispatch(handleError(e))
  }
}

const postPendingPurchase = params => async dispatch => {
  dispatch({ type: POST_PENDING_PURCHASE_REQUEST, ...params })

  // if error happens api-side, api returns a JSON with { "error": ... }
  const { error, ...pendingPurchase } = await api.postPendingPurchase({
    purchase: params,
  })

  if (error) {
    dispatch({ type: POST_PENDING_PURCHASE_FAILURE, error: error.message })
    dispatch(handleError(error))
    throw new Error(error)
  }

  dispatch({ type: POST_PENDING_PURCHASE_SUCCESS, payload: pendingPurchase })

  return pendingPurchase
}

const handleError = e => dispatch => {
  const error = e.message.includes(
    'Error: MetaMask Tx Signature: User denied transaction signature'
  )
    ? TRANSACTION_REJECTED_BY_USER_ERROR
    : e

  dispatch({
    type: PURCHASE_TOKENS_FAILURE,
    error: error instanceof Error ? error.message : error,
  })
}

export { spendStarTokens, spendEthTokens }
