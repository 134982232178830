import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import SubmitInfo from './SubmitInfo'

import { postProfileForm } from '../../../../../store/profile/profileActions'

const mapStateToProps = ({
  form: {
    profileForm: { submitting },
  },
}) => ({
  isSubmitting: submitting,
})

const mapDispatchToProps = dispatch => ({
  dispatch,
})

const mergeProps = (
  { isSubmitting },
  { dispatch },
  { handleSubmit, ...ownProps }
) => ({
  ...ownProps,
  isSubmitting,
  onSubmit: handleSubmit(values => dispatch(postProfileForm(values))),
})

export default reduxForm({
  form: 'profileForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(SubmitInfo)
)
