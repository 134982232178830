import {
  CLEAR_CURRENT_USER_ERROR,
  FETCH_CURRENT_USER_SUCCESS,
  FETCH_CURRENT_USER_FAILURE,
  POST_REQUEST_ACCOUNT_DELETION_FAILURE,
  USER_LOGGED_OUT,
} from '../types'

const initialState = {
  error: null,
  user: {},
}

export default (state = initialState, { type, payload, error }) => {
  switch (type) {
    case FETCH_CURRENT_USER_SUCCESS:
      return { user: payload.user, error: null }

    case FETCH_CURRENT_USER_FAILURE:
      return { ...state, error }

    case POST_REQUEST_ACCOUNT_DELETION_FAILURE:
      return { ...state, error }

    case CLEAR_CURRENT_USER_ERROR:
      return { ...state, error: null }

    case USER_LOGGED_OUT:
      return initialState

    default:
      return state
  }
}
