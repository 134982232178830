import Cookies from 'js-cookie'
import * as Sentry from '@sentry/browser'
import Auth from './lib/Auth'
import captureException from '../sentryHelpers/captureException'

const auth = new Auth()
const accessToken = Cookies.get('access_token')

if (process.env.REACT_APP_DISABLE_AUTH0 !== 'true' && accessToken) {
  auth.auth0.client.userInfo(accessToken, (error, { email }) => {
    if (process.env.REACT_APP_DISABLE_ERROR_REPORTING === 'true') return

    if (error) {
      captureException(error)
      return
    }

    if (email) Sentry.configureScope(scope => scope.setUser({ email }))
  })
}

export default auth
