import { connect } from 'react-redux'

import Profile from './Profile'

const mapStateToProps = state => ({
  step: state.location.payload.step,
})

const mapDispatchToProps = () => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile)
