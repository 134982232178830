import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import ProtectedActionIcon from './ProtectedActionIcon'

const DefaultProtectedPageMessage = () => (
  <p>
    In order to access this page, you need first to complete the registration
    form.
  </p>
)

const ProtectedPageModal = ({
  children = <DefaultProtectedPageMessage />,
  isOpen,
  title,
}) => (
  <Modal isOpen={isOpen}>
    <ModalHeader>
      <ProtectedActionIcon />
      {title}
    </ModalHeader>

    <ModalBody>
      <div className="text-center">{children}</div>
    </ModalBody>
  </Modal>
)

export default ProtectedPageModal
