import React from 'react'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'

import formatDate from 'app/scenes/helpers/formatDate'

const BountyInfo = ({
  bounty: { name, description, startDate, endDate, rewardAmount },
}) => (
  <>
    <ul class="list-group mb-4">
      <li class="list-group-item">
        Name: <small className="text-muted"> {name}</small>
      </li>
      <li class="list-group-item">
        Description: <small className="text-muted"> {description}</small>
      </li>

      <li class="list-group-item d-flex justify-content-between align-items-center">
        <span>
          Start Date:{' '}
          <span class="badge badge-primary badge-pill">
            {formatDate(startDate)}
          </span>
        </span>
        <span>
          End Date:{' '}
          <span class="badge badge-primary badge-pill">
            {formatDate(endDate)}
          </span>
        </span>
      </li>
      <li class="list-group-item ">
        Max Reward Amount:{' '}
        <span class="badge badge-primary badge-pill">{rewardAmount}</span>
      </li>
    </ul>
  </>
)

export default ({ close, isOpen, bounty }) => (
  <Modal isOpen={isOpen} size="lg">
    <ModalHeader>
      Bounty <small className="text-muted"> {bounty.id}</small>
    </ModalHeader>
    <ModalBody>
      <BountyInfo bounty={bounty} />
      <hr />
      <Button color="secondary" onClick={close}>
        Close
      </Button>
    </ModalBody>
  </Modal>
)
