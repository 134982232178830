import 'whatwg-fetch'
import { connect } from 'react-redux'

import Dev from './Dev'
import { SET_DEV_PREVIEW } from 'store/types'
import store from 'store/store'

const makePurchase = () =>
  fetch('/dev/makePurchase', {
    method: 'POST',
    body: null,
    headers: {
      Accept: 'application/json',
    },
  }).then(async res => {
    const json = await res.json()
    alert(`
Generated following entities:

${JSON.stringify(json)}
`)
  })

const makeDemoData = ({ type, data }) =>
  fetch(`/api/dev/makeDemoData/${type}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(async res => {
    const json = await res.json()
    store.dispatch({ type: SET_DEV_PREVIEW, payload: json })
  })

const makeDemoUser = () => makeDemoData({ type: 'user' })
const makeDemoUserAndProject = () => makeDemoData({ type: 'project' })
const makeDemoUserAndProjectAndTokenSale = (status = null) =>
  makeDemoData({ type: 'tokensale', data: { status } })

const makeDemoUserAndProjectAndTokenSales = () =>
  makeDemoData({ type: 'tokensales' })

const makeDemoUserAndProjectAndTokenSalesAndVoucherDefinition = () =>
  makeDemoData({ type: 'voucher' })

const makeDemoUserAndProjectAndBounty = () => makeDemoData({ type: 'bounty' })

const makeDemoUserAndProjectWithStake = () => makeDemoData({ type: 'stake' })

const makeUserApplicationRejected = () =>
  makeDemoData({ type: 'userApplicationRejected' })

const mapStateToProps = ({ dev: { preview } }) => ({
  preview: JSON.stringify(preview, null, 2),
})

const mapDispatchToProps = () => ({
  makeDemoUser,
  makeDemoUserAndProject,
  makeDemoUserAndProjectAndBounty,
  makeDemoUserAndProjectAndTokenSale,
  makeDemoUserAndProjectAndTokenSales,
  makeDemoUserAndProjectAndTokenSalesAndVoucherDefinition,
  makeDemoUserAndProjectWithStake,
  makePurchase,
  makeUserApplicationRejected,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dev)
