// IMPORTANT NOTE:
// Both incomeOptions.js in frontend and backend need to be kept identical.
// If you modify this file, be sure to apply the same changes to the other one.

export default [
  { value: '1', label: '< $100.000' },
  { value: '2', label: '$100.000 – $500.000' },
  { value: '3', label: '$500.000 – $1.000.000' },
  { value: '4', label: '$1.000.000 – $10.000.000' },
  { value: '5', label: '> $10.000.000' },
]
