export const PURCHASE_EXCEEDS_SUPPLY_SERVER_ERROR =
  'PURCHASE_EXCEEDS_SUPPLY_SERVER_ERROR'

export const TRANSACTION_REJECTED_BY_USER_ERROR =
  'TRANSACTION_REJECTED_BY_USER_ERROR'

export const METAMASK_IS_LOCKED_ERROR = 'METAMASK_IS_LOCKED_ERROR'

export const PROJECT_NOT_FOUND_SERVER_ERROR = 'PROJECT_NOT_FOUND_SERVER_ERROR'

export const SERVER_ERROR = 'SERVER_ERROR'

export const FETCH_BALANCE_SERVER_ERROR = 'FETCH_BALANCE_SERVER_ERROR'

export const UNSUPPORTED_PURCHASE_CURRENCY_ERROR =
  'UNSUPPORTED_PURCHASE_CURRENCY_ERROR'

export const CURRENT_USER_SERVER_ERROR = 'CURRENT_USER_SERVER_ERROR'
export const POST_USER_SERVER_ERROR = 'POST_USER_SERVER_ERROR'

export const REQUEST_USER_DELETION_SERVER_ERROR =
  'REQUEST_USER_DELETION_SERVER_ERROR'

export const USER_DELETION_SERVER_ERROR = 'USER_DELETION_SERVER_ERROR'

export const UNKNOWN_SERVER_ERROR = 'UNKNOWN_SERVER_ERROR'

export const WRONG_USER_LOGGED_IN_SERVER_ERROR =
  'WRONG_USER_LOGGED_IN_SERVER_ERROR'

export const JWT_DATA_MISMATCH_SERVER_ERROR = 'JWT_DATA_MISMATCH_SERVER_ERROR'

export const INVALID_JWT_SERVER_ERROR = 'INVALID_JWT_SERVER_ERROR'

export const PROJECT_OWNERS_CANNOT_BE_REMOVED_SERVER_ERROR =
  'PROJECT_OWNERS_CANNOT_BE_REMOVED_SERVER_ERROR'

export const ONLY_ACCEPTED_USERS_CAN_POST_COMMENTS_SERVER_ERROR =
  'ONLY_ACCEPTED_USERS_CAN_POST_COMMENTS_SERVER_ERROR'

export const POST_VOUCHER_CODE_UNIQUE_SERVER_ERROR =
  'POST_VOUCHER_CODE_UNIQUE_SERVER_ERROR'

export const VALIDATE_VOUCHER_CODE_WRONG_CODE =
  'VALIDATE_VOUCHER_CODE_WRONG_CODE'

export const VALIDATE_VOUCHER_CODE_WRONG_TOKEN_SALE =
  'VALIDATE_VOUCHER_CODE_WRONG_TOKEN_SALE'

export const VOUCHER_OWNER_IS_BUYER_SERVER_ERROR =
  'VOUCHER_OWNER_IS_BUYER_SERVER_ERROR'

export const VOUCHER_CODE_INSUFFICIENT_REWARD_CAP_SERVER_ERROR =
  'VOUCHER_CODE_INSUFFICIENT_REWARD_CAP_SERVER_ERROR'

export const VOUCHER_CODE_NOT_FOUND_SERVER_ERROR =
  'VOUCHER_CODE_NOT_FOUND_SERVER_ERROR'

export const BOUNTY_CAN_ONLY_BE_CLAIMED_ONCE_SERVER_ERROR =
  'BOUNTY_CAN_ONLY_BE_CLAIMED_ONCE_SERVER_ERROR'

export const BOUNTY_REACHED_MAX_NUMBER_CLAIMS_SERVER_ERROR =
  'BOUNTY_REACHED_MAX_NUMBER_CLAIMS_SERVER_ERROR'

export const GET_BOUNTIES_SERVER_ERROR = 'GET_BOUNTIES_SERVER_ERROR'

export const ONLY_ACCEPTED_USERS_CAN_POST_BOUNTY_CLAIM_SERVER_ERROR =
  'ONLY_ACCEPTED_USERS_CAN_POST_BOUNTY_CLAIM_SERVER_ERROR'
export const POST_BOUNTY_CLAIM_SERVER_ERROR = 'POST_BOUNTY_CLAIM_SERVER_ERROR'

export const PUT_ACCEPT_BOUNTY_CLAIM_SERVER_ERROR =
  'PUT_ACCEPT_BOUNTY_CLAIM_SERVER_ERROR'

export const PUT_REJECT_BOUNTY_CLAIM_SERVER_ERROR =
  'PUT_REJECT_BOUNTY_CLAIM_SERVER_ERROR'

export const GET_PROJECT_DASHBOARD_SERVER_ERROR =
  'GET_PROJECT_DASHBOARD_SERVER_ERROR'

export const PROJECT_ACCESS_DENIED_SERVER_ERROR =
  'PROJECT_ACCESS_DENIED_SERVER_ERROR'

export const BOUNTY_INSUFFICIENT_REWARD_CAP_SERVER_ERROR =
  'BOUNTY_INSUFFICIENT_REWARD_CAP_SERVER_ERROR'

export const PROJECT_OWNERS_CANNOT_SUBMIT_OWN_BOUNTY_SERVER_ERROR =
  'PROJECT_OWNERS_CANNOT_SUBMIT_OWN_BOUNTY_SERVER_ERROR'
