import { directS3Upload, fetchS3SignedUrl } from 's3Helpers'

export default async ({ file }) => {
  const { signedRequest, url } = await fetchS3SignedUrl({
    filename: 'residencyProof',
    filetype: file.type,
  })

  const response = await directS3Upload({ file, signedRequest, url })
  if (!response.success) throw Error('Upload of residency proof failed')

  // returns the target filename as saved in the bucket
  return response.success.split('/').reverse()[0]
}
