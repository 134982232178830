import {
  FETCH_COMMENTABLE_COMMENTS_REQUEST,
  FETCH_COMMENTABLE_COMMENTS_SUCCESS,
  FETCH_COMMENTABLE_COMMENTS_FAILURE,
  POST_NEW_COMMENT_FORM_FAILURE,
  POST_NEW_COMMENT_FORM_REQUEST,
  POST_NEW_COMMENT_FORM_SUCCESS,
} from '../types'

import * as api from '../../api'
import captureException from '../../sentryHelpers/captureException'

export const fetchCommentableComments = ({
  commentableId,
  commentableType,
}) => async dispatch => {
  dispatch({
    type: FETCH_COMMENTABLE_COMMENTS_REQUEST,
    commentableId,
    commentableType,
  })

  return api
    .fetchCommentableComments({ commentableId, commentableType })
    .then(({ comments }) => {
      dispatch({
        type: FETCH_COMMENTABLE_COMMENTS_SUCCESS,
        payload: { comments },
      })
    })
    .catch(error => {
      captureException(error)
      dispatch({
        type: FETCH_COMMENTABLE_COMMENTS_FAILURE,
        error: error.message,
      })
    })
}

export const postNewCommentForm = newCommentForm => async dispatch => {
  dispatch({ type: POST_NEW_COMMENT_FORM_REQUEST, newCommentForm })

  return api
    .postNewCommentForm(newCommentForm)
    .then(response => {
      dispatch({
        type: POST_NEW_COMMENT_FORM_SUCCESS,
        payload: response,
      })
    })
    .catch(error => {
      captureException(error)
      dispatch({ type: POST_NEW_COMMENT_FORM_FAILURE, error: error.message })
    })
}
