import { connect } from 'react-redux'

import Dashboard from './Dashboard'
import selectIsUserRegistered from '../../../store/selectors/selectIsUserRegistered'

const mapOwnerClaimToReward = ({
  id,
  createdAt,
  rewardAmountOwner,
  rewardTransactionHashOwner,
  status,
  voucherCode: {
    code,
    voucherDefinition: {
      tokenSale: { tokenSymbol },
    },
  },
}) => {
  return {
    id,
    createdAt,
    code,
    rewardAmount: rewardAmountOwner,
    status,
    tokenSymbol,
    transactionHash: rewardTransactionHashOwner,
  }
}

const mapBuyerClaimToReward = ({
  id,
  createdAt,
  rewardAmountBuyer,
  rewardTransactionHashBuyer,
  purchase,
  status,
  voucherCode: {
    code,
    voucherDefinition: {
      tokenSale: { tokenSymbol },
    },
  },
}) => {
  return {
    id,
    createdAt,
    code,
    purchase,
    rewardAmount: rewardAmountBuyer,
    status,
    tokenSymbol,
    transactionHash: rewardTransactionHashBuyer,
  }
}

const mapStateToProps = ({
  balances: { items, ...balances },
  dashboard: {
    currentUserPurchases,
    bounties,
    rewards: {
      bounty,
      voucher: { asBuyer, asVoucherOwner },
    },
    voucherCodes,
    ...dashboard
  },
  ...state
}) => ({
  balances: items,
  isBalanceLoading: balances.isLoading,
  isDashboardLoading: dashboard.isLoading,
  isUserRegistered: selectIsUserRegistered(state),
  purchases: currentUserPurchases,
  bounties,
  rewards: {
    bounty,
    voucher: {
      asBuyer: asBuyer.map(mapBuyerClaimToReward),
      asVoucherOwner: asVoucherOwner.map(mapOwnerClaimToReward),
    },
  },
  voucherCodes,
})

export default connect(mapStateToProps)(Dashboard)
