import React from 'react'
// import styled from 'styled-components/macro'
import { Modal, ModalBody } from 'reactstrap'
import { NavLink } from 'redux-first-router-link'

import { toPrivacy } from '../../../store/routerActions'

export default ({ onAllowCookies, isOpen }) => (
  <Modal isOpen={isOpen} size="lg">
    <ModalBody className="text-center">
      <div className="my-3">
        <p>
          This website including its third-party tools uses cookies, which are
          necessary for its functioning and required to achieve the purposes
          illustrated in the <NavLink to={toPrivacy()}>Privacy Policy</NavLink>.
        </p>

        <hr className="mb-4 mt-4" />

        <button
          className="btn btn-primary"
          color="primary"
          onClick={onAllowCookies}
        >
          I allow the usage of cookies
        </button>
      </div>
    </ModalBody>
  </Modal>
)
