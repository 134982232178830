import {
  CLEAR_ALL_VOUCHERS_ERROR,
  FETCH_VOUCHER_CODE_FAILURE,
  FETCH_VOUCHER_CODE_SUCCESS,
  FETCH_VOUCHER_DEFINITION_FAILURE,
  FETCH_VOUCHER_DEFINITION_SUCCESS,
  POST_VOUCHER_CODE_FORM_FAILURE,
  POST_VOUCHER_CODE_FORM_SUCCESS,
  VALIDATE_VOUCHER_CODE_FORM_REQUEST,
  VALIDATE_VOUCHER_CODE_FORM_SUCCESS,
  VALIDATE_VOUCHER_CODE_FORM_FAILURE,
} from '../types'

const initialState = {
  error: null,
  isLoading: true,
  voucherDefinition: null,
  voucherCode: null,
}

export default (state = initialState, { type, payload, error }) => {
  switch (type) {
    case CLEAR_ALL_VOUCHERS_ERROR:
      return { ...state, error: null, isLoading: false }

    case VALIDATE_VOUCHER_CODE_FORM_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case VALIDATE_VOUCHER_CODE_FORM_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
      }

    case VALIDATE_VOUCHER_CODE_FORM_FAILURE:
      return {
        ...state,
        isLoading: false,
        error,
      }

    case FETCH_VOUCHER_DEFINITION_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
        voucherDefinition: payload.voucherDefinition,
      }

    case FETCH_VOUCHER_CODE_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
        voucherCode: payload.voucherCode,
      }

    case POST_VOUCHER_CODE_FORM_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
        voucherCode: payload.voucherCode,
      }

    case POST_VOUCHER_CODE_FORM_FAILURE:
    case FETCH_VOUCHER_DEFINITION_FAILURE:
    case FETCH_VOUCHER_CODE_FAILURE:
      return { ...state, error, isLoading: false }

    default:
      return state
  }
}
