import React from 'react'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'

import RejectBountyClaimForm from './RejectBountyClaimFormContainer'

export default ({ close, isOpen, bountyClaimId }) => (
  <Modal isOpen={isOpen} size="lg">
    <ModalHeader>
      Reject the Bounty Claim:
      <small className="text-muted"> {bountyClaimId}</small>
    </ModalHeader>
    <ModalBody>
      <RejectBountyClaimForm
        bountyClaimId={bountyClaimId}
        form={'RejectBountyClaimForm-' + bountyClaimId}
      />
      <hr />
      <Button color="secondary" onClick={close}>
        Close
      </Button>
    </ModalBody>
  </Modal>
)
