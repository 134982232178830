import React from 'react'
import bowser from 'bowser'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const MetaMaskNotInstalledModal = () => {
  const browserName = bowser
    .getParser(window.navigator.userAgent)
    .getBrowserName()

  const chromeLink =
    'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?utm_source=chrome-ntp-icon'

  const firefoxLink =
    'https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/?src=search'

  const operaLink = 'https://addons.opera.com/en/extensions/details/metamask/'

  const metamaskLink =
    browserName === 'Chrome'
      ? chromeLink
      : browserName === 'Firefox'
      ? firefoxLink
      : browserName === 'Opera'
      ? operaLink
      : null

  return (
    <Modal isOpen={true}>
      <ModalHeader>Oops, you have not installed MetaMask</ModalHeader>
      <ModalBody>
        You’ll need a safe place to store all STAR tokens. The perfect place is
        in a secure wallet like MetaMask. This will also act as your login (no
        extra password needed).
      </ModalBody>
      <ModalFooter>
        {metamaskLink && (
          <Button color="primary" href={metamaskLink} target="_blank">
            Install MetaMask
          </Button>
        )}

        {!metamaskLink && (
          <i>
            Unfortunatelly you can <b>not</b> access the platform on your
            current browser because your browser is not supported by MetaMask.
            We strongly recommend you switch to{' '}
            <a
              href="https://www.google.com/chrome/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Chrome Browser
            </a>
          </i>
        )}
      </ModalFooter>
    </Modal>
  )
}

export default MetaMaskNotInstalledModal
