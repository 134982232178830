import React from 'react'
import { FormattedNumber } from 'react-intl'
import { Field } from 'redux-form'
import styled from 'styled-components/macro'

import Button from 'app/components/button/Button'

const voucherCodeIsValid = (validate, stateConfirmed, stateDenied) =>
  validate === null ? stateConfirmed : !validate ? stateDenied : ''

const VoucherCodeButton = styled(Button).attrs(({ valid }) => ({
  className: `rounded-right
    ${voucherCodeIsValid(valid, 'btn-success', 'btn-danger')}`,
}))`
  padding: 0.5rem 1rem;
`

const VoucherCodeInput = styled.input.attrs(({ valid }) => ({
  className: `form-control form-control-lg
    ${voucherCodeIsValid(valid, 'is-valid', 'is-invalid')}`,
}))``

const VoucherCodeUpperCase = ({ change, meta, input, isValidVoucherCode }) => (
  <VoucherCodeInput
    meta={meta}
    {...input}
    component="input"
    valid={isValidVoucherCode}
    disabled={isValidVoucherCode === true}
    name="voucherCode"
    placeholder="VOUCHER-CODE"
    onChange={change ? change : input.onChange}
    type="text"
  />
)

export default ({
  hasVoucherDefinition,
  isValidVoucherCode,
  isVoucherCodeFormActive,
  removeVoucherCode,
  rewardAmount,
  tokenSymbol,
  onChangeVoucherCode,

  validateVoucherCode,
}) => {
  return (
    <>
      {hasVoucherDefinition && isVoucherCodeFormActive && (
        <>
          <h4 className="text-center mt-5">Do you have a voucher code?</h4>
          <div className="row justify-content-center mt-3 mb-5">
            <div className="row justify-content-around col-12 col-sm-8 col-md-6">
              <div className="input-group mb-3">
                <Field
                  isValidVoucherCode={isValidVoucherCode}
                  component={VoucherCodeUpperCase}
                  valid={isValidVoucherCode}
                  disabled={isValidVoucherCode === true}
                  name="voucherCode"
                  placeholder="VOUCHER-CODE"
                  type="text"
                  change={onChangeVoucherCode}
                />
                <div className="input-group-append">
                  {isValidVoucherCode ? (
                    <Button color="secondary" onClick={removeVoucherCode}>
                      Remove
                    </Button>
                  ) : (
                    <VoucherCodeButton
                      onClick={validateVoucherCode}
                      valid={isValidVoucherCode}
                    >
                      Enter
                    </VoucherCodeButton>
                  )}
                </div>
              </div>
              <p className="text-muted text-center">
                Using this voucher code, you will receive
                <br />
                <b>
                  <FormattedNumber value={rewardAmount} /> extra {tokenSymbol}{' '}
                  tokens.
                </b>
              </p>
            </div>
          </div>
        </>
      )}
    </>
  )
}
