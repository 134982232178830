import * as Sentry from '@sentry/browser'
import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { IntlProvider } from 'react-intl'
import { createGlobalStyle, ThemeProvider } from 'styled-components/macro'

import * as theme from './theme.js'

import Alert from './components/alert/AlertContainer'
import AllowCookiesModal from './components/allowCookiesModal/AllowCookiesModalContainer'
import Body from './components/body/Body'
import Footer from './components/footer/Footer'
import Header from './components/header/HeaderContainer'
import Metatags from './components/Metatags'
import PurchasesToastsHandler from './components/toasts/PurchasesToastsHandler'
import SessionEndedModal from 'app/components/SessionEndedModalContainer'
import SessionEndingWarningModal from 'app/components/SessionEndingWarningModalContainer'
import Toasts from './components/toasts/Toasts'

import auth from './auth'

import './icons.css'
import './fonts.css'
import '../css/purpose.css'
import '../css/fontawesome.css'

const GlobalStyles = createGlobalStyle`
  html {    
    height: 100%;
  }
  body {
    color: #8492a6;
  }

  .form-control {
    color: black !important;
  }
`

const ModifiedContainer = styled.div.attrs({
  className: 'main-content position-relative',
})``

class App extends Component {
  ensureAuthenticated = () => {
    setTimeout(() => {
      if (this.props.isAuthenticationRequired) auth.login()
    }, 1000)
  }

  componentWillMount() {
    this.props.logoutUsersWithExpiredTokens()
    this.ensureAuthenticated()
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.REACT_APP_DISABLE_ERROR_REPORTING === 'true') return
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => scope.setExtra(key, errorInfo[key]))
      Sentry.captureException(error)
    })
  }

  componentDidUpdate() {
    this.props.logoutUsersWithExpiredTokens()
    this.ensureAuthenticated()
  }

  render() {
    const { scene } = this.props

    return (
      <IntlProvider locale="en">
        <ThemeProvider theme={theme}>
          <>
            <Metatags />
            <GlobalStyles />
            {/* <DangerBanner /> */}
            <ModifiedContainer>
              <AllowCookiesModal />
              <SessionEndedModal />
              <SessionEndingWarningModal />
              <Alert />
              <Toasts />
              <PurchasesToastsHandler />
              <Header />
              <Body scene={scene} />
              <Footer />
            </ModifiedContainer>
          </>
        </ThemeProvider>
      </IntlProvider>
    )
  }
}

export default App
