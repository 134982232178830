import { postAnalyticsEvent } from '../api'
import { get, has } from 'lodash'

import captureException from '../sentryHelpers/captureException'
import selectIsUserAccepted from '../store/selectors/selectIsUserAccepted'
import selectIsUserRegistered from '../store/selectors/selectIsUserRegistered'

const selectProject = (state, slug) =>
  has(state, 'projects.items')
    ? state.projects.items.find(p => p.slug === slug)
    : null

const selectUserType = state => {
  if (selectIsUserAccepted(state)) return 'ACCEPTED'
  else if (selectIsUserRegistered(state)) return 'REGISTERED'
  else return undefined
}

const selectTokenSale = project =>
  (project.tokenSales && project.tokenSales.find(ts => ts.isActive)) || null

const getEventProps = (state, slug) => {
  const project = selectProject(state, slug)
  const projectId = project.id
  const tokenSale = selectTokenSale(project)
  const tokenSaleId = tokenSale.id
  const userType = selectUserType(state)
  const userId = get(state, 'currentUser.user.id', undefined)

  return {
    projectId,
    tokenSaleId,
    userType,
    userId,
  }
}

export default store => next => action => {
  try {
    if (action.type === 'PROJECT') {
      const { slug, tab } = action.payload
      const state = store.getState()
      const project = selectProject(state, slug)
      const projectId = project.id
      const userType = selectUserType(state)
      const userId = get(state, 'currentUser.user.id', undefined)
      if (tab === undefined) {
        postAnalyticsEvent('project_view', { projectId, userType, userId })
      }
      if (tab === 'sales') {
        const event = getEventProps(state, slug)
        // eslint-disable-next-line prettier/prettier
        postAnalyticsEvent('sales_view', event)
      }
    }

    if (action.type === 'PAY') {
      const state = store.getState()
      const event = getEventProps(state, get(state, 'location.payload.slug'))
      // eslint-disable-next-line prettier/prettier
      postAnalyticsEvent('purchase_view', event)
    }

    if (action.type === 'PURCHASE_TOKENS_START') {
      const state = store.getState()
      const event = getEventProps(state, get(state, 'location.payload.slug'))
      // eslint-disable-next-line prettier/prettier
      postAnalyticsEvent('purchase_start', event)
    }

    if (action.type === 'PURCHASE_TOKENS_SUCCESS') {
      const state = store.getState()
      const event = getEventProps(state, get(state, 'location.payload.slug'))
      // eslint-disable-next-line prettier/prettier
      postAnalyticsEvent('purchased_success', event)
    }

    if (action.type === 'PURCHASE_TOKENS_FAILURE') {
      if (
        action.error.message ===
        'MetaMask Tx Signature: User denied transaction signature.'
      ) {
        const state = store.getState()
        const event = getEventProps(state, get(state, 'location.payload.slug'))
        // eslint-disable-next-line prettier/prettier
        postAnalyticsEvent('purchased_canceled', event)
      }
    }
  } catch (error) {
    console.error(error)
    captureException(error)
  }

  return next(action)
}
