// IMPORTANT NOTE:
// Both fundsOriginOptions.js in frontend and backend need to be kept identical.
// If you modify this file, be sure to apply the same changes to the other one.

export default [
  { label: 'Business activities / Savings / Earnings', value: 1 },
  { label: 'Stock sale', value: 2 },
  { label: 'Sale of real estate', value: 3 },
  { label: 'Donation', value: 4 },
  { label: 'Inherited', value: 5 },
  { label: 'ICO / Contribution', value: 6 },
  { label: 'Other origin', value: 7 },
]
