import isEmpty from 'lodash/isEmpty'
import pickBy from 'lodash/pickBy'
import { push } from 'redux-first-router'
import redirect from 'redux-first-router/dist/action-creators/redirect'

import * as api from './api'
import auth from './app/auth'
import captureException from './sentryHelpers/captureException'

import { fetchAllowedCountries } from './store/profile/profileActions'
import {
  fetchProjectBounties,
  fetchAllBounties,
} from './store/bounties/bountiesActions'
import { fetchCommentableComments } from './store/comments/commentsActions'
import { fetchCurrentUser } from './store/currentUser/currentUserActions'
import { fetchDashboardData } from './store/dashboard/dashboardActions'
import { fetchFiatRates } from './store/values/valuesActions'
import {
  fetchProject,
  fetchProjects,
  fetchProjectWithTokenSale,
} from './store/projects/projectsActions'

import { fetchProjectDashboard } from './store/project/projectActions'

import {
  fetchVoucherCode,
  fetchVoucherDefinition,
} from './store/voucher/voucherActions'

import { fetchStakeDefinition } from './store/ranking/rankingActions'

import {
  toHome,
  toProfile,
  toProjects,
  toUserProfile,
} from './store/routerActions'

import detectEnvironment from './detectEnvironment'

const routesMap = {
  BOUNTIES: {
    isPublic: true,
    path: '/bounties',
    thunk: async dispatch => {
      if (!window.localStorage) return // prevents failing tests
      await dispatch(fetchCurrentUser())
      dispatch(fetchAllBounties())
    },
  },

  DASHBOARD: {
    path: '/dashboard',
    thunk: async dispatch => {
      if (!window.localStorage) return
      await dispatch(fetchCurrentUser())
      dispatch(fetchDashboardData())
    },
  },

  DEV: {
    path: '/dev',
    thunk: dispatch => {
      if (detectEnvironment() === 'production') dispatch(redirect(toProjects()))
    },
  },

  HOME: {
    path: '/',
    thunk: dispatch => dispatch(redirect(toProjects())),
  },

  REGISTRATIONS_START: {
    path: '/registrations',
    isPublic: true,
    thunk: async dispatch => {
      await dispatch(fetchCurrentUser())
    },
  },

  PROFILE: {
    path: '/profile/:step',
    thunk: (dispatch, getState) => {
      if (!window.localStorage) return // prevents failing tests

      const isCurrentStepForbidden = () => {
        const currentStep = getState().location.payload.step
        const prevStep = getState().location.prev.payload.step
        return (
          currentStep > 1 &&
          !(prevStep && [currentStep - 1, currentStep + 1].includes(prevStep))
        )
      }

      // Prevents skipping steps:
      if (isCurrentStepForbidden()) dispatch(redirect(toProfile({ step: 1 })))

      dispatch(fetchAllowedCountries())

      api
        .fetchCurrentUser()
        .then(({ user }) => {
          if (!isEmpty(user) && user.status === 'ACCEPTED')
            dispatch(redirect(toUserProfile()))
        })
        .catch(captureException)

      // prevents warning in tests because jsdom doesn't support window.scrollTo()
      if (detectEnvironment() !== 'test') window.scrollTo(0, 0)

      // call metamask pop to unlock - it wont appear if the wallet is already unlocked
      if (detectEnvironment() !== 'test' && window.ethereum)
        window.ethereum.enable().catch(() => {})
    },
  },

  PAY: {
    path: '/projects/:slug/contribute',
    thunk: async (dispatch, getState) => {
      if (!window.localStorage) return // prevents failing tests
      await dispatch(fetchCurrentUser())

      const { project } = await dispatch(
        fetchProject({
          slug: getState().location.payload.slug,
          fields: ['tokenSales'],
        })
      )

      await dispatch(fetchFiatRates())

      if (!project) dispatch(redirect(toProjects()))

      const tokenSale = project.tokenSales.find(ts => ts.isActive)

      await dispatch(
        fetchVoucherDefinition({
          tokenSaleId: tokenSale ? tokenSale.id : null,
        })
      )
    },
  },

  PROJECT: {
    isPublic: true,
    path: '/projects/:slug/:tab?',
    thunk: async (dispatch, getState) => {
      await dispatch(fetchCurrentUser())
      const response = await dispatch(
        fetchProject({
          slug: getState().location.payload.slug,
          fields: ['tokenSales'],
        })
      )

      if (!response) return dispatch(redirect(toProjects()))

      const { project } = response
      const activeTokenSale = project.tokenSales.find(ts => ts.isActive)

      await dispatch(
        fetchCommentableComments({
          commentableId: project.id,
          commentableType: 'Project',
        })
      )

      await dispatch(
        fetchProjectBounties({
          projectSlug: project.slug,
        })
      )

      await dispatch(
        fetchVoucherDefinition({
          tokenSaleId: activeTokenSale ? activeTokenSale.id : null,
        })
      )

      await dispatch(
        fetchVoucherCode({
          tokenSaleId: activeTokenSale ? activeTokenSale.id : null,
        })
      )

      await dispatch(
        fetchStakeDefinition({
          projectSlug: project.slug,
        })
      )
    },
  },

  PROJECT_TOKEN_SALE: {
    isPublic: true,
    path: '/projects/:slug/sale/:tokenSaleSlug/:tab?',
    thunk: async (dispatch, getState) => {
      await dispatch(fetchCurrentUser())

      const response = await dispatch(
        fetchProjectWithTokenSale({
          projectSlug: getState().location.payload.slug,
          tokenSaleSlug: getState().location.payload.tokenSaleSlug,
        })
      )

      if (!response) return dispatch(redirect(toProjects()))
      const { project } = response

      await dispatch(
        fetchCommentableComments({
          commentableId: project.id,
          commentableType: 'Project',
        })
      )

      await dispatch(
        fetchProjectBounties({
          projectSlug: project.slug,
        })
      )
    },
  },

  PROJECT_DASHBOARD: {
    path: '/project/:slug/dashboard/',
    thunk: async (dispatch, getState) => {
      await dispatch(fetchCurrentUser())

      const slug = getState().location.payload.slug

      await dispatch(
        fetchProjectDashboard({
          slug,
        })
      )

      await dispatch(
        fetchProjectBounties({
          projectSlug: slug,
        })
      )
    },
  },

  PROJECT_EDIT: {
    path: '/project/:slug/edit/',
    thunk: async (dispatch, getState) => {
      await dispatch(fetchCurrentUser())

      await dispatch(
        fetchProject({
          slug: getState().location.payload.slug,
          fields: ['tokenSales'],
        })
      )
    },
  },

  PROJECT_CREATE_BOUNTY_DEFINITION: {
    path: '/project/:slug/new/campaign/',
    thunk: async (dispatch, getState) => {
      await dispatch(fetchCurrentUser())

      await dispatch(
        fetchProject({
          slug: getState().location.payload.slug,
          fields: ['tokenSales'],
        })
      )
    },
  },

  PROJECT_CREATE_BOUNTY_TASK: {
    path: '/project/:slug/:bountyDefinitionId/new/bounty/',
    thunk: async (dispatch, getState) => {
      await dispatch(fetchCurrentUser())

      await dispatch(
        fetchProject({
          slug: getState().location.payload.slug,
          fields: ['tokenSales'],
        })
      )
    },
  },

  PROJECTS: {
    isPublic: true,
    path: '/projects',
    thunk: async dispatch => {
      if (!window.localStorage) return // prevents failing tests
      await dispatch(fetchCurrentUser())
      dispatch(fetchProjects({ fields: ['tokenSales'] }))
    },
  },

  USER_PROFILE: {
    path: '/profile',
    thunk: dispatch => {
      if (!window.localStorage) return // prevents failing tests
      dispatch(fetchAllowedCountries())
      dispatch(fetchCurrentUser())
    },
  },

  USER_PROFILE_DELETION: {
    path: '/profile/delete/:jwtToken',
    thunk: dispatch => {
      if (!window.localStorage) return // prevents failing tests
      dispatch(fetchAllowedCountries())
      dispatch(fetchCurrentUser())
    },
  },

  AUTH_CALLBACK: {
    path: '/auth/callback',
    thunk: async dispatch => {
      if (/access_token|id_token|error/.test(window.location.hash)) {
        await dispatch(auth.handleAuthentication())
        await dispatch(fetchCurrentUser())
      }
      const return_path = localStorage.getItem('return_path')
      return_path ? push(return_path) : dispatch(toHome())
    },
  },

  //Footer Links
  PRIVACY: { isPublic: true, path: '/privacy' },
  TERMS_OF_USE: { isPublic: true, path: '/terms-of-use' },

  CATCH_ALL_REDIRECT: {
    path: '*',
    thunk: dispatch => dispatch(redirect(toHome())),
  },
}

export const publicRoutes = Object.keys(
  pickBy(routesMap, route => route.isPublic)
)

export default routesMap
