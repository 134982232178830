import Modal from 'reactstrap/lib/Modal'
import ModalBody from 'reactstrap/lib/ModalBody'
import ModalFooter from 'reactstrap/lib/ModalFooter'
import React from 'react'
import styled from 'styled-components/macro'

import Loading from 'app/components/loading/Loading'
import WarningIcon from 'app/components/WarningIcon'

const List = styled.ul`
  li {
    padding-bottom: 0.5em;
  }
`

class SubmitButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isSubmitting: false }
  }

  onClick = () => {
    this.setState({ isSubmitting: true })
    this.props.onClick()
  }

  render() {
    return (
      <button
        className="btn btn-danger btn-md"
        disabled={this.state.isSubmitting}
        onClick={this.onClick}
      >
        I want to remove my account
        <br />
        and <b>erase</b> all my personal data.
        {this.state.isSubmitting && <Loading />}
      </button>
    )
  }
}

const DeleteUserProfileModal = ({
  isOpen,
  onCancel,
  onConfirmDeletion,
  user,
}) => (
  <Modal isOpen={isOpen} size="lg">
    <div className="alert alert-danger mb-4 pt-4 text-center">
      <WarningIcon />
      <h4>Warning</h4>
      <p>
        Proceeding will disable your current account and erase your personal
        data
      </p>
    </div>

    <ModalBody>
      <List>
        <li>Your profile data will be anonymized</li>
        <li>Your documents will be deleted from our storage</li>
        <li>Your Auth0 user account will be deleted</li>
        <li>Your activity logs on our platform will be anonymized</li>
        <li>
          Your ethereum address{' '}
          <small>
            <b>{user.ethereumAddress}</b>
          </small>{' '}
          will be removed from the Starbase Whitelist Smart Contract
        </li>
      </List>

      <p className="alert pt-0 text-center">
        Only the data which is stricly necessary for the consistency of the
        Starbase platform will be kept, this comprises your ethereum address and
        purchases if any, without any identifiable information.
      </p>

      <p className="alert alert-warning mb-2 text-center">
        In order to use the Starbase Platform, you will have to register again{' '}
        <b className="text-nowrap">with a different ethereum address</b>.
      </p>
    </ModalBody>

    <ModalFooter className="justify-content-center text-center">
      <div>
        <div className="mb-2 mt-2">
          <SubmitButton onClick={onConfirmDeletion} />
        </div>
        <div>
          <button onClick={onCancel} className="btn btn-link text-muted">
            Cancel
          </button>
        </div>
      </div>
    </ModalFooter>
  </Modal>
)

export default DeleteUserProfileModal
