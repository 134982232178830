import {
  CLEAR_ALL_PROJECT_ERROR,
  FETCH_PROJECT_DASHBOARD_REQUEST,
  FETCH_PROJECT_DASHBOARD_SUCCESS,
  FETCH_PROJECT_DASHBOARD_FAILURE,
  PUT_ACCEPT_BOUNTY_CLAIM_REQUEST,
  PUT_ACCEPT_BOUNTY_CLAIM_SUCCESS,
  PUT_ACCEPT_BOUNTY_CLAIM_FAILURE,
  PUT_REJECT_BOUNTY_CLAIM_REQUEST,
  PUT_REJECT_BOUNTY_CLAIM_SUCCESS,
  PUT_REJECT_BOUNTY_CLAIM_FAILURE,
} from '../types'

export const initialState = {
  error: null,
  isLoading: true,
  item: {
    name: null,
    id: null,
    slug: null,
  },
  dashboard: {
    bountyClaims: [],
  },
}

export default (state = initialState, { type, payload, error }) => {
  switch (type) {
    case PUT_ACCEPT_BOUNTY_CLAIM_REQUEST:
    case PUT_REJECT_BOUNTY_CLAIM_REQUEST:
    case FETCH_PROJECT_DASHBOARD_REQUEST:
      return { ...state, isLoading: true }

    case FETCH_PROJECT_DASHBOARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        item: payload.project,
        dashboard: payload.dashboard,
      }

    case PUT_ACCEPT_BOUNTY_CLAIM_SUCCESS:
    case PUT_REJECT_BOUNTY_CLAIM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dashboard: {
          bountyClaims: [
            ...new Set([
              ...state.dashboard.bountyClaims.map(bountyClaim =>
                bountyClaim.id === payload.bountyClaim.id
                  ? {
                      ...payload.bountyClaim,
                    }
                  : bountyClaim
              ),
            ]),
          ],
        },
      }

    case PUT_ACCEPT_BOUNTY_CLAIM_FAILURE:
    case PUT_REJECT_BOUNTY_CLAIM_FAILURE:
    case FETCH_PROJECT_DASHBOARD_FAILURE:
      return { ...state, isLoading: false, error }

    case CLEAR_ALL_PROJECT_ERROR:
      return { ...state, error: null }

    default:
      return state
  }
}
