import React from 'react'
import styled from 'styled-components/macro'

import FormTitle from './FormTitle'

const SubmitResultInner = styled.div`
  padding: 100px 10%;
  text-align: center;
  min-height: 350px;
`

const SubmitResult = () => (
  <div>
    <SubmitResultInner>
      <FormTitle title=" Verification is pending" />
      <p>
        We are analyzing your information before allowing you to support great
        projects.
      </p>

      <p>
        We will come back to you with our verification results. This process can
        take up to 24 hours.
      </p>
      <p>
        When validated, your ethereum address will be approved and whitelisted.
      </p>
    </SubmitResultInner>
  </div>
)

export default SubmitResult
