import React from 'react'
import detectEnvironment from '../../../detectEnvironment'
import MetaMaskAccountDiffersModal from './MetaMaskAccountDiffersModal'
import MetaMaskAccountNotFoundModal from './MetaMaskAccountNotFoundModal'
import MetaMaskNetworkNotFoundModal from './MetaMaskNetworkNotFoundModal'
import MetaMaskNetworkNotMainnetModal from './MetaMaskNetworkNotMainnetModal'
import MetaMaskEthereumAddressAlreadyUsedModal from './MetaMaskEthereumAddressAlreadyUsedModal'
import MetaMaskNotInstalledModal from './MetaMaskNotInstalledModal'

export default ({
  currentUser: {
    user: { ethereumAddress },
  },
  profile: { isEthereumAddressUsed },
  metaMask: { account, isLoading, network },
  location: {
    type,
    payload: { step },
  },
}) => {
  if (!window.ethereum) return <MetaMaskNotInstalledModal />

  if (isLoading) return null

  if (!account) return <MetaMaskAccountNotFoundModal />

  if (!network) return <MetaMaskNetworkNotFoundModal />

  if (detectEnvironment() === 'production' && network !== 'main')
    return <MetaMaskNetworkNotMainnetModal />

  if (
    ethereumAddress &&
    account.toLowerCase() !== ethereumAddress.toLowerCase()
  )
    return <MetaMaskAccountDiffersModal ethereumAddress={ethereumAddress} />

  if (isEthereumAddressUsed && type === 'PROFILE' && step === 1)
    return <MetaMaskEthereumAddressAlreadyUsedModal />

  return null
}
