import { connect } from 'react-redux'

import selectProjectFromLocation from 'store/selectors/selectProjectFromLocation'

import TokenSale from './TokenSale'

const emptyProject = { tokenSales: [] }

const mapStateToProps = state => {
  const project = selectProjectFromLocation(state) || emptyProject

  const { tokenSaleSlug } = state.location.payload

  return {
    tokenSale: tokenSaleSlug
      ? project.tokenSales.find(ts => ts.name === tokenSaleSlug)
      : project.tokenSales[0],
    project,
  }
}

export default connect(mapStateToProps)(TokenSale)
