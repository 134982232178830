import * as api from 'api'
import * as web3Helpers from 'web3Helpers'

import {
  APPROVAL_TRANSACTION_CONFIRMED,
  APPROVAL_TRANSACTION_PENDING,
  METAMASK_APPROVE_TRANSACTION_START,
  METAMASK_APPROVE_TRANSACTION_SUCCESS,
  METAMASK_STAKE_TOKENS_TRANSACTION_START,
  METAMASK_STAKE_TOKENS_TRANSACTION_SUCCESS,
  STAKE_TOKENS_FAILURE,
  STAKE_TOKENS_START,
  STAKE_TOKENS_SUCCESS,
  STAKE_TOKENS_TRANSACTION_PENDING,
  STAKE_TOKENS_TRANSACTION_CONFIRMED,
} from 'store/types'

import { TRANSACTION_REJECTED_BY_USER_ERROR } from 'errors'

export default stakeParams => async dispatch => {
  try {
    const {
      amountToStake,
      ethereumAddressFrom,
      stakeContractAbi,
      stakeContractAddress,
      stakeDefinitionId,
      tokenContractAbi,
      tokenContractAddress,
    } = stakeParams

    dispatch({ type: STAKE_TOKENS_START })
    dispatch({ type: METAMASK_APPROVE_TRANSACTION_START })

    await web3Helpers
      .approveToken({
        spenderAddress: stakeContractAddress,
        tokenAmount: amountToStake,
        ethereumAddressFrom,
        tokenContractAbi,
        tokenContractAddress,
      })
      .on('transactionHash', () => {
        dispatch({ type: METAMASK_APPROVE_TRANSACTION_SUCCESS })
        dispatch({ type: APPROVAL_TRANSACTION_PENDING })
      })
      .on('error', error => dispatch(handleError(error)))

    dispatch({ type: APPROVAL_TRANSACTION_CONFIRMED })
    dispatch({ type: METAMASK_STAKE_TOKENS_TRANSACTION_START })

    const referenceAddress = await web3Helpers.getOneRankAboveReferenceAddress({
      starAmount: amountToStake,
      stakeContractAbi,
      stakeContractAddress,
      ethereumAddress: ethereumAddressFrom,
    })

    await web3Helpers
      .stake({
        starAmount: amountToStake,
        ethereumAddressFrom,
        referenceAddress,
        stakeContractAbi,
        stakeContractAddress,
      })
      .on('transactionHash', () => {
        dispatch({ type: METAMASK_STAKE_TOKENS_TRANSACTION_SUCCESS })
        dispatch({ type: STAKE_TOKENS_TRANSACTION_PENDING })
      })
      .on('receipt', receipt => {
        if (receipt.status === '0x0') dispatch({ type: STAKE_TOKENS_FAILURE })
        else {
          dispatch({ type: STAKE_TOKENS_TRANSACTION_CONFIRMED })
          // do not hold the thread
          api.postAcceptedStake({
            amountStaked: amountToStake,
            stakeDefinitionId,
          })
        }
      })
      .on('error', error => dispatch(handleError(error)))

    dispatch({ type: STAKE_TOKENS_SUCCESS })
  } catch (e) {
    dispatch(handleError(e))
  }
}

const handleError = e => dispatch => {
  console.error({ e })
  const error = e.message.includes(
    'Error: MetaMask Tx Signature: User denied transaction signature'
  )
    ? TRANSACTION_REJECTED_BY_USER_ERROR
    : e

  dispatch({
    type: STAKE_TOKENS_FAILURE,
    error: error instanceof Error ? error.message : error,
  })
}
