import React from 'react'
import styled from 'styled-components/macro'

import ArrowIcon from '../../ArrowIcon'

const Box = styled.div`
  ${({ theme }) => theme.boxShadow.container};
  background: ${({ theme }) => theme.color.lighter};
  border-radius: ${({ theme }) => theme.borderRadius.tab};
  height: 100px;
`

const PaymentWrapperBox = styled(Box)`
  height: 100px;
`

const TitleBox = styled(Box)`
  height: 24px;
  margin: 0 auto 10px;
  width: 50%;
`

const Skeleton = () => (
  <div className="row align-items-center">
    <div className="col-5">
      <TitleBox />
      <PaymentWrapperBox />
    </div>

    <div className="col-2 mt-4 text-center">
      <ArrowIcon skeleton />
    </div>

    <div className="col-5">
      <TitleBox />
      <PaymentWrapperBox />
    </div>
  </div>
)

export default Skeleton
