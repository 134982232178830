import React from 'react'
import styled from 'styled-components/macro'

const VoucherStatus = styled.div.attrs({
  className: 'badge',
})`
  && {
    padding: 1em 2.5em 0.75em 2.5em;
    letter-spacing: 0.05em;
    color: white;
    font-size: ${props => props.theme.font.xs};
    vertical-align: middle;
    background-color: ${props =>
      props.status === 'PENDING'
        ? props.theme.color.yellow
        : props.status === 'CONFIRMED' || props.status === 'FULFILLED'
        ? props.theme.color.greenSuccess
        : props.theme.color.red};
  }
`

const VoucherStatusBadge = ({ status }) => (
  <VoucherStatus status={status} target="_blank">
    {status}
  </VoucherStatus>
)

export default VoucherStatusBadge
