import React, { useState } from 'react'
import Link from 'redux-first-router-link'
import { get, pick } from 'lodash'
import classnames from 'classnames'
import { toProject } from 'store/routerActions'
import moment from 'moment'

const BountyTask = ({ campaign }) => {
  const getDaysBetween = (startDate, endDate) =>
    moment(endDate).diff(startDate, 'days')

  const project = get(campaign, '[0].bountyDefinition.project', null)
  const definition = get(campaign, '[0].bountyDefinition', null)

  const tasks = campaign.map(c =>
    pick(c, ['description', 'id', 'maxRewardAmount', 'name', 'type'])
  )

  let progressCap = (
    (definition.currentRewardCap / definition.rewardCap) *
    100
  ).toFixed(0)

  const [isOpen, setIsOpen] = useState(false)
  return (
    <div class="card card-progress border shadow-none">
      <div class="progress">
        <div
          class="progress-bar bg-success"
          role="progressbar"
          style={{ width: progressCap + '%' }}
        />
      </div>
      <div class="card-body row align-items-center">
        <div class="media media-pill align-items-center d-inline-flex bg-secondary">
          <span class="avatar avatar-sm rounded-circle">
            <img alt="token icon url" src={project.tokenIconUrl} />
          </span>
        </div>
        <div class="col-sm-6">
          <span class="h6">
            <Link to={toProject(project)}>
              {project.name} <i class="fa fa-external-link-alt mx-2" />
            </Link>
          </span>
          <div class="actions d-inline-block float-right float-sm-none">
            <div class="action-item ml-4">
              <span class="badge badge-lg badge-pill badge-secondary text-center">
                <i class="fa fa-tasks mx-2" />
                {campaign.length} tasks
              </span>
            </div>
          </div>
        </div>
        <div class="col-auto card-meta d-inline-flex align-items-center ml-sm-auto">
          <div>
            <span class="badge text-warning border border-warning badge-pill mr-3" />
          </div>
          <div class="actions d-inline-block">
            <div class="action-item mr-2">
              <i class="far fa-check-circle mr-2" />
              {progressCap}%
            </div>

            <div class="action-item mr-2">
              <i class="far fa-calendar-alt mr-2" />
              {getDaysBetween(
                Date.now(),
                new Date(definition.endDate).getTime()
              )}{' '}
              days left
            </div>

            <div class="action-item mr-3">
              <span class="badge badge-soft-success mr-2">
                <i class="fa fa-coins" /> {definition.rewardCap}{' '}
                {definition.tokenSymbol}
              </span>

              <i
                className={classnames(
                  'fa mr-2',
                  isOpen ? 'fa-chevron-up' : 'fa-chevron-down'
                )}
                onClick={() => setIsOpen(!isOpen)}
              />
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
        <div class="card-footer">
          {tasks.map(task => (
            <div class="row align-items-center py-4 border-top">
              <div class="col">
                <span class="h6 mb-0 avatar-content">
                  <p> {task.name}</p>
                  <span class="h6 mb-0 avatar-content text-muted">
                    {task.description}
                  </span>
                </span>
              </div>
              <div class="col text-right text-right">
                <div class="actions">
                  <span class="badge badge-soft-success mx-2">
                    <i class="fa fa-coins" />
                    {task.maxRewardAmount} reward
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default BountyTask
