import styled from 'styled-components/macro'

export default styled.button`
  & {
    background-color: #387ce8;
    color: white;
  }
  &:hover {
    background-color: #0e3b82;
    color: white;
  }
`
