import {
  FETCH_SWAP_ETH_STAR_RATE_SUCCESS,
  FETCH_SWAP_ETH_STAR_RATE_FAILURE,
  SWAP_TOKENS_START,
  METAMASK_APPROVE_TRANSACTION_START,
  METAMASK_APPROVE_TRANSACTION_SUCCESS,
  SWAP_TOKENS_TRANSACTION_PENDING,
  SWAP_TOKENS_TRANSACTION_CONFIRMED,
  SWAP_TOKENS_SUCCESS,
  SWAP_TOKENS_FAILURE,
  SWAP_TOKENS_OPEN_MODAL,
  SWAP_TOKENS_CLOSE_MODAL,
} from '../types'

const initialState = {
  error: null,
  ethToStarRate: 0,
  isLoading: true,
  status: {
    isModalOpen: false,
    completedSteps: {
      SWAP_TOKENS_START: false,
      METAMASK_APPROVE_TRANSACTION_START: false,
      METAMASK_APPROVE_TRANSACTION_SUCCESS: false,
      SWAP_TOKENS_TRANSACTION_PENDING: false,
      SWAP_TOKENS_TRANSACTION_CONFIRMED: false,
      SWAP_TOKENS_SUCCESS: false,
    },
    error: null,
  },
}

export default (state = initialState, { type, payload, error }) => {
  switch (type) {
    case SWAP_TOKENS_OPEN_MODAL:
      return {
        ...state,
        status: {
          ...state.status,
          isModalOpen: true,
        },
      }

    case SWAP_TOKENS_CLOSE_MODAL:
      return {
        ...state,
        status: {
          completedSteps: {},
          error: null,
          isModalOpen: false,
        },
      }

    case FETCH_SWAP_ETH_STAR_RATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ethToStarRate: payload,
      }

    case FETCH_SWAP_ETH_STAR_RATE_FAILURE:
      return { ...state, error, isLoading: false, ethToStarRate: 0 }

    case SWAP_TOKENS_START:
    case METAMASK_APPROVE_TRANSACTION_START:
    case METAMASK_APPROVE_TRANSACTION_SUCCESS:
    case SWAP_TOKENS_TRANSACTION_PENDING:
    case SWAP_TOKENS_TRANSACTION_CONFIRMED:
    case SWAP_TOKENS_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          completedSteps: {
            ...state.status.completedSteps,
            [type]: true,
          },
        },
      }

    case SWAP_TOKENS_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          completedSteps: {
            ...state.status.completedSteps,
            [type]: true,
            error,
          },
        },
      }

    default:
      return state
  }
}
