import { connect } from 'react-redux'
import { change } from 'redux-form'
import get from 'lodash/get'

import { postValidateVoucherCode } from 'store/voucher/voucherActions'
import { CLEAR_ALL_VOUCHERS_ERROR } from 'store/types'

import VoucherCode from './VoucherCode'

const mapStateToProps = (
  {
    form: {
      payForm: {
        values: { purchaseCurrency, purchaseValue, voucherCode },
      },
    },
    voucher: { error, voucherDefinition },
  },
  { tokenSale }
) => {
  const rewardPercentPurchase = get(
    voucherDefinition,
    'rewardPercentPurchase',
    0
  )

  const rewardPercentVoucherOwner = get(
    voucherDefinition,
    'rewardPercentVoucherOwner',
    0
  )

  const percentBuyerReward =
    (rewardPercentPurchase * (100 - rewardPercentVoucherOwner)) / 100 / 100

  const starPrice = get(tokenSale, 'starPrice', 0)
  const ethPrice = get(tokenSale, 'ethPrice', 0)
  const tokenPrice = purchaseCurrency === 'STAR' ? starPrice : ethPrice

  const currentRewardCap = get(voucherDefinition, 'currentRewardCap', 0)
  const rewardCap = get(voucherDefinition, 'rewardCap', 1)
  const isVoucherCodeFormActive = currentRewardCap < rewardCap

  const checkValidVoucherCode = error => {
    if (!!error) localStorage.removeItem('voucherCodeHasChecked')
    return localStorage.voucherCodeHasChecked
      ? true
      : !!error === false
      ? null
      : false
  }

  return {
    hasVoucherDefinition: !!voucherDefinition,
    isValidVoucherCode: checkValidVoucherCode(error),
    isVoucherCodeFormActive,
    percentBuyerReward,
    purchaseValue,
    tokenPrice,
    tokenSaleId: get(tokenSale, 'id'),
    tokenSymbol: get(tokenSale, 'tokenSymbol', ''),
    voucherCode,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  onChangeVoucherCode: e => {
    dispatch(
      change(
        'payForm',
        'voucherCode',
        e.target.value.replace(/\s/gi, '').toUpperCase()
      )
    )
  },
  init: () => localStorage.removeItem('voucherCodeHasChecked'),
})

const mergeProps = (
  {
    hasVoucherDefinition,
    isValidVoucherCode,
    isVoucherCodeFormActive,
    percentBuyerReward,
    purchaseValue,
    tokenPrice,
    tokenSaleId,
    tokenSymbol,
    voucherCode,
  },
  { dispatch, onChangeVoucherCode }
) => ({
  hasVoucherDefinition,
  isValidVoucherCode,
  isVoucherCodeFormActive,
  percentBuyerReward,
  purchaseValue,
  rewardAmount: tokenPrice * percentBuyerReward * purchaseValue,
  tokenPrice,
  onChangeVoucherCode,
  voucherCode,
  tokenSymbol,
  removeVoucherCode: () => {
    localStorage.removeItem('voucherCodeHasChecked')
    dispatch(change('payForm', 'voucherCode', ''))
  },
  validateVoucherCode: () => {
    if (voucherCode === '') {
      localStorage.removeItem('voucherCodeHasChecked')
      dispatch({ type: CLEAR_ALL_VOUCHERS_ERROR })
    } else {
      localStorage.voucherCodeHasChecked = true
      dispatch(
        postValidateVoucherCode({
          code: voucherCode,
          purchaseValue,
          tokenPrice,
          tokenSaleId,
        })
      )
    }
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(VoucherCode)
