import { Field } from 'redux-form'
import React from 'react'
import styled from 'styled-components/macro'

import {
  dropZoneValidateRequired,
  validateIsAlpha,
  validateRequired,
} from 'formHelpers/formValidation'

import FieldDropZone from 'app/components/forms/fields/FieldDropZone'
import FieldLabel from 'app/components/forms/fields/FieldLabel'
import FieldSelect from 'app/components/forms/fields/FieldSelect'
import FieldText from 'app/components/forms/fields/FieldText'

import FormFooter from './FormFooterContainer'
import FormTitle from './FormTitle'

import documentTypesOptions from './data/documentTypesOptions'

const DropzoneWrapper = styled.div`
  margin-top: 30px;
`

const ProofOfResidencyForm = ({
  allowedResidencyCountries,
  isUploading,
  onSubmit,
}) => (
  <form onSubmit={onSubmit}>
    <FormTitle title="Proof of Residency" />
    <div className="row">
      <div className="col-12">
        <div className="row justify-content-between">
          <div className="col-12 col-md-6">
            <FieldLabel
              htmlFor="residencyStreet"
              children="Street and Number"
            />
            <Field
              autoFocus
              isTouched
              name="residencyStreet"
              component={FieldText}
              placeholder="326 Bellflower st."
              validate={[validateRequired]}
            />
            <FieldLabel htmlFor="residencyCity" children="City" />
            <Field
              isTouched
              name="residencyCity"
              component={FieldText}
              validate={[validateRequired, validateIsAlpha]}
            />
          </div>
          <div className="col-12 col-md-6">
            <FieldLabel htmlFor="residencyState" children="State" />
            <Field
              isTouched
              name="residencyState"
              component={FieldText}
              placeholder="New York"
              validate={[validateRequired, validateIsAlpha]}
            />
            <FieldLabel htmlFor="residencyZipcode" children="Zipcode" />
            <Field
              isTouched
              name="residencyZipcode"
              component={FieldText}
              placeholder="54321"
              validate={[validateRequired]}
            />
          </div>
          <div className="col-12">
            <FieldLabel htmlFor="residencyCountry" children="Country" />
            <Field
              isTouched
              name="residencyCountry"
              component={FieldSelect}
              placeholder={'Select your country'}
              size="md"
              options={allowedResidencyCountries}
              validate={[validateRequired]}
            />
          </div>
        </div>
      </div>
      <div className="col-12">
        <FormTitle additional title="Upload Legal Document" />

        <FieldLabel htmlFor="residencyDocument" children="Document Type" />
        <Field
          isTouched
          name="residencyDocument"
          component={FieldSelect}
          placeholder={'Select a type of document'}
          size="md"
          options={documentTypesOptions}
          validate={[validateRequired]}
        />

        <DropzoneWrapper>
          <Field
            isTouched
            isUploading={isUploading}
            component={FieldDropZone}
            name="residencyProofFile"
            validate={[dropZoneValidateRequired]}
          />
        </DropzoneWrapper>
      </div>
    </div>

    <FormTitle additional title="Newsletter" />
    <div className="row">
      <div className="col-12 pb-5">
        <div className="form-check">
          <Field
            className="form-check-input"
            component="input"
            id="isNewsletterAccepted"
            name="isNewsletterAccepted"
            type="checkbox"
          />
          <label className="form-check-label" htmlFor="isNewsletterAccepted">
            Subscribe to our <strong>Newsletter</strong>.
            <br />
            <small>
              The latest news and resources sent straight to your inbox.
            </small>
          </label>
        </div>
      </div>
    </div>
    <FormFooter leftButtonText="Back" rightButtonText="Next" />
  </form>
)

export default ProofOfResidencyForm
