import React from 'react'
import Link from 'redux-first-router-link'
import styled from 'styled-components'

import { toPay, toUserProfile } from 'store/routerActions'

const Outer = styled.div`
  padding: 2em;
  background-color: ${props => props.theme.color.primaryPurpleAlt};
  border-radius: 0px 0px ${props => props.theme.borderRadius.tab}
    ${props => props.theme.borderRadius.tab};
  color: ${props => props.theme.color.white};
  .btn-success {
    color: #fff;
    background: ${props => props.theme.color.btnSuccess};
    &:hover {
      background: ${props => props.theme.color.btnSuccessHover};
    }
  }
`
const BottomInfo = ({ isUserRegistered, isUserAccepted, project }) => (
  <Outer>
    <div className="row">
      <div className="col-12 mb-2">
        <a
          href={project.whitepaperUrl}
          className="btn btn-block btn-light text-uppercase"
          target="_blank"
          rel="noopener noreferrer"
        >
          whitepaper
        </a>
      </div>

      <div className="col-12 text-right">
        {project.status === 'ONGOING' && !isUserRegistered && (
          <Link
            className="btn btn-block btn-primary text-uppercase"
            to={toUserProfile()}
          >
            register to contribute
          </Link>
        )}

        {project.status === 'ONGOING' && isUserRegistered && !isUserAccepted && (
          <Link
            className="btn btn-block btn-warning text-white text-uppercase"
            to={toUserProfile()}
          >
            profile is pending
          </Link>
        )}

        {project.status === 'ONGOING' && isUserRegistered && isUserAccepted && (
          <Link
            className="btn btn-block btn-success text-uppercase"
            to={toPay({ slug: project.slug })}
          >
            contribute to project
          </Link>
        )}
      </div>
    </div>
  </Outer>
)

export default BottomInfo
