import React, { useState } from 'react'
import styled from 'styled-components/macro'
import Rater from 'react-rater'
import 'react-rater/lib/react-rater.css'
import { Progress } from 'reactstrap'

const RatingItem = ({ rate, label, color }) => (
  <div className="row align-items-center justify-content-between">
    <div className="col-lg-3 text-right">
      <h5>
        <small className="text-muted">{label}</small>
      </h5>
    </div>
    <div className="col-12 col-lg-8">
      <Progress value={(rate / 5) * 100} color={color} />
    </div>
    <div className="col-lg-1">
      <h3>{rate.toFixed(1)}</h3>
    </div>
  </div>
)

const Rating = styled.div.attrs({
  className: 'container-fluid bg-white mt-3 mb-3 p-5',
})`
  border-radius: 4px;
`

export const Rate = styled(Rater)`
  font-size: ${props => (props.size === 'small' ? '2vw' : '4vw')};
  display: inline-flex;

  &&&& {
    .is-active {
      color: ${props => props.theme.color.yellow};
    }
    .is-active-half:before {
      color: ${props => props.theme.color.yellow};
    }
  }
`

const RatingForm = ({ onSubmit, setIsFormOpen, onRate }) => (
  <div className="row">
    <form onSubmit={onSubmit} className="w-100">
      <div className="col-12  text-center">
        Idea:{' '}
        <Rate
          total={5}
          rating={0}
          onRate={field => onRate('ratingIdea', field.rating)}
        />
      </div>

      <div className="col-12  text-center">
        Team:{' '}
        <Rate
          total={5}
          rating={0}
          onRate={field => onRate('ratingTeam', field.rating)}
        />
      </div>
      <div className="col-12  text-center">
        Website:{' '}
        <Rate
          total={5}
          rating={0}
          onRate={field => onRate('ratingWebsite', field.rating)}
        />
      </div>
      <div className="col-12  text-center">
        Whitepaper:{' '}
        <Rate
          total={5}
          rating={0}
          onRate={field => onRate('ratingWhitepaper', field.rating)}
        />
      </div>

      <div className="col-8 mx-auto text-center">
        <button type="submit" className="btn  btn-success btn-block">
          Submit
        </button>

        <button
          type="button"
          className="btn  btn-outline-danger btn-block"
          onClick={() => setIsFormOpen(false)}
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
)

export default ({
  isUserAccepted,
  onSubmit,
  onRate,
  rating: { final, idea, team, website, whitepaper },
}) => {
  const [isFormOpen, setIsFormOpen] = useState(false)

  return (
    <Rating>
      {isFormOpen && (
        <RatingForm
          onSubmit={onSubmit}
          onRate={onRate}
          setIsFormOpen={setIsFormOpen}
        />
      )}

      {!isFormOpen && (
        <div className="row">
          <div className="col-12 text-center">
            <h5 className="text-muted">Average user rating</h5>
            <h1 className="bold padding-bottom-7">
              {final.toFixed(1)}
              <small className="text-muted">/5</small>
            </h1>
            <Rate total={5} rating={final} interactive={false} />
          </div>
          <div className="col-12 align-self-center">
            <RatingItem label="Idea" rate={idea} color="success" />
            <RatingItem label="Team" rate={team} color="info" />
            <RatingItem label="Website" rate={website} />
            <RatingItem label="Whitepaper" rate={whitepaper} color="warning" />
            {isUserAccepted && (
              <button
                type="button"
                className="btn  btn-outline-primary btn-block"
                onClick={() => setIsFormOpen(true)}
              >
                Review this project
              </button>
            )}
          </div>
        </div>
      )}
    </Rating>
  )
}
