import get from 'lodash/get'

const selectIsUserProjectOwnerFromLocation = ({ currentUser, location }) => {
  const projectOwnedSlug = get(currentUser, 'user.projectOwned.slug', null)
  const requestedProjectSlug = get(location, 'payload.slug', null)

  if (!projectOwnedSlug) return false

  if (projectOwnedSlug !== requestedProjectSlug) return false

  return true
}

export default selectIsUserProjectOwnerFromLocation
