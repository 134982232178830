export const color = {
  boxShadow: 'rgba(113, 106, 202, 0.08)',
  dark: '#575962',
  darker: '#3f4047',
  ethereumPurpleOnWhite: '#6972aa',
  fieldBoxShadow: 'rgba(113, 106, 202, 0.11)',
  fieldBoxShadowFocus: 'rgba(113, 106, 202, 0.17)',
  greenSuccess: '#73d05e',
  greenSuccessBright: '#a0ea91',
  greenSuccessDark: '#34af37',
  btnSuccess: '#00c8a0',
  btnSuccessHover: '#00b793',
  iconGrey: '#7b7e8a',
  infoBlue: '#6fe6f9',
  infoBlueDark: '#17a2b8',
  inputBorder: '#ced4da',
  light: '#d9d9d9',
  iconLink: '#cad7e5',
  iconLinkHover: '#b3beca',
  iconLinkShadow: '#dbe2e9',
  lightBlue: '#ebedf2',
  lighter: '#f3f4f9',
  transluentWhite: `rgba(255,255,255, 0.75)`,
  lightPurple: '#c296e2',
  primaryPurple: '#6A7DB1',
  primaryPurpleAlt: '#7788B8',
  primaryPurpleHover: '#384ad7',
  progressGreen: 'rgba(23, 184, 74, 0.54)',
  progressPurple: `rgba(100, 148, 237, 0.71)`,
  red: '#ff0021',
  warning: '#fe5a5f',
  secondaryPurple: '#7b3dbd',
  steel: '#9BA6B2',
  white: '#ffffff',
  yellow: '#ffc107',
  yellowDark: '#dea703',
  lightPink: '#8050ce',
}

export const borderRadius = {
  button: '.3rem',
  container: '6px',
  tab: '4px',
}

export const boxShadow = {
  container: `box-shadow: 0 1px 15px 1px ${color.boxShadow};`,
  balanceCard: `box-shadow: 2px 2px 4px 0px #b0b0b0;`,
  balanceCardHighlight: `box-shadow: 0 0 5px 1px rgba(81, 203, 238, 1);`,
  payCurrency: `box-shadow: 2px 2px 3px 1px rgb(227, 227, 227);`,
  tableRow: `box-shadow: 0px 1px 1px ${color.light};`,
  listedProject: `box-shadow: 0 1px 11px  #ccc;`,
  tooltip: `box-shadow: 0 1px 15px rgba(0, 0, 0, 0.1);`,
}

export const spacing = {
  fieldMarginBottom: '2px',
}

export const font = {
  tiny: '.6rem',
  xxs: '.675rem',
  xs: '.775rem',
  sm: '.875rem',
  md: '.975rem',
  lg: '1rem',
  xl: '1.07rem',
  xxl: '1.30rem',
  xxxl: '1.50rem',
  mainFontFamily: `'Avenir Next', 'Rubik', 'Helvetica', sans-serif !important`,
  w300: 300,
  w700: 700,
}

export const mediaQueries = {
  xs: 'none',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
}

export const toast = {
  info: `background-color: ${color.infoBlueDark};`,
  success: `background-color: ${color.greenSuccessDark};`,
  warning: `background-color: ${color.yellowDark};`,
}

export const tooltip = {
  bgColor: color.white,
  borderColor: color.steel,
  color: color.dark,
}

export const grid = {
  ranking: '10% 40% 20% 30%',
}
