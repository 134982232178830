import React from 'react'
import styled from 'styled-components/macro'

const Message = styled.p`
  margin-top: 10px;
  min-height: 18px;
  color: ${props => props.theme.color.red};
  font-size: ${props => props.theme.font.xs};
`

const FieldErrorMessage = ({ isTouched, meta: { error, dirty } }) =>
  isTouched || dirty ? <Message>{error}</Message> : <Message />

export default FieldErrorMessage
