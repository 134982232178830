import {
  FETCH_PROJECT_BOUNTIES_REQUEST,
  FETCH_PROJECT_BOUNTIES_SUCCESS,
  FETCH_PROJECT_BOUNTIES_FAILURE,
  FETCH_ALL_BOUNTIES_REQUEST,
  FETCH_ALL_BOUNTIES_SUCCESS,
  FETCH_ALL_BOUNTIES_FAILURE,
  POST_BOUNTY_CLAIM_REQUEST,
  POST_BOUNTY_CLAIM_REQUEST_SUCCESS,
  POST_BOUNTY_CLAIM_REQUEST_FAILURE,
  FETCH_PROJECT_BOUNTIES_CLAIMED_SUCCESS,
} from '../types'

import * as api from '../../api'
import captureException from '../../sentryHelpers/captureException'

export const fetchProjectBounties = ({ projectSlug }) => async dispatch => {
  dispatch({
    type: FETCH_PROJECT_BOUNTIES_REQUEST,
    projectSlug,
  })

  return api
    .fetchProjectBounties({ projectSlug })
    .then(({ bountyCampaigns, claimed }) => {
      dispatch({
        type: FETCH_PROJECT_BOUNTIES_SUCCESS,
        payload: { bountyCampaigns },
      })

      dispatch({
        type: FETCH_PROJECT_BOUNTIES_CLAIMED_SUCCESS,
        payload: { claimed },
      })
    })
    .catch(error => {
      captureException(error)
      dispatch({
        type: FETCH_PROJECT_BOUNTIES_FAILURE,
        error: error.message,
      })
    })
}

export const fetchAllBounties = () => async dispatch => {
  dispatch({
    type: FETCH_ALL_BOUNTIES_REQUEST,
  })

  return api
    .fetchAllBounties()
    .then(({ bountyTasks }) => {
      dispatch({
        type: FETCH_ALL_BOUNTIES_SUCCESS,
        payload: { bountyTasks },
      })
    })
    .catch(error => {
      captureException(error)
      dispatch({
        type: FETCH_ALL_BOUNTIES_FAILURE,
        error: error.message,
      })
    })
}

export const postBountyClaim = bountyClaimForm => async dispatch => {
  dispatch({
    type: POST_BOUNTY_CLAIM_REQUEST,
  })

  return api
    .postBountyClaim(bountyClaimForm)
    .then(({ bountyClaim }) => {
      dispatch({
        type: POST_BOUNTY_CLAIM_REQUEST_SUCCESS,
        payload: { bountyClaim },
      })

      setTimeout(
        () =>
          alert(
            'You claim was successfully submitted! Now you must wait for the review from the project owner.'
          ),
        100
      )
    })
    .catch(error => {
      captureException(error)
      dispatch({
        type: POST_BOUNTY_CLAIM_REQUEST_FAILURE,
        error: error.message,
      })
    })
}
