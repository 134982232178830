import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import FieldErrorMessage from './FieldErrorMessage'

const FieldDatepicker = ({ input, isTouched, meta, ...custom }) => (
  <>
    <DatePicker
      {...custom}
      name={input.name}
      dateForm="MM/DD/YYYY"
      selected={input.value ? new Date(input.value) : null}
      onChange={value => input.onChange(Date.parse(value))}
      showTimeSelect
      dateFormat="Pp"
    />
    <FieldErrorMessage isTouched={isTouched} meta={meta} />
  </>
)

export default FieldDatepicker
