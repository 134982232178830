import * as Sentry from '@sentry/browser'
import detectEnvironment from 'detectEnvironment'

export default () => {
  if (process.env.REACT_APP_DISABLE_ERROR_REPORTING === 'true') return

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_ENDPOINT,
    environment: detectEnvironment(),

    // enable all default integrations
    // FIXME: we might want to pick only the ones we need
    integrations: integrations => integrations,
  })
}
