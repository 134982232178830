import {
  CLEAR_PROFILE_ERROR,
  FETCH_ALLOWED_COUNTRIES_FAILURE,
  FETCH_ALLOWED_COUNTRIES_SUCCESS,
  POST_PROFILE_FORM_FAILURE,
  POST_PROFILE_FORM_SUCCESS,
  TERMS_OF_USE_READING_COMPLETED,
  TERMS_OF_USE_SHOW_SCROLL_INCOMPLETE_TIP,
  REQUEST_ETHEREUM_ADDRESS_IS_USED,
  REQUEST_ETHEREUM_ADDRESS_IS_USED_SUCCESS,
  REQUEST_ETHEREUM_ADDRESS_IS_USED_FAILURE,
} from '../types'

const initialState = {
  allowedNationalities: [],
  allowedResidencyCountries: [],
  error: null,
  isEthereumAddressUsed: false,
  isTermsOfUseScrollIncompleteTipVisible: false,
  isTermsOfUseReadingComplete: false,
}

export default (state = initialState, { type, payload, error }) => {
  switch (type) {
    case POST_PROFILE_FORM_FAILURE:
      return { ...state, error }

    case POST_PROFILE_FORM_SUCCESS:
      return { ...state, error: null }

    case REQUEST_ETHEREUM_ADDRESS_IS_USED:
      return { ...state, error: null }

    case REQUEST_ETHEREUM_ADDRESS_IS_USED_SUCCESS:
      return { ...state, isEthereumAddressUsed: payload.isUsed, error: null }

    case REQUEST_ETHEREUM_ADDRESS_IS_USED_FAILURE:
      return { ...state, error: payload.error }

    case FETCH_ALLOWED_COUNTRIES_SUCCESS:
      return {
        ...state,
        allowedNationalities: payload.allowedNationalities,
        allowedResidencyCountries: payload.allowedResidencyCountries,
      }

    case FETCH_ALLOWED_COUNTRIES_FAILURE:
      return { ...state, error }

    case CLEAR_PROFILE_ERROR:
      return { ...state, error: null }

    case TERMS_OF_USE_READING_COMPLETED:
      return {
        ...state,
        isTermsOfUseReadingComplete: true,
      }
    case TERMS_OF_USE_SHOW_SCROLL_INCOMPLETE_TIP:
      return {
        ...state,
        isTermsOfUseScrollIncompleteTipVisible: true,
      }

    default:
      return state
  }
}
