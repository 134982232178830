import Cookies from 'js-cookie'

import captureException from '../sentryHelpers/captureException'
import { RESTORE_AUTH_DATA } from './types'

const loadState = () => {
  try {
    const serializedState = Cookies.get('state')
    if (serializedState === null) return undefined
    return JSON.parse(serializedState)
  } catch (error) {
    return undefined
  }
}

const saveState = state => {
  try {
    const serializedState = JSON.stringify(state)
    Cookies.set('state', serializedState)
  } catch (error) {
    captureException(error)
  }
}

let currentValue

export const persistAuthData = store => {
  store.dispatch({ type: RESTORE_AUTH_DATA, ...loadState() })

  store.subscribe(() => {
    let previousValue = currentValue
    currentValue = store.getState().authData
    if (previousValue !== currentValue) saveState(currentValue)
  })
}
