import React from 'react'
import styled from 'styled-components/macro'

import spaceshipImg from './spaceship.png'

const ImageCropper = styled.div`
  background-image: ${({ src }) => `url(${src})`};
  background-position: center 30px;
  background-size: cover;
  height: 78vw;
  border-radius: 3px;

  margin-top: -2em;
  margin-bottom: -2em;
  margin-left: -15px;
  margin-right: -15px;

  @media (min-width: ${props => props.theme.mediaQueries.sm}) {
    height: 56vw;
  }

  @media (min-width: ${props => props.theme.mediaQueries.md}) {
    height: 38vw;
  }

  @media (min-width: ${props => props.theme.mediaQueries.lg}) {
    height: 32vw;
  }

  @media (min-width: ${props => props.theme.mediaQueries.xl}) {
    height: 500px;
  }

  img {
    position: absolute;
    margin-left: -99999px;
  }
`

const Card = styled.div.attrs({ className: 'pt-5' })`
  border: 2px dashed #ccc;
  color: black;
  background: transparent;
  margin-bottom: 2em;
  border-radius: 5px;
  padding-right: 0em;
  padding-left: 0em;
  overflow: hidden;
`

const CardTitle = styled.h3.attrs({
  className: 'text-muted',
})``

const ProjectListItemLarge = ({ submitProjectUrl }) => (
  <Card className="row text-center align-items-center">
    <div className="col-12 col-sm-5 mb-5 mb-sm-0">
      <ImageCropper src={spaceshipImg}>
        <img alt="Spaceship illustration" src={spaceshipImg} />
      </ImageCropper>
    </div>

    <div className="col-12 col-sm-7 pl-4 pr-4">
      <CardTitle>Your project here?</CardTitle>

      <div className="card-text text-muted mt-3">
        <p>
          <a
            className="btn btn-primary btn-md"
            href={submitProjectUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Submit your idea!
          </a>
          <br />
          <small>(external link)</small>
        </p>
        <br />
        <p>
          Your project will be listed here once reviewed by our team and
          advisors.
        </p>

        <hr />

        <p>
          Know anyone who has a great project idea? <br /> Now is a great time
          to share this with them!
        </p>
      </div>
    </div>
  </Card>
)

export default ProjectListItemLarge
