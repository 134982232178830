import { connect } from 'react-redux'

import { forgetModal } from 'store/ui/uiActions'
import selectActiveModalName from 'store/selectors/selectActiveModalName'
import SessionEndedModal from './SessionEndedModal'

const MODAL_NAME = 'SESSION_ENDED'

const mapStateToProps = state => ({
  isOpen: selectActiveModalName(state) === MODAL_NAME,
})

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(forgetModal(MODAL_NAME)),
})

const mergeProps = ({ isOpen }, { close }) => {
  return {
    close,
    isOpen,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(SessionEndedModal)
