import React from 'react'
import styled from 'styled-components/macro'
import { Field } from 'redux-form'
import { NavLink } from 'redux-first-router-link'

import { toPrivacy, toTermsOfUse } from 'store/routerActions'

const CheckLabel = styled.label`
  margin-top: 0.1em;
  margin-left: 1.5em;
`

const AgreeWithTermsCheck = ({ project, tokenSaleContractAddress }) => (
  <>
    <h6 className="text-muted mt-5">
      You are purchasing tokens from the project <b>{project.name}</b>, for your
      own security we recommend you read the smart contract and the Terms of Use
      before purchasing.
    </h6>

    <div className="form-check">
      <Field
        className="form-check-input"
        component="input"
        name="isAgreedWithTerms"
        type="checkbox"
      />
      <CheckLabel>
        I have read and agree with:
        <ul>
          <li>
            <NavLink to={toTermsOfUse()}>Starbase Terms of Use</NavLink>
          </li>
          <li>
            <NavLink to={toPrivacy()}>Starbase Privacy Policy</NavLink>
          </li>

          <li>
            <a
              href={`https://etherscan.io/address/${tokenSaleContractAddress}#readContract`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {project.name} Project Tokensale
            </a>
          </li>

          <li>
            <a
              href={project.whitepaperUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {project.name} Project Whitepaper
            </a>
          </li>
        </ul>
      </CheckLabel>
    </div>
  </>
)

export default AgreeWithTermsCheck
