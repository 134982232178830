import React from 'react'

export default class extends React.Component {
  constructor(props) {
    super(props)
    this.state = { module: null }
  }

  componentDidMount() {
    const { path } = this.props
    import(`${path}`).then(module => this.setState({ module: module.default }))
  }

  render() {
    const { props } = this
    const { module: Mounted } = this.state
    return <div>{Mounted && <Mounted {...props} />}</div>
  }
}
