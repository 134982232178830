import {
  POST_VOUCHER_CODE_FORM_REQUEST,
  POST_VOUCHER_CODE_FORM_SUCCESS,
  POST_VOUCHER_CODE_FORM_FAILURE,
  VALIDATE_VOUCHER_CODE_FORM_REQUEST,
  VALIDATE_VOUCHER_CODE_FORM_SUCCESS,
  VALIDATE_VOUCHER_CODE_FORM_FAILURE,
  FETCH_VOUCHER_DEFINITION_REQUEST,
  FETCH_VOUCHER_DEFINITION_SUCCESS,
  FETCH_VOUCHER_DEFINITION_FAILURE,
  FETCH_VOUCHER_CODE_REQUEST,
  FETCH_VOUCHER_CODE_SUCCESS,
  FETCH_VOUCHER_CODE_FAILURE,
} from '../types'

import * as api from '../../api'
import captureException from '../../sentryHelpers/captureException'
import auth from 'app/auth'

export const fetchVoucherDefinition = ({ tokenSaleId }) => async dispatch => {
  dispatch({ type: FETCH_VOUCHER_DEFINITION_REQUEST })
  if (tokenSaleId)
    return api
      .fetchVoucherDefinition({ tokenSaleId })
      .then(response => {
        dispatch({
          type: FETCH_VOUCHER_DEFINITION_SUCCESS,
          payload: response,
        })
      })
      .catch(error => {
        captureException(error)
        dispatch({
          type: FETCH_VOUCHER_DEFINITION_FAILURE,
          error: error.message,
        })
      })
  else
    dispatch({
      type: FETCH_VOUCHER_DEFINITION_SUCCESS,
      payload: { voucherDefinition: null },
    })
}

export const fetchVoucherCode = ({ tokenSaleId }) => async dispatch => {
  if (!auth.isAuthenticated()) {
    dispatch({
      type: FETCH_VOUCHER_CODE_SUCCESS,
      payload: { voucherCode: null },
    })
    return
  }

  if (tokenSaleId) {
    dispatch({ type: FETCH_VOUCHER_CODE_REQUEST })
    return api
      .fetchVoucherCode({ tokenSaleId })
      .then(response => {
        dispatch({
          type: FETCH_VOUCHER_CODE_SUCCESS,
          payload: response,
        })
      })
      .catch(error => {
        captureException(error)
        dispatch({
          type: FETCH_VOUCHER_CODE_FAILURE,
          error: error.message,
        })
      })
  } else
    dispatch({
      type: FETCH_VOUCHER_CODE_SUCCESS,
      payload: { voucherCode: null },
    })
}

export const postVoucherCodeForm = voucherForm => async dispatch => {
  dispatch({ type: POST_VOUCHER_CODE_FORM_REQUEST })

  return api
    .postVoucherCodeForm(voucherForm)
    .then(response => {
      dispatch({
        type: POST_VOUCHER_CODE_FORM_SUCCESS,
        payload: response,
      })
      setTimeout(
        () =>
          alert(
            'Your voucher code was successfully created! Now, spread the word to get extra tokens.'
          ),
        100
      )
    })
    .catch(error => {
      captureException(error)
      dispatch({ type: POST_VOUCHER_CODE_FORM_FAILURE, error: error.message })
    })
}

export const postValidateVoucherCode = ({
  tokenSaleId,
  code,
  purchaseValue,
  tokenPrice,
}) => async dispatch => {
  dispatch({ type: VALIDATE_VOUCHER_CODE_FORM_REQUEST })

  return api
    .postValidateVoucherCode({ tokenSaleId, code, purchaseValue, tokenPrice })
    .then(response => {
      dispatch({
        type: VALIDATE_VOUCHER_CODE_FORM_SUCCESS,
        payload: response,
      })
    })
    .catch(error => {
      captureException(error)
      dispatch({
        type: VALIDATE_VOUCHER_CODE_FORM_FAILURE,
        error: error.message,
      })
    })
}
