import React from 'react'
import styled from 'styled-components/macro'

const Tab = styled.div`
  margin-bottom: -1px;
  position: relative;
  &:first-child {
    margin-left: 0;
  }
  i {
    font-family: starbase;
    display: inline-block;
  }
`
const TabTitle = styled.div`
  border: 0;
  border-bottom: 1px solid transparent;

  color: #7b7e8a;
  display: block;
  font-size: 0.9rem;
  font-weight: 400;
  display: table-cell;
  height: 70px;
  vertical-align: middle;
  &:focus {
    outline: 0;
  }
`
const StyledIcon = styled.i`
  font-size: 18px;
  color: ${props =>
    props.currentStep === props.step
      ? props.theme.color.primaryPurple
      : props.theme.color.iconGrey};

  @media (min-width: ${props => props.theme.mediaQueries.sm}) {
    font-size: 12.6px;
  }
`
const OkIcon = styled(StyledIcon)`
  color: ${props => props.theme.color.greenSuccess};
`
const Text = styled.span`
  font-size: 10px;
  color: ${props =>
    props.step <= props.currentStep
      ? props.theme.color.primaryPurple
      : props.theme.color.iconGrey};

  @media (min-width: ${props => props.theme.mediaQueries.md}) {
    font-size: 13px;
  }
`

const TabItem = ({ currentStep, icon, onClick, step, text }) => (
  <Tab>
    <TabTitle
      className="row align-items-center justify-content-center"
      role="tab"
      tabIndex={step}
      onClick={onClick}
    >
      {step < currentStep ? (
        <OkIcon className="icon-okcircled" />
      ) : (
        <StyledIcon currentStep={currentStep} step={step} className={icon} />
      )}
      <Text
        currentStep={currentStep}
        step={step}
        className="d-none d-sm-block d-md-inline-block"
      >
        {text}
      </Text>
    </TabTitle>
  </Tab>
)

export default TabItem
