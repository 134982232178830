import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import MetaMaskImage from '../MetamaskImage'

const MetaMaskEthereumAddressAlreadyUsedModal = () => (
  <Modal isOpen={true}>
    <ModalHeader>Oops, this ethereum address is already used.</ModalHeader>
    <ModalBody className="text-center">
      <p>Please change your ethereum address in your MetaMask account</p>
      <p>
        <MetaMaskImage />
      </p>
    </ModalBody>
  </Modal>
)

export default MetaMaskEthereumAddressAlreadyUsedModal
