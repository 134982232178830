import { connect } from 'react-redux'

import { closeModal, openModal } from 'store/ui/uiActions'
import selectActiveModalName from 'store/selectors/selectActiveModalName'

import BountyDetailsModal from './BountyDetailsModal'

const mapStateToProps = (state, { bounty }) => {
  const MODAL_NAME = `BOUNTY_DETAILS_MODAL_${bounty.id}`

  return {
    isOpen: selectActiveModalName(state) === MODAL_NAME,
    bounty,
    modalName: MODAL_NAME,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
})

const mergeProps = ({ bounty, isOpen, modalName }, { dispatch }) => ({
  bounty,
  isOpen,
  open: e => {
    e.preventDefault()
    dispatch(openModal(modalName))
  },
  close: () => dispatch(closeModal(modalName)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(BountyDetailsModal)
