import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import MetaMaskImage from '../MetamaskImage'

const MetaMaskNetworkNotFoundModal = () => (
  <Modal isOpen={true}>
    <ModalHeader>
      Oops, there is a problem with the MetaMask network
    </ModalHeader>
    <ModalBody>
      <p>Network error, please check it.</p>
      <p className="text-center">
        <MetaMaskImage />
      </p>
    </ModalBody>
  </Modal>
)

export default MetaMaskNetworkNotFoundModal
