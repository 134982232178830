import React from 'react'
import styled from 'styled-components/macro'

export default styled(({ children, className, TooltipContent }) => (
  <div className={className}>
    <div className="top">
      <TooltipContent /> <i />
    </div>
    <span className="children">{children}</span>
  </div>
))`
  display: inline-block;
  position: relative;

  .children {
    border-bottom: 1px dotted #666;
  }

  .top {
    background-color: ${({ theme }) => theme.tooltip.bgColor};
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.tooltip.borderColor};
    ${({ theme }) => theme.boxShadow.tooltip};
    box-sizing: border-box;
    color: ${({ theme }) => theme.tooltip.color};
    font-size: 13px;
    font-weight: normal;
    left: 50%;
    min-width: 250px;
    opacity: 0;
    padding: 13px 20px 12px 20px;
    position: absolute;
    top: -0px;
    transform: translate(-25%, -100%);
    transition: opacity 0.2s;
    visibility: hidden;
    z-index: 99999999;
  }

  &:hover .top {
    opacity: 1;
    visibility: visible;
  }

  .top i {
    height: 12px;
    left: 25%;
    margin-left: -12px;
    overflow: hidden;
    position: absolute;
    top: 100%;
    width: 24px;
  }

  .top i::after {
    background-color: ${({ theme }) => theme.tooltip.bgColor};
    border: 1px solid ${({ theme }) => theme.tooltip.borderColor};
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    content: '';
    height: 12px;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 12px;
  }
`
