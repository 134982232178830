import { connect } from 'react-redux'
import get from 'lodash/get'

import selectIdTokenNamePhotoEmail from '../../../../../store/selectors/selectIdTokenNamePhotoEmail'
import selectIsUserRegistered from '../../../../../store/selectors/selectIsUserRegistered'
import {
  fetchMyData,
  requestAccountDeletion,
} from '../../../../../store/currentUser/currentUserActions'

import UserProfile from './UserProfile'

const emptyObject = {}

const mapStateToProps = ({ profile, ...state }) => ({
  profile,
  isUserRegistered: selectIsUserRegistered(state),
  user: get(state, 'currentUser.user', emptyObject),
  userPhoto: (selectIdTokenNamePhotoEmail(state) || emptyObject).photo,
  isTermsOfUseAccepted: get(
    state,
    'form.profileForm.values.isTermsOfUseAccepted',
    emptyObject
  ),
})

const mapDispatchToProps = dispatch => ({
  dispatch,
})

const mergeProps = (
  { profile, ...stateProps },
  { dispatch },
  { ...ownProps }
) => ({
  ...ownProps,
  ...stateProps,
  profile,
  allowedNationalities: get(profile, 'allowedNationalities', emptyObject),
  allowedResidencyCountries: get(
    profile,
    'allowedResidencyCountries',
    emptyObject
  ),
  onRequestAccountDeletion: () => {
    const isConfirmed = window.confirm(
      'You will receive an email to confirm this action. Once you confirm it, there is no going back. Please be certain.'
    )
    if (isConfirmed) dispatch(requestAccountDeletion())
  },
  onDownloadMyData: () => {
    dispatch(fetchMyData())
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(UserProfile)
