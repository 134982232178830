import React from 'react'
import styled from 'styled-components/macro'

import FieldErrorMessage from './FieldErrorMessage'

const Select = styled.select`
  background-color: ${props => props.theme.color.white};
  border: 1px solid ${props => props.theme.color.inputBorder};
  color: ${props => props.theme.color.dark};
  cursor: pointer;
  margin-bottom: ${props => props.theme.spacing.fieldMarginBottom};
  padding: 15px 10px;
  width: 100%;
  font-size: ${props => props.theme.font.md};
  &.form-control:not([size]):not([multiple]) {
    height: 52px;
  }

  &.form-control {
    border-color: ${props =>
      (props.isTouched || props.meta.touched) && props.meta.error
        ? props.theme.color.red
        : props.theme.color.inputBorder};
  }
`

const FieldSelect = ({
  id,
  isTouched,
  label,
  placeholder,
  size = 'md',
  options,
  ...field
}) => (
  <div>
    {label && <label className="col-form-label">{label}</label>}

    <Select
      className={`form-control form-control-${size}`}
      isTouched={isTouched}
      meta={field.meta}
      id={id}
      {...field.input}
    >
      <option value="" defaultValue>
        {placeholder}
      </option>

      {options.map(({ label, value }) => (
        <option key={value} value={value}>
          {label || value}
        </option>
      ))}
    </Select>

    <FieldErrorMessage isTouched={isTouched} meta={field.meta} />
  </div>
)

export default FieldSelect
