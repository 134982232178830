import { connect } from 'react-redux'
import find from 'lodash/find'
import get from 'lodash/get'
import { reduxForm, change } from 'redux-form'

import getMetaMaskStatus from 'web3Helpers/getMetaMaskStatus'
import selectBalanceByTokenSymbol from 'store/selectors/selectBalanceByTokenSymbol'
import selectIsUserAccepted from 'store/selectors/selectIsUserAccepted'
import selectIsUserRegistered from 'store/selectors/selectIsUserRegistered'
import selectProjectFromLocation from 'store/selectors/selectProjectFromLocation'
import { spendStarTokens, spendEthTokens } from 'store/pay/payActions'
import { UNSUPPORTED_PURCHASE_CURRENCY_ERROR } from 'errors'

import Pay from './Pay'

const emptyObject = {}

const mapStateToProps = ({
  form,
  metaMask: { account: ethereumAddress },
  pay,
  values: { fiatRates },
  ...state
}) => {
  const starBalance = selectBalanceByTokenSymbol({ state, tokenSymbol: 'STAR' })
  const ethBalance = selectBalanceByTokenSymbol({ state, tokenSymbol: 'ETH' })
  const project = selectProjectFromLocation(state) || emptyObject

  const metaMaskStatus = getMetaMaskStatus({
    ethereumAddress,
    starBalance,
    ethBalance,
  })

  return {
    ethereumAddress,
    ethBalance,
    fiatRates,
    isUserAccepted: selectIsUserAccepted(state),
    isUserRegistered: selectIsUserRegistered(state),
    metaMaskStatus,
    pay,
    payForm: get(form, 'payForm.values', emptyObject),
    project,
    sale:
      project.status === 'ONGOING'
        ? find(project.tokenSales, ts => ts.isActive)
        : null,
    starBalance,
  }
}

const mapDispatchToProps = dispatch => ({
  // this init function reset every reload page the currency option
  init: dispatch(change('payForm', 'purchaseCurrency', null)),
  init2: dispatch(change('payForm', 'purchaseValue', '1')),
  useStar: () => dispatch(change('payForm', 'purchaseCurrency', 'STAR')),
  useEth: () => dispatch(change('payForm', 'purchaseCurrency', 'ETH')),
  dispatch,
})

const mergeProps = (
  {
    ethBalance,
    ethereumAddress,
    fiatRates,
    isUserAccepted,
    isUserRegistered,
    metaMaskStatus,
    pay,
    payForm,
    project,
    sale,
    starBalance,
  },
  { dispatch, useEth, useStar },
  { handleSubmit }
) => ({
  ethBalance,
  fiatRates,
  ethereumAddress,
  isFieldPurchaseValueEnabled: metaMaskStatus.isEnabled,
  isUserAccepted,
  isUserRegistered,
  payForm,
  payStatus: pay.spendTokensStatus,
  project,
  sale,
  starBalance,
  useEth,
  useStar,

  onSubmit: handleSubmit(({ purchaseValue, purchaseCurrency, voucherCode }) => {
    const tokensLeft = sale.tokenSaleCap - sale.tokensSold
    const tokensToReceive =
      purchaseCurrency === 'STAR'
        ? purchaseValue * sale.starPrice
        : purchaseValue * sale.ethsRaised

    if (tokensToReceive > tokensLeft)
      return alert(
        `Amount exceed the tokens supply! Only ${tokensLeft} ${
          sale.tokenSymbol
        } tokens available for purchase.`
      )

    if (purchaseValue < 0 || !purchaseValue) return alert('Amount is invalid')

    if (purchaseCurrency === 'STAR') {
      if (purchaseValue > starBalance) return alert('Insufficent STAR balance')

      dispatch(
        spendStarTokens({
          ethereumAddressFrom: ethereumAddress,
          purchaseValue,
          tokenPrice: sale.starPrice,
          tokenSaleContractAbi: sale.tokenSaleContract.abi,
          tokenSaleContractAddress: sale.tokenSaleContractAddress,
          tokenSaleId: sale.id,
          voucherCode,
        })
      )
    } else if (purchaseCurrency === 'ETH') {
      if (purchaseValue > ethBalance) return alert('Insufficent ETH balance')

      dispatch(
        spendEthTokens({
          ethereumAddressFrom: ethereumAddress,
          purchaseValue,
          tokenPrice: sale.ethPrice,
          tokenSaleContractAbi: sale.tokenSaleContract.abi,
          tokenSaleContractAddress: sale.tokenSaleContractAddress,
          tokenSaleId: sale.id,
          voucherCode,
        })
      )
    } else {
      throw UNSUPPORTED_PURCHASE_CURRENCY_ERROR
    }
  }),
})

export default reduxForm({
  form: 'payForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(Pay)
)
