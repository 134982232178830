import get from 'lodash/get'
import { isAlpha, isAlphanumeric, isNumeric, isURL } from 'validator'

export const validateRequired = value => (value ? undefined : 'Required')

export const validateIsAlpha = value => {
  if (value) {
    const valid = value.split(' ').every(word => {
      return isAlpha(word)
    })
    if (!valid) {
      // some of the words are invalid
      return 'Only letters allowed'
    } else {
      return undefined
    }
  }
}

export const validateIsNumber = value =>
  value && isNumeric(`${value}`) ? undefined : 'Only numbers allowed'

export const validateIsAlphaNumeric = value =>
  value && isAlphanumeric(value)
    ? undefined
    : 'Only letters and numbers allowed'

export const dropZoneValidateRequired = value =>
  get(value, '[0].preview') || get(value, '[0].isFromBackend')
    ? undefined
    : 'Required'

const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined

export const minLength3 = minLength(3)

const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined

export const maxLength15 = maxLength(15)

export const url = value => (value && isURL(value) ? undefined : 'Invalid url')
