import React from 'react'
import styled from 'styled-components/macro'

import formatDate from 'app/scenes/helpers/formatDate'

import ReactTable from 'react-table'
import './reactTable.css'

import BountyDetailsModal from './BountyDetailsModalContainer'
import AcceptBountyClaimModal from './AcceptBountyClaimModalContainer'
import RejectBountyClaimModal from './RejectBountyClaimModalContainer'

import { openModal } from 'store/ui/uiActions'
import store from 'store/store'

const Status = styled.span.attrs({
  className: 'badge',
})`
  && {
    padding: 0.5em 1em 0.5em 1em;
    letter-spacing: 0.05em;
    color: white;
    font-size: ${props => props.theme.font.xs};
    vertical-align: middle;
    background-color: ${props =>
      props.status === 'PENDING'
        ? props.theme.color.yellow
        : props.status === 'ACCEPTED'
        ? props.theme.color.greenSuccess
        : props.theme.color.red};
  }
`
const Icon = styled.i`
  font-size: ${props => props.theme.font.xs};
  top: 3px;
  position: relative;
  color: ${props => props.color};
`

const BadgeStatus = ({ status }) => <Status status={status}>{status}</Status>

const columns = [
  {
    Header: 'Submitted At',
    accessor: 'createdAt',
    resizable: false,
    width: 170,
    Cell: props => (
      <small className="text-muted">{formatDate(props.value)}</small>
    ),
  },
  {
    Header: 'Bounty Task',
    accessor: 'bountyTask',
    width: 300,
    resizable: false,
    filterable: true,
    filterMethod: (filter, row) =>
      row[filter.id].name.toLowerCase().search(filter.value.toLowerCase()) === 0
        ? true
        : false,
    Cell: ({ value }) => (
      <>
        <BountyDetailsModal bounty={value} />
        <button
          type="button"
          className="btn btn-link"
          onClick={() => {
            store.dispatch(openModal(`BOUNTY_DETAILS_MODAL_${value.id}`))
          }}
        >
          <b>{value.name}</b>
        </button>
      </>
    ),
  },
  {
    Header: 'Proof Url',
    accessor: 'proofUrl',
    resizable: false,
    width: 500,
    Cell: props => (
      <a href={props.value} target="_blank" rel="noopener noreferrer">
        <small>
          {props.value} <Icon className="icon-link-ext" color="black" />
        </small>
      </a>
    ),
  },
  {
    Header: 'Amount',
    accessor: 'rewardAmount',
    resizable: false,
    width: 100,
  },
  {
    Header: 'Distributed',
    accessor: 'rewardTransactionHash',
    resizable: false,
    width: 100,
    Cell: ({ value }) => (
      <>
        {value ? (
          <a
            href={`https://etherscan.io/tx/${value}`}
            target="_blank"
            rel="noopener noreferrer"
            className="badge badge-pill badge-success text-uppercase"
          >
            Yes <Icon className="icon-link-ext" color="white" />
          </a>
        ) : (
          <span className="badge badge-pill badge-secondary text-uppercase">
            Pending
          </span>
        )}
      </>
    ),
  },

  {
    Header: 'Status',
    accessor: 'status',
    resizable: false,
    width: 110,
    filterable: true,
    filterMethod: (filter, row) => {
      if (filter.value === 'ALL') return true
      if (filter.value === 'ACCEPTED') return row[filter.id] === 'ACCEPTED'
      if (filter.value === 'REJECTED') return row[filter.id] === 'REJECTED'
      return row[filter.id] === 'PENDING'
    },
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'ALL'}
      >
        <option value="ALL">Show All</option>
        <option value="ACCEPTED">ACCEPTED</option>
        <option value="REJECTED">REJECTED</option>
        <option value="PENDING">PENDING</option>
      </select>
    ),
    Cell: props => (
      <BadgeStatus status={props.value}>{props.value}</BadgeStatus>
    ),
  },

  {
    Header: 'Action',
    id: 'action',
    resizable: false,
    width: 140,
    accessor: ({ id, bountyTask, rewardTransactionHash }) => ({
      id,
      bountyTask,
      rewardTransactionHash,
    }),
    Cell: ({ value: { id, bountyTask, rewardTransactionHash } }) => (
      <>
        {!rewardTransactionHash && (
          <>
            <AcceptBountyClaimModal
              bountyClaimId={id}
              bountyTask={bountyTask}
            />
            <RejectBountyClaimModal bountyClaimId={id} />
            <button
              type="button"
              className="btn btn-success ml-2"
              onClick={() => {
                store.dispatch(openModal(`ACCEPT_BOUNTY_CLAIM_MODAL_${id}`))
              }}
            >
              <Icon className="icon-okcircled" color="white" />
            </button>
            <button
              type="button"
              className="btn btn-danger ml-2"
              onClick={() => {
                store.dispatch(openModal(`REJECT_BOUNTY_CLAIM_MODAL_${id}`))
              }}
            >
              <Icon className="icon-cancel-circled" color="white" />
            </button>
          </>
        )}
      </>
    ),
  },
]

const BountyClaims = ({ bountyClaims, project }) => (
  <>
    <h4 className="mb-4">{project.name} Bounty Claims</h4>
    <div className="container-fluid">
      {bountyClaims.length === 0 ? (
        <div className="alert alert-light text-center" role="alert">
          No claims were submitted
        </div>
      ) : (
        <>
          <ReactTable
            data={bountyClaims}
            columns={columns}
            className="-striped -highlight"
            defaultSorted={[
              {
                id: 'createdAt',
                desc: true,
              },
            ]}
            SubComponent={({ original: { status, poComment } }) =>
              status === 'REJECTED' ? (
                <div style={{ padding: '20px' }}>
                  <b> Reason:</b> <em>{poComment}</em>
                </div>
              ) : null
            }
          />
        </>
      )}
    </div>
  </>
)

export default BountyClaims
