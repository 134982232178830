import React from 'react'
import styled from 'styled-components/macro'
import { Field } from 'redux-form'

import Skeleton from './Skeleton'

const CurrencyLabel = styled.span`
  color: ${({ theme }) => theme.color.darker};
  font-size: ${({ theme }) => theme.font.xxl};
  font-weight: 700;
`

const Icon = styled.i`
  position: relative;
  font-size: 28px;
`

const IconStar = styled(Icon).attrs({
  className: 'icon-star',
})`
  color: ${({ theme }) => theme.color.primaryPurple};
  margin-right: 4px;
  margin-left: 8px;
  top: 1px;
`

const IconEthereum = styled(Icon).attrs({
  className: 'icon-ethereum',
})`
  color: ${({ theme }) => theme.color.ethereumPurpleOnWhite}
  font-size: calc(100% + 13px);
  top: 2px;
`

const PaymentInputCurrency = styled.div`
  && {
    margin-bottom: 15px;
    .btn {
      ${({ theme }) => theme.boxShadow.payCurrency}
      font-size: 20px;
      height: 76px;
    }
  }
`

const PaymentInputCurrencyRadio = styled(Field).attrs({
  component: 'input',
  name: 'purchaseCurrency',
  type: 'radio',
})`
  && {
    cursor: pointer;
    height: 100%;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

const Option = ({ value }) => (
  <PaymentInputCurrency className="col">
    <button className="btn btn-block btn-light col-xs-12 d-flex align-items-center">
      {value === 'STAR' ? <IconStar /> : <IconEthereum />}
      <PaymentInputCurrencyRadio value={value} />
      <CurrencyLabel>{value}</CurrencyLabel>
    </button>
  </PaymentInputCurrency>
)

const CurrencyChooser = ({ isEthAccepted }) => (
  <>
    <h4 className="text-center mt-5">Choose which asset to contribute</h4>

    <div className="row justify-content-center mt-3 mb-5">
      <div className="row justify-content-around col-12 col-sm-8 col-md-5">
        <>
          <Option value="STAR" />
          {isEthAccepted && <Option value="ETH" />}
        </>
      </div>
    </div>

    <div className="col d-none d-lg-block">
      <Skeleton />
    </div>
  </>
)
export default CurrencyChooser
