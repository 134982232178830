import React from 'react'
import { ToastContainer } from 'react-toastify'
import styled from 'styled-components/macro'

import 'react-toastify/dist/ReactToastify.css'

const Toasts = styled.div`
  .Toastify__toast {
    border: 1px solid ${({ theme }) => theme.color.transluentWhite};
    border-radius: ${({ theme }) => theme.borderRadius.container};
  }

  .Toastify__toast--info {
    ${({ theme }) => theme.toast.info};
  }

  .Toastify__toast--success {
    ${({ theme }) => theme.toast.success};
  }

  .Toastify__toast--warning {
    ${({ theme }) => theme.toast.warning};
  }
`

export default () => (
  <Toasts>
    <ToastContainer newestOnTop={true} />
  </Toasts>
)
