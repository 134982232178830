import {
  FETCH_PROJECT_DASHBOARD_REQUEST,
  FETCH_PROJECT_DASHBOARD_SUCCESS,
  FETCH_PROJECT_DASHBOARD_FAILURE,
  PUT_ACCEPT_BOUNTY_CLAIM_REQUEST,
  PUT_ACCEPT_BOUNTY_CLAIM_SUCCESS,
  PUT_ACCEPT_BOUNTY_CLAIM_FAILURE,
  PUT_REJECT_BOUNTY_CLAIM_REQUEST,
  PUT_REJECT_BOUNTY_CLAIM_SUCCESS,
  PUT_REJECT_BOUNTY_CLAIM_FAILURE,
  PUT_SUBMIT_ERROR,
  CLOSE_MODAL,
} from '../types'

import * as api from '../../api'

import captureException from '../../sentryHelpers/captureException'
import { toProjectDashboard } from '../routerActions'

export const fetchProjectDashboard = ({ slug }) => dispatch => {
  dispatch({ type: FETCH_PROJECT_DASHBOARD_REQUEST })

  return api
    .fetchProjectDashboard({ slug })
    .then(payload =>
      dispatch({ type: FETCH_PROJECT_DASHBOARD_SUCCESS, payload })
    )
    .catch(error => {
      captureException(error)
      dispatch({
        type: FETCH_PROJECT_DASHBOARD_FAILURE,
        error: error.message,
      })
    })
}

export const putAcceptBountyClaim = data => dispatch => {
  dispatch({ type: PUT_ACCEPT_BOUNTY_CLAIM_REQUEST })

  return api
    .putAcceptBountyClaim(data)
    .then(payload => {
      dispatch({ type: PUT_ACCEPT_BOUNTY_CLAIM_SUCCESS, payload })
      dispatch({
        type: CLOSE_MODAL,
        payload: `ACCEPT_BOUNTY_CLAIM_MODAL_${data.bountyClaimId}`,
      })
    })
    .catch(error => {
      captureException(error)
      dispatch({
        type: PUT_ACCEPT_BOUNTY_CLAIM_FAILURE,
        error: error.message,
      })
    })
}

export const putRejectBountyClaim = data => dispatch => {
  dispatch({ type: PUT_REJECT_BOUNTY_CLAIM_REQUEST })

  return api
    .putRejectBountyClaim(data)
    .then(payload => {
      dispatch({ type: PUT_REJECT_BOUNTY_CLAIM_SUCCESS, payload })
      dispatch({
        type: CLOSE_MODAL,
        payload: `REJECT_BOUNTY_CLAIM_MODAL_${data.bountyClaimId}`,
      })
    })
    .catch(error => {
      captureException(error)
      dispatch({
        type: PUT_REJECT_BOUNTY_CLAIM_FAILURE,
        error: error.message,
      })
    })
}

export const putUpdateProject = data => dispatch => {
  return api
    .putUpdateProject(data)
    .then(({ project: { slug } }) =>
      setTimeout(() => {
        alert('Project Updated!')

        dispatch(
          toProjectDashboard({
            slug,
          })
        )
      }, 100)
    )
    .catch(error => {
      captureException(error)
      dispatch({
        type: PUT_SUBMIT_ERROR,
        error: error.message,
      })
    })
}

export const postCreateBountyCampaign = data => dispatch => {
  return api
    .postCreateBountyCampaign(data)
    .then(({ project: { slug } }) =>
      setTimeout(() => {
        alert('Bounty Campaign Created!')
        dispatch(
          toProjectDashboard({
            slug,
          })
        )
      }, 100)
    )
    .catch(error => {
      captureException(error)
      dispatch({
        type: PUT_SUBMIT_ERROR,
        error: error.message,
      })
    })
}

export const postCreateBountyTask = data => dispatch => {
  return api
    .postCreateBountyTask(data)
    .then(({ project: { slug } }) =>
      setTimeout(() => {
        alert('Bounty Task Created!')
        dispatch(
          toProjectDashboard({
            slug,
          })
        )
      }, 100)
    )
    .catch(error => {
      captureException(error)
      dispatch({
        type: PUT_SUBMIT_ERROR,
        error: error.message,
      })
    })
}

export const deleteBountyTask = data => dispatch => {
  return api
    .deleteBountyTask(data)
    .then(({ project: { slug } }) =>
      setTimeout(() => {
        alert(`Bounty Task #${data} Deleted!`)
        dispatch(
          toProjectDashboard({
            slug,
          })
        )
      }, 100)
    )
    .catch(error => {
      captureException(error)
      alert('You cannot delete this task!')
    })
}
