import React from 'react'
import styled from 'styled-components/macro'

const AlertWrapper = styled.div.attrs(({ type }) => ({
  className:
    type === 'warning'
      ? 'alert alert-warning alert-dismissible'
      : 'alert alert-danger alert-dismissible',
}))`
  && {
    box-shadow: 0px 2px 20px rgb(0, 0, 0, 0.2);
    left: 30%;
    margin: 0 auto;
    position: fixed;
    top: 5%;
    width: 40%;
    z-index: 9999;
  }
`

const WarningIcon = styled.i.attrs({
  className: 'icon-warning',
})`
  position: relative;

  && {
    top: 3px;
  }
`

const Alert = ({ errors, onClose, isVisible }) => (
  <div>
    {isVisible && (
      <AlertWrapper>
        {errors.map((error, key) => (
          <h5 key={key}>
            <WarningIcon />
            <small dangerouslySetInnerHTML={{ __html: error }} />
            {errors.length - 1 !== key && <hr />}
          </h5>
        ))}

        <button className="close" onClick={onClose} type="button">
          <span>&times;</span>
        </button>
      </AlertWrapper>
    )}
  </div>
)

export default Alert
