export default ({ file, signedRequest, url }) =>
  new Promise(async (resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('PUT', signedRequest)
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve({ success: url })
        } else {
          reject({ error: 'file upload failed' })
        }
      }
    }
    xhr.send(file)
  })
