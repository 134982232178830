import { getContractWrite, getContractRead, toWei } from './generalHelper'

export const approveToken = ({
  tokenContractAbi,
  tokenContractAddress,
  tokenAmount,
  ethereumAddressFrom,
  spenderAddress,
}) => {
  const contract = getContractWrite(tokenContractAbi, tokenContractAddress)
  return contract.methods
    .approve(spenderAddress, toWei(tokenAmount))
    .send({ from: ethereumAddressFrom })
}

export const stake = ({
  starAmount,
  ethereumAddressFrom,
  stakeContractAbi,
  stakeContractAddress,
  referenceAddress,
}) => {
  const contract = getContractWrite(stakeContractAbi, stakeContractAddress)
  return contract.methods
    .stake(toWei(starAmount), referenceAddress)
    .send({ from: ethereumAddressFrom })
}

export const getOneRankAboveReferenceAddress = ({
  starAmount,
  stakeContractAbi,
  stakeContractAddress,
  ethereumAddress,
}) => {
  const contract = getContractRead(stakeContractAbi, stakeContractAddress)
  return contract.methods
    .getSortedSpotForNewStakeForUser(toWei(starAmount), ethereumAddress)
    .call()
}

export const withdrawStake = ({
  ethereumAddressFrom,
  stakeContractAbi,
  stakeContractAddress,
}) => {
  const contract = getContractWrite(stakeContractAbi, stakeContractAddress)
  return contract.methods
    .withdrawAllReceivedTokens()
    .send({ from: ethereumAddressFrom })
}

export const hasWithdrawnTokens = ({
  ethereumAddressFrom,
  stakeContractAbi,
  stakeContractAddress,
}) => {
  const contract = getContractWrite(stakeContractAbi, stakeContractAddress)
  return contract.methods.hasWithdrawnTokens(ethereumAddressFrom).call()
}
