import { createSelector } from 'reselect'
import uniq from 'lodash/uniq'

import selectActiveTokenSales from './selectActiveTokenSales'

export default createSelector(
  selectActiveTokenSales,
  tokenSales =>
    // tokenContractAddress must be in ethereum capital case format
    uniq(tokenSales.map(ts => ts.tokenContractAddress))
)
