import React from 'react'
import styled from 'styled-components/macro'

const UserStatus = styled.div.attrs({
  className: 'badge',
})`
  && {
    padding: 0.5em 1em 0.5em 1em;
    letter-spacing: 0.05em;
    color: white;
    font-size: ${props => props.theme.font.xs};
    vertical-align: middle;
    background-color: ${props =>
      props.status === 'PENDING'
        ? props.theme.color.yellow
        : props.status === 'ACCEPTED'
        ? props.theme.color.greenSuccess
        : props.theme.color.red};
  }
`

const UserProfileStatus = ({ status }) => (
  <UserStatus status={status}>{status}</UserStatus>
)

export default UserProfileStatus
