import React from 'react'
import styled from 'styled-components/macro'

const StyledText = styled.div`
  font-size: 13px;
  h1 {
    font-size: 35px;
    margin-top: 20px;
  }
  h3 {
    font-size: 15px;
    font-weight: 900;
    margin-top: 34px;
  }
  br {
    line-height: 34px;
  }
`

const TermsOfUseText = () => (
  <StyledText>
    <h1>Terms of Use</h1>
    <hr />
    The Platform (defined below) is owned and operated by [Starbase Pte. Ltd.]
    <b> (“Starbase”)</b> and provided to you under the following Terms of Use (
    <b>“Terms of Use”</b>
    ). When you read these Terms of Use, “you” and “your” refer to the Users
    (defined below) and the Project Creator (defined below), unless the context
    otherwise requires, and the words “we”, “our” and “us” refer to Starbase and
    its successors and assigns. By accessing the Platform, you agree to be bound
    by these Terms of Use (including the Additional Terms (defined below)). If
    you do not agree to these Terms of Use, kindly do not access or use the
    Platform.
    <br />
    <b>If you are below 18 years old:</b> you must obtain consent from your
    parent(s) or legal guardian(s), the}ir acceptance of these Terms of Use and
    their agreement to take responsibility for: (i) your actions; and (ii) your
    acceptance and compliance with these Terms of Use.{' '}
    <b>
      If you do not have consent from your parent(s) or legal guardian(s), you
      must stop using/accessing the Platform.
    </b>
    <br />
    <h3>DEFINITIONS</h3>
    In these Terms of Use, unless the context otherwise requires, the following
    expressions shall have the following meanings:
    <br />
    “Account” means such account for access to the password-protected and/or
    secure areas of the Platform created by a User by providing such information
    and in accordance with such instructions as may be prescribed by Starbase.
    <br />
    “Losses” means all losses, settlement sums, costs (including legal fees and
    expenses on a solicitor-client basis), charges, expenses, actions,
    proceedings, claims, demands and other liabilities, whether foreseeable or
    not.
    <br />
    “Order” means a User’s order for any tokens, products and/or services that
    may be or which are purchased or obtained or any other transactions entered
    into through the Platform.
    <br />
    “Password” refers to the password that meets Starbase-prescribed criteria,
    if any, which you have chosen when creating an Account and any changes
    thereto in the manner prescribed by Starbase.
    <br />
    “Platform” means both the web and mobile versions of the website operated
    and/or owned by Starbase which is presently located at the following URL:{' '}
    <a
      target="_blank"
      href="https://platform.starbase.co"
      rel="noopener noreferrer"
    >
      https://platform.starbase.co
    </a>
    .<br />
    “Privacy Policy” means the privacy policy as set out at the following URL:{' '}
    <a
      target="_blank"
      href="https://platform.starbase.co/privacy"
      rel="noopener noreferrer"
    >
      https://platform.starbase.co/privacy
    </a>
    .<br />
    “Project Creator” a person that wishes to raise funds through an initial
    token offering and whose tokens, products and/or services are to be offered,
    provided or otherwise made available via the Platform.
    <br />
    “Security Credentials” means UserID, Password, and any other unique login
    identification credentials issued or prescribed by us to allow Users, to
    access the password-protected and/or secure areas of the Platform. “User”
    means an authorized user of the Platform.
    <br />
    “UserID” means the unique login identification name which identifies a User
    or Project Creator who has an Account.
    <h3>LICENSE TO USE PLATFORM</h3>
    The Platform is proprietary to Starbase and must not be used other than
    strictly in accordance with the terms set out herein. Upon the successful
    registration for your Account, Starbase grants to you a personal, limited,
    non-exclusive, non-transferable, non-sublicensable right to use the
    Platform.
    <br />
    Starbase reserves all rights not granted hereunder.
    <br />
    For the avoidance of doubt, Starbase shall not at any time be obliged to
    provide any adaptations, enhancements and/or modifications to the Platform,
    including without limitation any updates, patches, bug-fixes and/or upgrades
    to the Platform or any new versions and/or releases of the Platform which
    incorporate new features or functions.
    <br />
    You may not under any circumstances: (i) copy, sell, resell, assign,
    license, distribute, transmit, publicly display, rent, lease, lend, export,
    offer on a “pay-per-use” basis, publish or otherwise reproduce the Platform
    or any part thereof in any form by any means; (ii) adapt, modify, decompile,
    disassemble, localize, port and/or reverse engineer the Platform or any part
    thereof; (iii) remove, obscure or destroy any copyright, trade secret,
    proprietary or confidential legends or marking of Starbase placed upon or
    contained within the Platform; (iv) prepare or develop derivative works
    based on the Platform; and/or (v) use the Platform for any purpose other
    than those permitted under these Terms of Use. Any breach of the aforesaid
    restrictions shall result in immediate and automatic termination of all
    rights and license granted hereunder. The use restrictions set out herein
    shall survive the termination of these Terms of Use.
    <h3>GENERAL CONDITIONS FOR THE USE OF THE PLATFORM</h3>
    You must comply with Starbase’s notices, guidelines and operating rules and
    policies pertaining to the use of the Platform which Starbase issues from
    time to time. These notices, guidelines and operating rules and policies
    will generally be notified to you via email and/or publication on the
    Platform or such other method of notification as may be designated by
    Starbase, which you acknowledge shall be sufficient notice for the purpose
    of this Clause.
    <br />
    You must also: (i) abide by all applicable laws and regulations in your use
    of the Platform; (ii) not impersonate any person or to falsely state or
    otherwise misrepresent your affiliation with any person; (iii) not send,
    distribute or upload, in any way, data or materials that contain viruses,
    malicious code or harmful components that may impair or damage the operation
    of another’s computer or device; and (iv) not post, promote or transmit
    through the Platform any unlawful, harassing, libelous, harmful, vulgar,
    obscene or otherwise objectionable material of any kind or nature.
    <h3>RESERVATION OF RIGHT</h3>
    Starbase may from time to time without giving any reason or prior notice,
    upgrade, modify, alter, suspend, discontinue the provision of or remove,
    whether in whole or in part, the Platform and shall not be liable if any
    such upgrade, modification, suspension or alteration prevents you from
    accessing the Platform or any part or feature thereof.
    <br />
    Starbase reserves the right, but shall not be obliged to: (i) monitor,
    screen or otherwise control any activity or content; (ii) investigate any
    violation of the Terms of Use contained herein and take any action it deems
    appropriate; (iii) prevent or restrict access of any User to the Platform;
    and/or (iv) report any activity it suspects to be in violation of any
    applicable law, statute or regulation to the appropriate authorities and to
    co-operate with such authorities.
    <br />
    Starbase reserves the right to decline to accept any Order if: (i) such
    Order does not comply with the requirements or Terms of Use applicable to
    the use of the Platform, including these Terms of Use, or is otherwise not
    in the mode or manner required by Starbase; (ii) Starbase is prohibited from
    processing such Order by reason of any applicable laws or legal
    requirements.
    <h3>OTHER APPLICABLE TERMS/NEW FEATURES</h3>
    In addition to these Terms of Use, the use of specific aspects of the
    Platform or more comprehensive or updated versions of the Platform may be
    subject to additional Terms of Use (<b>“Additional Terms”</b>
    ), which will apply in full force and effect.
    <br />
    Starbase reserves the right (but shall not be obliged) to introduce new
    products, applications, programs, services, functions and/or features
    (collectively, <b>“New Features”</b>) to the Platform. The term “Platform”
    shall include New Features which are provided through the Platform
    respectively at no charge or fee unless otherwise indicated.
    <br />
    All New Features shall be governed by these Terms of Use and may be subject
    to Additional Terms which you shall be required to agree to before access to
    and use of such New Features are provided, and shall be deemed to have
    agreed to upon use of such New Features. In the event of any inconsistency
    between these Terms of Use and the Additional Terms, the Additional Terms
    shall prevail in so far as the inconsistency relates to the product,
    application, program, service, function or feature in question unless
    otherwise provided.
    <h3>ACCOUNT AND SECURITY CREDENTIALS</h3>
    You must have an Account and Security Credentials to access the Platform as
    may be prescribed by Starbase. You represent and warrant that: (i) all
    information submitted by you to Starbase for the purposes of creating an
    Account and in the course of your use of the Platform is truthful and
    accurate; (ii) you will ensure that such information is kept up-to-date and
    accurate at all times; and (iii) your use of the Platform does not violate
    any applicable laws or legal requirements or these Terms of Use.
    <br />
    Starbase may at any time in its absolute discretion forthwith invalidate
    your Account and/or your Security Credentials, as applicable, without
    assigning any reason therefor without prior notice and shall not be liable
    or responsible for any loss or damage suffered by or caused to you or
    arising out of or in connection with or by reason of such invalidation.
    <br />
    You hereby agree to keep your Security Credentials confidential and shall be
    responsible for the security of your Account and liable for any disclosure
    or use (whether such use is authorized or not) of your Security Credentials.
    You are to notify us immediately if you have knowledge that or have a reason
    for suspecting that the confidentiality of your Security Credentials has
    been compromised or if there has been any unauthorized use of your Security
    Credentials or if any information in relation to your Account requires
    updating.
    <br />
    Starbase shall not be responsible or liable for any Losses, damage or
    embarrassment incurred or suffered by you or any person by reason of or
    arising from or as a consequence of using your Security Credentials in
    respect of any instructions, operations or transactions effected by you or
    purported to be affected by you through the Platform.
    <br />
    Any use of or access to the Platform and any information, data, instructions
    or communications referable to your Security Credentials (whether such
    access, use or communication is authorized by you or not) shall be deemed to
    be: (i) use or access of the Platform by you; and/or (ii) information, data,
    instructions or communications transmitted and validly issued by you.
    <br />
    You shall be bound by any access, use, instructions and/or communications
    carried out or transmitted through the Platform which are referable to your
    Security Credentials and you agree that Starbase shall be entitled (but not
    obliged) to act upon, rely on and/or hold you solely responsible and liable
    in respect thereof as if the same were carried out or transmitted by you.
    <h3>ORDERS AND TRANSACTIONS</h3>
    You acknowledge that except as otherwise indicated, all products and/or
    services offered for sale on the Platform are sold by the relevant Project
    Creator as indicated on the specific product or service listing on the
    Platform, and not Starbase. Starbase neither endorses nor assumes any
    responsibility or liability arising in connection with any Order or the
    products and/or services in respect of which an Order is carried out.
    Transfer of risk and property in purchased products, delivery, product
    warranties (including the conditions and remedies for such warranties),
    support, refunds, returns, replacements, exchanges and any other ancillary
    products or services in relation to such products and/or services shall be
    governed by and subject to such Terms of Use as may be prescribed by
    Starbase and/or by the relevant Project Creator as indicated on the specific
    product or service listing on the Platform.
    <br />
    You may place an Order in accordance with the instructions as prescribed by
    Starbase on the Platform from time to time. Orders placed in any other
    manner will not be accepted by Starbase and/or Project Creator. You
    acknowledge that you shall be responsible for ensuring the accuracy of the
    Order and that each Order shall be deemed to be irrevocable and
    unconditional upon transmission to the Platform and Starbase and/or the
    relevant Project Creator shall be entitled (but not obliged) to process such
    Order(s) without your further consent and without any further reference or
    notice to you. [Nevertheless, you may request to cancel or amend the Order
    if so permitted by and in accordance with such Terms of Use as may be
    prescribed by Starbase and/or the relevant Project Creator on the specific
    product or service listing on the Platform, which Starbase and/or Project
    Creator will endeavor to give effect to on a commercially reasonable basis.
    However, notwithstanding the foregoing, Starbase and/or Project Creator is
    not obliged to give effect to any request to cancel or amend any Order.]
    <br />
    All Orders shall be subject to the sole discretion of Starbase and/or the
    Project Creator’s acceptance of them, and each accepted Order shall
    constitute an agreement entered into directly and only between you and the
    relevant Project Creator (<b>“Customer Contract”</b>
    ). You acknowledge that unless you receive a notice from Project Creator
    accepting your Order, Project Creator shall not be party to any legally
    binding agreements or promises made between Project Creator and you for the
    sale or other dealings with the relevant products and/or services and
    accordingly Project Creator shall not be liable for any Losses which may be
    incurred as a result. For the avoidance of doubt, Starbase and Project
    Creator each reserve the right to decline to process or accept any Order
    received from or through the Platform in its absolute discretion.
    <br />
    The Project Creator reserves the right to terminate a Customer Contract, in
    the event that the relevant product and/or service has been mispriced on the
    Platform or for any other reason as determined by Project Creator in its
    absolute discretion, in which event Starbase shall, on behalf of Project
    Creator, notify you of such cancellation three (3) days prior to the
    effective date of such cancellation. In the case of a Customer Contract for
    the sale of products, Project Creator shall have such right to terminate the
    relevant Customer Contract whether or not the relevant product has been
    dispatched or are in transit and whether payment has been charged to you.
    <br />
    You acknowledge and warrant that you have not relied on any term, condition,
    warranty, undertaking, inducement or representation made by or on behalf of
    Project Creator which has not been stated expressly in a Customer Contract
    or upon any descriptions or illustrations or specifications contained in any
    document including any catalogs or publicity material produced either by
    Starbase or Project Creator. You also acknowledge and agree that the
    exclusion of warranties, exclusion of liability and exclusion of remedies in
    these Terms of Use and Customer Contracts allocate risks between the parties
    and permit Project Creator to provide its products and/or services at lower
    fees or prices than Project Creator otherwise could and you agree that such
    exclusions of liability are reasonable.
    <br />
    Without prejudice to the generality of the foregoing Clause 7.5:
    <br />
    Starbase gives no warranty as to the quality, merchantability, state,
    condition or fitness of the products and/or services offered, provided or
    made available on the Platform; no condition is made or to be implied nor is
    any warranty given or to be implied as to the life or wear of any products
    and/or services supplied or that they will be suitable for any particular
    purpose or use under any specific conditions, notwithstanding that such
    purpose or conditions may be known to Project Creator; and Starbase shall be
    under no liability for the following measures and actions taken by the User
    or third parties and the consequences thereof: improper remedy of defects,
    alteration of the relevant products and/or services without the prior
    agreement of Starbase, addition and insertion of parts, in particular of
    spare parts which do not come from Starbase.
    <br />
    You may make payment in respect of an Order by using any of the payment
    methods prescribed by Starbase from time to time and in accordance with any
    additional terms as may be prescribed by Starbase from time to time. When
    you place an Order, actual payment will be only charged upon acceptance of
    your Order by Starbase or Project Creator and formation of a Customer
    Contract. Payments may be made to and processed by Starbase as Project
    Creator’s agent. You acknowledge that Starbase is entitled to collect
    payments from you on behalf of Project Creator.
    <br />
    You may not claim against Project Creator or any of its agents (which may
    include Starbase), for any failure, disruption or error in connection with
    your chosen payment method. Starbase reserves the right at any time to
    modify or discontinue, temporarily or permanently, any payment method
    without notice to you or giving any reason.
    <h3>DISCLAIMERS</h3>
    Content and services from various sources, including Project Creators and
    any other third party content providers (<b>“Third Party Content”</b>
    ), may be included or provided through the Platform. None of Starbase,
    Project Creators or any third party content providers shall be liable for
    any errors or delays in the Platform or any part thereof, or for any actions
    taken in reliance thereon. Starbase is under no obligation to monitor or
    review Third Party Content through the Platform, and assumes no
    responsibility or liability for any Losses arising from or in connection
    with any such Third Party Content nor for any error, defamation, libel,
    slander, omission, falsehood, obscenity, pornography, profanity, inaccuracy
    or any other objectionable material contained in any such content. Any
    hyperlink to any other website or webpage is not an endorsement or
    verification of such website or webpage and should only be accessed at your
    own risk.
    <h3>COPYRIGHT ACT NOTIFICATION</h3>
    The Copyright Act (Chapter 63 of Singapore) (the <b>“Act”</b>) contains
    provisions related to limiting the liability of network service providers
    such as Starbase who provide services such as system caching or user storage
    and information location.
    <br />
    Where a copyright owner(
    <b>“Owner”</b>) furnishes to Starbase (through Starbase’s designated
    representative) a valid notice(
    <b>“take-down notice”</b>) in the form prescribed by the Act, Starbase will
    take reasonable steps to remove or disable access to the relevant material
    in accordance with the Act.
    <br />
    Following the removal of or disabling or access to the relevant material,
    Starbase will take reasonable steps to notify the person who made available
    such material (<b>“Provider”</b>) in accordance with the Act.
    <br />
    If the Provider furnishes to Starbase (through Starbase’s designated
    representative) a valid notice in the form prescribed by the Act (
    <b>“counter-notice”</b>
    ), Starbase will take reasonable steps to restore the relevant material if
    it is technically and practically feasible to do so, unless court
    proceedings are commenced by the Owner and Starbase is informed in writing
    in accordance with the Act.
    <br />
    The details of Starbase’s designated representative are as follows:
    <br />
    Eugene Lee
    <br />
    VP, Marketing and Business Development, Starbase
    <br />
    16 Collyer Quay, #18-00, Income at Raffles, Singapore, Singapore 049318
    <br />
    Email: eugene@starbase.co
    <br />
    Tel: +65 6818 9742
    <br />
    Fax: +65 6818 9602
    <br />
    These details should not be used other than to provide Starbase with
    take-down and counter-notices in the form prescribed by the Act.
    <br />
    Should you wish to provide a take-down and counter-notice to Starbase,
    please ensure that such take-down and counter-notices are in conformance
    with the form prescribed by the Act. Starbase will not act on any notice
    unless the notice is a valid notice in the form prescribed by the Act.
    <br />
    For your information, the Copyright(Network Service Provider) Regulations
    2005 sets out the form in which take-down and counter-notices should be
    provided to network service providers.
    <br />
    You should also be aware that the Act states that a person who, in making a
    take-down or counter -notice, makes any statement (in Singapore or
    elsewhere) that is false or which such person does not believe is true, will
    be liable on conviction to a fine of up to S$10,000 or to imprisonment for
    up to 2 years. Such person shall also be liable in damages to any party who
    suffers any loss or damage as a result of such take-down or counter-notice.
    <h3>NO WARRANTY</h3>
    The Platform is provided “as is” and “as available”. NO WARRANTY OF ANY
    KIND, IMPLIED, EXPRESS OR STATUTORY, INCLUDING BUT NOT LIMITED TO ANY
    WARRANTIES OF TITLE, NON-INFRINGEMENT OF THIRD PARTY RIGHTS,
    MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE AND
    FREEDOM FROM COMPUTER VIRUS OR OTHER MALICIOUS, DESTRUCTIVE OR CORRUPTING
    CODE, AGENT, PROGRAM OR MACROS, IS GIVEN IN CONJUNCTION WITH THE PLATFORM OR
    ANY INFORMATION AND MATERIALS PROVIDED THROUGH THE PLATFORM. Without
    prejudice to the generality of the foregoing, Starbase does not warrant: (i)
    the accuracy, timeliness, adequacy or completeness of the Platform; (ii)
    that your use of and/or access to the Platform, or the operation of the
    Platform, will be uninterrupted, secure or free from errors or omissions or
    that any identified defect will be corrected; (iii) that the Platform will
    meet your requirements or are free from any virus or other malicious,
    destructive or corrupting code, agent, program or macros; and (iv) that use
    of the Platform by you will not infringe the rights of third parties.
    <br />
    In addition, Starbase makes no warranty regarding any tokens, products or
    services that may be or which are purchased or obtained or any transactions
    entered into through the Platform.
    <br />
    You acknowledge and agree that Starbase does not warrant the security of any
    information transmitted by or to you using the Platform and you hereby
    accept the risk that any information transmitted or received using the
    Platform may be accessed by unauthorized third parties and/or disclosed by
    Starbase and by its officers, employees or agents to third parties
    purporting to be you or purporting to act under your authority.
    <br />
    You will not hold Starbase or any of its officers, employees or agents
    responsible or liable, in contract, tort (including negligence or breach of
    statutory duty), equity or otherwise, for any such access or disclosure or
    for Losses (whether direct or indirect, or whether foreseeable or not)
    suffered or incurred by you as a result of any such access or disclosure.
    <h3>DATA PRIVACY AND CONFIDENTIALITY</h3>
    You agree that all information and/or particulars sent or submitted by you
    through the Platform is non-confidential and non-proprietary unless
    otherwise expressly indicated by you and may be used by us in accordance
    with our{' '}
    <a
      target="_blank"
      href="https://platform.starbase.co/privacy"
      rel="noopener noreferrer"
    >
      Privacy Policy.
    </a>
    <h3>EXCLUSION OF LIABILITY AND INDEMNITY</h3>
    Starbase shall in no event nor for any reason whatsoever be liable, even if
    Starbase has been advised of the possibility of such Losses, for any Losses,
    including direct, indirect, special, or consequential damage, or economic
    loss, arising from or in connection with: (i) any access, use or the
    inability to access or use the Platform, including in connection with any
    maintenance, breakdown, fault or non-availability of any part of the
    Platform; (ii) any system, server or connection failure, error, omission,
    interruption, delay in transmission, computer virus or other malicious,
    destructive or corrupting code, agent program or macros; (iii) any use of or
    access to any other website or webpage provided through the Platform; (iv)
    any services, products, information, data, software or other material
    obtained or downloaded through the Platform or from any other website or
    webpage provided through the Platform or from any other party referred
    through the Platform, or through the use of the Platform or reliance on the
    Platform or the contents thereof; or (v) your use or misuse of the Platform.
    In no event shall Starbase be liable to you, or any other party for: (a) any
    amounts due from Project Creators or other users of the Platform; and/or (b)
    damages arising in connection with your use of and/or access to the
    Platform, by you or other third parties.
    <br />
    You shall indemnify and hold harmless and keep Starbase indemnified in full
    against all and any claims, actions, proceedings, loss, damage, costs
    (including legal costs on a full indemnity basis), expenses and liabilities
    of whatsoever nature and howsoever arising which may be brought against or
    suffered or incurred by Starbase arising from or which is directly or
    indirectly related to:- (i) your access to and/or use of the Platform and/or
    any other person’s use of the Platform where such person was able to access
    and/or use the Platform by using your Security Credentials (where
    applicable); or (ii) any breach or non-observance of any of these Terms of
    Use by you or by any other person where such person was able to access
    and/or use the Platform by using your Security Credentials (where
    applicable).
    <h3>INTELLECTUAL PROPERTY AND CONTENT TRANSMITTED VIA THE PLATFORM</h3>
    All copyright and other intellectual property and proprietary rights in the
    Platform are owned, licensed to or controlled by Starbase, Starbase’s
    licensors or service providers, unless otherwise indicated. You may access
    the Platform provided that Starbase or the relevant copyright owners retain
    all copyright and other proprietary notices contained on the content and
    materials on the Platform. The trademarks, logos, and service marks
    (collectively the <b>“Trademarks”</b>) displayed on the Platform are
    registered and unregistered Trademarks of Starbase and where applicable,
    Project Creators or other third party proprietors identified on the
    Platform. No right or license is given to any party accessing and/or using
    the Platform to download, reproduce or use any such Trademarks.
    <br />
    In relation to any information, data, text, messages or other materials (
    <b>“Content”</b>) that you upload, post, email or otherwise transmit via the
    Platform, you hereby grant to Starbase a worldwide royalty-free perpetual
    license of the copyright and intellectual property rights in such Content
    for any purpose it deems necessary, desirable or expedient, including the
    copying, transmission, distribution and publication thereof, unless
    restricted by applicable law. You agree that Starbase shall not be under any
    obligation of confidentiality to you regarding any such Content transmitted
    to us using the Platform unless otherwise agreed in a separate direct
    contract between you and us or required by law.
    <h3>TERMINATION</h3>
    Starbase, in its sole discretion, may with immediate effect upon giving you
    notice in any of the manners prescribed in Clause 16 below, terminate your
    right to access and/or use the Platform and/or invalidate your Security
    Credentials and may bar access to the Platform (or any part thereof) for any
    reason whatsoever, including without limitation, a breach of any of these
    Terms of Use.
    <br />
    You may terminate these Terms of Use by giving seven (7) days’ notice in
    writing to Starbase.
    <br />
    Upon termination of these Terms of Use for any reason whatsoever, all rights
    and/or licenses granted to you under these Terms of Use shall immediately
    cease and terminate and you shall forthwith cease the use of the Platform in
    any way whatsoever.
    <br />
    Termination of this these Terms of Use for any reason shall not bring to an
    end Starbase’s rights accrued prior to termination, and your obligations
    under any provisions of these Terms of Use which are meant to survive the
    termination.
    <h3>AMENDMENTS TO TERMS OF USE</h3>
    Starbase may impose such further Terms of Use and make such amendments to
    these Terms of Use as Starbase may in its discretion deem fit from time to
    time. Starbase will notify you of such amendments via email and/or
    publication on the Platform or such other method of notification, as may be
    designated by Starbase, which you agree shall be sufficient notice for the
    purpose of this Clause. If you do not agree to be bound by the amendments,
    you shall immediately cease all access and/or use of the Platform. You
    further agree that if you continue to use and/or access the Platform after
    being notified of such amendments to these Terms of Use, such use and/or
    access shall constitute an affirmative: (i) acknowledgment by you of these
    Terms of Use and its amendments; and (ii) agreement by you to abide and be
    bound by these Terms of Use and its amendments. Starbase’s right to vary
    these Terms of Use in the manner aforesaid may be exercised without the
    consent of any person who is not a party to these Terms of Use. NOTICES Any
    notice or other communication in connection with these Terms of Use: (i) may
    be given personally, by post, facsimile or electronically if sent to the
    address or facsimile number then most recently notified by the recipient to
    the sender; (ii) if given personally, will be deemed to have been received
    upon delivery; (iii) by post, will be deemed to have been received on the
    second day following posting; (iv) if given by facsimile, will be deemed to
    have been received upon production of a transmission report by the machine
    from which the facsimile was sent which indicates that the facsimile was
    sent in its entirety to the facsimile number of the recipient; and (v) (a)
    if given by Starbase to you electronically, will be deemed to have been
    received upon delivery (and a delivery report received by Starbase will be
    conclusive evidence of delivery even if the communication is not opened by
    you); and (b) if given to Starbase electronically, will be deemed to have
    been received upon being opened by us.
    <h3>FORCE MAJEURE</h3>
    Starbase shall not be liable for any non-performance, error, interruption or
    delay in the performance of its obligations or in the Platform’s operation
    if due, in whole or in part, directly or indirectly to an event or failure
    which is beyond its reasonable control (including acts of God, natural
    disasters, epidemics, acts of war or terrorism, acts of any government or
    authority, power failures or power supply problems, acts or defaults of any
    telecommunications network operator or carriers, Internet or network related
    problems, problems with the services rendered by third party vendors or
    service providers and the acts or a party for whom Starbase is not
    responsible for).
    <h3>GENERAL</h3>
    In these Terms of Use: (i) whenever the words “include”, “includes” or
    “including” are used in these Terms of Use, they will be deemed to be
    followed by the words “without limitation”; (ii) reference to Clauses,
    Paragraphs and Schedules are (unless otherwise indicated) to clauses,
    paragraphs and schedules of these Terms of Use; (iii) words importing the
    singular only shall also include the plural and vice versa where the context
    requires and references to persons include bodies incorporated or
    unincorporated, including partnerships and their successors and assigns;
    (iv) unless expressly indicated otherwise, all references to a number of
    days mean calendar days, and the words “month” or “monthly” as well as all
    references to a number of months means calendar months; (v) clause,
    paragraph and/or schedule headings are inserted for convenience only and
    shall not affect the interpretation of these Terms of Use; and (vi)
    references to a statute, law, by-law, regulation, rule, directive, delegated
    legislation or order also refers to the same as amended, modified or
    replaced from time and to any by-law, regulation, rule, directive, delegated
    legislation or order made thereunder.
    <br />
    Use of the Platform and these Terms of Use shall be governed by and
    construed in accordance with Singapore law and you hereby submit to the
    exclusive jurisdiction of Singapore courts.
    <br />
    The relationship of the Users, Project Creators and Starbase established by
    these Terms of Use shall be solely that of independent contractors. Nothing
    contained in these Terms of Use shall constitute or be construed as creating
    any fiduciary, partnership, joint venture, agency or sales representative
    relationship between the Users, Project Creators and Starbase, and none of
    the Users, Project Creators nor Starbase shall have any right whatsoever to
    incur any obligations or liabilities on behalf of or binding on the other
    parties, unless otherwise indicated in these Terms.
    <br />
    You may not assign your rights under these Terms of Use without Starbase’s
    prior written consent. Starbase may assign its rights under these Terms of
    Use to any third party.
    <br />
    These Terms of Use will bind you and Starbase and Starbase’s respective
    successors in title and assigns. These Terms of Use will continue to bind
    you notwithstanding any change in Starbase’s name or constitution or
    Starbase’s merger, consolidation or amalgamation with or into any other
    entity (in which case these Terms of Use will bind you to Starbase’s
    successor entity).
    <br />
    If any provision of these Terms of Use is held to be invalid, illegal or
    unenforceable (whether in whole or in part), such provision shall be struck
    and severed from these Terms of Use and the remaining provisions of these
    Terms of Use shall not be affected thereby.
    <br />
    No failure or delay to exercise Starbase’s rights under these Terms of Use
    shall operate as a waiver thereof nor shall such failure or delay affect
    Starbase’s right to enforce its rights under these Terms of Use.
    <br />
    You acknowledge and agree that Starbase's records and any records of the
    communications, transactions, instructions or operations made or performed,
    processed or effected through the Platform by you or any person purporting
    to be you, acting on your behalf or purportedly acting on your behalf, with
    or without your consent, or any record of communications, transactions,
    instructions or operations relating to the operation of the Platform and any
    record of any communications, transactions, instructions or operations
    maintained by Starbase or by any relevant person authorized by Starbase
    relating to or connected with the Platform shall be binding on you for all
    purposes whatsoever and shall be conclusive evidence of such communications,
    transactions, instructions or operations. You further acknowledge and agree
    that Starbase’s decisions on all matters relating to these Terms of Use will
    be at its absolute discretion and will be final and binding on you.
    <br />
    No person who is not a party to these Terms of Use shall have any right
    under the Contracts (Rights of Third Parties) Act (Chapter 53B of Singapore)
    or other similar laws to enforce any term of these Terms of Use, regardless
    of whether such person has been identified by name, as a member of a class
    or as answering a particular description. For the avoidance of doubt, this
    shall not affect the rights of any permitted assignee or transferee of these
    Terms of Use.
    <br />
    You agree and acknowledge that these Terms of Use and the services provided
    or made available hereunder do not include the provision of Internet access
    or other telecommunication services by Starbase. Any Internet access or
    telecommunications services (such as mobile data connectivity) required by
    you to access and use the Platform shall be your sole responsibility and
    shall be separately obtained by you, at their own cost, from the appropriate
    telecommunications or internet access service provider.
  </StyledText>
)

export default TermsOfUseText
