import { connect } from 'react-redux'

import get from 'lodash/get'

import ProjectDashboard from './ProjectDashboard'
import selectIsUserProjectOwnerFromLocation from 'store/selectors/selectIsUserProjectOwnerFromLocation'

const mapStateToProps = ({
  project: {
    item,
    dashboard: { bountyClaims, analytics, googleAnalytics },
  },
  bounties: { bountyCampaigns },
  ...state
}) => ({
  isLoading: get(state, 'projectOwned.isLoading', false),
  isUserProjectOwner: selectIsUserProjectOwnerFromLocation(state),
  bountyClaims,
  bountyCampaigns,
  project: item,
  analytics: get(analytics, 'data'),
  googleAnalytics,
})

export default connect(mapStateToProps)(ProjectDashboard)
