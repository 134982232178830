import React from 'react'
import { Helmet } from 'react-helmet'

import logoIcon from '../assets/images/logo-icon-color.png'
import banner from '../assets/images/starbase-banner.png'

const defaultMeta = {
  title:
    'Starbase - Crowdfund your rocket idea by issuing global tokens on Blockchain',
  description:
    'Starbase official website. Crowdfund your rocket idea by issuing global tokens on blockchain. Besides with Starbase, your space mission can have on board great and global supporters!',
  keywords:
    'Starbase, crowdsale, ICO, blockchain, tokens, crypto currencies, Bitcoin, Ethereum, ERC20 token, Ethereum token, bounty campaign, startups, crowdfunding, crowdsourcing, recruiting, incentive sharing',
  url: 'https://' + window.location.hostname,
  icon: logoIcon,
  banner,
  name: 'Starbase',
  twitter: '@starbaseco',
  facebook: 'https://www.facebook.com/Starbase-1670679649909123/',
}

const Metatags = ({ meta = null }) => {
  const data = { ...defaultMeta, ...meta }

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{data.title}</title>
      <html prefix="og: http://ogp.me/ns#" />

      {/* general */}
      <meta name="description" content={data.description} />
      <meta name="keywords" content={data.keywords} />
      <link rel="canonical" href={data.url} />
      <link rel="icon" href={data.icon} type="image/png" />
      <link rel="apple-touch-icon" href={data.icon} />
      <meta itemprop="name" content={data.name} />
      <meta itemprop="description" content={data.description} />
      <meta itemprop="image" content={data.url + data.banner} />

      {/* facebook */}
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={data.name} />
      <meta property="og:title" content={data.title} />
      <meta property="og:url" content={data.url} />
      <meta property="og:description" content={data.description} />
      <meta property="og:image" content={data.url + data.banner} />
      <meta property="og:image:url" content={data.url + data.banner} />
      <meta property="article:publisher" content={data.facebook} />

      {/* twitter */}
      <meta name="twitter:title" content={data.title} />
      <meta name="twitter:url" content={data.url} />
      <meta name="twitter:description" content={data.description} />
      <meta name="twitter:image" content={data.url + data.banner} />
      <meta name="twitter:site" content={data.twitter} />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  )
}

export default Metatags
