import { FETCH_FIAT_RATES_SUCCESS, FETCH_FIAT_RATES_FAILURE } from '../types'

const initialState = {
  fiatRates: [],
  error: null,
  isLoading: true,
}

export default (state = initialState, { type, payload, error }) => {
  switch (type) {
    case FETCH_FIAT_RATES_SUCCESS:
      return {
        ...state,
        fiatRates: payload.fiatRates,
        isLoading: false,
      }

    case FETCH_FIAT_RATES_FAILURE:
      return { ...state, error, isLoading: false }

    default:
      return state
  }
}
