import { connect } from 'react-redux'
import Alert from './Alert'

import { closeAlert } from '../../../store/ui/uiActions'

import {
  BOUNTY_CAN_ONLY_BE_CLAIMED_ONCE_SERVER_ERROR,
  BOUNTY_INSUFFICIENT_REWARD_CAP_SERVER_ERROR,
  BOUNTY_REACHED_MAX_NUMBER_CLAIMS_SERVER_ERROR,
  CURRENT_USER_SERVER_ERROR,
  FETCH_BALANCE_SERVER_ERROR,
  GET_BOUNTIES_SERVER_ERROR,
  GET_PROJECT_DASHBOARD_SERVER_ERROR,
  INVALID_JWT_SERVER_ERROR,
  JWT_DATA_MISMATCH_SERVER_ERROR,
  METAMASK_IS_LOCKED_ERROR,
  ONLY_ACCEPTED_USERS_CAN_POST_BOUNTY_CLAIM_SERVER_ERROR,
  ONLY_ACCEPTED_USERS_CAN_POST_COMMENTS_SERVER_ERROR,
  POST_BOUNTY_CLAIM_SERVER_ERROR,
  POST_USER_SERVER_ERROR,
  POST_VOUCHER_CODE_UNIQUE_SERVER_ERROR,
  PROJECT_ACCESS_DENIED_SERVER_ERROR,
  PROJECT_NOT_FOUND_SERVER_ERROR,
  PROJECT_OWNERS_CANNOT_BE_REMOVED_SERVER_ERROR,
  PROJECT_OWNERS_CANNOT_SUBMIT_OWN_BOUNTY_SERVER_ERROR,
  PURCHASE_EXCEEDS_SUPPLY_SERVER_ERROR,
  PUT_ACCEPT_BOUNTY_CLAIM_SERVER_ERROR,
  PUT_REJECT_BOUNTY_CLAIM_SERVER_ERROR,
  REQUEST_USER_DELETION_SERVER_ERROR,
  TRANSACTION_REJECTED_BY_USER_ERROR,
  UNKNOWN_SERVER_ERROR,
  USER_DELETION_SERVER_ERROR,
  VALIDATE_VOUCHER_CODE_WRONG_CODE,
  VALIDATE_VOUCHER_CODE_WRONG_TOKEN_SALE,
  VOUCHER_CODE_INSUFFICIENT_REWARD_CAP_SERVER_ERROR,
  VOUCHER_CODE_NOT_FOUND_SERVER_ERROR,
  VOUCHER_OWNER_IS_BUYER_SERVER_ERROR,
  WRONG_USER_LOGGED_IN_SERVER_ERROR,
} from '../../../errors'

const errorDictionary = {
  [CURRENT_USER_SERVER_ERROR]:
    'Sorry, we could not retrieve your user data this time, please try again soon.',

  [POST_USER_SERVER_ERROR]:
    'Sorry, we could not process your submission this time, please try submitting again.',

  [FETCH_BALANCE_SERVER_ERROR]:
    'Sorry, we coud not reach the Ethereum blockchain this time, please try again soon.',

  [REQUEST_USER_DELETION_SERVER_ERROR]:
    "Sorry, the deletion request didn't work this time, please try again soon.",

  [PROJECT_NOT_FOUND_SERVER_ERROR]: 'Project not found.',

  [UNKNOWN_SERVER_ERROR]:
    'Sorry, something went wrong. We have been notified of the error, please try again soon.',

  [WRONG_USER_LOGGED_IN_SERVER_ERROR]:
    'You are not logged in with the correct user. Please, logout from this account and login with the correct account.',

  [USER_DELETION_SERVER_ERROR]:
    'Sorry, something went wrong. We have been notified of the error, we will take it from here.',

  [JWT_DATA_MISMATCH_SERVER_ERROR]:
    'You are not logged in with the correct user.',

  [INVALID_JWT_SERVER_ERROR]:
    'Sorry, something went wrong. We have been notified of the error, please try again soon.',

  [PROJECT_OWNERS_CANNOT_BE_REMOVED_SERVER_ERROR]: `
    As a project owner, you cannot be removed from the platform without potentially impacting other users. 
    <br /><br /> 
    Please write to <a href="mailto:support@starbase.co">support@starbase.co</a> for further support with your request.
  `,

  [ONLY_ACCEPTED_USERS_CAN_POST_COMMENTS_SERVER_ERROR]:
    'Only accepted users can post comments.',

  [POST_VOUCHER_CODE_UNIQUE_SERVER_ERROR]:
    'Your voucher code has already been taken. Please use another code.',

  [VALIDATE_VOUCHER_CODE_WRONG_CODE]: 'Wrong voucher code.',

  [VALIDATE_VOUCHER_CODE_WRONG_TOKEN_SALE]:
    'This voucher code is not for this token sale',

  [VOUCHER_OWNER_IS_BUYER_SERVER_ERROR]:
    'You can not use your own voucher code',

  [VOUCHER_CODE_INSUFFICIENT_REWARD_CAP_SERVER_ERROR]:
    'Voucher Program has insufficient tokens to reward',

  [VOUCHER_CODE_NOT_FOUND_SERVER_ERROR]: 'Wrong voucher code.',

  [BOUNTY_CAN_ONLY_BE_CLAIMED_ONCE_SERVER_ERROR]:
    'Bounty already claimed by you',

  [BOUNTY_REACHED_MAX_NUMBER_CLAIMS_SERVER_ERROR]:
    'Sorry, you can`t claim this Bounty! You had reached maximum approved claims limit.',

  [GET_BOUNTIES_SERVER_ERROR]:
    'Sorry, something went wrong. We have been notified of the error, please try again soon.',
  [GET_PROJECT_DASHBOARD_SERVER_ERROR]:
    'Sorry, something went wrong. We have been notified of the error, please try again soon.',

  [ONLY_ACCEPTED_USERS_CAN_POST_BOUNTY_CLAIM_SERVER_ERROR]:
    'Only accepted users can submit bounty claims.',

  [POST_BOUNTY_CLAIM_SERVER_ERROR]:
    'Sorry, something went wrong. We have been notified of the error, please try again soon.',

  [PUT_ACCEPT_BOUNTY_CLAIM_SERVER_ERROR]:
    'Sorry, something went wrong. We have been notified of the error, please try again soon.',

  [PUT_REJECT_BOUNTY_CLAIM_SERVER_ERROR]:
    'Sorry, something went wrong. We have been notified of the error, please try again soon.',

  [PROJECT_ACCESS_DENIED_SERVER_ERROR]:
    'Access Denied! Only the project owner can access it.',

  [BOUNTY_INSUFFICIENT_REWARD_CAP_SERVER_ERROR]:
    'Bounty Campaign has insufficient tokens to reward.',

  [PROJECT_OWNERS_CANNOT_SUBMIT_OWN_BOUNTY_SERVER_ERROR]:
    'You can not claim on your own bounty campaign.',
}

const humanizeKnownErrors = error =>
  errorDictionary[error] ? errorDictionary[error] : null

const mapStateToProps = state => {
  // erros which will not be handle by alert component
  // because they are already handled by the modal
  const ignoredErrors = [
    METAMASK_IS_LOCKED_ERROR,
    PURCHASE_EXCEEDS_SUPPLY_SERVER_ERROR,
    TRANSACTION_REJECTED_BY_USER_ERROR,
  ]
  const errors = [
    state.bounties.error,
    state.comments.error,
    state.currentUser.error,
    state.dashboard.error,
    state.metaMask.error,
    state.pay.error,
    state.profile.error,
    state.projects.error,
    state.project.error,
    state.voucher.error,
  ] // custom mesage should be handle here: .filter(e => if(e===SOME_BAD_ERROR) return 'Ops, something bad happened')
    .filter(e => e)
    .filter(e => !ignoredErrors.includes(e))
    .map(humanizeKnownErrors)
    .filter(e => e)

  return {
    errors,
    isVisible: !!errors.length,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  onClose: () => dispatch(closeAlert()),
})

const mergeProps = ({ errors, isVisible }, { dispatch, onClose }) => ({
  errors,
  isVisible,
  dispatch,
  onClose,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Alert)
