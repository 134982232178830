import React from 'react'

import InfoBlock from './components/InfoBlockContainer'
import Breadcrumbs from './components/Breadcrumbs'

import BodyContainer from 'app/components/BodyContainer'
import Wrapper from 'app/components/Wrapper'

const TokenSale = ({ project, tokenSale }) => (
  <Wrapper>
    <BodyContainer>
      <Breadcrumbs project={project} tokenSale={tokenSale} />
      <InfoBlock project={project} tokenSale={tokenSale} />
    </BodyContainer>
  </Wrapper>
)

export default TokenSale
