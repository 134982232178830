import React from 'react'
import styled from 'styled-components/macro'

const Seperator = styled.div`
  border-bottom: 0.07rem dashed #ebedf2;
  margin: 15px -30px;
`
const Title = styled.h2`
  &:first-child {
    margin-top: ${props => (props.additional ? '50px' : '15px')};
  }
`
const FormTitle = ({ title, additional }) => (
  <div>
    <Title additional={additional}>{title}</Title>
    <Seperator />
  </div>
)

export default FormTitle
