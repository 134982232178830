/* eslint-env browser */
import auth0 from 'auth0-js'
import Cookies from 'js-cookie'

import { userLoggedIn, userLoggedOut } from 'store/auth/authActions'
import { toHome } from 'store/routerActions'

const { REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_DOMAIN } = process.env

const baseUrl = `${window.location.protocol}//${window.location.host}`

const ONE_MINUTE_GUARD = 60 * 1000 // expire in frontend before backend
const expiresAt = () =>
  new Date(Cookies.get('expires_at')).getTime() - ONE_MINUTE_GUARD

const now = () => new Date().getTime()

export default class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: REACT_APP_AUTH0_DOMAIN,
      clientID: REACT_APP_AUTH0_CLIENT_ID,
      redirectUri: `${baseUrl}/auth/callback`,
      audience: `https://${REACT_APP_AUTH0_DOMAIN}/userinfo`,
      responseType: 'token id_token',
      scope: 'openid profile email',
    })
  }

  login = () => {
    localStorage.setItem('return_path', window.location.pathname)
    this.auth0.authorize()
  }

  handleAuthentication = () => dispatch =>
    new Promise((resolve, reject) =>
      this.auth0.parseHash((err, authResult) => {
        if (err) {
          console.error(`Error: ${err.error}.`)
          reject(err)
        } else if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult)

          dispatch(userLoggedIn(authResult))
          resolve({ authResult })
        } else {
          reject(`Error: authentication failed`)
        }
      })
    )

  isAuthenticated = () => now() < expiresAt()

  logout = () => dispatch => {
    if (!Cookies.get('expires_at')) return false
    Cookies.remove('access_token')
    Cookies.remove('id_token')
    Cookies.remove('expires_at')
    dispatch(userLoggedOut())
    dispatch(toHome())
    return true
  }

  setSession(authResult) {
    const expires = new Date(now() + authResult.expiresIn * 1000)

    Cookies.set('access_token', authResult.accessToken, { expires })
    Cookies.set('id_token', authResult.idToken, { expires })
    Cookies.set('expires_at', expires)
  }

  timeRemaining = () => (expiresAt() ? expiresAt() - now() : 0)
}
