import Link from 'redux-first-router-link'
import React from 'react'

import { toProjects } from 'store/routerActions'

import CreateYourProfileDialog from '../profile/components/userProfile/CreateYourProfileDialogContainer'

import Wrapper from '../../components/Wrapper'

export default () => (
  <Wrapper>
    <div className="text-center" style={{ padding: '75px 0 50px' }}>
      <CreateYourProfileDialog />
    </div>

    <div className="text-center">
      <p>
        <Link to={toProjects()}>
          <small>Or is there a project idea you would like to submit?</small>
        </Link>
      </p>
    </div>
  </Wrapper>
)
