import React from 'react'
import styled from 'styled-components/macro'

import DigitalIdentificationForm from './components/forms/DigitalIdentificationFormContainer'

import MetaMaskErrorModals from '../../components/metaMaskErrorModals/MetaMaskErrorModalsContainer'
import TgeUserRedirectionModal from './components/modals/TgeUserRedirectionModalContainer'

import TabItem from './components/tab/TabItemContainer'
import PersonalInfoForm from './components/forms/PersonalInfoFormContainer'
import ProofOfResidencyForm from './components/forms/ProofOfResidencyFormContainer'
import SubmitResult from './components/forms/SubmitResultContainer'
import SubmitInfo from './components/forms/SubmitInfoContainer'

import BodyContainer from '../../components/BodyContainer'
import Wrapper from '../../components/Wrapper'

const TabHead = styled.div`
  border-bottom: 1px solid ${props => props.theme.color.lightBlue};
  border-top-left-radius: ${props => props.theme.borderRadius.tab};
  border-top-right-radius: ${props => props.theme.borderRadius.tab};
  display: table;
  height: 54px;
  padding: 0 2.2rem;
  width: 100%;
  &:after {
    content: '';
    height: 2px;
    display: block;
    left: 0;
    right: ${props =>
      props.step === 1
        ? '79'
        : props.step === 2
        ? '62'
        : props.step === 3
        ? '34.5'
        : props.step === 4
        ? '7.5'
        : '0'}%;
    position: absolute;
    transition: right 1s ease;
    top: 69px;
    border-radius: 0 13px 13px 0;
    background: -webkit-linear-gradient(
        left,
        #a293f7 40%,
        #cba1f9 60%,
        #d1b1ff 70%,
        #cba1f9 80%,
        #a293f7 95%
      )
      repeat;
    background-size: 50% 100%;
    animation-name: moving-gradient;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes moving-gradient {
    0% {
      background-position: 525deg left bottom;
    }

    100% {
      background-position: right bottom;
    }
  }
`
const TabList = styled.div`
  position: relative;
  height: 100%;
  bottom: -1px;
  border-bottom: 1px solid ${props => props.theme.color.lightBlue};
`

const Profile = ({ step }) => (
  <Wrapper>
    <MetaMaskErrorModals />
    <TgeUserRedirectionModal />

    <TabHead step={step}>
      <TabList
        className="row no-gutters justify-content-between"
        role="tablist"
      >
        <TabItem
          className="col-sm-3 align-self-center"
          step={1}
          currentStep={step}
          icon="icon-profile"
          text="1. Personal Information"
        />

        <TabItem
          className="col-sm-3 align-self-center"
          step={2}
          currentStep={step}
          icon="icon-document"
          text="2. Proof of residency"
        />

        <TabItem
          className="col-sm-3 align-self-center"
          step={3}
          currentStep={step}
          icon="icon-form"
          text="3. Digital Identification"
        />
      </TabList>
    </TabHead>
    <BodyContainer>
      {step >= 1 && (
        <div style={{ display: step === 1 ? 'block' : 'none' }}>
          <PersonalInfoForm />
        </div>
      )}
      {step >= 2 && (
        <div style={{ display: step === 2 ? 'block' : 'none' }}>
          <ProofOfResidencyForm />
        </div>
      )}
      {step >= 3 && (
        <div style={{ display: step === 3 ? 'block' : 'none' }}>
          <DigitalIdentificationForm />
        </div>
      )}
      {step >= 4 && (
        <div style={{ display: step === 4 ? 'block' : 'none' }}>
          <SubmitInfo />
        </div>
      )}
      {step >= 5 && (
        <div style={{ display: step === 5 ? 'block' : 'none' }}>
          <SubmitResult />
        </div>
      )}
    </BodyContainer>
  </Wrapper>
)

export default Profile
