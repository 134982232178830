import Modal from 'reactstrap/lib/Modal'
import ModalBody from 'reactstrap/lib/ModalBody'
import ModalHeader from 'reactstrap/lib/ModalHeader'
import React from 'react'

import WithdrawButton from './WithdrawButton'

export default ({
  balance,
  bonusTokenContractAbi,
  bonusTokenAddress,
  completedSteps: {
    WITHDRAW_BONUS_TOKENS_FAILURE,
    WITHDRAW_BONUS_TOKENS_START,
    WITHDRAW_BONUS_TOKENS_TRANSACTION_PENDING,
    WITHDRAW_BONUS_TOKENS_TRANSACTION_CONFIRMED,
    error,
  },
  invalid,
  isOpen,
  onSubmit,
  onToggle,
  submitSucceeded,
  tokenSymbol,
}) => (
  <Modal size="md" isOpen={isOpen} toggle={onToggle} backdrop="static">
    <ModalHeader toggle={onToggle}>Withdraw Bonus Tokens</ModalHeader>
    <ModalBody>
      <div className="row">
        <div className="col-12 text-center">
          {!submitSucceeded && parseFloat(balance) > 0 && (
            <>
              You are withdrawing all {balance + ' ' + tokenSymbol} bonus tokens{' '}
              from {bonusTokenAddress}.
              <WithdrawButton
                onClick={() =>
                  onSubmit({
                    bonusTokenContractAbi,
                    bonusTokenAddress,
                  })
                }
                type="submit"
                className="btn btn-lg btn-block"
                disabled={invalid}
              >
                Withdraw
              </WithdrawButton>
            </>
          )}

          {submitSucceeded && (
            <>
              {WITHDRAW_BONUS_TOKENS_START &&
                !WITHDRAW_BONUS_TOKENS_TRANSACTION_PENDING &&
                !WITHDRAW_BONUS_TOKENS_FAILURE && (
                  <>MetaMask is creating your transaction...</>
                )}

              {WITHDRAW_BONUS_TOKENS_TRANSACTION_PENDING && (
                <>Your withdrawal transaction was successfully created!</>
              )}

              {WITHDRAW_BONUS_TOKENS_FAILURE && (
                <>There was an error creating your transaction: {error}</>
              )}

              {WITHDRAW_BONUS_TOKENS_TRANSACTION_CONFIRMED && (
                <>
                  Your withdrawal transaction was successfully confirmed!
                  Please, refresh this page to see your information updated.
                </>
              )}
            </>
          )}
        </div>
      </div>
    </ModalBody>
  </Modal>
)
