import React from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'

import detectEnvironment from '../../../detectEnvironment'
import { SET_ACTIVE_TOAST } from '../../../store/types'

const A = styled.a`
  color: white;
  text-decoration: underline;
  &:hover {
    color: white;
    text-decoration: none;
  }
`

const urlPrefix = detectEnvironment() === 'production' ? '' : 'rinkeby.'

const pendingPurchaseId2ToastId = ({ purchaseId }) =>
  purchaseId ? `purchase-${purchaseId}` : undefined

const acceptedPurchaseId2ToastId = ({ purchaseId }) =>
  purchaseId ? `accepted-purchase-${purchaseId}` : undefined

const rejectedPurchaseId2ToastId = ({ purchaseId }) =>
  purchaseId ? `rejected-purchase-${purchaseId}` : undefined

const showPendingPurchaseToast = ({ purchase }) => dispatch => {
  toast.info(
    <div>
      Your purchase{' '}
      <A
        href={`https://${urlPrefix}etherscan.io/tx/${
          purchase.ethereumTransactionHash
        }`}
        target="_blank"
      >
        {purchase.id}
      </A>{' '}
      is pending on the blockchain...
    </div>,
    {
      autoClose: false,
      closeOnClick: false,
    }
  )

  dispatch({
    type: SET_ACTIVE_TOAST,
    payload: {
      toastId: pendingPurchaseId2ToastId({ purchaseId: purchase.id }),
    },
  })
}

const showAcceptedPurchaseToast = ({ purchase }) => dispatch => {
  toast.success(
    <div>
      Your purchase{' '}
      <A
        href={`https://${urlPrefix}etherscan.io/tx/${
          purchase.ethereumTransactionHash
        }`}
        target="_blank"
      >
        {purchase.id}
      </A>{' '}
      was successfully confirmed on the blockchain
    </div>,
    {
      autoClose: false,
      closeOnClick: false,
    }
  )

  dispatch({
    type: SET_ACTIVE_TOAST,
    payload: {
      toastId: acceptedPurchaseId2ToastId({ purchaseId: purchase.id }),
    },
  })
}

const showRejectedPurchaseToast = ({ purchase }) => dispatch => {
  toast.error(
    <div>
      Your purchase{' '}
      <A
        href={`https://${urlPrefix}etherscan.io/tx/${
          purchase.ethereumTransactionHash
        }`}
        target="_blank"
      >
        {purchase.id}
      </A>{' '}
      was rejected by the blockchain
    </div>,
    {
      autoClose: false,
      closeOnClick: false,
    }
  )

  dispatch({
    type: SET_ACTIVE_TOAST,
    payload: {
      toastId: rejectedPurchaseId2ToastId({ purchaseId: purchase.id }),
    },
  })
}

const isAcceptedPurchaseToastActive = ({ activeToasts, isPending, purchase }) =>
  !activeToasts.includes(
    acceptedPurchaseId2ToastId({ purchaseId: purchase.id })
  ) &&
  !isPending &&
  purchase.isAccepted &&
  purchase.isConfirmed

const isPendingPurchaseToastActive = ({
  activeToasts,
  isPending,
  isPurchaseSuccess,
  purchase,
}) =>
  !activeToasts.includes(
    pendingPurchaseId2ToastId({ purchaseId: purchase.id })
  ) &&
  !isPending &&
  isPurchaseSuccess

const isRejectedPurchaseToastActive = ({ activeToasts, isPending, purchase }) =>
  !activeToasts.includes(
    rejectedPurchaseId2ToastId({ purchaseId: purchase.id })
  ) &&
  !isPending &&
  !purchase.isAccepted &&
  purchase.isConfirmed

const mapStateToProps = ({
  pay: {
    spendTokensStatus: { completedSteps, isPending, purchases },
  },
  ui: {
    toast: { activeToasts },
  },
}) => {
  const isPurchaseSuccess = completedSteps.PURCHASE_TOKENS_SUCCESS

  const acceptedPurchasesActiveToasts = purchases.filter(purchase =>
    isAcceptedPurchaseToastActive({
      activeToasts,
      isPending,
      purchase,
    })
  )

  const pendingPurchasesActiveToasts = purchases.filter(purchase =>
    isPendingPurchaseToastActive({
      activeToasts,
      isPending,
      isPurchaseSuccess,
      purchase,
    })
  )

  const rejectedPurchasesActiveToasts = purchases.filter(purchase =>
    isRejectedPurchaseToastActive({
      activeToasts,
      isPending,
      purchase,
    })
  )

  return {
    acceptedPurchasesActiveToasts,
    pendingPurchasesActiveToasts,
    rejectedPurchasesActiveToasts,
  }
}

const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = (
  {
    acceptedPurchasesActiveToasts,
    pendingPurchasesActiveToasts,
    rejectedPurchasesActiveToasts,
  },
  { dispatch }
) => ({
  showPendingPurchaseToast: pendingPurchasesActiveToasts.map(purchase =>
    dispatch(showPendingPurchaseToast({ purchase }))
  ),
  showAcceptedPurchaseToast: acceptedPurchasesActiveToasts.map(purchase =>
    dispatch(showAcceptedPurchaseToast({ purchase }))
  ),
  showRejectedPurchaseToast: rejectedPurchasesActiveToasts.map(purchase =>
    dispatch(showRejectedPurchaseToast({ purchase }))
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(() => null)
