import classnames from 'classnames'
import Link from 'redux-first-router-link'
import React from 'react'

import { toProjects, toProject } from 'store/routerActions'

export default ({ project, tokenSale }) => (
  <nav aria-label="breadcrumb">
    <ol className="breadcrumb">
      <li className="breadcrumb-item">
        <Link to={toProjects()}>Projects</Link>
      </li>

      <li
        className={classnames('breadcrumb-item', tokenSale ? '' : 'active')}
        aria-current="page"
      >
        <Link to={toProject(project)}> {project.name}</Link>
      </li>

      {tokenSale && (
        <>
          <li className="breadcrumb-item" aria-current="page">
            Token Sale
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            {tokenSale.name}
          </li>
        </>
      )}
    </ol>
  </nav>
)
