import { FormattedNumber } from 'react-intl'
import React from 'react'
import Link from 'redux-first-router-link'
import styled from 'styled-components/macro'

import { toProject } from '../../../store/routerActions'
import getTokenSaleProgress from '../helpers/getTokenSaleProgress'

import ProgressBar from 'app/components/ProgressBar'
import ProjectStatusBadge from 'app/components/ProjectStatusBadge'
import DateWrapper from 'app/components/DateWrapper'
import { Rate } from 'app/components/rating/Rating'
import Markdown from 'app/components/Markdown'

const ParticipantsCount = styled.span`
  color: ${props => props.theme.color.primaryPurple};
  font-weight: bolder;
`

const PriceInStar = styled.span`
  color: ${props => props.theme.color.primaryPurple};
  font-size: ${props => props.theme.font.xxl};
  font-weight: 'bolder';
`
const PriceInStarWrapper = styled.div`
  text-align: right;

  @media (min-width: ${props => props.theme.mediaQueries.md}) {
    text-align: left;
  }

  @media (min-width: ${props => props.theme.mediaQueries.lg}) {
    text-align: right;
  }
`

const StarPledged = styled.div`
  font-size: ${props => props.theme.font.xs};
  text-align: right;
`

const FigureColumn = styled.figure`
  background-color: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
  border-radius: 3px;
  position: relative;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.mediaQueries.lg}) {
    height: 356px;
    max-width: 550px !important;
    min-width: 550px !important;
    width: 550px !important;
  }
`

const ImageCropper = styled.img`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.mediaQueries.lg}) {
    left: 50%;
    min-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`
const CurrentPrice = styled.div`
  margin-bottom: 1rem;
  font-size: ${props => props.theme.font.sm};
`

const Card = styled(Link)`
  height: 100%;
  overflow: hidden;

  &:hover {
    box-shadow: 0 6px 14px rgba(87, 102, 222, 0.72);
    transform: translateY(-5px);
    transition: all 0.5s ease;
    text-decoration: none;
  }
`

const CardText = styled.div.attrs({
  className: 'text-muted mb-2',
})`
  overflow: hidden;
`

const CardTitle = styled.h3`
  overflow: hidden;
  position: relative;
  white-space: nowrap;
`

const StarIcon = styled.i.attrs({
  className: 'icon-star',
})`
  color: ${props => props.theme.color.primaryPurple};
  font-size: 13px;
  margin-left: -9px;
`

const BonusBadger = styled.span.attrs({
  className: 'badge',
})`
  && {
    font-size: 16px;
  }
`

export default ({
  project: {
    activeTokenSale,
    buyersCount,
    coverPicture,
    hasBounty,
    hasStakeDefinition,
    hasVoucherDefinition,
    name,
    slug,
    shortDescription,
    status,
    rating,
  },
}) => (
  <Card to={toProject({ slug })} className="card hover-shadow-lg">
    <FigureColumn className="card-header border-0 pb-0">
      <ImageCropper src={coverPicture} />
      <ProjectStatusBadge
        name={name}
        status={hasStakeDefinition ? 'STAKING' : status}
        index={slug}
        style={{
          zIndex: 999,
          position: 'relative',
        }}
      />
    </FigureColumn>

    <div className="card-body p-4">
      <div className="row justify-content-between mb-2">
        <div className="col-auto">
          <CardTitle>{name} </CardTitle>
        </div>
      </div>

      {status !== 'CANDIDATE' && (
        <ProgressBar
          progress={getTokenSaleProgress({
            status,
            tokenSale: activeTokenSale,
          })}
        />
      )}

      {activeTokenSale && (
        <CurrentPrice className="row justify-content-between align-items-end">
          <div className="col-6 col-md-12 col-lg-6">
            <ParticipantsCount>
              <FormattedNumber value={buyersCount} />
            </ParticipantsCount>{' '}
            <span>{buyersCount === 1 ? 'Participant' : 'Participants'}</span>
          </div>

          <PriceInStarWrapper className="col-6 col-md-12 col-lg-6">
            <strong className="text-muted">
              {activeTokenSale.tokenSymbol} ={' '}
            </strong>
            <PriceInStar>
              <FormattedNumber value={1 / activeTokenSale.starPrice} />{' '}
              <StarIcon />
            </PriceInStar>
          </PriceInStarWrapper>
        </CurrentPrice>
      )}

      <div className="row">
        <div className="col-12 text-left">
          {activeTokenSale && (
            <>
              <DateWrapper value={activeTokenSale.startDate} /> -{' '}
              <DateWrapper value={activeTokenSale.endDate} />
            </>
          )}
        </div>
      </div>

      {(status === 'CANDIDATE' ||
        status === 'SUCCESSFUL' ||
        'UNSUCCESSFUL') && (
        <CardText>
          <Markdown content={shortDescription} />
        </CardText>
      )}
    </div>

    {activeTokenSale && (
      <div class="card-footer">
        <div className="row">
          <div className="col-12">
            <StarPledged className="text-muted text-center">
              <FormattedNumber value={activeTokenSale.tokensSold} />{' '}
              <strong>{activeTokenSale.tokenSymbol}</strong> tokens were minted
              of <FormattedNumber value={activeTokenSale.tokenSaleCap} /> goal
            </StarPledged>
          </div>
        </div>
      </div>
    )}

    {(hasBounty || hasVoucherDefinition || hasStakeDefinition) && (
      <div class="card-footer">
        <div className="actions d-flex justify-content-between px-4">
          {hasBounty && (
            <BonusBadger className="badge-primary">Bounty</BonusBadger>
          )}

          {hasVoucherDefinition && (
            <BonusBadger className="badge-warning">Voucher</BonusBadger>
          )}

          {hasStakeDefinition && (
            <BonusBadger className="badge-success">Stake</BonusBadger>
          )}
        </div>
      </div>
    )}

    {rating.final > 0 && (
      <div class="card-footer">
        <div class="d-flex justify-content-center">
          <Rate
            size="small"
            total={5}
            rating={rating.final}
            interactive={false}
          />
        </div>
      </div>
    )}
  </Card>
)
