import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import MetaMaskImage from '../MetamaskImage'

const MetaMaskNetworkNotMainnetModal = () => (
  <Modal isOpen={true}>
    <ModalHeader>Oops, wrong network</ModalHeader>
    <ModalBody>
      <p>
        You need to connect to Ethereum Mainnet, please check your MetaMask
        network provider.
      </p>
      <p className="text-center">
        <MetaMaskImage />
      </p>
    </ModalBody>
  </Modal>
)

export default MetaMaskNetworkNotMainnetModal
