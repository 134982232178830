import {
  FETCH_BONUS_TOKEN_BALANCE_SUCCESS,
  FETCH_BONUS_TOKEN_BALANCE_FAILURE,
  WITHDRAW_BONUS_TOKENS_OPEN_MODAL,
  WITHDRAW_BONUS_TOKENS_CLOSE_MODAL,
  WITHDRAW_BONUS_TOKENS_START,
  WITHDRAW_BONUS_TOKENS_TRANSACTION_PENDING,
  WITHDRAW_BONUS_TOKENS_FAILURE,
  WITHDRAW_BONUS_TOKENS_DISABLE_BUTTON,
  WITHDRAW_BONUS_TOKENS_ENABLE_BUTTON,
} from '../types'

const initialState = {
  error: null,
  status: {
    isModalOpen: false,
    completedSteps: {
      WITHDRAW_BONUS_TOKENS_START: false,
      WITHDRAW_BONUS_TOKENS_TRANSACTION_PENDING: false,
      WITHDRAW_BONUS_TOKENS_FAILURE: false,
    },
    error: null,
  },
}

export default (state = initialState, { type, payload, error }) => {
  switch (type) {
    case WITHDRAW_BONUS_TOKENS_OPEN_MODAL:
      return {
        ...state,
        status: {
          ...state.status,
          isModalOpen: true,
        },
        openClaim: payload,
      }

    case WITHDRAW_BONUS_TOKENS_CLOSE_MODAL:
      return {
        ...state,
        status: {
          completedSteps: {
            WITHDRAW_BONUS_TOKENS_START: false,
            WITHDRAW_BONUS_TOKENS_TRANSACTION_PENDING: false,
            WITHDRAW_BONUS_TOKENS_FAILURE: false,
          },
          error: null,
          isModalOpen: false,
        },
      }

    case FETCH_BONUS_TOKEN_BALANCE_SUCCESS:
      return {
        ...state,
        ...payload,
      }

    case FETCH_BONUS_TOKEN_BALANCE_FAILURE:
      return { ...state, error }

    case WITHDRAW_BONUS_TOKENS_START:
    case WITHDRAW_BONUS_TOKENS_TRANSACTION_PENDING:
      return {
        ...state,
        status: {
          ...state.status,
          completedSteps: {
            ...state.status.completedSteps,
            [type]: true,
          },
        },
      }

    case WITHDRAW_BONUS_TOKENS_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          completedSteps: {
            ...state.status.completedSteps,
            [type]: true,
            error,
          },
        },
      }

    case WITHDRAW_BONUS_TOKENS_DISABLE_BUTTON:
      return {
        ...state,
        [payload]: { ...state[payload], disabled: true },
      }

    case WITHDRAW_BONUS_TOKENS_ENABLE_BUTTON:
      return {
        ...state,
        [payload]: { ...state[payload], disabled: false },
      }

    default:
      return state
  }
}
