import ReactGA from 'react-ga'
import store from 'store/store'

const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID
ReactGA.initialize(trackingId)

let previousPathName

export default () =>
  store.subscribe(() => {
    const { pathname } = store.getState().location
    if (previousPathName !== pathname) ReactGA.pageview(pathname)
    previousPathName = pathname
  })
