import {
  CANCEL_PURCHASE_REQUEST,
  CANCEL_PURCHASE_SUCCESS,
  CANCEL_PURCHASE_FAILURE,
  PURCHASE_TOKENS_ACCEPTED,
  PURCHASE_TOKENS_REJECTED,
} from '../../types'

import * as api from '../../../api'
import captureException from '../../../sentryHelpers/captureException'

export { spendStarTokens, spendEthTokens } from './spendTokens'

export const cancelPurchase = ({ purchaseId }) => async dispatch => {
  dispatch({ type: CANCEL_PURCHASE_REQUEST, purchaseId })

  return api
    .postCancelledPurchase({ purchaseId })
    .then(({ purchase }) =>
      dispatch({ type: CANCEL_PURCHASE_SUCCESS, purchase })
    )
    .catch(error => {
      captureException(error)
      dispatch({ type: CANCEL_PURCHASE_FAILURE, error: error.message })
    })
}

export const setPurchaseAccepted = ({ purchase }) => async dispatch =>
  dispatch({
    type: PURCHASE_TOKENS_ACCEPTED,
    payload: { purchase },
  })

export const setPurchaseRejected = ({ purchase }) => async dispatch =>
  dispatch({
    type: PURCHASE_TOKENS_REJECTED,
    payload: { purchase },
  })
