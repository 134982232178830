import { set } from 'lodash/fp'
import orderBy from 'lodash/orderBy'

import {
  APPROVAL_TRANSACTION_CONFIRMED,
  APPROVAL_TRANSACTION_PENDING,
  CLEAR_STAKE_ERROR,
  FETCH_STAKE_DEFINITION_FAILURE,
  FETCH_STAKE_DEFINITION_REQUEST,
  FETCH_STAKE_DEFINITION_SUCCESS,
  FETCH_STAKE_NEW_POSITION_SUCCESS,
  METAMASK_APPROVE_TRANSACTION_START,
  METAMASK_APPROVE_TRANSACTION_SUCCESS,
  METAMASK_STAKE_TOKENS_TRANSACTION_START,
  METAMASK_STAKE_TOKENS_TRANSACTION_SUCCESS,
  METAMASK_WITHDRAW_STAKE_TOKENS_TRANSACTION_START,
  METAMASK_WITHDRAW_STAKE_TOKENS_TRANSACTION_SUCCESS,
  STAKE_TOKENS_CLOSE_MODAL,
  STAKE_TOKENS_CLOSE_USER_NOT_ACCEPTED_MODAL,
  STAKE_TOKENS_CLOSE_USER_NOT_AUTHENTICATED_MODAL,
  STAKE_TOKENS_CLOSE_USER_NOT_REGISTERED_MODAL,
  STAKE_TOKENS_FAILURE,
  STAKE_TOKENS_OPEN_MODAL,
  STAKE_TOKENS_OPEN_USER_NOT_ACCEPTED_MODAL,
  STAKE_TOKENS_OPEN_USER_NOT_AUTHENTICATED_MODAL,
  STAKE_TOKENS_OPEN_USER_NOT_REGISTERED_MODAL,
  STAKE_TOKENS_START,
  STAKE_TOKENS_SUCCESS,
  STAKE_TOKENS_TRANSACTION_CONFIRMED,
  STAKE_TOKENS_TRANSACTION_PENDING,
  WITHDRAW_STAKE_TOKENS_CLOSE_MODAL,
  WITHDRAW_STAKE_TOKENS_FAILURE,
  WITHDRAW_STAKE_TOKENS_OPEN_MODAL,
  WITHDRAW_STAKE_TOKENS_START,
  WITHDRAW_STAKE_TOKENS_SUCCESS,
  WITHDRAW_STAKE_TOKENS_TRANSACTION_CONFIRMED,
  WITHDRAW_STAKE_TOKENS_TRANSACTION_PENDING,
} from 'store/types'

const stakeSteps = [
  STAKE_TOKENS_START,
  METAMASK_APPROVE_TRANSACTION_START,
  METAMASK_APPROVE_TRANSACTION_SUCCESS,
  APPROVAL_TRANSACTION_PENDING,
  APPROVAL_TRANSACTION_CONFIRMED,
  METAMASK_STAKE_TOKENS_TRANSACTION_START,
  METAMASK_STAKE_TOKENS_TRANSACTION_SUCCESS,
  STAKE_TOKENS_TRANSACTION_PENDING,
  STAKE_TOKENS_TRANSACTION_CONFIRMED,
  STAKE_TOKENS_SUCCESS,
  STAKE_TOKENS_FAILURE,
]

const withdrawSteps = [
  WITHDRAW_STAKE_TOKENS_START,
  METAMASK_WITHDRAW_STAKE_TOKENS_TRANSACTION_START,
  METAMASK_WITHDRAW_STAKE_TOKENS_TRANSACTION_SUCCESS,
  WITHDRAW_STAKE_TOKENS_TRANSACTION_PENDING,
  WITHDRAW_STAKE_TOKENS_TRANSACTION_CONFIRMED,
  WITHDRAW_STAKE_TOKENS_SUCCESS,
  WITHDRAW_STAKE_TOKENS_FAILURE,
]

const initialState = {
  error: null,
  isLoading: false,
  stakeDefinitions: [],
  stakeTokensStatus: {
    completedSteps: {},
    error: null,
    isPending: false,
    isStakeModalOpen: false,
    isUserNotAuthenticatedModalOpen: false,
    isUserNotAcceptedModalOpen: false,
    isUserNotRegisteredModalOpen: false,
  },
  withdrawStakeTokensStatus: {
    completedSteps: {},
    error: null,
    isModalOpen: false,
  },
}

const stakeReducer = (state = initialState, { type, payload, error }) => {
  switch (type) {
    case STAKE_TOKENS_START:
      return {
        ...state,
        stakeTokensStatus: {
          ...state.stakeTokensStatus,
          error: null,
          isPending: true,
        },
      }

    case STAKE_TOKENS_SUCCESS:
      return {
        ...state,
        stakeTokensStatus: {
          ...state.stakeTokensStatus,
          error: null,
          isPending: false,
        },
      }
    case STAKE_TOKENS_FAILURE:
      return {
        ...state,
        stakeTokensStatus: {
          ...state.stakeTokensStatus,
          error,
          isPending: false,
        },
      }
    case STAKE_TOKENS_CLOSE_MODAL:
      return {
        ...state,
        stakeTokensStatus: {
          ...state.stakeTokensStatus,
          isStakeModalOpen: false,
        },
      }

    case STAKE_TOKENS_OPEN_MODAL:
      return {
        ...state,
        stakeTokensStatus: {
          ...state.stakeTokensStatus,
          isStakeModalOpen: true,
        },
      }

    case STAKE_TOKENS_CLOSE_USER_NOT_ACCEPTED_MODAL:
      return {
        ...state,
        stakeTokensStatus: {
          ...state.stakeTokensStatus,
          isUserNotAcceptedModalOpen: false,
        },
      }

    case STAKE_TOKENS_OPEN_USER_NOT_ACCEPTED_MODAL:
      return {
        ...state,
        stakeTokensStatus: {
          ...state.stakeTokensStatus,
          isUserNotAcceptedModalOpen: true,
        },
      }

    case STAKE_TOKENS_CLOSE_USER_NOT_AUTHENTICATED_MODAL:
      return {
        ...state,
        stakeTokensStatus: {
          ...state.stakeTokensStatus,
          isUserNotAuthenticatedModalOpen: false,
        },
      }

    case STAKE_TOKENS_OPEN_USER_NOT_AUTHENTICATED_MODAL:
      return {
        ...state,
        stakeTokensStatus: {
          ...state.stakeTokensStatus,
          isUserNotAuthenticatedModalOpen: true,
        },
      }

    case STAKE_TOKENS_CLOSE_USER_NOT_REGISTERED_MODAL:
      return {
        ...state,
        stakeTokensStatus: {
          ...state.stakeTokensStatus,
          isUserNotRegisteredModalOpen: false,
        },
      }

    case STAKE_TOKENS_OPEN_USER_NOT_REGISTERED_MODAL:
      return {
        ...state,
        stakeTokensStatus: {
          ...state.stakeTokensStatus,
          isUserNotRegisteredModalOpen: true,
        },
      }

    case CLEAR_STAKE_ERROR:
      return { ...state, error: null }

    case FETCH_STAKE_DEFINITION_REQUEST:
      return {
        ...state,
        isLoading: true,
      }

    case FETCH_STAKE_DEFINITION_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
        stakeDefinitions: payload.stakeDefinitions.map(def => ({
          ...def,
          previewTopRank: def.topRank,
        })),
      }

    case FETCH_STAKE_DEFINITION_FAILURE:
      return { ...state, error, isLoading: false }

    case FETCH_STAKE_NEW_POSITION_SUCCESS:
      const isCurrentUserInRank = ({ topRank }) =>
        topRank.find(
          ({ ethereumAddress }) => ethereumAddress === payload.ethereumAddress
        )

      const addCurrentUserToRank = ({ topRank }) => [...topRank, payload]

      const replaceCurrentUserInRank = ({ topRank }) => [
        ...topRank.map(rank =>
          rank.ethereumAddress === payload.ethereumAddress ? payload : rank
        ),
      ]

      const getNewRank = def =>
        orderBy(
          isCurrentUserInRank(def)
            ? replaceCurrentUserInRank(def)
            : addCurrentUserToRank(def),
          ['points', 'staked'],
          'desc'
        )

      return {
        ...state,
        error: null,
        isLoading: false,
        stakeDefinitions: [
          ...state.stakeDefinitions.map(def =>
            def.isCurrentStake
              ? {
                  ...def,
                  previewTopRank: getNewRank(def),
                }
              : def
          ),
        ],
      }

    case WITHDRAW_STAKE_TOKENS_OPEN_MODAL:
      return {
        ...state,
        withdrawStakeTokensStatus: {
          ...state.withdrawStakeTokensStatus,
          isModalOpen: true,
        },
      }

    case WITHDRAW_STAKE_TOKENS_CLOSE_MODAL:
      return {
        ...state,
        withdrawStakeTokensStatus: {
          ...state.withdrawStakeTokensStatus,
          isModalOpen: false,
        },
      }

    case WITHDRAW_STAKE_TOKENS_FAILURE:
      return {
        ...state,
        withdrawStakeTokensStatus: {
          ...state.withdrawStakeTokensStatus,
          error,
          isModalOpen: true,
        },
      }

    default:
      return state
  }
}

const injectCompletedSteps = ({
  state,
  action,
  steps,
  statusName,
  startType,
  closeType,
}) => {
  if (action.type === startType) {
    return set([statusName, 'completedSteps'], {
      [action.type]: true,
    })(state)
  }

  if (action.type === closeType)
    return set([statusName, 'completedSteps'], {})(state)

  if (steps.includes(action.type))
    return set([statusName, 'completedSteps', action.type], true)(state)

  return state
}

export default (state, action) => {
  const originalResult = stakeReducer(state, action)
  const stateWithStakeCompletedSteps = injectCompletedSteps({
    state: originalResult,
    action: action,
    steps: stakeSteps,
    statusName: 'stakeTokensStatus',
    startType: STAKE_TOKENS_START,
    closeType: STAKE_TOKENS_CLOSE_MODAL,
  })

  const stateAllSteps = injectCompletedSteps({
    state: stateWithStakeCompletedSteps,
    action: action,
    steps: withdrawSteps,
    statusName: 'withdrawStakeTokensStatus',
    startType: WITHDRAW_STAKE_TOKENS_START,
    closeType: WITHDRAW_STAKE_TOKENS_CLOSE_MODAL,
  })

  return stateAllSteps
}
