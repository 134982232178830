import React from 'react'
import { Modal, ModalBody } from 'reactstrap'

import WarningIcon from 'app/components/WarningIcon'

export default ({ close, isOpen, minutesRemaining }) => (
  <Modal isOpen={isOpen}>
    <ModalBody>
      <div className="text-center">
        <div className="alert alert-warning">
          <WarningIcon />
          <p>
            Your will be logged out automatically in {minutesRemaining} minutes.
          </p>
        </div>

        <p>
          For your security, your session is closed automatically after a
          certain time.
        </p>

        <p className="small">
          To continue using the platform, please logout and login again.
        </p>

        <button className="btn btn-primary" onClick={close}>
          I understand, close this dialog
        </button>
      </div>
    </ModalBody>
  </Modal>
)
