import React from 'react'

import TableRow from 'app/components/TableRow'
import MobileLabel from 'app/components/MobileLabel'

import WithdrawBonusTokenModal from './WithdrawBonusTokenModalContainer'
import WithdrawButton from './WithdrawButton'

const LOADING_TEXT = 'Loading...'

const TableRowWrapper = ({
  balance,
  buttonDisabled,
  campaign,
  ethereumAddress,
  onOpenModal,
}) => (
  <TableRow className="row align-items-center justify-content-start">
    <div className="col-4 text-center">
      <MobileLabel className="d-block d-md-block">Balance</MobileLabel>
      {balance}
    </div>
    <div className="col-4 text-center">
      <MobileLabel className="d-block d-md-block">Token</MobileLabel>
      {campaign.tokenSymbol}
    </div>
    <div className="col-4 text-center">
      <WithdrawButton
        disabled={buttonDisabled}
        type="button"
        className="btn btn-outline"
        onClick={() =>
          onOpenModal({
            balance,
            ethereumAddress,
            ...campaign,
          })
        }
      >
        Withdraw Bonus Tokens
      </WithdrawButton>
    </div>
  </TableRow>
)

export default class WithdrawBonusTokenButton extends React.Component {
  render() {
    const {
      campaigns,
      ethereumAddress,
      isModalOpen,
      onToggle,
      onOpenModal,
      withdrawBonusTokens,
    } = this.props

    return (
      <>
        <h5>Available bonus tokens</h5>
        <WithdrawBonusTokenModal isOpen={isModalOpen} onToggle={onToggle} />

        {campaigns.map(campaign => {
          const { bonusTokenAddress } = campaign
          const bonusTokenData = this.getBonusTokenData({
            bonusTokenAddress,
            withdrawBonusTokens,
          })
          const balance = this.getTokenBalance(bonusTokenData)
          const buttonDisabled = !!(
            !bonusTokenData ||
            bonusTokenData.disabled ||
            balance === LOADING_TEXT ||
            balance === '0'
          )

          return (
            <TableRowWrapper
              balance={balance}
              buttonDisabled={buttonDisabled}
              campaign={campaign}
              ethereumAddress={ethereumAddress}
              key={bonusTokenAddress}
              onOpenModal={onOpenModal}
            />
          )
        })}
      </>
    )
  }

  componentDidMount() {
    const { campaigns, ethereumAddress, fetchBonusTokensBalances } = this.props

    fetchBonusTokensBalances({ campaigns, ethereumAddress })
  }

  getTokenBalance(bonusTokenData) {
    if (!bonusTokenData) return LOADING_TEXT

    return bonusTokenData.balance
  }

  getBonusTokenData({ bonusTokenAddress, withdrawBonusTokens }) {
    return withdrawBonusTokens[bonusTokenAddress]
  }
}
