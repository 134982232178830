import { SET_DEV_PREVIEW } from '../types'

const initialState = {
  preview: null,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_DEV_PREVIEW:
      console.log({ payload })

      return {
        ...state,
        preview: payload,
      }

    default:
      return state
  }
}
