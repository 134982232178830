import { connect } from 'react-redux'
import { reduxForm, change } from 'redux-form'

import captureException from '../../../../../sentryHelpers/captureException'
import ProofOfResidencyForm from './ProofOfResidencyForm'
import { postOnfidoCreateApplicant } from '../../../../../api'
import { toProfile } from '../../../../../store/routerActions'
import uploadResidencyProofToS3 from './helpers/uploadResidencyProofToS3'

const mapStateToProps = ({
  form: {
    profileForm: { submitting },
  },
  profile: { allowedResidencyCountries },
}) => ({
  allowedResidencyCountries,
  isUploading: submitting,
})

const mapDispatchToProps = dispatch => ({
  onfidoApplicantSuccess: ({ onfidoApplicantId }) =>
    dispatch(change('profileForm', 'onfidoApplicantId', onfidoApplicantId)),
  onSuccess: () => dispatch(toProfile({ step: 3 })),
  onS3UploadSuccess: ({ s3Key }) =>
    dispatch(change('profileForm', 'residencyProof', s3Key)),
})

const mergeProps = (
  { allowedResidencyCountries, isUploading },
  { onSuccess, onfidoApplicantSuccess, onS3UploadSuccess, ...dispatchProps },
  { handleSubmit, ...ownProps }
) => ({
  allowedResidencyCountries,
  isUploading,
  ...dispatchProps,
  ...ownProps,
  onSubmit: handleSubmit(async values => {
    try {
      const file = values.residencyProofFile[0]
      const s3Key = await uploadResidencyProofToS3({ file })
      onS3UploadSuccess({ s3Key })

      const applicant = await postOnfidoCreateApplicant(values)
      onfidoApplicantSuccess({ onfidoApplicantId: applicant.id })
      onSuccess()
    } catch (error) {
      captureException(error)
    }
  }),
})

export default reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  form: 'profileForm',
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(ProofOfResidencyForm)
)
