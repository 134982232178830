import { connect } from 'react-redux'

import auth from '../../auth'
import selectIdTokenNamePhotoEmail from 'store/selectors/selectIdTokenNamePhotoEmail'
import selectIsUserRegistered from 'store/selectors/selectIsUserRegistered'

import Header from './Header'

const mapStateToProps = state => ({
  isUserAuthenticated:
    auth.isAuthenticated() && selectIdTokenNamePhotoEmail(state),
  isUserRegistered: selectIsUserRegistered(state),
  projects: state.projects.items.map(project => ({
    ...project,
    activeTokenSale: project.tokenSales.find(ts => ts.isActive) || null,
  })),
  scene: state.location.type,
  bounties: state.bounties.allBountyTasks,
})

const mapDispatchToProps = () => ({
  login: auth.login,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
