import { createSelector } from 'reselect'

const selectPurchases = state => state.pay.spendTokensStatus.purchases

export default createSelector(
  selectPurchases,
  result => {
    return result
  }
)
