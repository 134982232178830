import {
  CLEAR_PROJECTS_ERROR,
  FETCH_PROJECTS_FAILURE,
  FETCH_PROJECTS_REQUEST,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECT_FAILURE,
  FETCH_PROJECT_SUCCESS,
} from '../types'

export const initialState = {
  error: null,
  isLoading: true,
  items: [],
}

export default (state = initialState, { type, payload, error }) => {
  switch (type) {
    case FETCH_PROJECTS_REQUEST:
      return { ...state, isLoading: true }

    case FETCH_PROJECT_SUCCESS:
      return {
        ...state,
        items: [
          ...new Set([
            ...state.items.map(project =>
              project.slug === payload.project.slug ? payload.project : project
            ),
            payload.project,
          ]),
        ],
      }

    case FETCH_PROJECT_FAILURE:
      return { ...state, error }

    case FETCH_PROJECTS_SUCCESS:
      return { ...state, isLoading: false, items: payload.projects }

    case FETCH_PROJECTS_FAILURE:
      return { ...state, isLoading: false, error }

    case CLEAR_PROJECTS_ERROR:
      return { ...state, error: null }

    default:
      return state
  }
}
