import React from 'react'
import styled from 'styled-components/macro'
import Link from 'redux-first-router-link'
import { UncontrolledTooltip } from 'reactstrap'

import formatDate from 'app/scenes/helpers/formatDate'
import { toProject } from 'store/routerActions'

import TableRow from 'app/components/TableRow'
import MobileLabel from 'app/components/MobileLabel'

const BountyStatusBadge = styled.span.attrs({
  className: 'badge',
})`
  && {
    padding: 0.5em 1em 0.5em 1em;
    letter-spacing: 0.05em;
    color: white;
    font-size: ${props => props.theme.font.xs};
    vertical-align: middle;
    background-color: ${props =>
      props.status === 'PENDING'
        ? props.theme.color.yellow
        : props.status === 'ACCEPTED'
        ? props.theme.color.greenSuccess
        : props.theme.color.red};
  }
`

const Icon = styled.i`
  font-size: ${props => props.theme.font.xs};
  color: ${props => props.theme.color.white};
  top: 3px;
  position: relative;
`

const DeliveriedReward = styled.a.attrs({
  className: 'badge',
})`
  text-transform: uppercase;
  &&& {
    padding: 0.5em 1em 0.5em 1em;
    letter-spacing: 0.05em;
    color: white;
    font-size: ${props => props.theme.font.xs};
    vertical-align: middle;
    background-color: ${props => props.theme.color.greenSuccess};
  }
`

const DeliveriedRewardBadge = ({ href }) => (
  <DeliveriedReward href={href} target="_blank">
    delivered
    <Icon className="icon-link-ext" />
  </DeliveriedReward>
)

const BountyStatus = ({ bountyClaimId, status, reason }) => (
  <BountyStatusBadge status={status} id={`status-${bountyClaimId}`}>
    {status}
    {status === 'REJECTED' && (
      <>
        <Icon className="icon-info-circled" />
        <UncontrolledTooltip
          autohide={false}
          placement="top"
          target={`status-${bountyClaimId}`}
        >
          <span className="col-12 text-center">
            <b className="text-muted">Reason:</b>
            <br />
            <span>{reason}</span>
          </span>
        </UncontrolledTooltip>
      </>
    )}
  </BountyStatusBadge>
)

const BountyClaim = ({
  bountyClaim: {
    id,
    proofUrl,
    rewardTransactionHash,
    status,
    poComment,
    createdAt,
    bountyTask,
  },
}) => (
  <TableRow key={id} className="row align-items-center justify-content-start">
    <div className="col-6 col-sm-4 col-md-2">
      <MobileLabel className="d-block d-md-block">Submitted At</MobileLabel>
      {formatDate(createdAt)}
    </div>

    <div className="col-6 col-sm-4 col-md-2">
      <MobileLabel className="d-block d-md-block">Bounty</MobileLabel>
      {bountyTask.name}
    </div>

    <div className="col-6 col-sm-4 col-md-2">
      <MobileLabel className="d-block d-md-block">Project</MobileLabel>
      <Link to={toProject(bountyTask.bountyDefinition.project)}>
        {bountyTask.bountyDefinition.project.name}
      </Link>
    </div>

    <div
      className="col-6 col-sm-8 col-md-4"
      style={{ wordBreak: 'break-word' }}
    >
      <MobileLabel className="d-block d-md-block">Proof Url</MobileLabel>
      <a href={proofUrl} target="_blank" rel="noopener noreferrer">
        {proofUrl}
      </a>
    </div>

    <div className="col-12 col-sm-3 col-md-2">
      <MobileLabel className="d-block d-md-block">Status</MobileLabel>
      {rewardTransactionHash ? (
        <DeliveriedRewardBadge
          href={`https://etherscan.io/tx/${rewardTransactionHash}`}
        />
      ) : (
        <BountyStatus status={status} bountyClaimId={id} reason={poComment} />
      )}
    </div>
  </TableRow>
)

export default ({ bountyClaims }) => (
  <div className="mb-4">
    <h5>Your bounty claims</h5>

    <div>
      {bountyClaims.length === 0 && (
        <div className="alert alert-light text-center" role="alert">
          No bounty claims were made
        </div>
      )}

      {bountyClaims.map((bountyClaim, i) => (
        <BountyClaim key={i} bountyClaim={bountyClaim} />
      ))}
    </div>
  </div>
)
