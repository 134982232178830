export default ({
  ethWeiValue,
  ethereumAddressFrom,
  tokenSaleContractAbi,
  tokenSaleContractAddress,
}) => {
  const { web3 } = window

  const contract = new web3.eth.Contract(
    JSON.parse(tokenSaleContractAbi),
    tokenSaleContractAddress
  )

  const sendObject = ethWeiValue
    ? { from: ethereumAddressFrom, value: ethWeiValue }
    : { from: ethereumAddressFrom }

  return contract.methods.buyTokens(ethereumAddressFrom).send(sendObject)
}
