import { connect } from 'react-redux'
import { reduxForm, change } from 'redux-form'
import get from 'lodash/get'
import selectIsUserAccepted from 'store/selectors/selectIsUserAccepted'
import selectProjectBySlug from 'store/selectors/selectProjectBySlug'
import * as api from 'api.js'
import Rating from './Rating'

const mapStateToProps = (state, { project }) => {
  return {
    isUserAccepted: selectIsUserAccepted(state),
    project: selectProjectBySlug(state),
    rating: get(project, 'rating', {
      final: 0,
      idea: 0,
      team: 0,
      website: 0,
      whitepaper: 0,
    }),
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
})

const mergeProps = (
  { isUserAccepted, rating, project },
  { dispatch },
  { handleSubmit }
) => ({
  isUserAccepted,
  rating,
  onRate: (fieldName, rating) =>
    dispatch(change('ratingForm', fieldName, rating)),
  onSubmit: handleSubmit(values =>
    api
      .postRating({ ...values, projectSlug: project.slug })
      .then(() => {
        alert('Review was submitted! Thank you for! ')
        setTimeout(() => window.location.reload(true), 100)
      })
      .catch(error => {
        console.log(error.message)
        if (error.message === 'RATING_CAN_ONLY_BE_CLAIMED_ONCE_SERVER_ERROR')
          alert('You can only review a project once')
        else if (
          error.message ===
          'PROJECT_OWNERS_CANNOT_SUBMIT_OWN_RATING_SERVER_ERROR'
        )
          alert('You can not review your own project')
        else alert('Error when submiting your review. Try again later')
      })
  ),
})

export default reduxForm({
  form: 'ratingForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  initialValues: {
    ratingIdea: 0,
    ratingTeam: 0,
    ratingWebsite: 0,
    ratingWhitepaper: 0,
  },
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(Rating)
)
