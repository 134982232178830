import React from 'react'

import Link from 'redux-first-router-link'
import { toPrivacy, toTermsOfUse } from '../../../store/routerActions'

export default () => (
  <div class="footer pt-5 pb-4 footer-light" id="footer-main">
    <div class="row text-center text-sm-left align-items-sm-center">
      <div class="col-sm-6">
        <p class="text-sm mb-0">
          Copyright © 2018 Starbase PTE LTD - Singapore - Earth
        </p>
      </div>
      <div class="col-sm-6 mb-md-0">
        <ul class="nav justify-content-center justify-content-md-end">
          <li class="nav-item dropdown border-right" />
          <li class="nav-item">
            <a
              className="nav-link"
              href="https://telegram.me/starbase"
              target="_blank"
              rel="noopener noreferrer"
            >
              Join our community on Telegram
            </a>
          </li>
          <li class="nav-item">
            <Link className="nav-link" to={toPrivacy()}>
              Privacy
            </Link>
          </li>
          <li class="nav-item">
            <Link className="nav-link" to={toTermsOfUse()}>
              Terms of Use
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </div>
)
