import Link from 'redux-first-router-link'
import React from 'react'

import { toUserProfile } from '../../../../../store/routerActions'

export default ({ user, isUserAuthenticated, isUserRegistered }) =>
  isUserRegistered ? (
    <div>
      <p>Thank you for registering!</p>
      <p>Big things coming soon. Stay tuned!</p>
    </div>
  ) : (
    <React.Fragment>
      <p>
        Hello {user.firstName} {user.lastName}.
      </p>

      <p>You haven't created your profile yet.</p>

      <p>
        <small>
          All of the following steps are mandatory for the KYC process.
        </small>
        <br />
        <small>
          You have to complete each one of them in order to become a verified
          user.
        </small>
      </p>

      {!isUserAuthenticated && (
        <p>
          <small>
            As a first necessary step, you will be invited to log in with a
            method of your choice.
          </small>
        </p>
      )}

      <br />

      <Link className="btn btn-primary btn-lg" to={toUserProfile()}>
        Create your profile
      </Link>
    </React.Fragment>
  )
