import React from 'react'
import styled from 'styled-components/macro'
import Link from 'redux-first-router-link'
import { UncontrolledTooltip } from 'reactstrap'
import { FormattedNumber } from 'react-intl'

import PurchaseBadgeStatus from './PurchaseBadgeStatus'

import formatDate from 'app/scenes/helpers/formatDate'
import { toProject } from 'store/routerActions'

import TableRow from 'app/components/TableRow'
import MobileLabel from 'app/components/MobileLabel'

const Icon = styled.i`
  font-size: ${props => props.theme.font.tiny};
  ${props =>
    props.purchaseCurrency === 'ETH' &&
    'margin-right: -3px;'} position: relative;
  top: 1px;
`
const CurrencyBadge = styled.small.attrs({
  className: props =>
    props.currency === 'STAR'
      ? 'badge badge-primary'
      : props.currency === 'ETH'
      ? 'badge badge-secondary'
      : 'badge badge-dark',
})`
  && {
    width: 100%;
    font-size: ${props => props.theme.font.sm};
    padding: 12px 5px;
  }
`

const Purchases = ({ purchases }) => (
  <div className="mb-4">
    <h4>Purchases</h4>

    <div>
      {purchases.length === 0 && (
        <div className="alert alert-light text-center" role="alert">
          No purchases were made
        </div>
      )}

      {purchases.map(purchase => (
        <TableRow
          key={purchase.ethereumTransactionHash}
          className="row align-items-center justify-content-start"
        >
          <div className="col-md-12 col-lg-3">
            <MobileLabel className="d-block d-md-block">Project</MobileLabel>
            <Link to={toProject(purchase.project)}>
              {purchase.project.name}
            </Link>
          </div>

          <div
            className="col-md-6 col-lg-2"
            id={`costPurchase${purchase.ethereumTransactionHash}`}
          >
            <MobileLabel className="d-block d-md-block">Cost</MobileLabel>
            <CurrencyBadge currency={purchase.purchaseCurrency}>
              {purchase.purchaseValue}{' '}
              <Icon
                purchaseCurrency={purchase.purchaseCurrency}
                className={
                  purchase.purchaseCurrency === 'STAR'
                    ? 'icon-star'
                    : 'icon-ethereum'
                }
              />{' '}
            </CurrencyBadge>
          </div>

          <div className="col-md-6 col-lg-2">
            <MobileLabel className="d-block d-md-block">Amount</MobileLabel>
            <CurrencyBadge currency={purchase.tokenSymbol}>
              <FormattedNumber
                maximumFractionDigits={8}
                minimumFractionDigits={2}
                value={purchase.amount}
              />{' '}
              {purchase.tokenSymbol}
            </CurrencyBadge>
          </div>

          <div className="col-md-6 col-lg-2">
            <MobileLabel className="d-block d-md-block">Status</MobileLabel>
            <PurchaseBadgeStatus
              status={purchase.status}
              url={`https://etherscan.io/tx/${
                purchase.ethereumTransactionHash
              }`}
            />
            <UncontrolledTooltip
              autohide={false}
              placement="top"
              target={`costPurchase${purchase.ethereumTransactionHash}`}
            >
              <span className="col-12">
                <span className="text-muted">Price: </span>1{' '}
                <Icon
                  purchaseCurrency={purchase.purchaseCurrency}
                  className={
                    purchase.purchaseCurrency === 'STAR'
                      ? 'icon-star'
                      : 'icon-ethereum'
                  }
                />{' '}
                = {purchase.tokenPrice} {purchase.tokenSymbol}
              </span>
            </UncontrolledTooltip>
          </div>

          <div className="col-md-6 col-lg-3">
            <MobileLabel className="d-block d-md-block">Date</MobileLabel>
            {formatDate(purchase.createdAt)}
          </div>
        </TableRow>
      ))}
    </div>
  </div>
)

export default Purchases
