import isEmpty from 'lodash/isEmpty'

import auth from '../app/auth'
import pollMetaMaskSync from './pollMetaMaskSync'
import store from '../store/store'

let fetchBalancesIntervalValue
let isSyncing
let syncMetaMaskIntervalValue

const startPolling = () => {
  pollMetaMaskSync().then(({ syncMetaMaskInterval, fetchBalancesInterval }) => {
    syncMetaMaskIntervalValue = syncMetaMaskInterval
    fetchBalancesIntervalValue = fetchBalancesInterval
  })
}

const stopPolling = () => {
  clearInterval(syncMetaMaskIntervalValue)
  clearInterval(fetchBalancesIntervalValue)
}

export default () =>
  store.subscribe(() => {
    const { authResult } = store.getState().authData
    const isAuthenticated = !isEmpty(authResult) && auth.isAuthenticated()

    if (isAuthenticated && !isSyncing) {
      isSyncing = true
      startPolling()
    } else if (!isAuthenticated && isSyncing) {
      isSyncing = false
      stopPolling()
    }
  })
