import padStart from 'lodash/padStart'

import {
  FETCH_ALLOWED_COUNTRIES_FAILURE,
  FETCH_ALLOWED_COUNTRIES_REQUEST,
  FETCH_ALLOWED_COUNTRIES_SUCCESS,
  POST_PROFILE_FORM_FAILURE,
  POST_PROFILE_FORM_REQUEST,
  POST_PROFILE_FORM_SUCCESS,
  TERMS_OF_USE_READING_COMPLETED,
  TERMS_OF_USE_SHOW_SCROLL_INCOMPLETE_TIP,
} from '../types'

import * as api from '../../api'
import captureException from '../../sentryHelpers/captureException'

import { openModal } from 'store/ui/uiActions'
import { toProfile } from '../routerActions'

export const confirmTermsOfUseScrollComplete = () => ({
  type: TERMS_OF_USE_READING_COMPLETED,
})

export const showTermsOfUseScrollIncompleteTip = () => ({
  type: TERMS_OF_USE_SHOW_SCROLL_INCOMPLETE_TIP,
})

export const postProfileForm = originalProfileForm => async dispatch => {
  const cleanupBirthdate = profileForm => ({
    ...profileForm,
    day: padStart(profileForm.day, 2, '0'),
    month: padStart(profileForm.month, 2, '0'),
  })

  const profileForm = cleanupBirthdate(originalProfileForm)

  dispatch({ type: POST_PROFILE_FORM_REQUEST })

  return api
    .postProfileForm(profileForm)
    .then(({ isUserForTgeRedirection }) => {
      dispatch({ type: POST_PROFILE_FORM_SUCCESS })
      dispatch(toProfile({ step: 5 }))
      if (isUserForTgeRedirection) dispatch(openModal('TGE_REDIRECTION'))
    })
    .catch(error => {
      captureException(error)
      dispatch({ type: POST_PROFILE_FORM_FAILURE, error: error.message })
    })
}

export const fetchAllowedCountries = () => dispatch => {
  dispatch({ type: FETCH_ALLOWED_COUNTRIES_REQUEST })

  return api
    .fetchAllowedCountries()
    .then(payload =>
      dispatch({ type: FETCH_ALLOWED_COUNTRIES_SUCCESS, payload })
    )
    .catch(error => {
      captureException(error)
      dispatch({ type: FETCH_ALLOWED_COUNTRIES_FAILURE, error: error.message })
    })
}
