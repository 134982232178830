import find from 'lodash/find'

const selectProjectFromLocation = ({
  location: {
    payload: { slug },
  },
  projects: { items },
}) => find(items, project => project.slug === slug)

export default selectProjectFromLocation
