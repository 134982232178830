import { connect } from 'react-redux'

import App from './App'
import auth from 'app/auth'
import { openModal } from 'store/ui/uiActions'
import { publicRoutes } from 'routesMap'
import selectIdTokenNamePhotoEmail from 'store/selectors/selectIdTokenNamePhotoEmail'

const mapStateToProps = state => {
  const route = state.location.type
  const isUserAuthenticated =
    auth.isAuthenticated() && selectIdTokenNamePhotoEmail(state)
  const isRoutePublic = publicRoutes.includes(route)

  return {
    isAuthenticationRequired: !isUserAuthenticated && !isRoutePublic,
    scene: route,
  }
}

const mapDispatchToProps = dispatch => ({
  logoutUsersWithExpiredTokens: () =>
    !auth.isAuthenticated() &&
    dispatch(auth.logout()) &&
    dispatch(openModal('SESSION_ENDED')),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
