import React, { Component } from 'react'
import loading from './loading.svg'
import styled from 'styled-components/macro'

const Load = styled.img`
  height: 30px;
`
class Loading extends Component {
  render() {
    return <Load src={loading} alt="loading" />
  }
}

export default Loading
