import Link from 'redux-first-router-link'
import React from 'react'
import styled from 'styled-components/macro'

import { toProfile } from '../../../../../store/routerActions'

import {
  Badge,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap'

import isEmpty from 'lodash/isEmpty'

import DeleteUserProfileModal from '../modals/DeleteUserProfileModalContainer'

import BodyContainer from 'app/components/BodyContainer'
import Wrapper from 'app/components/Wrapper'
import UserProfileStatus from 'app/components/UserProfileStatus'
import DateWrapper from 'app/components/DateWrapper'

import TermsOfUse from '../forms/TermsOfUseContainer'

import fundsOriginOptions from '../../../../scenes/profile/components/forms/data/fundsOriginOptions'
import incomeOptions from '../../../../scenes/profile/components/forms/data/incomeOptions'
import tradingVolumeOptions from '../../../../scenes/profile/components/forms/data/tradingVolumeOptions'

const FullName = styled.div`
  color: ${props => props.theme.color.dark};
  font-size: ${props => props.theme.font.xxl};
  text-transform: capitalize;
`
const LightText = styled.div`
  color: ${props => props.theme.color.steel};
  font-size: ${props => props.theme.font.sm};
`
const EthereumAddress = styled(LightText)`
  font-size: ${props => props.theme.font.tiny};
  font-size: 2.5vw;
  @media (min-width: ${props => props.theme.mediaQueries.sm}) {
    font-size: ${props => props.theme.font.sm};
  }
`

const ProfilePhoto = styled.img`
  border-radius: 50%;
  height: 58px;
  margin: 0 auto;
  width: 58px;
`
const HeaderInfo = styled.div`
  font-size: ${props => props.theme.font.xs};
  text-align: center;
  width: 100%;
  @media (min-width: 582px) {
    /* Custom Media Query to match line break of the text wrap. BS4 couldn't offer the same finished look */
    width: auto;
    margin-left: 15px;
    text-align: left;
  }
`
const MoreActions = styled(UncontrolledDropdown)`
  &&&& {
    font-size: ${props => props.theme.font.sm};
    padding: 0px;
    i {
      color: ${props => props.theme.color.iconGrey};
      &:hover {
        color: ${props => props.theme.color.light};
      }
    }

    .btn-secondary {
      background-color: transparent;
      border-color: transparent;
    }

    .btn-secondary:focus {
      background-color: transparent;
      box-shadow: none;
    }

    .btn-secondary:active {
      background-color: transparent;
      box-shadow: none;
    }

    .dropdown-toggle::after {
      display: none;
    }

    .dropdown-item:active {
      background-color: transparent;
      border: none;
      box-shadow: none;
    }

    .button:focus {
      outline: none;
    }

    .dropdown-item {
      cursor: pointer;
    }
  }
`

const Seperator = styled.div`
  border-bottom: 0.07rem dashed #ebedf2;
  margin: 15px -30px;
`
const Title = styled.h4`
  margin: 30px 0;
`
const Label = styled.span`
  color: ${props => props.theme.color.steel};
  font-size: ${props => props.theme.font.s12};
`
const Value = styled.span`
  color: black;
  margin-bottom: 30px;
`

const presentFundsOrigin = value =>
  fundsOriginOptions.find(o => String(o.value) === String(value)).label

const presentTradingVolume = value =>
  tradingVolumeOptions.find(o => String(o.value) === String(value)).label

const presentAnnualIncome = value =>
  incomeOptions.find(o => String(o.value) === String(value)).label

const UserProfile = ({
  allowedNationalities,
  allowedResidencyCountries,
  isUserRegistered,
  isTermsOfUseAccepted,
  onRequestAccountDeletion,
  onDownloadMyData,
  user,
  userPhoto,
}) => (
  <Wrapper>
    <DeleteUserProfileModal />
    <BodyContainer>
      {isUserRegistered ? (
        <div>
          <div className="row  align-items-center justify-content-between">
            <div className="col-10 col-sm-2 d-flex align-self-center">
              <ProfilePhoto src={userPhoto} />
            </div>
            <div className="col-10 col-sm-9">
              <HeaderInfo>
                <FullName>
                  {user.firstName} {user.lastName}{' '}
                  <div className="w-100 d-block d-sm-none" />
                  <UserProfileStatus status={user.status} />
                </FullName>
                <LightText>{user.email}</LightText>
                <EthereumAddress>{user.ethereumAddress}</EthereumAddress>
              </HeaderInfo>
            </div>

            <div className="col-2 col-sm-1">
              <MoreActions>
                <DropdownToggle caret>
                  <i className="icon-dotsvert" />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={onDownloadMyData}>
                    Download my data
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={onRequestAccountDeletion}>
                    <h5>
                      <Badge color="danger">DELETE MY ACCOUNT</Badge>
                    </h5>
                  </DropdownItem>
                </DropdownMenu>
              </MoreActions>
            </div>
          </div>

          <Seperator />
          <Title className="col-12">Personal Information</Title>
          <div className="row col-12">
            <div className="col-12 col-md-6">
              <div className="row">
                <Label className="col-12">Date of birth</Label>
                <Value className="col-12">{user.birthDate}</Value>
                <Label className="col-12">Phone Number</Label>
                <Value className="col-12">{user.phoneNumber}</Value>
                <Label className="col-12">Residency</Label>
                <Value className="col-12">
                  {user.residencyStreet}
                  <br />
                  {user.residencyCity}, {user.residencyState},{' '}
                  {user.residencyZipcode}
                  <br />
                  {!isEmpty(allowedResidencyCountries)
                    ? allowedResidencyCountries.find(
                        obj => obj.value === user.residencyCountry
                      ).label
                    : user.residencyCountry}
                </Value>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="row">
                {' '}
                <Label className="col-12">Member Since</Label>
                <Value className="col-12">
                  <DateWrapper value={user.createdAt} />
                </Value>
                <Label className="col-12">Nationality</Label>
                <Value className="col-12">
                  {!isEmpty(allowedNationalities)
                    ? allowedNationalities.find(
                        obj => obj.value === user.nationality
                      ).label
                    : user.nationality}
                </Value>
                <Label className="col-12">Profession</Label>
                <Value className="col-12">{user.profession}</Value>
                <Label className="col-12">Profession Sector</Label>
                <Value className="col-12">{user.professionSector}</Value>
              </div>
            </div>
            <Title className="col-12">Additional Information</Title>
            <div className="col-12">
              <div className="row">
                <Label className="col-12">Annual Income</Label>
                <Value className="col-12">
                  {presentAnnualIncome(user.annualIncome)}
                </Value>
                <Label className="col-12">
                  Origin of funds used in the business relationship with
                  Starbase PTE LTD.
                </Label>
                <Value className="col-12">
                  {presentFundsOrigin(user.fundsOrigin)}
                </Value>
                <Label className="col-12">Origin of funds description</Label>
                <Value className="col-12">{user.fundsDescription}</Value>
                <Label className="col-12">
                  Average Monthly Trading Volume in USD
                </Label>
                <Value className="col-12">
                  {presentTradingVolume(user.tradingVolume)}
                </Value>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center" style={{ padding: '100px 0' }}>
          <h4>Before we create your profile</h4>
          <p className="mb-4">
            Please read through and understand the entire Terms of Use to
            continue.
          </p>
          <TermsOfUse />
          <div className="col-12 text-center">
            <Link to={toProfile()}>
              <Button
                color="primary"
                className="mt-4"
                size="lg"
                disabled={!isTermsOfUseAccepted}
              >
                Create your profile
              </Button>
            </Link>
          </div>
        </div>
      )}
    </BodyContainer>
  </Wrapper>
)

export default UserProfile
