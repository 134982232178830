import React from 'react'
import Link from 'redux-first-router-link'

import { toProfile, toProject, toProjects } from 'store/routerActions'

import BodyContainer from 'app/components/BodyContainer'
import MetaMaskErrorModals from 'app/components/metaMaskErrorModals/MetaMaskErrorModalsContainer'
import ProtectedPageModal from 'app/components/ProtectedPageModal'
import Wrapper from 'app/components/Wrapper'

import Form from './Form'
import PayStatusModal from './PayStatusModalContainer'

const Pay = ({
  fiatRates,
  isFieldPurchaseValueEnabled,
  isUserAccepted,
  isUserRegistered,
  onSubmit,
  payForm,
  payStatus,
  project,
  sale,
  useEth,
  useStar,
}) => (
  <div>
    <MetaMaskErrorModals />

    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to={toProjects()}>Projects</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to={toProject(project)}>{project.name}</Link>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Contribute
        </li>
      </ol>
    </nav>

    <Wrapper>
      <ProtectedPageModal title="Access Denied" isOpen={!isUserRegistered}>
        <p>
          In order to be able to buy tokens for this project, you need first to
          complete the registration form.
        </p>
        <p>
          <Link className="btn btn-primary" to={toProfile()}>
            To the registration form
          </Link>
        </p>
      </ProtectedPageModal>

      <ProtectedPageModal
        title="Access Denied"
        isOpen={isUserRegistered && !isUserAccepted}
      >
        <p>
          Your registration is currently being reviewed. You'll be able to
          contribute to projects as soon as it is accepted. Please keep an eye
          on your emails, you will receive a notification as soon as it happens.
        </p>
      </ProtectedPageModal>

      <PayStatusModal />

      <BodyContainer>
        {sale ? (
          <Form
            fiatRates={fiatRates}
            isFieldPurchaseValueEnabled={isFieldPurchaseValueEnabled}
            onSubmit={onSubmit}
            payForm={payForm}
            payStatus={payStatus}
            project={project}
            sale={sale}
            useEth={useEth}
            useStar={useStar}
          />
        ) : (
          <div>Error: There is no active token sale for this project.</div>
        )}
      </BodyContainer>
    </Wrapper>
  </div>
)

export default Pay
