import styled from 'styled-components'

export default styled.span.attrs({
  className: 'badge mb-2 badge-success text-uppercase',
})`
  && {
    padding: 0.5rem 1rem;
    padding-top: 0.6rem;
    font-size: ${props => props.theme.font.lg};
  }
`
