import React, { useState } from 'react'
import Link from 'redux-first-router-link'
import classnames from 'classnames'
import {
  toCreateBountyDefinition,
  toCreateBountyTask,
} from 'store/routerActions'

import { deleteBountyTask } from 'store/project/projectActions'
import store from 'store/store'

const BountyTaskStatusBadge = ({ type }) => (
  <>
    {type === 'NORMAL' && <span class="badge badge-primary">{type}</span>}

    {type === 'SOCIAL_FACEBOOK_SHARE' && (
      <span class="badge badge-info">{type}</span>
    )}

    {type === 'SOCIAL_TWITTER_SHARE' && (
      <span class="badge badge-info">{type}</span>
    )}
  </>
)

const BountyTaskDetails = ({
  bounty: { id, name, type, maxRewardAmount, description },
}) => {
  async function onClickDelete() {
    const isConfirmed = window.confirm(
      'You can only delete this bounty task if it has not been claim by an user.'
    )
    if (isConfirmed) {
      await store.dispatch(deleteBountyTask(id))
    }
  }

  return (
    <div class="card mt-3 mb-3">
      <div class="card-header d-flex justify-content-between align-items-center bg-secondary">
        <div>
          Name: <b>{name}</b>
        </div>

        <div>
          Type: <BountyTaskStatusBadge type={type} />
        </div>

        <div>
          Max. Reward Amount:{' '}
          <span class="badge badge-primary">{maxRewardAmount}</span>
        </div>

        <button class="btn btn-danger" onClick={onClickDelete}>
          <i class="far fa-trash-alt" /> Delete
        </button>
      </div>

      <div class="card-body">
        Description: <b>{description}</b>
      </div>
    </div>
  )
}

const BountyDefinitionDetails = ({
  slug,
  definition: {
    id,
    name,
    tokenSymbol,
    startDate,
    endDate,
    rewardCap,
    currentRewardCap,
    bonusTokenAddress,
    maxClaimsPerUser,
    isFinished,
  },
  bounties,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const percentage = (currentRewardCap / rewardCap) * 100

  return (
    <>
      <div class="card card-fluid">
        <div class="card-header">
          <h6 class="mb-0">
            Campaigns overview: <strong>{name}</strong>
          </h6>
        </div>
        <div class="card-body py-3 flex-grow-1">
          <div class="pb-3 mb-3 border-bottom">
            <div class="row align-items-center">
              <div class="col ml-n2">
                <div class="progress-wrapper">
                  <span class="progress-percentage">
                    <small class="font-weight-bold">Completed: </small>
                    {percentage}%{' '}
                  </span>
                  <div class="progress progress-md mt-2">
                    <div
                      class="progress-bar bg-success"
                      role="progressbar"
                      style={{
                        width: `${percentage}%`,
                      }}
                    />
                  </div>
                  <p class="font-weight-bold text-dark text-center">
                    {currentRewardCap} / {rewardCap} {tokenSymbol} tokens
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body py-0">
          <ul class="list-group list-group-flush">
            <li class="list-group-item px-0">
              <div class="row align-items-center">
                <div class="col-6">
                  <span class="form-control-label">
                    Bounty Contract Address:
                  </span>
                </div>
                <div class="col-6 text-right">
                  {bonusTokenAddress ? (
                    <span class="badge badge-light badge-pill">
                      {bonusTokenAddress}
                    </span>
                  ) : (
                    <span class="badge badge-danger badge-pill">
                      NOT DEPLOYED
                    </span>
                  )}
                </div>
              </div>
            </li>

            <li class="list-group-item px-0">
              <div class="row align-items-center">
                <div class="col-6">
                  <span class="form-control-label">Max. Claims Per User:</span>
                </div>
                <div class="col-6 text-right">
                  <span class="badge badge-light badge-pill">
                    {maxClaimsPerUser}
                  </span>{' '}
                  tokens
                </div>
              </div>
            </li>
            <li class="list-group-item px-0">
              <div class="row align-items-center">
                <div class="col-6">
                  <span class="form-control-label">Finished:</span>
                </div>
                <div class="col-6 text-right">
                  {isFinished ? (
                    <span class="badge badge-success badge-pill">Yes</span>
                  ) : (
                    <span class="badge badge-danger badge-pill">No</span>
                  )}
                </div>
              </div>
            </li>
            <li class="list-group-item px-0">
              <div class="row align-items-center">
                <div class="col-6">
                  <small>Start date:</small>
                  <div class="h6 mb-0">
                    {new Date(startDate).toLocaleString()}
                  </div>
                </div>
                <div class="col-6">
                  <small>End date:</small>
                  <div class="h6 mb-0">
                    {new Date(endDate).toLocaleString()}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="card-footer d-flex justify-content-between align-items-center bg-secondary">
          <h5>
            Tasks{' '}
            <Link
              className="btn btn-success"
              to={toCreateBountyTask({ slug, bountyDefinitionId: id })}
            >
              Create Bounty Task
            </Link>
          </h5>

          <div>
            <i
              className={classnames(
                'fa mr-2',
                isOpen ? 'fa-chevron-up' : 'fa-chevron-down'
              )}
              onClick={() => setIsOpen(!isOpen)}
            />
          </div>
        </div>
      </div>

      {isOpen && (
        <>
          {bounties.length > 0 ? (
            <>
              {bounties.map((bounty, index) => (
                <BountyTaskDetails key={index} bounty={bounty} />
              ))}
            </>
          ) : (
            <div class="card mt-3 mb-3">
              <div class="card-header d-flex justify-content-between align-items-center bg-secondary">
                <p>You don't have any bounty task</p>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default ({ campaigns, project }) => {
  const hasBountyCampaigns = campaigns.length > 0
  return (
    <div>
      {hasBountyCampaigns ? (
        <>
          {campaigns.map(campaign => (
            <BountyDefinitionDetails
              key={campaign.name}
              definition={campaign.bountyDefinition}
              bounties={campaign.bounties}
              slug={project.slug}
            />
          ))}
        </>
      ) : (
        <div className="jumbotron">
          <p className="lead text-center">
            You don't have any bounty campaign, create one now!
          </p>
          <hr className="my-4" />
          <div className="row">
            <Link
              className="btn btn-success mx-auto"
              to={toCreateBountyDefinition(project.slug)}
            >
              Create Bounty Campaign
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}
