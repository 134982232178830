import React from 'react'

const ContractsInfo = ({ sale }) => (
  <div className="text-center">
    <p>
      Sale contract address:
      <br />
      <a
        href={`https://etherscan.io/address/${
          sale.tokenSaleContractAddress
        }#readContract`}
        rel="noopener noreferrer"
        target="_blank"
      >
        {sale.tokenSaleContractAddress}
      </a>
    </p>

    <p>
      <b>{sale.tokenSymbol}</b> Token contract address:
      <br />
      <a
        href={`https://etherscan.io/token/${
          sale.tokenContractAddress
        }#readContract`}
        rel="noopener noreferrer"
        target="_blank"
      >
        {sale.tokenContractAddress}
      </a>
    </p>
  </div>
)

export default ContractsInfo
