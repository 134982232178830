import React from 'react'
import { Field, Form } from 'redux-form'

import { validateRequired, validateIsNumber } from 'formHelpers/formValidation'

import FieldText from 'app/components/forms/fields/FieldText'

export default ({ onSubmit }) => (
  <Form className="form-inline mb-3" onSubmit={onSubmit}>
    <label className="mb-5">Reward Amount:</label>
    <div className="form-group mx-sm-3 ">
      <Field
        component={FieldText}
        className="form-control"
        isTouched
        name="rewardAmount"
        placeholder="Insert amount"
        validate={[validateRequired, validateIsNumber]}
      />
    </div>
    <button type="submit" className="btn btn-success mb-5">
      Confirm
    </button>
  </Form>
)
