import { set } from 'lodash/fp'

import {
  APPROVAL_TRANSACTION_CONFIRMED,
  APPROVAL_TRANSACTION_PENDING,
  CLEAR_PAY_ERROR,
  METAMASK_APPROVE_TRANSACTION_START,
  METAMASK_APPROVE_TRANSACTION_SUCCESS,
  METAMASK_BUYTOKENS_TRANSACTION_START,
  METAMASK_BUYTOKENS_TRANSACTION_SUCCESS,
  POST_PENDING_PURCHASE_FAILURE,
  POST_PENDING_PURCHASE_REQUEST,
  POST_PENDING_PURCHASE_SUCCESS,
  PURCHASE_TOKENS_CHANGE_MODAL_STEP,
  PURCHASE_TOKENS_CLOSE_MODAL,
  PURCHASE_TOKENS_FAILURE,
  PURCHASE_TOKENS_START,
  PURCHASE_TOKENS_SUCCESS,
  PURCHASE_TOKENS_ACCEPTED,
  PURCHASE_TOKENS_REJECTED,
} from '../types'

const steps = [
  APPROVAL_TRANSACTION_CONFIRMED,
  APPROVAL_TRANSACTION_PENDING,
  METAMASK_APPROVE_TRANSACTION_START,
  METAMASK_APPROVE_TRANSACTION_SUCCESS,
  METAMASK_BUYTOKENS_TRANSACTION_START,
  METAMASK_BUYTOKENS_TRANSACTION_SUCCESS,
  POST_PENDING_PURCHASE_FAILURE,
  POST_PENDING_PURCHASE_REQUEST,
  POST_PENDING_PURCHASE_SUCCESS,
  PURCHASE_TOKENS_FAILURE,
  PURCHASE_TOKENS_START,
  PURCHASE_TOKENS_SUCCESS,
]

export const initialState = {
  error: null,
  spendTokensStatus: {
    completedSteps: {},
    error: null,
    isPending: false,
    isPayStatusModalOpen: false,
    payStatusModalStep: 'default',
    purchases: [],
  },
}

const payReducer = (state = initialState, { type, payload, error }) => {
  switch (type) {
    case PURCHASE_TOKENS_START:
      return {
        ...state,
        spendTokensStatus: {
          ...state.spendTokensStatus,
          error: null,
          isPayStatusModalOpen: true,
          isPending: true,
        },
      }

    case POST_PENDING_PURCHASE_SUCCESS:
      return {
        ...state,
        spendTokensStatus: {
          ...state.spendTokensStatus,
          purchases: [
            ...state.spendTokensStatus.purchases,
            {
              ...payload.purchase,
              isAccepted: false,
              isConfirmed: false,
            },
          ],
        },
      }

    case POST_PENDING_PURCHASE_FAILURE:
      return {
        ...state,
        spendStarTokensStatus: {
          ...state.spendStarTokensStatus,
          error,
        },
      }

    case PURCHASE_TOKENS_SUCCESS:
      return {
        ...state,
        spendTokensStatus: {
          ...state.spendTokensStatus,
          error: null,
          isPending: false,
        },
      }
    case PURCHASE_TOKENS_FAILURE:
      return {
        ...state,
        spendTokensStatus: {
          ...state.spendTokensStatus,
          error,
          isPending: false,
        },
      }

    case PURCHASE_TOKENS_ACCEPTED:
      return {
        ...state,
        spendTokensStatus: {
          ...state.spendTokensStatus,
          purchases: [
            ...new Set([
              ...state.spendTokensStatus.purchases.map(p =>
                p.id === payload.purchase.id
                  ? {
                      ...payload.purchase,
                      isAccepted: true,
                      isConfirmed: true,
                    }
                  : p
              ),
            ]),
          ],
        },
      }

    case PURCHASE_TOKENS_REJECTED:
      return {
        ...state,
        spendTokensStatus: {
          ...state.spendTokensStatus,
          purchases: [
            ...new Set([
              ...state.spendTokensStatus.purchases.map(p =>
                p.id === payload.purchase.id
                  ? {
                      ...payload.purchase,
                      isAccepted: false,
                      isConfirmed: true,
                    }
                  : p
              ),
            ]),
          ],
        },
      }

    case PURCHASE_TOKENS_CHANGE_MODAL_STEP:
      return {
        ...state,
        spendTokensStatus: {
          ...state.spendTokensStatus,
          payStatusModalStep: payload.step,
        },
      }

    case PURCHASE_TOKENS_CLOSE_MODAL:
      return {
        ...state,
        spendTokensStatus: {
          ...state.spendTokensStatus,
          isPayStatusModalOpen: false,
        },
      }

    case CLEAR_PAY_ERROR:
      return { ...state, error: null }

    default:
      return state
  }
}

const injectCompletedSteps = (state, action) => {
  if (action.type === PURCHASE_TOKENS_START) {
    const result = set(['spendTokensStatus', 'completedSteps'], {
      [action.type]: true,
    })(state)
    return result
  }

  if (action.type === PURCHASE_TOKENS_CLOSE_MODAL)
    return set(['spendTokensStatus', 'completedSteps'], {})(state)

  if (steps.includes(action.type))
    return set(['spendTokensStatus', 'completedSteps', action.type], true)(
      state
    )

  return state
}

export default (state, action) => {
  const originalResult = payReducer(state, action)
  const withCompletedSteps = injectCompletedSteps(originalResult, action)
  return withCompletedSteps
}
