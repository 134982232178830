import React from 'react'
import styled from 'styled-components/macro'
import Link from 'redux-first-router-link'

import logoWhite from '../../assets/images/logo-white.svg'

import Balances from './BalancesContainer'
import ProjectsHeader from './ProjectsHeader'
import BountiesHeader from './BountiesHeader'

import UserProfileMenu from './UserProfileMenuContainer'

const LogoDesktop = styled.img`
  height: 5.5rem;
  padding-right: 1rem;
  position: relative;
  top: -5px;
`
const LoginIcon = styled.i`
  color: ${({ theme }) => theme.color.white};
  cursor: pointer;
`
const DesktopView = styled.div`
  display: none;
  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    display: block;
  }
`
const MobileView = styled.div`
  display: block;
  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    display: none;
  }
`

const Login = styled.span`
  color: ${({ theme }) => theme.color.white};
  cursor: pointer;
  margin-right: 1.5rem;
`

const Header = ({
  isUserRegistered,
  isUserAuthenticated,
  login,
  projects,
  bounties,
  scene,
}) => {
  const addToken = () => {
    if (!window.ethereum) {
      alert('Please install MetaMask and try again!')
      return
    }

    try {
      window.web3.currentProvider.sendAsync(
        {
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: '0xf70a642bd387f94380ffb90451c2c81d4eb82cbc',
              symbol: 'STAR',
              decimals: '18',
              image: 'https://etherscan.io/token/images/starbase_28.png',
            },
          },
          id: Math.round(Math.random() * 100000),
        },
        (err, data) => {
          if (!err) {
            if (data.result) {
              console.log('Token added')
            } else {
              console.log(data)
              console.log('Some error')
            }
          } else {
            console.log(err.message)
          }
        }
      )
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <nav
        class="navbar navbar-main navbar-expand-lg navbar-dark bg-primary navbar-border"
        id="navbar-main"
      >
        <div class="container-fluid">
          <ul class="navbar-nav align-items-lg-center">
            <li class="nav-item">
              <Link to="/projects">
                <LogoDesktop src={logoWhite} />
              </Link>
            </li>
            <li class="border-top opacity-2 my-2" />

            <li class="nav-item ">
              <Link class="nav-link pl-lg-0" to="/projects">
                Projects
              </Link>
            </li>

            <li class="nav-item">
              <Link class="nav-link" to="/bounties">
                Bounties
              </Link>
            </li>

            {isUserRegistered && (
              <li class="nav-item">
                <Link class="nav-link" to="/dashboard">
                  Dashboard
                </Link>
              </li>
            )}

            <li class="nav-item">
              <a
                href="https://app.uniswap.org/#/swap?inputCurrency=0xf70a642bd387f94380ffb90451c2c81d4eb82cbc"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn--sm btn--primary type--uppercase"
                style={{
                  backgroundColor: 'deeppink',
                  borderColor: 'lightpink',
                  textTransform: 'uppercase',
                  color: 'white',
                }}
              >
                Swap
              </a>
            </li>

            <li class="nav-item">
              <Link onClick={addToken} className="nav-link">
                Add <u>STAR</u> to Metamask
              </Link>
            </li>
          </ul>

          <ul class="navbar-nav ml-lg-auto align-items-center d-lg-flex">
            {/* {process.env.REACT_APP_UNISWAP_ENABLE === 'true' &&
              isUserRegistered && (
                <li class="nav-item">
                  <SwapButton />
                </li>
              )} */}

            {isUserAuthenticated ? (
              <>
                <li class="nav-item">
                  <Balances />
                </li>
                <li class="nav-item">
                  <UserProfileMenu />
                </li>
              </>
            ) : (
              <li class="nav-item">
                <DesktopView>
                  <Login onClick={login}>Log in</Login>
                  <button className="btn btn-outline-light" onClick={login}>
                    Sign up
                  </button>
                </DesktopView>
                <MobileView>
                  <LoginIcon className="icon-login" onClick={login} />
                </MobileView>
              </li>
            )}
          </ul>
        </div>
      </nav>

      {scene === 'PROJECTS' && <ProjectsHeader projects={projects} />}
      {scene === 'BOUNTIES' && <BountiesHeader bounties={bounties} />}
    </>
  )
}

export default Header
