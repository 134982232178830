import {
  FETCH_STAKE_DEFINITION_FAILURE,
  FETCH_STAKE_DEFINITION_REQUEST,
  FETCH_STAKE_DEFINITION_SUCCESS,
  STAKE_TOKENS_CLOSE_MODAL,
  STAKE_TOKENS_CLOSE_USER_NOT_ACCEPTED_MODAL,
  STAKE_TOKENS_CLOSE_USER_NOT_AUTHENTICATED_MODAL,
  STAKE_TOKENS_CLOSE_USER_NOT_REGISTERED_MODAL,
  STAKE_TOKENS_OPEN_MODAL,
  STAKE_TOKENS_OPEN_USER_NOT_ACCEPTED_MODAL,
  STAKE_TOKENS_OPEN_USER_NOT_AUTHENTICATED_MODAL,
  STAKE_TOKENS_OPEN_USER_NOT_REGISTERED_MODAL,
  STAKE_TOKENS_START,
  FETCH_STAKE_NEW_POSITION_SUCCESS,
  WITHDRAW_STAKE_TOKENS_OPEN_MODAL,
  WITHDRAW_STAKE_TOKENS_CLOSE_MODAL,
} from 'store/types'

import * as api from 'api.js'
import * as web3Helpers from 'web3Helpers'
import captureException from 'sentryHelpers/captureException'
import insertStake from 'store/ranking/insertStake'
import previewStake from 'store/ranking/previewStake'
import withdrawStake from 'store/ranking/withdrawStake'

export const openStakeModal = () => dispatch => {
  dispatch({ type: STAKE_TOKENS_OPEN_MODAL })
  dispatch({ type: STAKE_TOKENS_START })
}

export const openStakeWithdrawModal = params => async dispatch => {
  const hasWithdrawnTokens = await web3Helpers.hasWithdrawnTokens(params)
  if (!hasWithdrawnTokens) {
    dispatch({ type: WITHDRAW_STAKE_TOKENS_OPEN_MODAL })
    dispatch(withdrawStake(params))
  } else {
    setTimeout(
      () => alert('Forbidden Action! You already has withdrawn your tokens.'),
      100
    )
  }
}

export const closeUserNotAcceptedModal = () => dispatch =>
  dispatch({ type: STAKE_TOKENS_CLOSE_USER_NOT_ACCEPTED_MODAL })

export const openUserNotAcceptedModal = () => dispatch =>
  dispatch({ type: STAKE_TOKENS_OPEN_USER_NOT_ACCEPTED_MODAL })

export const closeUserNotRegisteredModal = () => dispatch =>
  dispatch({ type: STAKE_TOKENS_CLOSE_USER_NOT_REGISTERED_MODAL })

export const openUserNotRegisteredModal = () => dispatch =>
  dispatch({ type: STAKE_TOKENS_OPEN_USER_NOT_REGISTERED_MODAL })

export const closeUserNotAuthenticatedModal = () => dispatch =>
  dispatch({ type: STAKE_TOKENS_CLOSE_USER_NOT_AUTHENTICATED_MODAL })

export const openUserNotAuthenticatedModal = () => dispatch =>
  dispatch({ type: STAKE_TOKENS_OPEN_USER_NOT_AUTHENTICATED_MODAL })

export const closeStakeModal = () => dispatch =>
  dispatch({ type: STAKE_TOKENS_CLOSE_MODAL })

export const closeStakeWithdrawModal = () => dispatch =>
  dispatch({ type: WITHDRAW_STAKE_TOKENS_CLOSE_MODAL })

export const confirmStake = stakeParams => dispatch =>
  dispatch(insertStake(stakeParams))

export const fetchStakeDefinition = ({ projectSlug }) => async dispatch => {
  dispatch({ type: FETCH_STAKE_DEFINITION_REQUEST })

  return api
    .fetchStakeDefinition({ projectSlug })
    .then(response => {
      dispatch({
        type: FETCH_STAKE_DEFINITION_SUCCESS,
        payload: response,
      })
    })
    .catch(error => {
      captureException(error)
      dispatch({
        type: FETCH_STAKE_DEFINITION_FAILURE,
        error: error.message,
      })
    })
}

export const fetchNewStakePosition = params => async dispatch => {
  const newRank = await previewStake(params)
  dispatch({
    type: FETCH_STAKE_NEW_POSITION_SUCCESS,
    payload: newRank,
  })
}

export const withdrawStakeTokens = params => async dispatch =>
  dispatch(withdrawStake(params))
