import get from 'lodash/get'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import PersonalInfoForm from './PersonalInfoForm'
import selectIdTokenNamePhotoEmail from '../../../../../store/selectors/selectIdTokenNamePhotoEmail'
import { toProfile } from '../../../../../store/routerActions'

const emptyObject = {}

const mapStateToProps = state => ({
  allowedNationalities: state.profile.allowedNationalities,
  isLegalPerson: get(state.form.profileForm, 'values.isLegalPerson') === 'true',
  isPep: get(state.form.profileForm, 'values.isPep') === 'true',
  isPepRelationshipOther:
    get(state.form.profileForm, 'values.pepRelationship') === 'Other',
  user: selectIdTokenNamePhotoEmail(state) || emptyObject,
})

const mapDispatchToProps = dispatch => ({
  onSubmit: () => dispatch(toProfile({ step: 2 })),
})

const mergeProps = (
  { allowedNationalities, isLegalPerson, isPep, isPepRelationshipOther, user },
  { onSubmit },
  { handleSubmit, ...ownProps }
) => ({
  ...ownProps,
  allowedNationalities,
  isLegalPerson,
  isPep,
  isPepRelationshipOther,
  user,
  onSubmit: handleSubmit(onSubmit),
})

export default reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  form: 'profileForm',
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(PersonalInfoForm)
)
