import React from 'react'
import styled from 'styled-components/macro'

import formatDate from 'app/scenes/helpers/formatDate'

import TableRow from 'app/components/TableRow'
import MobileLabel from 'app/components/MobileLabel'

import VoucherStatusBadge from './VoucherStatusBadge'

const CurrencyBadge = styled.small.attrs({
  className: 'badge badge-dark',
})`
  && {
    font-size: ${props => props.theme.font.sm};
    padding: 12px 5px;
  }
`

const TableRowWrapper = ({
  reward: { index, code, rewardAmount, status, tokenSymbol, createdAt },
}) => (
  <TableRow
    className="row align-items-center justify-content-start"
    key={index}
  >
    <div className="col-6 col-sm-3">
      <MobileLabel className="d-block d-md-block">Code</MobileLabel>
      {code}
    </div>

    <div className="col-6 col-sm-3">
      <MobileLabel className="d-block d-md-block">Amount</MobileLabel>
      <CurrencyBadge>
        {rewardAmount} {tokenSymbol}
      </CurrencyBadge>
    </div>

    <div className="col-12 col-sm-2">
      <MobileLabel className="d-block d-md-block">Status</MobileLabel>
      <VoucherStatusBadge status={status} />
    </div>

    <div className="col-6 col-sm-4">
      <MobileLabel className="d-block d-md-block">Date</MobileLabel>
      {formatDate(createdAt)}
    </div>
  </TableRow>
)

const VoucherRewards = ({ rewards: { asBuyer, asVoucherOwner } }) => (
  <>
    <div className="mb-5">
      <h5>Your voucher codes</h5>

      {asVoucherOwner.length === 0 ? (
        <div className="alert alert-light text-center" role="alert">
          No rewards were found
        </div>
      ) : (
        <>
          {asVoucherOwner.map((reward, index) => (
            <TableRowWrapper key={index} reward={{ ...reward, index }} />
          ))}
        </>
      )}
    </div>

    <div>
      <h5>Used voucher codes</h5>

      {asBuyer.length === 0 ? (
        <div className="alert alert-light text-center" role="alert">
          No rewards were found
        </div>
      ) : (
        <>
          {asBuyer.map(reward => (
            <TableRowWrapper key={reward.id} reward={reward} />
          ))}
        </>
      )}
    </div>
  </>
)

export default VoucherRewards
