import {
  CLEAR_ALL_BOUNTIES_ERROR,
  CLEAR_ALL_PROJECT_ERROR,
  CLEAR_ALL_VOUCHERS_ERROR,
  CLEAR_COMMENTS_ERROR,
  CLEAR_CURRENT_USER_ERROR,
  CLEAR_DASHBOARD_ERROR,
  CLEAR_METAMASK_ERROR,
  CLEAR_PAY_ERROR,
  CLEAR_PROFILE_ERROR,
  CLEAR_PROJECTS_ERROR,
  CLEAR_PROJECT_BOUNTIES_ERROR,
  CLOSE_ALERT,
  CLOSE_MODAL,
  FORGET_MODAL,
  OPEN_MODAL,
  TOGGLE_USER_DROPDOWN_MENU,
} from '../types'

export const toggleUserDropdownMenu = () => ({
  type: TOGGLE_USER_DROPDOWN_MENU,
})

export const closeModal = name => ({ type: CLOSE_MODAL, payload: name })
export const forgetModal = name => ({ type: FORGET_MODAL, payload: name })
export const openModal = name => ({ type: OPEN_MODAL, payload: name })

export const closeAlert = () => dispatch => {
  dispatch({ type: CLOSE_ALERT })
  dispatch({ type: CLEAR_ALL_BOUNTIES_ERROR })
  dispatch({ type: CLEAR_COMMENTS_ERROR })
  dispatch({ type: CLEAR_CURRENT_USER_ERROR })
  dispatch({ type: CLEAR_DASHBOARD_ERROR })
  dispatch({ type: CLEAR_METAMASK_ERROR })
  dispatch({ type: CLEAR_PAY_ERROR })
  dispatch({ type: CLEAR_PROFILE_ERROR })
  dispatch({ type: CLEAR_PROJECTS_ERROR })
  dispatch({ type: CLEAR_PROJECT_BOUNTIES_ERROR })
  dispatch({ type: CLEAR_ALL_VOUCHERS_ERROR })
  dispatch({ type: CLEAR_ALL_PROJECT_ERROR })
}
