import React from 'react'
import styled from 'styled-components/macro'
import { FormattedNumber } from 'react-intl'

import Section from './Section'

const BalanceCard = styled.div`
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  background-color: ${props =>
    props.tokenSymbol === 'STAR'
      ? props.theme.color.primaryPurple
      : props.tokenSymbol === 'ETH'
      ? props.theme.color.darker
      : props.theme.color.lighter};
  color: ${props =>
    props.tokenSymbol === 'STAR'
      ? props.theme.color.white
      : props.tokenSymbol === 'ETH'
      ? props.theme.color.white
      : props.theme.color.darker};
  border-radius: 4px;
  height: 100%;
`
const BalanceCardName = styled.div`
  font-weight: bolder;
`
const BalanceCardImage = styled.div`
  margin-right: -20px;
  img {
    max-height: 64px;
  }
`

const BalanceCardBalance = styled.div`
  font-size: ${props => props.theme.font.lg};
`

const Balances = ({ balances, isLoading }) => (
  <>
    <h4>Balance</h4>
    {isLoading ? (
      <div className="alert alert-light text-center p-5" role="alert">
        Loading...
      </div>
    ) : (
      <Section className="row">
        {balances.map(token => (
          <div
            className="col-12 col-sm-6 col-md-4 mb-4"
            key={token.tokenSymbol}
          >
            <BalanceCard tokenSymbol={token.tokenSymbol}>
              <div className="row justify-content-center  align-items-center">
                <BalanceCardImage className="col-auto">
                  <img
                    alt="token"
                    className="rounded-circle"
                    src={token.project.tokenIconUrl}
                  />
                </BalanceCardImage>
                <div className="col px-4">
                  <BalanceCardName>{token.project.name}</BalanceCardName>
                  <BalanceCardBalance>
                    <FormattedNumber
                      maximumFractionDigits={8}
                      minimumFractionDigits={2}
                      value={parseFloat(token.amount).toFixed(8)}
                    />{' '}
                    {token.tokenSymbol}
                  </BalanceCardBalance>
                </div>
              </div>
            </BalanceCard>
          </div>
        ))}
      </Section>
    )}
  </>
)

export default Balances
