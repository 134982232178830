import React from 'react'
import styled from 'styled-components/macro'

import BodyContainer from '../../components/BodyContainer'
import Wrapper from '../../components/Wrapper'

import ProjectListItem from './ProjectListItemLarge'
import SubmitProjectListItem from './SubmitProjectListItemLarge'

const Row = styled.div.attrs({
  className: 'row',
})`
  margin-bottom: 1em;
`

const Loader = ({ children, isLoading }) =>
  isLoading ? (
    <div style={{ padding: '20vh 0', textAlign: 'center' }}>Loading...</div>
  ) : (
    children
  )

const submitProjectUrl = 'https://bit.ly/Starbase_Submission'

const Projects = ({ isLoading, projects }) => (
  <Loader isLoading={isLoading}>
    <Row>
      {!!projects.length ? (
        <>
          {projects.map(project => (
            <div className="col-xl-4 col-lg-4 col-sm-6 mb-3" key={project.slug}>
              <ProjectListItem project={project} />
            </div>
          ))}
          <div className="col-12" key="SubmitProjectListItem">
            <SubmitProjectListItem submitProjectUrl={submitProjectUrl} />
          </div>
        </>
      ) : (
        <Wrapper style={{ width: '100%' }}>
          <BodyContainer>
            <div className="row justify-content-center">
              <div className="col-12 col-md-8 col-lg-8">
                <div className="text-center " style={{ padding: '100px 0' }}>
                  <h3>Project submission</h3>
                  <br />
                  <p>
                    <a
                      className="btn btn-primary btn-lg"
                      href={submitProjectUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Submit your idea!
                    </a>
                    <br />
                    <small>(external link)</small>
                  </p>
                  <br />
                  <p>
                    Projects will be listed here once reviewed by our team and
                    advisors.
                  </p>
                  <p style={{ lineHeight: '1.3' }}>
                    <small>
                      Know anyone who has a great project idea? <br /> Now is a
                      great time to share this with them!
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </BodyContainer>
        </Wrapper>
      )}
    </Row>
  </Loader>
)

export default Projects
