import { connect } from 'react-redux'
import has from 'lodash/has'
import get from 'lodash/get'

import auth from '../../auth'
import { toggleUserDropdownMenu } from '../../../store/ui/uiActions'
import { toUserProfile } from '../../../store/routerActions'
import selectIdTokenNamePhotoEmail from '../../../store/selectors/selectIdTokenNamePhotoEmail'

import UserProfileMenu from './UserProfileMenu'

const mapStateToProps = state => ({
  user: selectIdTokenNamePhotoEmail(state),
  isVisible: state.ui.header.userDropdownMenu,
  hasProject: has(state, 'currentUser.user.projectOwned'),
  projectOwnedSlug: get(state, 'currentUser.user.projectOwned.slug', null),
})

const mapDispatchToProps = dispatch => ({
  toggleDropdown: () => dispatch(toggleUserDropdownMenu()),
  toUserProfile: () => dispatch(toUserProfile()),
  logout: () => dispatch(auth.logout()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileMenu)
