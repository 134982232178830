import {
  FETCH_FIAT_RATES_REQUEST,
  FETCH_FIAT_RATES_SUCCESS,
  FETCH_FIAT_RATES_FAILURE,
} from '../types'

import captureException from '../../sentryHelpers/captureException'
import { authorizedGet } from '../../api'

export const fetchFiatRates = () => dispatch => {
  dispatch({ type: FETCH_FIAT_RATES_REQUEST })

  return authorizedGet({ url: '/api/fiatRates' })
    .then(payload => dispatch({ type: FETCH_FIAT_RATES_SUCCESS, payload }))
    .catch(error => {
      captureException(error)
      dispatch({ type: FETCH_FIAT_RATES_FAILURE, error: error.message })
    })
}
