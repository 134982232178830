// IMPORTANT NOTE:
// Both tradingVolumeOptions.js in frontend and backend need to be kept identical.
// If you modify this file, be sure to apply the same changes to the other one.

export default [
  { value: '1', label: '< $1000' },
  { value: '2', label: '$1.000 – $10.000' },
  { value: '3', label: '$10.000 – $50.000' },
  { value: '4', label: '$50.000 – $100.000' },
  { value: '5', label: '> $100.000' },
]
