import {
  CLEAR_METAMASK_ERROR,
  SET_METAMASK_DATA,
  SET_METAMASK_FAILURE,
  SET_METAMASK_LOADED,
} from '../types'

const initialState = {
  account: null,
  error: null,
  isLoading: true,
  network: null,
}

export default (state = initialState, { type, payload, error }) => {
  switch (type) {
    case SET_METAMASK_DATA:
      return {
        ...state,
        account: payload.account ? payload.account : state.account,
        network: payload.network ? payload.network : state.network,
      }

    case SET_METAMASK_LOADED:
      return { ...state, isLoading: false }

    case SET_METAMASK_FAILURE:
      return { ...state, error }

    case CLEAR_METAMASK_ERROR:
      return { ...state, error: null }

    default:
      return state
  }
}
