import React from 'react'
import { connect } from 'react-redux'

import selectIsUserRegistered from 'store/selectors/selectIsUserRegistered'
import selectIsUserAccepted from 'store/selectors/selectIsUserAccepted'

import InfoBlockButtons from './InfoBlockButtons'
import InfoBlockTop from './InfoBlockTop'

import Rating from 'app/components/rating/RatingContainer'

const mapStateToProps = (
  { ranking: { stakeDefinitions }, ...state },
  { project, tokenSale }
) => {
  return {
    isUserAccepted: selectIsUserAccepted(state),
    isUserRegistered: selectIsUserRegistered(state),
    project,
    tokenSale,
    stakeDefinitions,
  }
}

export default connect(mapStateToProps)(
  ({
    isUserAccepted,
    isUserRegistered,
    project,
    tokenSale,
    stakeDefinitions,
  }) => (
    <>
      <InfoBlockTop
        project={project}
        tokenSale={tokenSale}
        stakeDefinitions={stakeDefinitions}
      />

      <InfoBlockButtons
        isUserRegistered={isUserRegistered}
        isUserAccepted={isUserAccepted}
        project={project}
      />

      <Rating project={project} />
    </>
  )
)
