import React from 'react'
import styled from 'styled-components/macro'

const Badge = styled.span.attrs({
  className: 'badge mb-2',
})`
  && {
    padding: 0.5rem 1rem;
    padding-top: 0.6rem;
    font-size: ${props => props.theme.font.lg};
  }

  &&.badge-warning {
    color: saddlebrown;
  }
`

const ProjectStatusBadge = ({ status, style }) => (
  <div style={style}>
    {status === 'UPCOMING' && <Badge className="badge-warning">UPCOMING</Badge>}
    {status === 'ONGOING' && <Badge className="badge-info">ONGOING</Badge>}
    {status === 'PAUSED' && <Badge className="badge-info">PAUSED</Badge>}
    {status === 'SOLDOUT' && <Badge className="badge-success">SOLD OUT</Badge>}
    {status === 'SUCCESSFUL' && (
      <Badge className="badge-success">SUCCESSFUL</Badge>
    )}
    {status === 'UNSUCCESSFUL' && (
      <Badge className="badge-danger">UNSUCCESSFUL</Badge>
    )}
    {status === 'CANCELLED' && (
      <Badge className="badge-danger">CANCELLED</Badge>
    )}
    {status === 'STAKING' && <Badge className="badge-success">STAKING</Badge>}
  </div>
)

export default ProjectStatusBadge
