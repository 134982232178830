import { find } from 'lodash'
const emptyObject = {}

const selectProjectBySlug = ({
  projects,
  location: {
    payload: { slug },
  },
}) => find(projects.items, project => project.slug === slug) || emptyObject

export default selectProjectBySlug
